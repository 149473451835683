import { Box, Breadcrumbs, Button, Grid, TextField, Typography, Table, Paper, TableContainer, TableHead, TableBody, TableCell, TableRow, IconButton, TablePagination } from "@mui/material";
import { FC, useState } from "react";
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import SearchIcon from '@mui/icons-material/Search';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SupplierTypeCreateView from "./SupplierTypeCreateView";
import { ISupplierType, ISupplierTypeCriteria, ISupplierTypeListView } from "../Services/SupplierTypeService";
import ListViewPage from "../../../components/ListViewPage";
import { rowPerPageOptions } from "../../../constants/viewOption";
import { IUserDetail } from "../../Login/Services/LoginService";
import dayjs from "dayjs";

interface SupplierTypeListViewProps {
  onSubmit: (data: ISupplierType) => void;
  onUpdate: (data: ISupplierType) => void;
  supplierTypes: ISupplierTypeListView;
  onSearchByCriteria: (criteria: ISupplierTypeCriteria) => void;
  isLoading?: boolean;
  permission: IUserDetail;
}

const SupplierTypeListView: FC<SupplierTypeListViewProps> = (props) => {
  const [name, setName] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [supplier, setSupplier] = useState<ISupplierType>();


  const searchByCiteria = (pageNumber: number = 0, rowLimit?: number) => {
    setPage(pageNumber === 0 ? 0 : pageNumber);
    setLimit(rowLimit ? rowLimit : 10);
    const criteria: ISupplierTypeCriteria = {
      name: name,
      page: pageNumber,
      limit: rowLimit ?? 10,
    };
    props.onSearchByCriteria(criteria);
  };

  const onSubmit = (data: ISupplierType) => {
    if (data.id) {
      props.onUpdate(data);
    } else {
      props.onSubmit(data);
    }
  };


  return (
    <ListViewPage
      breadcrumb={
        <>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Typography>ประเภท Supplier</Typography>
            <Typography variant="h6" color="text.primary">รายการ</Typography>
          </Breadcrumbs>
        </>
      }
      criteria={
        <>
          <Grid container spacing={2}>
            <Grid item xs={10} sm={10} md={10} lg={10}>
              <TextField fullWidth label="ประเภท Supplier" onChange={(e) => setName(e.target.value)}
              />
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} textAlign={"center"} alignSelf={"center"}>
              <Button
                variant="contained"
                onClick={() => searchByCiteria(0)}
                startIcon={<SearchIcon />}>ค้นหา
              </Button>
            </Grid>
          </Grid>
        </>
      }
      table={
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} >
              <Grid
                container
                justifyContent="space-between"
                alignItems="center" >
                <Grid item>
                  <Typography>ทั้งหมด {props.supplierTypes.total} รายการ</Typography>
                </Grid>
                <Grid item>
                  {props.permission.create &&
                    <Button
                      variant="contained"
                      startIcon={<NoteAddIcon />}
                      onClick={() => {
                        setSupplier({} as ISupplierType);
                        setIsOpen(true);
                      }}>สร้าง
                    </Button>
                  }
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={12} md={12} lg={12} mt={2}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>ประเภท Supplier</TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>บันทึกล่าสุด</TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>แก้ไขล่าสุด</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.supplierTypes.datas && props.supplierTypes.datas?.length > 0 ? props.supplierTypes.datas?.map((supplierType: ISupplierType) => {
                      return (
                        <TableRow key={supplierType.id}>
                          <TableCell>{supplierType.name}</TableCell>
                          <TableCell sx={{ textAlign: 'center' }}>
                            <Box component={'div'}>
                              <Box component={'div'}>{supplierType.createBy?.firstName || ""} {supplierType.createBy?.lastName || ""}</Box>
                              <Box component={'div'}>{dayjs(supplierType.createAt).format('DD/MM/YYYY') === "Invalid Date" ? "" :
                                <Box>
                                  <Box>{dayjs(supplierType.createAt).format('DD/MM/YYYY')}</Box>
                                  <Box>{dayjs(supplierType.createAt).format('HH:mm')}</Box>
                                </Box>
                              }</Box>
                            </Box>
                          </TableCell>
                          <TableCell sx={{ textAlign: 'center' }}>
                            <Box component={'div'}>
                              <Box component={'div'}>{supplierType.updateBy?.firstName || ""} {supplierType.updateBy?.lastName || ""}</Box>
                              <Box component={'div'}>{dayjs(supplierType.updateAt).format('DD/MM/YYYY') === "Invalid Date" ? "" :
                                <Box>
                                  <Box>{dayjs(supplierType.updateAt).format('DD/MM/YYYY')}</Box>
                                  <Box>{dayjs(supplierType.updateAt).format('HH:mm')}</Box>
                                </Box>
                              }</Box>
                            </Box></TableCell>
                          <TableCell sx={{ alignContent: 'flex-end', textAlign: 'end' }}>
                            <IconButton
                              onClick={() => {
                                setSupplier(supplierType);
                                setIsOpen(true);
                              }}>
                              <ChevronRightIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>);
                    }) : <TableRow>
                      <TableCell colSpan={4} sx={{ textAlign: 'center' }}>ไม่พบข้อมูล</TableCell>
                    </TableRow>}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box>
                <TablePagination
                  component="div"
                  rowsPerPageOptions={rowPerPageOptions}
                  count={props.supplierTypes.total ?? 0}
                  onPageChange={(e, newPage) => {
                    setPage(newPage);
                    searchByCiteria(newPage);
                  }}
                  page={page}
                  rowsPerPage={limit ? limit : 10}
                  onRowsPerPageChange={(e: any) => {
                    setLimit(e.target.value);
                    setPage(0);
                    searchByCiteria(page, e.target.value);
                  }} />
              </Box>
            </Grid>
          </Grid>
        </>
      }
      dialog={
        <>
          <SupplierTypeCreateView open={isOpen} onClose={() => { setIsOpen(false); }}
            onSubmit={(data: ISupplierType) => {
              onSubmit(data);
            }}
            data={supplier ?? {} as ISupplierType}
            key={supplier?.id}
            permission={props.permission} />
        </>
      }
      isLoading={props.isLoading ?? false}
    />
  );
};
export default SupplierTypeListView;