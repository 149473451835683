import { FC } from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface ImagePreviewProps {
  open: boolean;
  imageSrc: string | null;
  onClose: () => void;
}

const ImagePreview: FC<ImagePreviewProps> = (props) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle sx={{ py: 2.5 }}>
        <IconButton
          color="inherit"
          onClick={props.onClose}
          aria-label="close"
          sx={{
            position: 'absolute', top: 0, right: 0
          }}><CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 2 }}>
        {props.imageSrc && (
          <img
            src={props.imageSrc}
            alt="Preview"
            style={{ maxWidth: '100%', height: 'auto' }}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ImagePreview;
