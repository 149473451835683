import { useState } from 'react';

export const useFileManagement = () => {
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [images, setImages] = useState<any[]>([]);
  const [pdfFiles, setPdfFiles] = useState<any[]>([]);
  const [deleteImages, setDeleteImages] = useState<any[]>([]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      setSelectedFiles([...selectedFiles, ...Array.from(files)]);
    }
  };

  const handleFileRemove = (index: number) => {
    const _selectedFiles = [...selectedFiles];
    _selectedFiles.splice(index, 1);
    setSelectedFiles(_selectedFiles);
  };

  const handleFileRemoveImageList = (file: any) => {
    const _images = [...images];
    const index = _images.findIndex((img) => img.name === file.name);
    if (index > -1) {
      const deletedImage = _images[index];
      setDeleteImages((prev: any) => [...prev, { id: deletedImage.id, name: deletedImage.name }]);
      _images.splice(index, 1);
      setImages(_images);
    }
  };

  const handleFileRemovePdfList = (file: any) => {
    const _pdfFiles = [...pdfFiles];
    const index = _pdfFiles.findIndex((pdf) => pdf.name === file.name);
    if (index > -1) {
      const deletedFile = _pdfFiles[index];
      setDeleteImages((prev: any) => [...prev, { id: deletedFile.id, name: deletedFile.name }]);
      _pdfFiles.splice(index, 1);
      setPdfFiles(_pdfFiles);
    }
  };

  return {
    selectedFiles,
    images,
    pdfFiles,
    deleteImages,
    setImages,
    setPdfFiles,
    setSelectedFiles,
    setDeleteImages,
    handleFileChange,
    handleFileRemove,
    handleFileRemoveImageList,
    handleFileRemovePdfList,
  };
};
