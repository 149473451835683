import { Alert, Box, Checkbox, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from "@mui/material";
import { FC, MouseEvent, useState } from "react";
import { useForm } from "react-hook-form";
import { ILogin } from "../features/Login/Services/LoginService";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import LoadingButton from '@mui/lab/LoadingButton';

interface LoginFormProps {
  onSubmit?: (data: ILogin) => void;
  hasError?: boolean;
  hasSuccess?: boolean;
  isLoading?: boolean;
}

const LoginForm: FC<LoginFormProps> = (props) => {
  const { register, handleSubmit, getValues, formState: { errors } } = useForm<ILogin>();
  const [showPassword, setShowPassword] = useState(false);

  const onSubmit = handleSubmit((data: ILogin) => {
    if (props.onSubmit) {
      props.onSubmit(data);
    }
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <Box component={'form'} onSubmit={onSubmit}>
      <TextField fullWidth label="Email" variant="outlined" margin="normal" {...register("email", { required: true, pattern: /^\S+@\S+$/i })}
        error={!!errors.email}
        helperText={errors.email ? "กรุณาระบุ Email ของท่าน" : ""}
        InputProps={{
          readOnly: props.isLoading,
        }}
        InputLabelProps={{
          shrink: getValues("email") ? true : undefined,
        }} />
      <FormControl fullWidth variant="outlined">
        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
        <OutlinedInput
          id="outlined-adornment-password"
          required
          fullWidth
          label="Password"
          type={showPassword ? 'text' : 'password'}
          {...register("password", { required: true })}
          error={!!errors.password}
          endAdornment={(
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
                disabled={props.isLoading}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          )}
          inputProps={{
            readOnly: props.isLoading,
          }}
        />
      </FormControl>
      <FormControl fullWidth>
        <FormControlLabel
          control={<Checkbox color="primary" {...register("isRemember")} />}
          label="Remember me"
        />
      </FormControl>
      {props.hasError && <Alert sx={{ my: 1 }} severity="error">Email หรือ Password ไม่ถูกต้อง</Alert>}
      {props.hasSuccess && <Alert sx={{ my: 1 }} severity="success">เข้าสู่ระบบสำเร็จ</Alert>}
      {/* <Button type="submit" variant="contained" color="primary" fullWidth disabled={isLoading}>เข้าสู่ระบบ</Button> */}
      <LoadingButton
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
        loading={props.isLoading}
      >เข้าสู่ระบบ</LoadingButton>
    </Box>
  )
};

export default LoginForm;