import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { IUserDetail } from "../../SuperAdminManagement/Services/UserService";
import shippingTypeService, { IShippingType, IShippingTypeCriteria, IShippingTypeList } from "../Services/ShippingTypeService";
import getPermissionBySubMenu from "../../../utils/getPermissionBySubMenu";
import { enqueueSnackbar } from "notistack";
import ShippingTypeListView from "../Views/ShippingTypeListView";

const ShippingtypeController = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [permission, setPermission] = useState<IUserDetail>();
  const [data, setData] = useState<IShippingTypeList>();
  const defaultCriteria: IShippingTypeCriteria = {
    name: "",
    page: 0,
    limit: 10,
  };
  useEffect(() => {
    getByCriteria(defaultCriteria);
    checkPermissionActionButton();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getByCriteria = async (criteria: IShippingTypeCriteria) => {
    setIsLoading(true);
    shippingTypeService
      .getByCriteria(criteria)
      .then((res) => {
        setData(res);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const create = async (data: IShippingType) => {
    try {
      setIsLoading(true);
      const res = await shippingTypeService.create(data);
      if (res) {
        enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      } else {
        enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      }
      getByCriteria(defaultCriteria);
    } catch (error) {
      console.error("An error occurred while creating the shipping type:", error);
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const update = async (data: IShippingType) => {
    try {
      setIsLoading(true);
      const res = await shippingTypeService.update(data);
      if (res) {
        enqueueSnackbar("อัพเดทข้อมูลสำเร็จ", { variant: "success" });
      } else {
        enqueueSnackbar("อัพเดทข้อมูลไม่สำเร็จ", { variant: "error" });
      }
      getByCriteria(defaultCriteria);
    } catch (error) {
      console.error("An error occurred while updating the  shipping type:", error);
      enqueueSnackbar("อัพเดทข้อมูลไม่สำเร็จ", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };
  async function checkPermissionActionButton() {
    const result = getPermissionBySubMenu("setting-shipping-type");
    if (result) { setPermission(result); }
  }

  return <ShippingTypeListView
    isLoading={isLoading}
    datas={data ?? {} as IShippingTypeList}
    onSerchByCriteria={getByCriteria}
    onCreate={create}
    onUpdate={update}
    permission={permission ?? {} as IUserDetail}
  />;
});

export default ShippingtypeController;