import { FC, useState } from "react";
import { IUserDetail } from "../../SuperAdminManagement/Services/UserService";
import { IGradeListView, IGradeCriteria } from "../Services/GradeService";
import { IGrade } from "../Services/GradeService";
import { useForm } from "react-hook-form";
import { Box, Breadcrumbs, Button, Grid, IconButton, TextField, Typography } from "@mui/material";
import ListViewPage from "../../../components/ListViewPage";
import TableDataList from "../../../components/TableDataList";
// icon
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import SearchIcon from "@mui/icons-material/Search";
import GradeCreateView from "./GradeCreateView";
import dayjs from "dayjs";

interface GradeListViewProps {
  onSubmit: (data: IGrade) => void;
  onUpdate: (data: IGrade) => void;
  grades: IGradeListView;
  onSearchByCriteria: (criteria: IGradeCriteria) => void;
  isLoading?: boolean;
  permission: IUserDetail;
}

const GradeListView: FC<GradeListViewProps> = (props) => {
  const { register, getValues } = useForm<IGradeCriteria>({
    defaultValues: {
      name: '',
      page: 0,
      limit: 10,
    },
  });
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [gradeData, setGradeData] = useState<IGrade>();
  const [openCreate, setOpenCreate] = useState(false);

  const column = [
    { label: 'ชื่อ', render: (row: IGrade) => row.name },
    { label: 'Capacity', render: (row: IGrade) => `${row.capacityFrom ?? 'ไม่ระบุ'} - ${row.capacityTo ?? 'ไม่ระบุ'}` },
    { label: 'น้ำหนัก', render: (row: IGrade) => `${row.weightFrom || ""} - ${row.weightTo || ""}` },
    {
      label: 'บันทึกล่าสุด', render: (row: IGrade) => (
        <Box component={'div'}>
          <Box component={'div'}>{row.createBy?.firstName || ""} {row.createBy?.lastName || ""}</Box>
          <Box component={'div'}>{dayjs(row.createAt).format('DD/MM/YYYY') === "Invalid Date" ? "" :
            <Box>
              <Box>{dayjs(row.createAt).format('DD/MM/YYYY')}</Box>
              <Box>{dayjs(row.createAt).format('HH:mm')}</Box>
            </Box>
          }</Box>
        </Box>
      )
    },
    {
      label: 'แก้ไขล่าสุด', render: (row: IGrade) => (
        <Box component={'div'}>
          <Box component={'div'}>{row.updateBy?.firstName || ""} {row.updateBy?.lastName || ""}</Box>
          <Box component={'div'}>{dayjs(row.updateAt).format('DD/MM/YYYY') === "Invalid Date" ? "" :
            <Box>
              <Box>{dayjs(row.updateAt).format('DD/MM/YYYY')}</Box>
              <Box>{dayjs(row.updateAt).format('HH:mm')}</Box>
            </Box>
          }</Box>
        </Box>
      )
    },
  ];

  const actionRow = (row: IGrade) => (
    <IconButton
      onClick={() => {
        setGradeData(row);
        setOpenCreate(true);
      }}>
      <KeyboardArrowRightIcon />
    </IconButton>
  );

  const actionButtons = props.permission.create ? (
    <Button
      variant="contained"
      startIcon={<NoteAddIcon />}
      onClick={() => {
        setOpenCreate(true);
      }}>
      สร้าง
    </Button>
  ) : <></>;

  const searchByCriteria = (pageNumber: number = 0, rowLimit?: number) => {
    setPage(pageNumber);
    setLimit(rowLimit ? rowLimit : 10);
    const data = getValues();
    props.onSearchByCriteria({ ...data, page: pageNumber, limit: rowLimit ? rowLimit : 10 });
  };

  return (
    <ListViewPage
      breadcrumb={
        <>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Typography>เกรด</Typography>
            <Typography variant="h6" color="text.primary">รายการ</Typography>
          </Breadcrumbs>
        </>
      }
      criteria={
        <>
          <Box component="form">
            <Grid container spacing={2} alignItems={"end"}>
              <Grid item xs={12} sm={10} md={10} lg={10} >
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={12} lg={12} alignSelf={'flex-end'}>
                    <TextField
                      {...register("name")}
                      label="ชื่อ"
                      fullWidth
                    />
                  </Grid>

                </Grid>
              </Grid>
              <Grid item xs={12} sm={2} md={2} lg={2} textAlign={"end"} alignSelf={"end"}>
                <Button
                  onClick={() => searchByCriteria()}
                  variant="contained"
                  startIcon={<SearchIcon />}
                >
                  ค้นหา
                </Button>
              </Grid>
            </Grid>
          </Box>
        </>
      }
      table={
        <>
          <TableDataList
            data={props.grades.datas || []}
            total={props.grades.total}
            columns={column}
            actionButtons={actionButtons}
            rowActions={actionRow}
            page={page}
            limit={limit}
            onPageChange={(newPage) => {
              searchByCriteria(newPage);
            }}
            onRowsPerPageChange={(newLimit) => {
              setLimit(newLimit);
              setPage(0);
              searchByCriteria(0, newLimit);
            }}
          />
          <GradeCreateView
            data={gradeData ?? {} as IGrade}
            open={openCreate}
            onClose={() => {
              setOpenCreate(false);
              setGradeData(undefined);
            }}
            onSubmit={(data) => {
              if (data?.id) {
                props.onUpdate(data);
              } else { props.onSubmit(data); }
            }}
            permission={props.permission}
          />
        </>
      }
      isLoading={props.isLoading}
    />
  );
};

export default GradeListView;