import { ChangeEvent, FC, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Box, Grid, Breadcrumbs, Typography, Button, Card, CardContent, TextField, Autocomplete, Divider, Link, FormControlLabel, Switch } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import DialogConfirm from "../../../components/DialogConfirm";
import SimpleBackdrop from "../../../components/SimpleBackdrop";
import { ICompany, IUploadFile, IProvince, IDistrict, ISubDistrict } from "../Services/CompanyService";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DeleteIcon from "@mui/icons-material/Delete";
import { IUserDetail } from "../Services/UserService";

interface CompanyDetailViewProps {
  onUpdate: (id: string, data: ICompany, uploadFile: IUploadFile) => void;
  onCreate: (data: ICompany, uploadFile: IUploadFile) => Promise<void>;
  company?: ICompany, uploadFile: IUploadFile;
  provinceData?: IProvince[];
  districtData?: IDistrict[];
  subDistrictData?: ISubDistrict[];
  onFetchDistrict: (id: number | undefined) => void;
  onFetchSubDistrict: (id: number | undefined) => void;
  isLoading: boolean;
  permission: IUserDetail;
}
const CompanyDetailView: FC<CompanyDetailViewProps> = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { register, formState: { errors }, reset, getValues, handleSubmit, control, setValue, watch } = useForm<ICompany>({
    defaultValues: {
      province: null,
      district: null,
      subDistrict: null,
      isActive: true
    }
  });
  const path = window.location.pathname;
  const [openConfirm, setOpenConfirm] = useState({
    open: false,
    title: "",
    message: "",
    action: "create" as "create" | "edit" | "delete" | "warning",
    type: "success" as "success" | "error" | "warning",
    data: {}
  });
  //TODO: attach file
  const [selectedFile, setSelectedFile] = useState<any>();
  const handleSave = (data: ICompany) => {
    props.onCreate({ ...data, isAdmin: true, isDelete: !data.isActive }, selectedFile);
    reset();
  };

  const handleEdit = (data: ICompany) => {
    if (id) {
      props.onUpdate(id, { ...data, isDelete: !data.isActive }, selectedFile);
      reset();
    }
  };

  const handleDelete = (id: string) => {
    props.onUpdate(id, { isDelete: true, isActive: false }, selectedFile);
    reset();
  };
  watch();
  useEffect(() => {
    reset();
    if (props.company && id) {
      reset(props.company);
    }
  }, [props.company, reset, id]);

  const onSubmit = handleSubmit(() => {
    handleOpenConfirm(id ? "edit" : "create");
  });

  const handleOpenConfirm = (action: "create" | "edit" | "delete" | "warning" = "create") => {
    const data = getValues();
    setOpenConfirm({
      ...openConfirm,
      open: true,
      title: action !== "create" ? (action === "delete" ? "ลบข้อมูล" : "แก้ไขข้อมูล") : ("บันทึกข้อมูล"),
      message: action !== "create" ? (action === "delete" ? "คุณต้องการลบข้อมูลนี้ใช่หรือไม่" : "คุณต้องการแก้ไขข้อมูลนี้ใช่หรือไม่") : "คุณต้องการบันทึกข้อมูลนี้ใช่หรือไม่",
      type: action === "create" || action === "edit" ? "success" : (action === "delete" ? "error" : "warning"),
      action: action,
      data: data,
    });
  };

  const onConfirmSubmit = () => {
    if (openConfirm.action === "create") {
      handleSave(openConfirm.data);
    } else if (openConfirm.action === "edit") {
      handleEdit(openConfirm.data);
    } else if (openConfirm.action === "delete" && id) {
      handleDelete(id);
    }
    setOpenConfirm({ ...openConfirm, open: false });
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      setSelectedFile(e.target.files[0]);
    }
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  return (
    <Box component={"div"}>
      <Grid container >
        <Grid item xs={8.5} display={"flex"} alignItems={"center"}>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Typography>จัดการระบบ</Typography>
            <Link component="button" onClick={() => navigate("/admin-company")}>
              บริษัท
            </Link>
            <Typography variant="h6" color="text.primary">
              ข้อมูลบริษัท
            </Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12} sm={3} md={3} lg={3} display={"flex"} justifyContent={"end"}>
          {(props.company && path !== "/setting-company" && id && props.permission.delete) && (
            <Button
              variant="contained"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={() => handleOpenConfirm("delete")}
            >
              ลบข้อมูล
            </Button>
          )}
        </Grid>
      </Grid>
      <Box component={"form"} onSubmit={onSubmit}>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={2} mt={2}>
                  <Grid item xs={12} sm={12} md={8} lg={8}>
                    <TextField
                      label="ชื่อบริษัท"
                      fullWidth
                      {...register("name", { required: true })}
                      error={!!errors.name}
                      helperText={errors.name ? "กรุณาระบุชื่อบริษัท" : " "}
                      InputLabelProps={{
                        shrink: getValues("name") ? true : undefined,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <TextField
                      label="สาขา"
                      fullWidth
                      {...register("branch")}
                      InputLabelProps={{
                        shrink: getValues("branch") ? true : undefined,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4} sx={{ mt: { xs: 2, md: 0 } }}>
                    <TextField
                      label="เลขประจําตัวผู้เสียภาษี"
                      fullWidth
                      {...register("tax", { required: true })}
                      error={!!errors.tax}
                      helperText={errors.tax ? "กรุณาระบุเลขประจําตัวผู้เสียภาษี" : " "}
                      InputLabelProps={{
                        shrink: getValues("tax") ? true : undefined,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={8} lg={8}>
                    <TextField
                      label="ที่อยู่"
                      fullWidth
                      {...register("address", { required: true })}
                      error={!!errors.address}
                      helperText={errors.address ? "กรุณาระบุที่อยู่" : " "}
                      InputLabelProps={{
                        shrink: getValues("address") ? true : undefined,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Controller
                      name="province"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          value={field?.value as IProvince || null}
                          options={props.provinceData || []}
                          getOptionLabel={(option: any) => {
                            if (typeof option === 'string') {
                              return option;
                            }
                            if (option.inputValue) {
                              return option.inputValue;
                            }
                            return option.nameTh || "";
                          }}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          onChange={(event, newValue) => {
                            field.onChange(newValue);
                            if (newValue?.id) {
                              setValue("district", null);
                              setValue("subDistrict", null);
                              setValue("zipCode", "");
                              props.onFetchDistrict(newValue.id);
                              props.onFetchSubDistrict(undefined);
                            }
                          }}
                          renderInput={(params) => <TextField
                            error={!!errors.province}
                            helperText={errors.province ? "กรุณาเลือกจังหวัด" : ""}
                            {...params} label="จังหวัด" />}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4} sx={{ mt: { xs: 2, md: 0, lg: 0 } }}>
                    <Controller
                      name="district"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          disabled={!getValues("province")?.id}
                          value={field?.value as IDistrict || null}
                          options={props.districtData || []}
                          getOptionLabel={(option: any) => {
                            if (typeof option === 'string') {
                              return option;
                            }
                            if (option.inputValue) {
                              return option.inputValue;
                            }
                            return option.nameTh || "";
                          }}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          onChange={(event, newValue) => {
                            field.onChange(newValue);
                            if (newValue?.id) {
                              setValue("subDistrict", null);
                              setValue("zipCode", "");
                              props.onFetchSubDistrict(newValue.id);
                            }
                          }}
                          renderInput={(params) => <TextField
                            error={!!errors.district}
                            helperText={errors.district ? "กรุณาเลือกอําเภอ" : ""}
                            {...params} label="อําเภอ" />}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4} sx={{ mt: { xs: 2, md: 0, lg: 0 } }}>
                    <Controller
                      name="subDistrict"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Autocomplete
                          disabled={!getValues("district")?.id}
                          {...field}
                          value={field?.value as ISubDistrict || null}
                          options={props.subDistrictData || []}
                          getOptionLabel={(option: any) => {
                            if (typeof option === 'string') {
                              return option;
                            }
                            if (option.inputValue) {
                              return option.inputValue;
                            }
                            return option.nameTh || "";
                          }}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          onChange={(event, newValue: any) => {
                            field.onChange(newValue);
                            if (newValue?.zipCode) {
                              setValue("zipCode", newValue.zipCode);
                            }
                          }}
                          renderInput={(params) => <TextField
                            error={!!errors.subDistrict}
                            helperText={errors.subDistrict ? "กรุณาเลือกตําบล" : ""}
                            {...params} label="ตําบล" />}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4} sx={{ mt: { xs: 2, md: 2 } }}>
                    <TextField
                      label="รหัสไปรษณีย์"
                      fullWidth
                      {...register("zipCode", { required: true })}
                      error={!!errors.zipCode}
                      helperText={errors.zipCode ? "กรุณาระบุรหัสไปรษณีย์" : " "}
                      InputLabelProps={{
                        shrink: getValues("zipCode") ? true : undefined,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4} sx={{ mt: { xs: 0, md: 2 } }}>
                    <TextField
                      label="เบอร์โทรศัพท์"
                      fullWidth
                      {...register("tel")}
                      InputLabelProps={{
                        shrink: getValues("tel") ? true : undefined,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4} sx={{ mt: { xs: 2, md: 2 } }} textAlign={"right"}>
                    <Controller
                      name="isActive"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <FormControlLabel
                          control={
                            <Switch
                              checked={value}
                              onChange={onChange}
                            />
                          }
                          label="Active"
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} mt={2} md={12} lg={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12} mt={2}>
                  <Button
                    component="label"
                    variant="outlined"
                    startIcon={<UploadFileIcon />}
                  >
                    เลือกไฟล์
                    <VisuallyHiddenInput
                      type="file"
                      accept=".jpg, .jpeg, .png"
                      onChange={handleFileChange}
                    />
                  </Button>
                </Grid>
                <Grid item xs={4} mt={2}>
                  {selectedFile && (
                    <Box >
                      <img
                        src={URL.createObjectURL(selectedFile)}
                        alt="Selected"
                        style={{ maxWidth: 300, height: "auto" }}
                      />
                    </Box>
                  )}
                  {props.company?.image && !selectedFile ? (
                    <Box>
                      <img
                        src={`${process.env.REACT_APP_API_URL}/uploads/${(props.company.image as any).name
                          }`}
                        alt="Selected"
                        style={{ maxWidth: 300, height: "auto" }}
                      />
                    </Box>
                  ) : (
                    []
                  )}
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="right"
                  alignItems="center"
                  mt={2}
                >
                  <Button
                    variant="outlined"
                    style={{ marginRight: "10px", width: "15%" }}
                    onClick={() => navigate("/admin-company")}
                  >
                    ยกเลิก
                  </Button>
                  {props.permission.create || (props.permission.edit && id) ?
                    <Button
                      variant="contained"
                      style={{ width: "15%" }}
                      type="submit"
                    >
                      บันทึก
                    </Button>
                    : <></>}
                </Grid>
              </CardContent>

            </Card>
          </Grid>
        </Grid>
      </Box>
      <SimpleBackdrop open={props.isLoading} />
      <DialogConfirm
        open={openConfirm.open}
        type={openConfirm.type}
        title={openConfirm.title}
        message={openConfirm.message}
        onClose={() => setOpenConfirm({ ...openConfirm, open: false })}
        onSubmit={onConfirmSubmit}
      />
    </Box>
  );
};

export default CompanyDetailView;
