import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import EliminateListView from "../Views/EliminateListView";
import productService from "../Services/ProductService";
import dayjs from "dayjs";
import supplierService, { ISupplierCriteria } from "../../Supplier/Services/SupplierService";
import getPermissionBySubMenu from "../../../utils/getPermissionBySubMenu";
import loginService, { IUserDetail } from "../../Login/Services/LoginService";
import eliminateService, { IEliminateCriteria, IEliminateListView } from "../Services/EliminateService";
import { clearLocalStorageKeyOnUnload, getDateRangeFromStorage } from "../../../utils/dateRangeLocalStorage";
import { STORAGE_KEYS } from "../../../constants/dateRangeStorageKey";

const EliminateController = observer(() => {
  const initialDateRange = getDateRangeFromStorage(STORAGE_KEYS.PROCESS);
  const [isLoading, setIsLoading] = useState(false);
  const [permission, setPermission] = useState<IUserDetail>();
  const [eliminateData, setEliminateData] = useState<IEliminateListView>();
  const defaultCriteria: IEliminateCriteria = {
    dateFrom: initialDateRange.dateFrom
      ? dayjs(initialDateRange.dateFrom).format('YYYY-MM-DD')
      : dayjs().startOf('month').format('YYYY-MM-DD'),
    dateTo: initialDateRange.dateTo
      ? dayjs(initialDateRange.dateTo).format('YYYY-MM-DD')
      : dayjs().startOf('day').format('YYYY-MM-DD'),
    invoiceNo: '',
    product: '',
    supplier: '',
    status: 'all',
    page: 0,
    limit: 10,
  };

  useEffect(() => {
    getByCriteria(defaultCriteria);
    getProduct();
    fetchOrchardSupplier();
    checkPermissionActionButton();
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , []);

  clearLocalStorageKeyOnUnload(STORAGE_KEYS.PROCESS);

  const getProduct = async () => {
    try {
      setIsLoading(true);
      await productService.getAll();
    } catch (error) {
      console.error("An error occurred while getting the products:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getByCriteria = async (criteria: IEliminateCriteria) => {
    try {
      setIsLoading(true);
      await eliminateService.getByCriteria(criteria).then((data) => {
        setEliminateData(data);
      }
      );
    } catch (error) {
      console.error("An error occurred while getting the products:", error);
    } finally {
      setIsLoading(false);
    }
  };


  const fetchOrchardSupplier = async () => {
    try {
      await supplierService.getOrchardSupplier();
    } catch (error) {
      console.error("An error occurred while getting the suppliers:", error);
    }
  };

  const checkPermissionActionButton = () => {
    const result = getPermissionBySubMenu("process-eliminate");
    if (result) {
      setPermission(result);
    }
  };


  return <EliminateListView
    isLoading={isLoading}
    ordersEliminate={eliminateData ?? {} as IEliminateListView}
    productSelects={productService.selectProduct ?? []}
    onSearchByCriteria={getByCriteria}
    suppliers={supplierService.supplierListData ?? [] as ISupplierCriteria[]}
    permission={permission ?? {} as IUserDetail}
    user={loginService.userLoginedData ?? {} as IUserDetail}

  />;
});

export default EliminateController;