import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import reportService, { ILossReport, ILossReports, IReportCriteria } from "../Services/ReportService";
import LossReportListView from "../Views/LossReportListView";
import { STORAGE_KEYS } from "../../../constants/dateRangeStorageKey";
import { clearLocalStorageKeyOnUnload, getDateRangeFromStorage } from "../../../utils/dateRangeLocalStorage";
import dayjs from "dayjs";


const LossReportListController = observer(() => {
  const initialDateRange = getDateRangeFromStorage(STORAGE_KEYS.REPORT);
  const [isLoading, setIsLoading] = useState(false);
  const [lossReportData, setLossReportData] = useState<ILossReports>();
  const defaultCriteria: IReportCriteria = {
    dateFrom: initialDateRange.dateFrom
      ? dayjs(initialDateRange.dateFrom).format('YYYY-MM-DD')
      : dayjs().startOf('month').format('YYYY-MM-DD'),
    dateTo: initialDateRange.dateTo
      ? dayjs(initialDateRange.dateTo).format('YYYY-MM-DD')
      : dayjs().startOf('day').format('YYYY-MM-DD'),
    page: 0,
    limit: 10,
  };

  useEffect(() => {
    getLossReportData(defaultCriteria);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  clearLocalStorageKeyOnUnload(STORAGE_KEYS.REPORT);


  const getLossReportData = async (criteria: IReportCriteria) => {
    try {
      setIsLoading(true);
      await reportService.getLossReports(criteria).then((data) => {
        data?.datas?.sort((a: ILossReport, b: ILossReport) => (b.orderDate?.toString() ?? '').localeCompare(a.orderDate?.toString() ?? ''));
        setLossReportData(data);
      });
    } catch (error) {
      console.error("An error occurred while getting the loss reports:", error);
    } finally {
      setIsLoading(false);
    }
  };


  return <LossReportListView isLoading={isLoading} lossReportData={lossReportData ?? {} as ILossReports}
    getLossReportData={getLossReportData}
  />;
});

export default LossReportListController;
