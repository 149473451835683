import { IDocumentStatus } from "../features/Inventory/Services/TransferService";

export const documentStatus: IDocumentStatus = {
  all: {
    name: "ทั้งหมด",
    value: "all",
    color: "default"
  },
  approved: {
    name: "Approved",
    value: "approved",
    color: "success"
  },
  waiting: {
    name: "Waiting",
    value: "waiting",
    color: "info"
  },
  rejected: {
    name: "Rejected",
    value: "rejected",
    color: "error"
  }
}

