import { FC, useState } from "react";
import { ISupplierCriteria, ISupplierListView } from "../Services/SupplierService";
import { Box, Breadcrumbs, Typography, Grid, TextField, Button, TableContainer, Paper, Table, TableBody, TableCell, TableHead, TableRow, IconButton, TablePagination, Autocomplete } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useNavigate } from "react-router-dom";
import { ISupplierType } from "../Services/SupplierTypeService";
import ListViewPage from "../../../components/ListViewPage";
import { rowPerPageOptions } from "../../../constants/viewOption";
import { IUserDetail } from "../../SuperAdminManagement/Services/UserService";
import dayjs from "dayjs";

interface SupplierListViewProps {
  onSearchByCriteria: (criteria: ISupplierCriteria) => void;
  suppliers: ISupplierListView;
  supplierTypes: ISupplierType[];
  isLoading?: boolean;
  permission: IUserDetail;
}

const SupplierListView: FC<SupplierListViewProps> = (props) => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [supplierType, setSupplierType] = useState({ id: "", name: "ทั้งหมด" });
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);


  const searchByCiteria = (pageNumber: number = 0, rowLimit?: number) => {
    setPage(pageNumber === 0 ? 0 : pageNumber);
    setLimit(rowLimit ? rowLimit : 10);
    const criteria: ISupplierCriteria = {
      name: name,
      supplierType: supplierType.id,
      page: pageNumber,
      limit: rowLimit ?? 10,
    };
    props.onSearchByCriteria(criteria);
  };

  return (
    <ListViewPage
      breadcrumb={
        <>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Typography>Supplier</Typography>
            <Typography variant="h6" color="text.primary">รายการ</Typography>
          </Breadcrumbs>
        </>
      }
      criteria={
        <>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={6} md={8} lg={8}>
              <TextField
                fullWidth
                label="ชื่อ Supplier"
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={4} sm={4} md={2} lg={2}>
              <Autocomplete
                value={supplierType}
                options={[{ id: "", name: "ทั้งหมด" }, ...props.supplierTypes]}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField {...params} label="ประเภท Supplier" />}
                onChange={(e, value) => {
                  setSupplierType(value ?? { id: "", name: "ทั้งหมด" });
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />
            </Grid>

            <Grid item xs={2} sm={2} md={2} lg={2} textAlign={"center"} alignSelf={"center"}>
              <Button
                variant="contained"
                onClick={() => {
                  searchByCiteria(0);
                }}
                startIcon={<SearchIcon />}
              >
                ค้นหา
              </Button>
            </Grid>
          </Grid>
        </>
      }
      table={
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid container spacing={2} justifyContent="space-between" alignItems="center">
                <Grid item xs={2} sm={2} md={2} lg={2}>
                  <Typography >ทั้งหมด {props.suppliers.total} รายการ</Typography>
                </Grid>
                <Grid item xs={10} sm={10} md={10} lg={10} textAlign="right">
                  {props.permission.create &&
                    <Button
                      variant="contained"
                      startIcon={<NoteAddIcon />}
                      onClick={() => {
                        navigate('/supplier-create');
                      }}
                    >
                      สร้าง
                    </Button>
                  }
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow >
                      <TableCell>ชื่อ Supplier</TableCell>
                      <TableCell>ประเภท Supplier</TableCell>
                      <TableCell>ที่อยู่</TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>สาขา</TableCell>
                      <TableCell>ชื่อผู้ติดต่อ</TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>เบอร์โทร</TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>บันทึกล่าสุด</TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>แก้ไขล่าสุด</TableCell>
                      <TableCell sx={{ textAlign: 'center' }}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      props.suppliers.datas && props.suppliers.datas.length > 0 ?
                        props.suppliers.datas.map((supplier, index) => (
                          <TableRow key={index}>
                            <TableCell>{supplier.name}</TableCell>
                            <TableCell>{supplier.supplierType?.name}</TableCell>
                            <TableCell>{supplier.address}</TableCell>
                            <TableCell sx={{ textAlign: 'center' }}>{supplier.branch}</TableCell>
                            <TableCell>{supplier.contract}</TableCell>
                            <TableCell sx={{ textAlign: 'center' }}>{supplier.tel}</TableCell>
                            <TableCell sx={{ textAlign: 'center' }}>
                              <Box component={'div'}>
                                <Box component={'div'}>{supplier.createBy?.firstName || ""} {supplier.createBy?.lastName || ""}</Box>
                                <Box component={'div'}>{dayjs(supplier.createAt).format('DD/MM/YYYY') === "Invalid Date" ? "" :
                                  <Box>
                                    <Box>{dayjs(supplier.createAt).format('DD/MM/YYYY')}</Box>
                                    <Box>{dayjs(supplier.createAt).format('HH:mm')}</Box>
                                  </Box>
                                }</Box>
                              </Box>
                            </TableCell>
                            <TableCell sx={{ textAlign: 'center' }}>
                              <Box component={'div'}>
                                <Box component={'div'}>{supplier.updateBy?.firstName || ""} {supplier.updateBy?.lastName || ""}</Box>
                                <Box component={'div'}>{dayjs(supplier.updateAt).format('DD/MM/YYYY') === "Invalid Date" ? "" :
                                  <Box>
                                    <Box>{dayjs(supplier.updateAt).format('DD/MM/YYYY')}</Box>
                                    <Box>{dayjs(supplier.updateAt).format('HH:mm')}</Box>
                                  </Box>
                                }</Box>
                              </Box></TableCell>
                            <TableCell sx={{ alignContent: 'flex-end', textAlign: 'end' }}>
                              <IconButton
                                onClick={() => {
                                  navigate(`/supplier-edit/${supplier.id}`);
                                }}
                              >
                                <ChevronRightIcon />
                              </IconButton>
                            </TableCell>

                          </TableRow>
                        )) : <TableRow>
                          <TableCell colSpan={8} sx={{ textAlign: 'center' }}>ไม่พบข้อมูล</TableCell>
                        </TableRow>
                    }
                  </TableBody>
                </Table>
              </TableContainer>
              <Box>
                <TablePagination
                  component="div"
                  rowsPerPageOptions={rowPerPageOptions}
                  count={props.suppliers.total ?? 0}
                  onPageChange={(e, newPage) => {
                    setPage(newPage);
                    searchByCiteria(newPage);
                  }}
                  page={page}
                  rowsPerPage={limit ? limit : 10}
                  onRowsPerPageChange={(e: any) => {
                    setLimit(e.target.value);
                    setPage(0);
                    searchByCiteria(page, e.target.value);
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </>
      }
      dialog={<></>}
      isLoading={props.isLoading ?? false}
    />
  );
};

export default SupplierListView;  