import { Box, Grid, Typography } from "@mui/material";
import dayjs from "dayjs";
import React, { } from "react";
import { IPrintShippingPackingList } from "../../../features/ProductProcess/Services/ShippingService";

interface PrintShippingPackingListProps {
  data: IPrintShippingPackingList;
}



const PrintShippingPackingList = React.forwardRef((props: PrintShippingPackingListProps, ref) => {
  return (
    <Box ref={ref} sx={{
      margin: 0,
      width: "100%",
      borderCollapse: "collapse",
      paddingLeft: '20px',
      paddingRight: '20px',
      '&@page': {
        size: 'A4 portrait',
      },
      // marginLeft: '30px',
      marginTop: '20px',
      overflow: 'hidden',
      fontSize: 6,
      display: 'block',
      pageBreakBefore: 'auto',
    }}>
      <Grid container spacing={1} >
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography variant="h6" align="center" gutterBottom>
            PACKING LIST
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container spacing={1}>
            <Grid item xs={2} sm={2} md={2} lg={2} sx={{ border: '1px solid black' }}>
              <Typography sx={{ fontSize: '10px' }} align="left" gutterBottom>
                Date
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} sx={{ border: '1px solid black', borderLeft: 'none' }}>
              <Typography sx={{ fontSize: '10px' }} align="left" gutterBottom>
                {`: ${dayjs(props.data.date).format('DD/MM/YYYY')}`}
              </Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} sx={{ border: '1px solid black', borderLeft: 'none' }}>
              <Grid container>
                <Grid item xs={4} sm={4} md={4} lg={4}>
                  <Typography sx={{ fontSize: '10px' }} align="left" gutterBottom>
                    Destination
                  </Typography>
                </Grid>
                <Grid item xs={8} sm={8} md={8} lg={8}>
                  <Typography sx={{ fontSize: '10px' }} align="left" gutterBottom>
                    {`: ${props.data.destination ?? ''}`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} sx={{ border: '1px solid black', borderTop: 'none' }}>
              <Typography sx={{ fontSize: '10px' }} align="left" gutterBottom>
                Invoice Ref.No.
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none' }}>
              <Typography sx={{ fontSize: '10px' }} align="left" gutterBottom>
                {`: ${props.data.invoiceRefNo ?? ''}`}
              </Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none' }}>
              <Grid container>
                <Grid item xs={4} sm={4} md={4} lg={4}>
                  <Typography sx={{ fontSize: '10px' }} align="left" gutterBottom>
                    Ship By
                  </Typography>
                </Grid>
                <Grid item xs={8} sm={8} md={8} lg={8}>
                  <Typography sx={{ fontSize: '10px' }} align="left" gutterBottom>
                    {`: ${props.data && props.data.shippingBy ? props.data.shippingBy.name : ''}`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} sx={{ border: '1px solid black', borderTop: 'none' }}>
              <Typography sx={{ fontSize: '10px' }} align="left" gutterBottom>
                FLIGHT
              </Typography>
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none' }}>
              <Typography sx={{ fontSize: '10px' }} align="left" gutterBottom>
                {`: ${props.data.flightNo ?? ''}`}
              </Typography>
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none' }}>
              <Typography sx={{ fontSize: '10px' }} align="left" gutterBottom>
                ETD-ETA
              </Typography>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none' }}>
              <Typography sx={{ fontSize: '10px' }} align="left" gutterBottom>
                {`: ${props.data.etd && props.data.eta ? `${dayjs(props.data.etd).format('HH:mm')} - ${dayjs(props.data.eta).format('HH:mm')}` : ''}`}
              </Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none' }}>
              <Grid container>
                <Grid item xs={4} sm={4} md={4} lg={4}>
                  <Typography sx={{ fontSize: '10px' }} align="left" gutterBottom>
                    AWB NO:
                  </Typography>
                </Grid>
                <Grid item xs={8} sm={8} md={8} lg={8}>
                  <Typography sx={{ fontSize: '10px' }} align="left" gutterBottom>
                    {`: ${props.data.awbNo ?? ''}`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ border: '1px solid black', borderTop: 'none' }}>
              <Typography align="center" gutterBottom>
                PRODUCTS-DESCRIPTION
              </Typography>
            </Grid>
            <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} sx={{ border: '1px solid black', borderTop: 'none' }} display={'flex'} alignItems={'center'} justifyContent={'center'}>
              <Typography sx={{ fontSize: '10px' }} align="center" gutterBottom>
                Customer
              </Typography>
            </Grid>
            <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none' }} display={'flex'} alignItems={'center'} justifyContent={'center'}>
              <Typography sx={{ fontSize: '10px' }} align="center" gutterBottom>
                Product Name
              </Typography>
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none' }} display={'flex'} alignItems={'center'} justifyContent={'center'}>
              <Typography sx={{ fontSize: '10px' }} align="center" >
                Size / KG
              </Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none' }}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none', borderRight: 'none' }}>
                  <Typography align="center" gutterBottom>
                    {`\u00A0`}
                  </Typography>
                </Grid>
                <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none', borderBottom: 'none' }}>
                  <Typography sx={{ fontSize: '10px' }} align="center" gutterBottom>
                    8
                  </Typography>
                </Grid>
                <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none', borderBottom: 'none' }}>
                  <Typography sx={{ fontSize: '10px' }} align="center" gutterBottom>
                    10
                  </Typography>
                </Grid>
                <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none', borderBottom: 'none' }}>
                  <Typography sx={{ fontSize: '10px' }} align="center" gutterBottom>
                    12
                  </Typography>
                </Grid>
                <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none', borderBottom: 'none' }}>
                  <Typography sx={{ fontSize: '10px' }} align="center" gutterBottom>
                    14
                  </Typography>
                </Grid>
                <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none', borderBottom: 'none' }}>
                  <Typography sx={{ fontSize: '10px' }} align="center" gutterBottom>
                    16
                  </Typography>
                </Grid>
                <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none', borderBottom: 'none' }}>
                  <Typography sx={{ fontSize: '10px' }} align="center" gutterBottom>
                    18
                  </Typography>
                </Grid>
                <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none', borderBottom: 'none' }}>
                  <Typography sx={{ fontSize: '10px' }} align="center" gutterBottom>
                    20
                  </Typography>
                </Grid>
                <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none', borderRight: 'none', borderBottom: 'none' }}>
                  <Typography sx={{ fontSize: '10px' }} align="center" gutterBottom>
                    22
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none' }}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none', borderRight: 'none' }}>
                  <Typography align="center" gutterBottom>
                    {`\u00A0`}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none', borderBottom: 'none' }}>
                  <Typography sx={{ fontSize: '10px' }} align="center" gutterBottom>
                    Quantity
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none', borderBottom: 'none', borderRight: 'none' }}>
                  <Typography sx={{ fontSize: '10px' }} align="center" gutterBottom>
                    Net Weight / kg
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none' }}>
              <Typography sx={{ fontSize: '10px' }} align="center" gutterBottom>
                Remarks
              </Typography>
            </Grid>
            <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} sx={{ border: '1px solid black', borderTop: 'none' }} display={'flex'} alignItems={'center'} justifyContent={'center'}>
              <Typography align="center" gutterBottom>
                {`\u00A0`}
              </Typography>
            </Grid>
            <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none' }} display={'flex'} alignItems={'center'} justifyContent={'center'}>
              <Typography align="center" gutterBottom>
                {`\u00A0`}
              </Typography>
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none' }} display={'flex'} alignItems={'center'} justifyContent={'center'}>
              <Typography align="center" >
                {`\u00A0`}
              </Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none' }}>
              <Grid container spacing={1}>
                <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none', borderBottom: 'none' }}>
                  <Typography align="center" gutterBottom>
                    {`\u00A0`}
                  </Typography>
                </Grid>
                <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none', borderBottom: 'none' }}>
                  <Typography align="center" gutterBottom>
                    {`\u00A0`}
                  </Typography>
                </Grid>
                <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none', borderBottom: 'none' }}>
                  <Typography align="center" gutterBottom>
                    {`\u00A0`}
                  </Typography>
                </Grid>
                <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none', borderBottom: 'none' }}>
                  <Typography align="center" gutterBottom>
                    {`\u00A0`}
                  </Typography>
                </Grid>
                <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none', borderBottom: 'none' }}>
                  <Typography align="center" gutterBottom>
                    {`\u00A0`}
                  </Typography>
                </Grid>
                <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none', borderBottom: 'none' }}>
                  <Typography align="center" gutterBottom>
                    {`\u00A0`}
                  </Typography>
                </Grid>
                <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none', borderBottom: 'none' }}>
                  <Typography align="center" gutterBottom>
                    {`\u00A0`}                  </Typography>
                </Grid>
                <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none', borderRight: 'none', borderBottom: 'none' }}>
                  <Typography align="center" gutterBottom>
                    {`\u00A0`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none' }}>
              <Grid container spacing={1}>
                <Grid item xs={6} sm={6} md={6} lg={6} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none', borderBottom: 'none' }}>
                  <Typography align="center" gutterBottom>
                    {`\u00A0`}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none', borderBottom: 'none', borderRight: 'none' }}>
                  <Typography align="center" gutterBottom>
                    {`\u00A0`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none' }}>
              <Typography align="center" gutterBottom>
                {`\u00A0`}
              </Typography>
            </Grid>
            {/*  */}
            <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} sx={{ border: '1px solid black', borderTop: 'none' }} display={'flex'} alignItems={'center'} justifyContent={'center'}>
              <Typography sx={{ fontSize: '10px' }} align="center" gutterBottom>
                {props.data.customer ?? ''}
              </Typography>
            </Grid>
            <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none' }} display={'flex'} alignItems={'center'} justifyContent={'center'}>
              <Typography sx={{ fontSize: '10px' }} align="center" gutterBottom>
                {props.data && props.data.product ? props.data.product.name : ''}
              </Typography>
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none' }} display={'flex'} alignItems={'center'} justifyContent={'center'}>
              <Typography align="center" >
                {props.data.sizeKg ?? ''}
              </Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none' }}>
              <Grid container spacing={1}>
                <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none', borderBottom: 'none' }}>
                  <Typography align="center" gutterBottom>
                    {/* {props.data.cap8 ?? ''} */}
                    {props.data.cap8 ? props.data.cap8 : `\u00A0`}
                  </Typography>
                </Grid>
                <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none', borderBottom: 'none' }}>
                  <Typography align="center" gutterBottom>
                    {props.data.cap10 ? props.data.cap10 : `\u00A0`}
                  </Typography>
                </Grid>
                <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none', borderBottom: 'none' }}>
                  <Typography align="center" gutterBottom>
                    {props.data.cap12 ? props.data.cap12 : `\u00A0`}
                  </Typography>
                </Grid>
                <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none', borderBottom: 'none' }}>
                  <Typography align="center" gutterBottom>
                    {props.data.cap14 ? props.data.cap14 : `\u00A0`}
                  </Typography>
                </Grid>
                <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none', borderBottom: 'none' }}>
                  <Typography align="center" gutterBottom>
                    {props.data.cap16 ? props.data.cap16 : `\u00A0`}
                  </Typography>
                </Grid>
                <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none', borderBottom: 'none' }}>
                  <Typography align="center" gutterBottom>
                    {props.data.cap18 ? props.data.cap18 : `\u00A0`}
                  </Typography>
                </Grid>
                <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none', borderBottom: 'none' }}>
                  <Typography align="center" gutterBottom>
                    {props.data.cap20 ? props.data.cap20 : `\u00A0`}
                  </Typography>
                </Grid>
                <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none', borderRight: 'none', borderBottom: 'none' }}>
                  <Typography align="center" gutterBottom>
                    {props.data.cap22 ? props.data.cap22 : `\u00A0`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none' }}>
              <Grid container spacing={1}>
                <Grid item xs={6} sm={6} md={6} lg={6} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none', borderBottom: 'none' }}>
                  <Typography align="center" gutterBottom>
                    {props.data.quantity ?? ''}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none', borderBottom: 'none', borderRight: 'none' }}>
                  <Typography align="center" gutterBottom>
                    {props.data.netWeight ?? ''}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none' }}>
              <Typography align="center" gutterBottom>
                {props.data.remarks ?? ''}
              </Typography>
            </Grid>
            <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} sx={{ border: '1px solid black', borderTop: 'none' }} display={'flex'} alignItems={'center'} justifyContent={'center'}>
              <Typography align="center" gutterBottom>
                {`\u00A0`}
              </Typography>
            </Grid>
            <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none' }} display={'flex'} alignItems={'center'} justifyContent={'center'}>
              <Typography align="center" gutterBottom>
                {`\u00A0`}
              </Typography>
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none' }} display={'flex'} alignItems={'center'} justifyContent={'center'}>
              <Typography align="center" >
                {`\u00A0`}
              </Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none' }}>
              <Grid container spacing={1}>
                <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none', borderBottom: 'none' }}>
                  <Typography align="center" gutterBottom>
                    {`\u00A0`}
                  </Typography>
                </Grid>
                <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none', borderBottom: 'none' }}>
                  <Typography align="center" gutterBottom>
                    {`\u00A0`}
                  </Typography>
                </Grid>
                <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none', borderBottom: 'none' }}>
                  <Typography align="center" gutterBottom>
                    {`\u00A0`}
                  </Typography>
                </Grid>
                <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none', borderBottom: 'none' }}>
                  <Typography align="center" gutterBottom>
                    {`\u00A0`}
                  </Typography>
                </Grid>
                <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none', borderBottom: 'none' }}>
                  <Typography align="center" gutterBottom>
                    {`\u00A0`}
                  </Typography>
                </Grid>
                <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none', borderBottom: 'none' }}>
                  <Typography align="center" gutterBottom>
                    {`\u00A0`}
                  </Typography>
                </Grid>
                <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none', borderBottom: 'none' }}>
                  <Typography align="center" gutterBottom>
                    {`\u00A0`}                  </Typography>
                </Grid>
                <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none', borderRight: 'none', borderBottom: 'none' }}>
                  <Typography align="center" gutterBottom>
                    {`\u00A0`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none' }}>
              <Grid container spacing={1}>
                <Grid item xs={6} sm={6} md={6} lg={6} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none', borderBottom: 'none' }}>
                  <Typography align="center" gutterBottom>
                    {`\u00A0`}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none', borderBottom: 'none', borderRight: 'none' }}>
                  <Typography align="center" gutterBottom>
                    {`\u00A0`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none' }}>
              <Typography align="center" gutterBottom>
                {`\u00A0`}
              </Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} sx={{ border: '1px solid black', borderTop: 'none' }} display={'flex'} alignItems={'center'} justifyContent={'center'}>
              <Typography align="center" gutterBottom>
                {`Total`}
              </Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none' }}>
              <Grid container spacing={1}>
                <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none', borderBottom: 'none' }}>
                  <Typography align="center" gutterBottom>
                    {`\u00A0`}
                  </Typography>
                </Grid>
                <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none', borderBottom: 'none' }}>
                  <Typography align="center" gutterBottom>
                    {`\u00A0`}
                  </Typography>
                </Grid>
                <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none', borderBottom: 'none' }}>
                  <Typography align="center" gutterBottom>
                    {`\u00A0`}
                  </Typography>
                </Grid>
                <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none', borderBottom: 'none' }}>
                  <Typography align="center" gutterBottom>
                    {`\u00A0`}
                  </Typography>
                </Grid>
                <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none', borderBottom: 'none' }}>
                  <Typography align="center" gutterBottom>
                    {`\u00A0`}
                  </Typography>
                </Grid>
                <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none', borderBottom: 'none' }}>
                  <Typography align="center" gutterBottom>
                    {`\u00A0`}
                  </Typography>
                </Grid>
                <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none', borderBottom: 'none' }}>
                  <Typography align="center" gutterBottom>
                    {`\u00A0`}
                  </Typography>
                </Grid>
                <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none', borderRight: 'none', borderBottom: 'none' }}>
                  <Typography align="center" gutterBottom>
                    {`\u00A0`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none' }}>
              <Grid container spacing={1}>
                <Grid item xs={6} sm={6} md={6} lg={6} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none', borderBottom: 'none' }}>
                  <Typography align="center" gutterBottom>
                    {props.data.quantity ?? ''}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none', borderBottom: 'none', borderRight: 'none' }}>
                  <Typography align="center" gutterBottom>
                    {props.data.netWeight ?? ''}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1} sx={{ border: '1px solid black', borderLeft: 'none', borderTop: 'none' }}>
              <Typography align="center" gutterBottom>
                {`\u00A0`}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
});

export default PrintShippingPackingList;

