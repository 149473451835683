import { FC } from "react";
import { IProfitReport, IProfitReports, IReportCriteria } from "../Services/ReportService";
import ReportListView from "../../../components/ReportListView";
import dayjs from "dayjs";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import thaiFormatNumber from "../../../utils/thaiFormatNumber";

interface ProfitReportListViewProps {
  isLoading: boolean;
  profitReportData: IProfitReports;
  getProfitReportData: (criteria: IReportCriteria) => void;

}
const ProfitReportListView: FC<ProfitReportListViewProps> = (props) => {
  const navigate = useNavigate();
  const profitReportColumns = [
    { label: 'วันที่', render: (row: IProfitReport) => row.orderDate ? dayjs(row.orderDate).format("DD/MM/YYYY HH:mm") : "" },
    { label: 'ชื่อโปรเจกต์', render: (row: IProfitReport) => row.invoiceNo ?? "" },
    { label: 'มูลค่าทั้งหมด', render: (row: IProfitReport) => row.totalProfit ? thaiFormatNumber(row.totalProfit.toString()) : "0.00" },
    { label: 'พนักงานขาย', render: (row: IProfitReport) => `${row.worker?.firstName} ${row.worker?.lastName}` },
  ];

  const totalValues = [
    { label: "ต้นทุน", value: props.profitReportData?.totalValue?.totalCost, percentage: props.profitReportData?.totalValue?.costPercentChange },
    { label: "ยอดขาย", value: props.profitReportData?.totalValue?.totalSales, percentage: props.profitReportData?.totalValue?.salesPercentChange },
    { label: "กำไร", value: props.profitReportData?.totalValue?.totalProfit, percentage: props.profitReportData?.totalValue?.profitPercentChange },
    { label: "ยอดสูญเสีย", value: props.profitReportData?.totalValue?.totalLoss, percentage: props.profitReportData?.totalValue?.lossPercentChange },
  ];

  const rowActions = (row: IProfitReport) => (
    <IconButton
      onClick={() => {
        navigate(`/process-order-edit/${row.id}`);
      }}>
      <KeyboardArrowRightIcon />
    </IconButton>
  );

  return (
    <>
      <ReportListView
        isLoading={props.isLoading}
        reportData={props.profitReportData}
        getReportData={props.getProfitReportData}
        reportTitle="รายงานกำไร"
        totalValues={totalValues}
        reportColumns={profitReportColumns}
        rowActions={rowActions}
      />
    </>

  );
};

export default ProfitReportListView;
