
import supplierService, { ISupplierCriteria, ISupplierListView } from "../Services/SupplierService";
import { useEffect, useState } from "react";
import SupplierListView from "../Views/SupplierListView";
import { observer } from "mobx-react-lite";
import supplierTypeService from "../Services/SupplierTypeService";
import getPermissionBySubMenu from "../../../utils/getPermissionBySubMenu";
import { IUserDetail } from "../../Login/Services/LoginService";


const SupplierListController = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [permission, setPermission] = useState<IUserDetail>();
  const [supplierData, setSupplierData] = useState<ISupplierListView>();
  const defaultCriteria: ISupplierCriteria = {
    name: '',
    supplierType: '',
    page: 0,
    limit: 10,
  };

  useEffect(() => {
    fetchSuppliersByCriteria(defaultCriteria);
    fetchSupplierTypes();
    checkPermissionActionButton();
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , []);


  const fetchSupplierTypes = async () => {
    try {
      await supplierTypeService.getAll();
    } catch (error) {
      console.error("An error occurred while getting the supplier types:", error);
    }
  };


  const fetchSuppliersByCriteria = async (criteria: ISupplierCriteria) => {
    try {
      setIsLoading(true);
      await supplierService.getByCriteria(criteria).then((data) => {
        setSupplierData(data);
      }
      );
    } catch (error) {
      console.error("An error occurred while getting the suppliers:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const checkPermissionActionButton = () => {
    const result = getPermissionBySubMenu("supplier-list");
    if (result) {
      setPermission(result);
    }
  };

  return (
    <SupplierListView
      onSearchByCriteria={(criteria: ISupplierCriteria) => {
        fetchSuppliersByCriteria(criteria);
      }}
      suppliers={supplierData ?? {} as ISupplierListView}
      supplierTypes={supplierTypeService.supplierTypeListData ?? []}
      isLoading={isLoading}
      permission={permission ?? {} as IUserDetail}
    />
  );
});

export default SupplierListController;