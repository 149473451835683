import { FC, useState } from "react";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { Breadcrumbs, Typography, Grid, TextField, Button, IconButton, FormControl, InputLabel, MenuItem, Select, Chip, Box } from "@mui/material";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ListViewPage from "../../../components/ListViewPage";
import { IReceiveView, IReceiveCriteria, IReceive } from "../Services/ReceiveService";
import { IWarehouse } from "../Services/WareshousesService";
import { documentStatus } from "../../../constants/documentStatus";
import SimpleBackdrop from "../../../components/SimpleBackdrop";
import { Controller, useForm } from "react-hook-form";
import TableDataList from "../../../components/TableDataList";
import { IUserDetail } from "../../Login/Services/LoginService";
import thaiFormatNumber from "../../../utils/thaiFormatNumber";
import { STORAGE_KEYS } from "../../../constants/dateRangeStorageKey";
import { getDateRangeFromStorage, setDateRangeToStorage } from "../../../utils/dateRangeLocalStorage";

interface ReceivesListViewProps {
  receives: IReceiveView;
  onSerchByCriteria: (criteria: IReceiveCriteria) => void;
  warehousesData?: IWarehouse[];
  isLoading: boolean;
  permission: IUserDetail;
}

const ReceivesListView: FC<ReceivesListViewProps> = (props) => {
  const initialDateRange = getDateRangeFromStorage(STORAGE_KEYS.INVENTORY);
  const { control, getValues } = useForm<IReceiveCriteria>({
    defaultValues: {
      status: "all",
      dateFrom: initialDateRange.dateFrom || dayjs().startOf('month').toDate(),
      dateTo: initialDateRange.dateTo || dayjs().toDate(),
      poNumber: "",
    }
  });
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [searchDateType, setSearchDateType] = useState("Receive_Date");

  const documentStatusOptions = Object.values(documentStatus);
  const navigate = useNavigate();

  const handleNavigateToCreate = (from: string, id?: string) => {
    if (id) {
      navigate(`/inventory-receive-create/${id}`, { state: { from } });
    } else {
      navigate(`/inventory-receive-create`, { state: { from } });
    }
  };

  const searchByCriteria = (pageNumber: number = 0, rowLimit?: number) => {
    setPage(pageNumber);
    setLimit(rowLimit ?? 10);
    const criteria: IReceiveCriteria = {
      dateFrom: searchDateType === "all" ? undefined : dayjs(getValues("dateFrom")).format("YYYY-MM-DD"),
      dateTo: searchDateType === "all" ? undefined : dayjs(getValues("dateTo")).format("YYYY-MM-DD"),
      poNumber: getValues("poNumber"),
      status: getValues("status") === "all" ? undefined : getValues("status"),
      page: pageNumber,
      limit: rowLimit ?? 10,
    };

    props.onSerchByCriteria(criteria);
  };

  const receiveColumns = [
    { label: 'วันที่เอกสาร', render: (row: IReceive) => row.receivedDate ? dayjs(row.receivedDate).format("DD/MM/YYYY") : "" },
    { label: 'ใบเสร็จ/ใบส่งของ', render: (row: IReceive) => row.poNumber },
    { label: 'รายการทั้งหมด', render: (row: IReceive) => row.totalLists },
    { label: 'สินค้าทั้งหมด', render: (row: IReceive) => row.totalItems ? thaiFormatNumber(row.totalItems.toString() || '0') : '0' },
    { label: 'มูลค่าทั้งหมด', render: (row: IReceive) => row.totalPrice ? thaiFormatNumber(row.totalPrice.toString() || '0') : '0' },
    { label: 'พนักงานรับเข้า', render: (row: IReceive) => `${row.createBy?.firstName} ${row.createBy?.lastName}` },
    {
      label: 'บันทึกล่าสุด', render: (row: IReceive) => (
        <Box component={'div'}>
          <Box component={'div'}>{row.createBy?.firstName || ""} {row.createBy?.lastName || ""}</Box>
          <Box component={'div'}>{dayjs(row.createAt).format('DD/MM/YYYY') === "Invalid Date" ? "" :
            <Box>
              <Box>{dayjs(row.createAt).format('DD/MM/YYYY')}</Box>
              <Box>{dayjs(row.createAt).format('HH:mm')}</Box>
            </Box>
          }</Box>
        </Box>
      )
    },
    {
      label: 'แก้ไขล่าสุด', render: (row: IReceive) => (
        <Box component={'div'}>
          <Box component={'div'}>{row.updateBy?.firstName || ""} {row.updateBy?.lastName || ""}</Box>
          <Box component={'div'}>{dayjs(row.updateAt).format('DD/MM/YYYY') === "Invalid Date" ? "" :
            <Box>
              <Box>{dayjs(row.updateAt).format('DD/MM/YYYY')}</Box>
              <Box>{dayjs(row.updateAt).format('HH:mm')}</Box>
            </Box>
          }</Box>
        </Box>
      )
    },
    {
      label: 'สถานะ', render: (row: IReceive) => (
        <Chip
          label={documentStatus[row.status]?.name || 'default'}
          color={documentStatus[row.status]?.color as "default"}
          onClick={() => handleNavigateToCreate('status', row.id)}
        />
      )
    },
  ];

  const rowActions = (row: IReceive) => (
    <IconButton onClick={() => handleNavigateToCreate('arrow', row.id)}>
      <KeyboardArrowRightIcon />
    </IconButton>
  );

  const actionButtons = props.permission.create ? (
    <Button
      variant="contained"
      startIcon={<NoteAddIcon />}
      onClick={() => handleNavigateToCreate('create')}
    >
      สร้าง
    </Button>
  ) : <></>;

  return (
    <ListViewPage
      breadcrumb={
        <>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Typography>รับเข้าวัสดุ</Typography>
            <Typography variant="h6" color="text.primary">รายการ</Typography>
          </Breadcrumbs></>
      }

      criteria={
        <>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={5} md={5} lg={5} >
              <FormControl fullWidth>
                <InputLabel id="search-select-label">เลือกช่วงเวลา</InputLabel>
                <Select
                  labelId="search-select-label"
                  id="search-select"
                  label="เลือกช่วงเวลา"
                  value={searchDateType}
                  onChange={(e) => setSearchDateType(e.target.value)}
                >
                  <MenuItem value="all">ทั้งหมด</MenuItem>
                  <MenuItem value="Receive_Date">วันที่เอกสาร</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {searchDateType === "Receive_Date" ? (
              <>
                <Grid item xs={12} sm={2.5} md={2.5} lg={2.5} >
                  <Controller
                    name="dateFrom"
                    control={control}
                    render={({ field }) => (
                      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'th'}>
                        <DatePicker
                          label="ตั้งแต่วันที่"
                          sx={{ width: "100%" }}
                          value={field.value ? dayjs(field.value) : dayjs()}
                          onChange={(newValue: any) => {
                            field.onChange(newValue);
                            setDateRangeToStorage(STORAGE_KEYS.INVENTORY, newValue.startOf('day').toDate(), dayjs(getValues('dateTo')) ?? dayjs().startOf('day'));
                          }}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={2.5} md={2.5} lg={2.5} >
                  <Controller
                    name="dateTo"
                    control={control}
                    render={({ field }) => (
                      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'th'}>
                        <DatePicker
                          label="ถึงวันที่"
                          sx={{ width: "100%" }}
                          value={field.value ? dayjs(field.value) : dayjs()}
                          onChange={(newValue: any) => {
                            field.onChange(newValue);
                            setDateRangeToStorage(
                              STORAGE_KEYS.INVENTORY,
                              dayjs(getValues('dateFrom') ?? dayjs().startOf('month').startOf('day')),
                              newValue.startOf('day').toDate()
                            );

                          }}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </Grid>
              </>
            ) : (
              <Grid item xs={0} sm={6} md={6} lg={6} />
            )}
            <Grid item xs={12} sm={5} md={5} lg={5}>
              <Controller
                name="poNumber"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    label="ใบเสร็จ/ใบส่งของ"
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={5} md={5} lg={5}>
              <Controller
                name="status"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth>
                    <InputLabel id="status-select-label">สถานะ</InputLabel>

                    <Select
                      labelId="status-select-label"
                      value={field.value || "all"}
                      onChange={(e) => field.onChange(e.target.value)}
                      label="สถานะ"
                    >                      {documentStatusOptions.map((status) => (
                      <MenuItem key={status.value} value={status.value}>
                        {status.name}
                      </MenuItem>
                    ))}
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={2} md={2} lg={2} textAlign="end" alignSelf="center">
              <Button
                variant="contained"
                startIcon={<SearchIcon />}
                onClick={() => searchByCriteria(0)}
              >
                ค้นหา
              </Button>
            </Grid>
          </Grid>
        </>
      }
      table={
        <>
          <TableDataList
            data={props.receives.datas}
            total={props.receives.total}
            columns={receiveColumns}
            actionButtons={actionButtons}
            rowActions={rowActions}
            page={page}
            limit={limit}
            onPageChange={(page) => {
              setPage(page);
              searchByCriteria(page, limit);
            }}
            onRowsPerPageChange={(newLimit) => {
              setLimit(newLimit);
              setPage(0);
              searchByCriteria(0, newLimit);
            }}
          />
        </>
      }
      dialog={
        <SimpleBackdrop open={props.isLoading} />
      }
    />
  );
};

export default ReceivesListView;
