import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { CssBaseline, ThemeProvider } from "@mui/material";
import { theme } from "./themes";
import { SnackbarProvider } from "notistack";

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider maxSnack={5} anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }} autoHideDuration={3000}>
        <App />
      </SnackbarProvider>
    </ThemeProvider>
  </React.StrictMode>
);