import { Box, Breadcrumbs, Typography, Grid, Card, CardContent, TextField, Button, IconButton, Link, Autocomplete, Divider, ButtonProps, Checkbox, FormControl, FormControlLabel, createFilterOptions } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { ISupplier } from "../Services/SupplierService";
import { ISupplierType } from "../Services/SupplierTypeService";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import DialogConfirm from "../../../components/DialogConfirm";
import SimpleBackdrop from "../../../components/SimpleBackdrop";
import { IProvince, IDistrict, ISubDistrict } from "../../Company/Services/CompanyService";
import { IUserDetail } from "../../Login/Services/LoginService";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SupplierTypeCreateView from "./SupplierTypeCreateView";

interface SupplierCreateViewProps {
  onCreate: (data: ISupplier) => void;
  onUpdate: (data: ISupplier) => void;
  onDelete: (data: ISupplier) => void;
  supplierTypes: ISupplierType[];
  supplier: ISupplier;
  isLoading: boolean;
  provinceData?: IProvince[];
  districtData?: IDistrict[];
  subDistrictData?: ISubDistrict[];
  onFetchDistrict: (id: number | undefined) => void;
  onFetchSubDistrict: (id: number | undefined) => void;
  permission: IUserDetail;
  // supplierType
  onCreateSupplierType: (data: ISupplierType) => void;
}

const SupplierCreateView: FC<SupplierCreateViewProps> = (props) => {
  const filterOptions = createFilterOptions<any>();
  const navigate = useNavigate();
  const { register, handleSubmit, reset, getValues, watch, setValue, formState: { errors }, control } = useForm<ISupplier>();
  watch();
  const [openConfirm, setOpenConfirm] = useState({
    open: false,
    title: "",
    message: "",
    color: "primary" as ButtonProps["color"],
    type: "create" as "create" | "update" | "delete",
  });
  const [openSupplierType, setOpenSupplierType] = useState(false);
  const [name, setName] = useState("");
  useEffect(() => {
    if (props.supplier.id) {
      reset(props.supplier);
    }
  }
    , [props.supplier, reset]);

  const handleConfirm = handleSubmit(() => {
    if (props.supplier.id) {
      setOpenConfirm({
        open: true,
        title: "แก้ไขข้อมูล",
        message: "คุณต้องการแก้ไขข้อมูลนี้ใช่หรือไม่",
        color: "primary",
        type: "update",
      });
    } else {
      setOpenConfirm({
        open: true,
        title: "บันทึกข้อมูล",
        message: "คุณต้องการบันทึกข้อมูลนี้ใช่หรือไม่",
        color: "primary",
        type: "create",
      });
    }
  });

  const handleDelete = () => {
    setOpenConfirm({
      open: true,
      title: "ลบข้อมูล",
      message: "คุณต้องการลบข้อมูลนี้ใช่หรือไม่",
      color: "error",
      type: "delete",
    });
  };

  const onConfirm = () => {
    if (props.supplier.id) {
      props.onUpdate(getValues());
    } else {
      props.onCreate(getValues());
    }
    reset();
  };

  const onDelete = () => {
    props.onDelete({ ...getValues(), isDelete: true });
    reset();

  };

  return (
    <Box component={"form"} onSubmit={handleConfirm}>
      <Grid container>
        <Grid item xs={1} sm={1} md={0.5} lg={0.5}>
          <IconButton
            aria-label="delete"
            onClick={() => navigate("/supplier-list")}
          >
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={11} sm={8} md={8.5} lg={8.5} display={"flex"} alignItems={"center"}>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Typography>Supplier</Typography>
            <Link component="button" onClick={() => navigate("/supplier-list")}>
              รายการ
            </Link>
            <Typography variant="h6" color="text.primary">
              {props.supplier.id ? "แก้ไข" : "สร้าง"}
            </Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12} sm={3} md={3} lg={3} display={"flex"} justifyContent={"end"}>
          {(props.supplier.id && props.permission.delete) && (
            <Button
              variant="contained"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={() => handleDelete()}
            >
              ลบข้อมูล
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={8} lg={8}>
                  <TextField
                    fullWidth
                    label="ชื่อ Supplier"
                    {...register("name", { required: true })}
                    error={!!errors.name}
                    helperText={errors.name ? "กรุณาระบุชื่อ Supplier" : ""}
                    InputLabelProps={{
                      shrink: getValues("name") ? true : undefined
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Controller
                    name="supplierType"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        value={field?.value as ISupplierType || null}
                        options={Array.isArray(props.supplierTypes) ? props.supplierTypes : []}
                        getOptionLabel={(option: any) => {
                          if (typeof option === 'string') {
                            return option;
                          }
                          if (option.inputValue) {
                            return option.inputValue;
                          }
                          return option.name || null;
                        }}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        onChange={(event, newValue) => {
                          if (newValue?.id === "create") {
                            setOpenSupplierType(true);
                          } else if (newValue?.id !== "create") {
                            field.onChange(newValue);
                          }
                        }}
                        filterOptions={(options, params) => {
                          const filtered = filterOptions(options, params);
                          if (params.inputValue !== '') {
                            filtered.push({
                              name: `สร้าง '${params.inputValue}'`,
                              id: 'create',
                            });
                            setName(params.inputValue);
                          }
                          return filtered;
                        }}
                        renderOption={(props, option) => {
                          const { key, ...rest } = props;
                          return (
                            <Typography key={key} {...rest} variant="body2" >{option.id === "create" ? <Box component={'span'} sx={{ color: "primary.main", display: "flex", alignItems: "center" }}>
                              <AddCircleIcon /><Box component={'span'} ml={1}> {option.name}</Box>
                            </Box> : option.name}</Typography>
                          );
                        }}
                        renderInput={(params) => <TextField
                          error={!!errors.supplierType}
                          helperText={errors.supplierType ? "กรุณาเลือกประเภท Supplier" : ""}
                          {...params} label="ประเภท" />}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={8}>
                  <TextField
                    fullWidth
                    label="ที่อยู่"
                    {...register("address", { required: true })}
                    error={!!errors.address}
                    helperText={errors.address ? "กรุณาระบุที่อยู่" : ""}
                    InputLabelProps={{
                      shrink: getValues("address") ? true : undefined
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Controller
                    name="province"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        value={field?.value as IProvince || null}
                        options={props.provinceData || []}
                        getOptionLabel={(option: any) => {
                          if (typeof option === 'string') {
                            return option;
                          }
                          if (option.inputValue) {
                            return option.inputValue;
                          }
                          return option.nameTh || null;
                        }}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        onChange={(event, newValue) => {
                          field.onChange(newValue);
                          if (newValue?.id) {
                            setValue("district", undefined);
                            setValue("subDistrict", undefined);
                            setValue("zipCode", "");
                            props.onFetchDistrict(newValue.id);
                            props.onFetchSubDistrict(undefined);
                          }
                        }}
                        renderInput={(params) => <TextField
                          error={!!errors.province}
                          helperText={errors.province ? "กรุณาเลือกจังหวัด" : ""}
                          {...params} label="จังหวัด" />}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Controller
                    name="district"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        disabled={!getValues("province")?.id}
                        value={field?.value as IDistrict || null}
                        options={props.districtData || []}
                        getOptionLabel={(option: any) => {
                          if (typeof option === 'string') {
                            return option;
                          }
                          if (option.inputValue) {
                            return option.inputValue;
                          }
                          return option.nameTh || null;
                        }}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        onChange={(event, newValue) => {
                          field.onChange(newValue);
                          if (newValue?.id) {
                            setValue("subDistrict", undefined);
                            setValue("zipCode", "");
                            props.onFetchSubDistrict(newValue.id);
                          }
                        }}
                        renderInput={(params) => <TextField
                          error={!!errors.district}
                          helperText={errors.district ? "กรุณาเลือกอําเภอ" : ""}
                          {...params} label="อําเภอ" />}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Controller
                    name="subDistrict"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Autocomplete
                        disabled={!getValues("district")?.id}
                        {...field}
                        value={field?.value as ISubDistrict || null}
                        options={props.subDistrictData || []}
                        getOptionLabel={(option: any) => {
                          if (typeof option === 'string') {
                            return option;
                          }
                          if (option.inputValue) {
                            return option.inputValue;
                          }
                          return option.nameTh || null;
                        }}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        onChange={(event, newValue: any) => {
                          field.onChange(newValue);
                          if (newValue?.zipCode) {
                            setValue("zipCode", newValue.zipCode);
                          }
                        }}
                        renderInput={(params) => <TextField
                          error={!!errors.subDistrict}
                          helperText={errors.subDistrict ? "กรุณาเลือกตําบล" : ""}
                          {...params} label="ตําบล" />}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <TextField
                    label="รหัสไปรษณีย์"
                    fullWidth
                    {...register("zipCode", { required: true })}
                    error={!!errors.zipCode}
                    helperText={errors.zipCode ? "กรุณาระบุรหัสไปรษณีย์" : " "}
                    InputLabelProps={{
                      shrink: getValues("zipCode") ? true : undefined,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <TextField
                    fullWidth
                    label="สาขา"
                    {...register("branch")}
                    InputLabelProps={{
                      shrink: getValues("branch") ? true : undefined
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <TextField
                    fullWidth
                    label="ชื่อผู้ติดต่อ"
                    {...register("contract")}
                    InputLabelProps={{
                      shrink: getValues("contract") ? true : undefined
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <TextField
                    fullWidth
                    label="เบอร์โทร"
                    {...register("tel")}
                    InputLabelProps={{
                      shrink: getValues("tel") ? true : undefined
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <FormControl fullWidth>
                    <FormControlLabel
                      control={<Checkbox color="primary"  {...register("isOrchard")} checked={getValues('isOrchard') ? true : false} />}
                      label="แสดงในเมนูรับจากสวน"
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12} mt={2} md={12} lg={12}>
                  <Divider />
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="right"
                  alignItems="center"
                  mt={2}
                >
                  <Button
                    variant="outlined"
                    style={{ marginRight: "10px", width: "15%" }}
                    onClick={() => navigate("/supplier-list")}
                  >
                    ยกเลิก
                  </Button>
                  {props.permission.create || (props.permission.edit && props.supplier.id) ?
                    <Button
                      variant="contained"
                      style={{ width: "15%" }}
                      type="submit"

                    >
                      บันทึก
                    </Button>
                    : <></>}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <DialogConfirm
        open={openConfirm.open}
        type={openConfirm.type === "delete" ? "error" : "success"}
        title={openConfirm.title}
        message={openConfirm.message}
        onClose={() => setOpenConfirm({ ...openConfirm, open: false })}
        onSubmit={() => {
          setOpenConfirm({ ...openConfirm, open: false });
          if (openConfirm.type === "delete") {
            onDelete();
          } else {
            onConfirm();
          }
        }}
      />
      <SupplierTypeCreateView
        open={openSupplierType}
        onClose={() => setOpenSupplierType(false)}
        onSubmit={(data: ISupplierType) => {
          setOpenSupplierType(false);
          props.onCreateSupplierType(data);
        }}
        data={{} as ISupplierType}
        permission={props.permission}
        name={name}
      />
      <SimpleBackdrop open={props.isLoading} />
    </Box>
  );
};

export default SupplierCreateView;