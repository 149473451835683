import { useEffect, useState } from "react";
import CompanyView from "../Views/CompanyView";
import CompanyService, { ICompany, IUploadFile } from "../Services/CompanyService";
import { enqueueSnackbar } from "notistack";
import { observer } from "mobx-react-lite";
import companyService from "../Services/CompanyService";
import getPermissionBySubMenu from "../../../utils/getPermissionBySubMenu";
import { IUserDetail } from "../../Login/Services/LoginService";

const CompanyController = observer(() => {
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const id = user.company?.id || undefined;
  const [isLoading, setIsLoading] = useState(false);
  const [permission, setPermission] = useState<IUserDetail>();

  useEffect(() => {
    if (id) {
      getOne(id);
    }
    checkPermissionActionButton();
    getProvince();
  }, [id]);

  const getOne = async (id: string) => {
    try {
      setIsLoading(true);
      const res = await CompanyService.getOne(id);
      if (res) {
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const getProvince = async () => {
    try {
      setIsLoading(true);
      const res = await CompanyService.getProvince();
      if (res) {
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const getDistrict = async (id?: number) => {
    if (id) {
      try {
        setIsLoading(true);
        const res = await CompanyService.getDistrict(id);
        if (res) {
          setIsLoading(false);
        }
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    }
  };

  const getSubDistrict = async (id?: number) => {
    if (id) {
      try {
        setIsLoading(true);
        const res = await CompanyService.getSubDistrict(id);
        if (res) {
          setIsLoading(false);
        }
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    }
  };
  const create = async (data: ICompany, uploadFile: IUploadFile) => {
    try {
      setIsLoading(true);
      const res = await CompanyService.create(data, { file: uploadFile });
      if (res) {
        localStorage.setItem("user", JSON.stringify({ ...user, company: res }));
        enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      } else {
        enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      }
      setIsLoading(false);
    } catch (error) {
      console.error("เกิดข้อผิดพลาดในการบันทึก", error);
      setIsLoading(false);
    }
  };

  const update = async (id: string, data: any, uploadFile: IUploadFile) => {
    try {
      setIsLoading(true);
      const _uploadFile = data.image === uploadFile ? undefined : uploadFile;
      const res = await CompanyService.update(id, data, { file: _uploadFile });
      if (res) {
        enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      } else {
        enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      }
      setIsLoading(false);
    } catch (error) {
      console.error("เกิดข้อผิดพลาดในการอัพเดท", error);
      setIsLoading(false);
    }
  };

  const checkPermissionActionButton = () => {
    const result = getPermissionBySubMenu("setting-company");
    if (result) {
      setPermission(result);
    }
  };

  return <CompanyView
    companyId={id || ""}
    onUpdate={(id, data, uploadFile) => {
      update(id, data, uploadFile);
    }}
    onCreate={create}
    company={companyService.companyData}
    uploadFile={{} as IUploadFile}
    provinceData={companyService.provinceData || []}
    districtData={companyService.districtData || []}
    subDistrictData={companyService.subDistrictData || []}
    onFetchDistrict={(id) => getDistrict(id)}
    onFetchSubDistrict={(id) => getSubDistrict(id)}
    isLoading={isLoading}
    permission={permission ?? {} as IUserDetail}
  />;
});

export default CompanyController;
