import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import packagingService, { IPackagingListView, IPackagingCriteria, IPrintPackingList, IPackaging } from "../Services/PackagingService";
import getPermissionBySubMenu from "../../../utils/getPermissionBySubMenu";
import PackagingListView from "../Views/PackagingListView";
import { IUserDetail } from "../../Login/Services/LoginService";
import { clearLocalStorageKeyOnUnload, getDateRangeFromStorage } from "../../../utils/dateRangeLocalStorage";
import { STORAGE_KEYS } from "../../../constants/dateRangeStorageKey";

const PackagingListController = observer(() => {
  const initialDateRange = getDateRangeFromStorage(STORAGE_KEYS.PROCESS);

  const [isLoading, setIsLoading] = useState(false);
  const [permission, setPermission] = useState<IUserDetail>();
  const [packagesData, setPackagesData] = useState<IPackagingListView>();
  const [isPrint, setIsPrint] = useState(false);
  const defaultCriteria: IPackagingCriteria = {
    dateFrom: initialDateRange.dateFrom
      ? dayjs(initialDateRange.dateFrom).format('YYYY-MM-DD')
      : dayjs().startOf('month').format('YYYY-MM-DD'),
    dateTo: initialDateRange.dateTo
      ? dayjs(initialDateRange.dateTo).format('YYYY-MM-DD')
      : dayjs().startOf('day').format('YYYY-MM-DD'),
    docNo: '',
    status: 'all',
    order: '',
    page: 0,
    limit: 10,
  };
  useEffect(() => {
    fetchOrdersByCriteria(defaultCriteria);
    checkPermissionActionButton();
    setIsPrint(false);
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , []);

  clearLocalStorageKeyOnUnload(STORAGE_KEYS.PROCESS);

  const fetchOrdersByCriteria = async (criteria: IPackagingCriteria) => {
    try {
      setIsLoading(true);
      await packagingService.getByCriteria(criteria).then((data) => {
        setPackagesData(data);
      }
      );
    } catch (error) {
      console.error("An error occurred while getting the packages:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const checkPermissionActionButton = () => {
    const result = getPermissionBySubMenu("process-packaging");
    if (result) {
      setPermission(result);
    }
  };

  const getOne = async (id: string) => {
    try {
      setIsPrint(false);
      await packagingService.getOne(id);
      setIsPrint(true);
    } catch (error) {
      console.error("Error fetching product:", error);
    }
  };

  return <PackagingListView
    isLoading={isLoading}
    packagings={packagesData ?? {} as IPackagingListView}
    onSearchByCriteria={fetchOrdersByCriteria}
    permission={permission ?? {} as IUserDetail}
    onGetOne={getOne}
    packaging={packagingService.packagingData ?? {} as IPackaging}
    printPackingListData={packagingService.printPackingListData ?? {} as IPrintPackingList}
    isPrint={isPrint}
  />;
});

export default PackagingListController;