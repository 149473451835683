export const entryTypeExpense = {
  income: {
    name: "รายรับ",
    value: "income",
    color: "success"
  },
  expense: {
    name: "รายจ่าย",
    value: "expense",
    color: "error"
  }
};