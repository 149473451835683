
import supplierService, { ISupplier } from "../Services/SupplierService";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import supplierTypeService, { ISupplierType } from "../Services/SupplierTypeService";
import { observer } from "mobx-react-lite";
import SupplierCreateView from "../Views/SupplierCreateView";
import companyService from "../../Company/Services/CompanyService";
import getPermissionBySubMenu from "../../../utils/getPermissionBySubMenu";
import { IUserDetail } from "../../Login/Services/LoginService";

const SupplierCreateController = observer(() => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [permission, setPermission] = useState<IUserDetail>();
  useEffect(() => {
    getSupplierType();
    getProvince();
    if (id) {
      getOne(id);
    }
    checkPermissionActionButton();
  }
    , [id]);

  const getProvince = async () => {
    try {
      setIsLoading(true);
      await companyService.getProvince();
    } catch (error) {
      console.error("An error occurred while getting the province:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getDistrict = async (id?: number) => {
    if (!id) return;
    try {
      setIsLoading(true);
      await companyService.getDistrict(id);
    } catch (error) {
      console.error("An error occurred while getting the district:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getSubDistrict = async (id?: number) => {
    if (!id) return;
    try {
      setIsLoading(true);
      await companyService.getSubDistrict(id);
    } catch (error) {
      console.error("An error occurred while getting the sub-district:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getOne = async (id: string) => {
    await supplierService.getOne(id);
  };

  const getSupplierType = async () => {
    await supplierTypeService.getAll();
  };

  const createSupplier = async (supplier: ISupplier) => {
    try {
      setIsLoading(true);
      const response = await supplierService.create(supplier);
      if (response) {
        enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
        navigate(`/supplier-edit/${response.id}`);
      }
    } catch (error) {
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
    }
    finally {
      setIsLoading(false);
    }
  };

  const updateSupplier = async (supplier: ISupplier) => {
    try {
      setIsLoading(true);
      await supplierService.update(supplier);
      enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      getOne(supplier.id);

    } catch (error) {
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
    }
    finally {
      setIsLoading(false);
    }
  };

  const deleteSupplier = async (supplier: ISupplier) => {
    try {
      setIsLoading(true);
      await supplierService.update(supplier);
      enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      navigate(`/supplier`);
    } catch (error) {
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
    }
    finally {
      setIsLoading(false);
    }
  };

  const checkPermissionActionButton = () => {
    const result = getPermissionBySubMenu("supplier-list");
    if (result) {
      setPermission(result);
    }
  };


  //#region SupplierType
  const createSupplierType = async (supplierType: ISupplierType) => {
    try {
      await supplierTypeService.create(supplierType);
      enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      getSupplierType();
    } catch (error) {
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
    }
  };
  //#endregion

  return (
    <SupplierCreateView
      supplier={id ? supplierService.supplierData ?? {} as ISupplier : {} as ISupplier}
      supplierTypes={supplierTypeService.supplierTypeListData ?? [] as ISupplierType[]}
      onCreate={(data: ISupplier) => {
        createSupplier(data);
      }}
      onUpdate={(data: ISupplier) => {
        updateSupplier(data);
      }}
      onDelete={(data: ISupplier) => {
        deleteSupplier(data);
      }}
      isLoading={isLoading}
      provinceData={companyService.provinceData || []}
      districtData={companyService.districtData || []}
      subDistrictData={companyService.subDistrictData || []}
      onFetchDistrict={getDistrict}
      onFetchSubDistrict={getSubDistrict}
      permission={permission ?? {} as IUserDetail}
      // supplierType
      onCreateSupplierType={createSupplierType}
    />
  );
});


export default SupplierCreateController;