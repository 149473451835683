import apiService from "../../../services/ApiService";
import { IAllTotalValue } from "../../Report/Services/ReportService";
import { configure, makeAutoObservable } from "mobx";

export interface IDashboard {
  datas?: IDashboardDatas[] | undefined;
}

export interface IDashboardDatas {
  month: string;
  cost: number;
  sales: number;
  profit: number;
  loss: number;
}

export interface IDashboardCriteria {
  month?: string | number | undefined;
  year?: number;
}
class DashboardService {
  dashboardDatas: IDashboard | null = null;
  totalValue: IAllTotalValue | null = null;

  constructor() {
    configure({
      enforceActions: "never",
    });
    makeAutoObservable(this);
  }

  async getDashboardDatas(criteria: IDashboardCriteria) {
    try {
      const result = await apiService.post("report/dashboard", criteria);
      this.dashboardDatas = result;
      return this.dashboardDatas;
    } catch (error) {
      throw error;
    }
  }

  async getTotalValue(criteria: IDashboardCriteria) {
    try {
      const result = await apiService.post("report/total-value", criteria);
      this.totalValue = result;
      return this.totalValue;
    } catch (error) {
      throw error;
    }
  }

  get dashboardData() {
    return this.dashboardDatas;
  }

  get totalValueData() {
    return this.totalValue;
  }
}

const dashboardService = new DashboardService();
export default dashboardService;
