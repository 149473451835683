import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import getPermissionBySubMenu from "../../../utils/getPermissionBySubMenu";
import { IUserDetail } from "../../Login/Services/LoginService";
import shippingService, { IPrintShippingPackingList, IShippingCriteria, IShippingList } from "../Services/ShippingService";
import ShippingsListView from "../Views/ShippingsListView";
import dayjs from "dayjs";
import { clearLocalStorageKeyOnUnload, getDateRangeFromStorage } from "../../../utils/dateRangeLocalStorage";
import { STORAGE_KEYS } from "../../../constants/dateRangeStorageKey";

const ShippingListController = observer(() => {
  const initialDateRange = getDateRangeFromStorage(STORAGE_KEYS.PROCESS);
  const [isLoading, setIsLoading] = useState(false);
  const [permission, setPermission] = useState<IUserDetail>();
  const [datas, setData] = useState<IShippingList>();
  const [isPrint, setIsPrint] = useState(false);
  const defaultCriteria: IShippingCriteria = {
    dateFrom: initialDateRange.dateFrom
      ? dayjs(initialDateRange.dateFrom).format('YYYY-MM-DD')
      : dayjs().startOf('month').format('YYYY-MM-DD'),
    dateTo: initialDateRange.dateTo
      ? dayjs(initialDateRange.dateTo).format('YYYY-MM-DD')
      : dayjs().startOf('day').format('YYYY-MM-DD'),
    docNo: '',
    status: 'all',
    order: '',
    page: 0,
    limit: 10,
  };

  useEffect(() => {
    fetchByCriteria(defaultCriteria);
    checkPermissionActionButton();
    setIsPrint(false);
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , []);

  clearLocalStorageKeyOnUnload(STORAGE_KEYS.PROCESS);

  const fetchByCriteria = async (criteria: IShippingCriteria) => {
    try {
      setIsLoading(true);
      await shippingService.getByCriteria(criteria).then((data) => {
        setData(data);
      }
      );
    } catch (error) {
      console.error("An error occurred while getting the shippings:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getOne = async (id: string) => {
    try {
      setIsPrint(false);
      await shippingService.getOne(id);
      setIsPrint(true);
    } catch (error) {
      console.error("Error fetching product:", error);
    }
  };

  const checkPermissionActionButton = () => {
    const result = getPermissionBySubMenu("process-shipping");
    if (result) {
      setPermission(result);
    }
  };

  return <ShippingsListView
    isLoading={isLoading}
    datas={datas ?? {} as IShippingList}
    onSearchByCriteria={fetchByCriteria}
    permission={permission ?? {} as IUserDetail}
    onGetOne={getOne}
    isPrint={isPrint}
    printShippingPackingList={shippingService.printShippingPackingListData ?? {} as IPrintShippingPackingList}
  />;
});

export default ShippingListController;