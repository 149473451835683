import { FC, useState } from "react";
import { Box, Grid, Card, CardContent, Tab, Tabs, Typography, Link } from "@mui/material";

interface ManualCreateViewProps { }
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const ManualCreateView: FC<ManualCreateViewProps> = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <Box component={'div'}>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Card>
            <CardContent>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                  <Tab label="คู่มือการใช้งาน" />
                  <Tab label="วิดีโอการใช้งาน" />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <Typography>
                  <Link href="https://www.bigbuckbunny.org/" target="_blank">เอกสารคู่มือการใช้งานระบบบริหารจัดการ WSMS</Link>
                </Typography>
                <Box
                  component="div"
                  mt={2}
                  style={{ width: '100%', height: '100vh', overflow: 'hidden', }}
                >
                  <iframe
                    src={`${process.env.PUBLIC_URL}/assets/WSMS_manual.pdf`}
                    title="PDF Viewer"
                    width="100%"
                    height="100%"
                    style={{ border: 'none' }}
                  />
                </Box>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <Typography>
                  <Link href="https://drive.google.com/file/d/1bas9R4_QQDtS68BSBx-frxnIAqlRv4lo/view?usp=sharing" target="_blank">วิดีโอคู่มือการใช้งานระบบบริหารจัดการ WSMS</Link>
                </Typography>
                <Box component={'div'} style={{ width: '100%', height: '100vh' }} mt={2}>
                  <iframe
                    src="https://drive.google.com/file/d/1bas9R4_QQDtS68BSBx-frxnIAqlRv4lo/preview" width="100%" height="480" allow="autoplay"
                    title="Video Player"

                  ></iframe>
                </Box>

              </CustomTabPanel>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ManualCreateView;
