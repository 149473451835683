import { useEffect, useState } from "react";
import DashboardView from "../Views/DashboardView";
import { observer } from "mobx-react-lite";
import dashboardService, { IDashboardCriteria } from "../Services/DashboardService";

const DashboardController = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const defaultCriteriaDashboard: IDashboardCriteria = {
    year: new Date().getFullYear(),
  };
  const defaultCriteriaTotalValue: IDashboardCriteria = {
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  };
  useEffect(() => {
    getTotalValue(defaultCriteriaTotalValue);
    getDashboardData(defaultCriteriaDashboard);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);




  const getDashboardData = async (criteria: IDashboardCriteria) => {
    try {
      setIsLoading(true);
      await dashboardService.getDashboardDatas(criteria);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getTotalValue = async (criteria: IDashboardCriteria) => {
    try {
      setIsLoading(true);
      await dashboardService.getTotalValue(criteria);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  return <DashboardView isLoading={isLoading} dashboardData={dashboardService.dashboardData} getDashboardData={getDashboardData} totalValueData={dashboardService.totalValueData} getTotalValue={getTotalValue} />;
});

export default DashboardController;