import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import reportService, { IProfitReport, IProfitReports, IReportCriteria } from "../Services/ReportService";
import ProfitReportListView from "../Views/ProfitReportListView";
import dayjs from "dayjs";
import { STORAGE_KEYS } from "../../../constants/dateRangeStorageKey";
import { getDateRangeFromStorage, clearLocalStorageKeyOnUnload } from "../../../utils/dateRangeLocalStorage";


const ProfitReportListController = observer(() => {
  const initialDateRange = getDateRangeFromStorage(STORAGE_KEYS.REPORT);
  const [isLoading, setIsLoading] = useState(false);
  const [profitReportData, setProfitReportData] = useState<IProfitReports>();
  const defaultCriteria: IReportCriteria = {
    dateFrom: initialDateRange.dateFrom
      ? dayjs(initialDateRange.dateFrom).format('YYYY-MM-DD')
      : dayjs().startOf('month').format('YYYY-MM-DD'),
    dateTo: initialDateRange.dateTo
      ? dayjs(initialDateRange.dateTo).format('YYYY-MM-DD')
      : dayjs().startOf('day').format('YYYY-MM-DD'),
    page: 0,
    limit: 10,
  };

  useEffect(() => {
    getProfitReportData(defaultCriteria);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  clearLocalStorageKeyOnUnload(STORAGE_KEYS.REPORT);

  const getProfitReportData = async (criteria: IReportCriteria) => {
    try {
      setIsLoading(true);
      await reportService.getProfitReports(criteria).then((data) => {
        data?.datas?.sort((a: IProfitReport, b: IProfitReport) => (b.orderDate?.toString() ?? '').localeCompare(a.orderDate?.toString() ?? ''));
        setProfitReportData(data);
      });
    } catch (error) {
      console.error("An error occurred while getting the loss reports:", error);
    } finally {
      setIsLoading(false);
    }
  };




  return <ProfitReportListView isLoading={isLoading} profitReportData={profitReportData ?? {} as IProfitReports}
    getProfitReportData={getProfitReportData}
  />;
});

export default ProfitReportListController;
