import { FC, useEffect, useState } from "react";
import { IDistrict, IProduct, IProvince, ISubDistrict } from "../Services/ProductService";
import { Controller, useForm } from "react-hook-form";
import { Autocomplete, Box, Button, ButtonProps, createFilterOptions, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, TextField, Typography } from "@mui/material";
import { ISupplier } from "../../Supplier/Services/SupplierService";
import DialogConfirm from "../../../components/DialogConfirm";
import DialogCreate from "../../../components/DialogCreate";
import { ISupplierType } from "../../Supplier/Services/SupplierTypeService";
import { supplierDatas } from "../../../constants/dialogCreateInputs";
import AddCircleIcon from '@mui/icons-material/AddCircle';

interface ProductCreateViewProps {
  open: boolean;
  suppliers: ISupplier[];
  onClose: () => void;
  onCreate: (data: IProduct) => void;
  provinceData?: IProvince[];
  districtData?: IDistrict[];
  subDistrictData?: ISubDistrict[];
  onFetchData: (id?: any, type?: string) => void;
  onCreateDialog: (data: any, type: string) => void;
  supplierTypesOptions?: ISupplierType[];
  name?: string;
}
const filterOptions = createFilterOptions<any>();
const ProductCreateView: FC<ProductCreateViewProps> = (props) => {
  const { register, handleSubmit, reset, getValues, formState: { errors }, control } = useForm<IProduct>();

  const [openConfirm, setOpenConfirm] = useState({
    open: false,
    title: "",
    message: "",
    color: "primary" as ButtonProps["color"],
    type: "create" as "create",
  });

  const [openCreateDailog, setOpenCreateDailog] = useState({ open: false, datas: [], type: "", title: "" });
  const [nameType, setNameType] = useState("");

  // NOTE: supplier input
  const datasInChildCreateDialog = [{ type: "text", label: "ประเภท", name: "name", xs: 12, sm: 12, md: 12, lg: 12, defaultValue: "" },];
  let _supplierDatas: any = supplierDatas({
    provinceData: props?.provinceData || [],
    districtData: props?.districtData || [],
    subDistrictData: props?.subDistrictData || [],
    datasInChildCreateDialog: datasInChildCreateDialog || [],
    supplierTypesOptions: props?.supplierTypesOptions || [],
  }) || [];

  const handleFormSubmit = (data: IProduct) => {
    props.onCreate(data);
    props.onClose();
    reset();
  };

  const onSubmit = handleSubmit(() => {
    setOpenConfirm({
      open: true,
      title: "บันทึกข้อมูล",
      message: "คุณต้องการบันทึกข้อมูลนี้ใช่หรือไม่",
      color: "primary",
      type: "create",
    });
  });

  const onConfirm = () => {
    handleFormSubmit(getValues());
  };

  const onClose = () => {
    props.onClose();
    setOpenConfirm({ ...openConfirm, open: false });
  };

  const handleOpenCreateDailog = (name: string) => {
    setNameType(name);
    switch (name) {
      case "supplier": setOpenCreateDailog(
        {
          open: true, datas: _supplierDatas, type: "supplier", title: "สร้าง Supplier"
        }
      );
        break;
    }
  };

  useEffect(() => {
    if (props.open) {
      reset({
        name: props.name || ""
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open, reset]);

  useEffect(() => {
    handleOpenCreateDailog(nameType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.districtData, props.subDistrictData, props.supplierTypesOptions]);

  return (
    <Dialog open={props.open} fullWidth maxWidth={"lg"}>
      <DialogTitle>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography variant="h6">สร้างผลิตภัณฑ์</Typography>
          </Grid>
        </Grid>

      </DialogTitle>
      <DialogContent>
        <Box component="form" onSubmit={onSubmit}>
          <Grid container spacing={2} mt={1}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <TextField
                fullWidth
                label="ชื่อผลิตภัณฑ์"
                {...register("name", { required: true })}
                error={!!errors.name}
                helperText={errors.name ? "กรุณาระบุชื่อผลิตภัณฑ์" : ""}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Controller
                name="supplier"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    value={value || null}
                    options={Array.isArray(props.suppliers) ? props.suppliers : []}
                    getOptionLabel={(option: any) => {
                      if (typeof option === 'string') {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option.name || null;
                    }}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    onChange={(_, newValue) => {
                      if (newValue?.id === "create") {
                        _supplierDatas[0].defaultValue = newValue?.name?.replace('สร้าง ', '').replace(/'/g, '');
                        handleOpenCreateDailog("supplier");
                        onChange(null);
                      } else if (newValue?.id) { onChange(newValue); }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filterOptions(options, params);
                      if (params.inputValue !== '') {
                        filtered.push({
                          name: `สร้าง '${params.inputValue}'`,
                          id: 'create',
                        });
                      }
                      return filtered;
                    }}
                    renderOption={(props, option) => {
                      const { key, ...rest } = props;
                      return (
                        <Typography key={key} {...rest} variant="body2" >{option?.id === "create" ? <Box component={'span'} sx={{ color: "primary.main", display: "flex", alignItems: "center" }}>
                          <AddCircleIcon /><Box component={'span'} ml={1}> {option.name}</Box>
                        </Box> : option.name}</Typography>
                      );
                    }}
                    renderInput={(params) => <TextField
                      error={!!errors.supplier}
                      helperText={errors.supplier ? "กรุณาเลือกป Supplier" : ""}
                      {...params} label="Supplier" />}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField
                fullWidth
                label="รายละเอียด"
                {...register("remark")}
              />
            </Grid>
            <Grid item xs={12} sm={12} mt={2} md={12} lg={12}>
              <Divider />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          ยกเลิก
        </Button>
        <Button type="submit" onClick={onSubmit} variant="contained" color="primary">
          บันทึก
        </Button>
      </DialogActions>
      <DialogConfirm
        open={openConfirm.open}
        type="success"
        title={openConfirm.title}
        message={openConfirm.message}
        onClose={() => setOpenConfirm({ ...openConfirm, open: false })}
        onSubmit={() => {
          setOpenConfirm({ ...openConfirm, open: false });
          onConfirm();
        }}
      />
      <DialogCreate datas={openCreateDailog?.datas} open={openCreateDailog?.open} title={openCreateDailog?.title} type={openCreateDailog?.type} onClose={() => setOpenCreateDailog({ ...openCreateDailog, open: false })} onSubmit={(data, type) => { props?.onCreateDialog(data, type); }} onFetchData={(id, type) => {
        props?.onFetchData(id, type);
      }} onCreateDialog={(data, type) => { props?.onCreateDialog(data, type); }} />
    </Dialog>
  );
};

export default ProductCreateView;