import { FC, useEffect, useState } from "react";
import { IGrouping } from "../Services/GroupingService";
import { IMaterial } from "../Services/MaterialsService";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { Box, Breadcrumbs, Button, ButtonProps, Card, CardContent, Divider, FormControl, FormHelperText, Grid, IconButton, InputLabel, MenuItem, Select, Stack, TextField, Typography, useMediaQuery, Link, FormControlLabel, Checkbox, Autocomplete, createFilterOptions, } from "@mui/material";
import { theme } from "../../../themes";
import { useNavigate } from "react-router-dom";
import DialogConfirm from "../../../components/DialogConfirm";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SimpleBackdrop from "../../../components/SimpleBackdrop";
import { IUserDetail } from "../../Login/Services/LoginService";
import MaterialsCreateView from "./MaterialCreateView";


interface GroupingCreateViewProps {
  onCreate: (data: IGrouping) => void;
  onUpdate: (id: string, data: IGrouping) => void;
  onDelete: (data: IGrouping) => void;
  groupingData?: IGrouping;
  mainMaterialsData?: IMaterial[];
  subMaterialsData?: IMaterial[];
  groupingId?: string;
  isLoading?: boolean;
  permission?: IUserDetail;
  onCreateDialog: (data: any, type: string) => void;
}
const filterOptions = createFilterOptions<any>();
const GroupingCreateView: FC<GroupingCreateViewProps> = (props) => {
  const { control, handleSubmit, reset, getValues, setValue, formState: { errors } } = useForm<IGrouping>();
  const [openConfirm, setOpenConfirm] = useState({
    open: false,
    title: "",
    message: "",
    color: "primary" as ButtonProps["color"],
    type: "create" as "create" | "update" | "delete",
  });

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const [selectedMaterialUnits, setSelectedMaterialUnits] = useState<{ [key: string]: string[]; }>({});
  const [openCreateDailog, setOpenCreateDailog] = useState({ open: false, datas: [], type: "", title: "" });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "details",
  });
  const [name, setName] = useState("");

  const onFormSubmit = (data: IGrouping) => {
    if (props.groupingId) {
      props.onUpdate(props.groupingId, data);
    } else {
      props.onCreate(data);
    }
  };

  const onSubmit = (type: "create" | "delete") => {
    if (type === "delete") {
      setOpenConfirm({
        open: true,
        title: "ลบข้อมูล",
        message: "คุณต้องการลบข้อมูลนี้ใช่หรือไม่",
        color: "error",
        type: "delete",
      });
    } else {
      handleSubmit(() => {
        setOpenConfirm({
          open: true,
          title: "บันทึกข้อมูล",
          message: "คุณต้องการบันทึกข้อมูลนี้ใช่หรือไม่",
          color: "primary",
          type: "create",
        });
      })();
    }
  };

  const onDelete = () => {
    const data = getValues();
    props.onDelete({ ...data, isDelete: true });
  };

  const handleOpenCreateDailog = (name: string) => {
    switch (name) {
      case "material": setOpenCreateDailog(
        {
          open: true, datas: [], type: "material", title: "เพิ่มรายการวัสดุ"
        }
      );
        break;
    }
  };

  useEffect(() => {
    if (props.subMaterialsData) {
      const units = props.subMaterialsData.reduce((acc, material) => {
        if (material.unit || material.subUnit) {
          acc[material.id] = [material.unit, ...(material.subUnit ? [material.subUnit] : [])];
        }
        return acc;
      }, {} as { [key: string]: string[]; });

      setSelectedMaterialUnits(units);
    }
  }, [props.subMaterialsData]);

  useEffect(() => {
    if (props.groupingData && props.groupingId) {
      reset(props.groupingData);
    } else {
      reset({
        details: [{
          order: 1,
          subMaterial: {} as IMaterial,
          qty: 0,
          unit: "",
          isCalculate: false,
        }],
      });

    }
  }, [props.groupingData, reset, props.groupingId]);

  return (
    <Box component={'div'}>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={1} sm={1} md={0.5} lg={0.5}>
          <IconButton
            aria-label="back"
            onClick={() => navigate("/inventory-grouping")}
          >
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={11} sm={8} md={8.5} lg={8.5} display={"flex"} alignItems={"center"}>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Typography>กลุ่มวัสดุ</Typography>
            <Link component="button" onClick={() => navigate("/inventory-grouping")}>
              รายการ
            </Link>
            <Typography variant="h6" color="text.primary">
              {props.groupingId ? "แก้ไข" : "สร้าง"}
            </Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12} sm={3} md={3} lg={3} display={"flex"} justifyContent={"end"}>
          {(props.groupingId && props.permission?.delete) && (
            <Button
              variant="contained"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={() => onSubmit("delete")}
            >
              ลบข้อมูล
            </Button>
          )}
        </Grid>
      </Grid>

      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Card>
            <CardContent>
              <Box component="form" onSubmit={handleSubmit(onFormSubmit)} mt={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Controller
                      name="name"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <TextField label="ชื่อกลุ่มวัสดุ"
                          value={value || ""} fullWidth
                          onChange={(e) => {
                            onChange(e.target.value);
                          }}
                          error={!!errors.name}
                          helperText={errors.name ? "กรุณากรอกชื่อกลุ่มวัสดุ" : ""}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Controller
                      name="mainMaterial"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          value={value || null}
                          options={props.mainMaterialsData || []}
                          getOptionLabel={(option: any) => {
                            if (typeof option === 'string') {
                              return option;
                            }
                            if (option.inputValue) {
                              return option.inputValue;
                            }
                            return option.name || "";
                          }}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          onChange={(_, newValue) => {
                            if (newValue?.id === "create") {
                              handleOpenCreateDailog("material");
                              onChange(null);
                            } else if (newValue?.id) {
                              onChange(newValue);
                            }
                          }}
                          filterOptions={(options, params) => {
                            const filtered = filterOptions(options, params);
                            if (params.inputValue !== '') {
                              filtered.push({
                                name: `สร้าง '${params.inputValue}'`,
                                id: 'create',
                              });
                              setName(params.inputValue);
                            }
                            return filtered;
                          }}
                          renderOption={(props, option) => {
                            const { key, ...rest } = props;
                            return (
                              <Typography key={key} {...rest} variant="body2" >{option?.id === "create" ? <Box component={'span'} sx={{ color: "primary.main", display: "flex", alignItems: "center" }}>
                                <AddCircleIcon /><Box component={'span'} ml={1}> {option.name}</Box>
                              </Box> : option.name}</Typography>
                            );
                          }}
                          renderInput={(params) => <TextField
                            error={!!errors.mainMaterial}
                            helperText={errors.mainMaterial ? "กรุณาเลือกวัสดุหลัก" : ""}
                            {...params} label="วัสดุหลัก" />}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={6} sm={6} md={3} lg={3}>
                    <Controller
                      name="nwFrom"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextField label="น้ําหนักผลิตภัณฑ์ต่ำสุด"
                          value={value || 0}
                          type="number"
                          fullWidth
                          onChange={(e) => {
                            onChange(Number(e.target.value));
                          }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={6} sm={6} md={3} lg={3}>
                    <Controller
                      name="nwTo"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextField label="น้ําหนักผลิตภัณฑ์สูงสุด"
                          value={value || 0}
                          type="number"
                          fullWidth
                          onChange={(e) => {
                            onChange(Number(e.target.value));
                          }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={6} sm={6} md={3} lg={3}>
                    <Controller
                      name="ngFrom"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextField label="น้ําหนักผลิตภัณฑ์รวมกล่องต่ำสุด"
                          value={value || 0}
                          type="number"
                          fullWidth
                          onChange={(e) => {
                            onChange(Number(e.target.value));
                          }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={6} sm={6} md={3} lg={3}>
                    <Controller
                      name="ngTo"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextField label="น้ําหนักผลิตภัณฑ์รวมกล่องสูงสุด"
                          value={value || 0}
                          type="number"
                          fullWidth
                          onChange={(e) => {
                            onChange(Number(e.target.value));
                          }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} mt={2} md={12} lg={12}>
                    <Divider />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography variant="subtitle1">รายการวัสดุ</Typography>
                  </Grid>

                  {!isMobile && (
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={0.5} sm={0.5} md={0.5} lg={0.5} justifyContent={'center'} alignItems={'center'} display={'flex'}>

                        </Grid>
                        <Grid item xs={0.5} sm={0.5} md={0.5} lg={0.5}>
                          <Typography>ลำดับ</Typography>
                        </Grid>
                        <Grid item xs={5} sm={5} md={5} lg={5} justifyContent={'center'} display={'flex'}>
                          <Typography>รายการวัสดุ</Typography>
                        </Grid>
                        <Grid item xs={2} sm={2} md={2} lg={2}>
                          <Typography>จำนวน</Typography>
                        </Grid>
                        <Grid item xs={2} sm={2} md={2} lg={2}>
                          <Typography>หน่วย</Typography>
                        </Grid>
                        <Grid item xs={2} sm={2} md={2} lg={2} textAlign={"center"}>
                          <Typography>คิดตามจำนวน</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}

                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    {fields.map((item, index) => (
                      <Grid container spacing={2} key={item.id} mb={1}>
                        {!isMobile && (
                          <Grid item xs={0.5} sm={0.5} md={0.5} lg={0.5} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                            <IconButton
                              color="error"
                              onClick={() => remove(index)}
                            >
                              <RemoveCircleIcon />
                            </IconButton>
                          </Grid>
                        )}
                        <Grid item xs={12} sm={12} md={0.5} lg={0.5} justifyContent={isMobile ? "left " : "center"} alignItems={'center'} display={'flex'}>
                          {isMobile && "รายการที่ "}{index + 1}
                        </Grid>
                        <Grid item xs={12} sm={12} md={5} lg={5}>
                          <Controller
                            name={`details.${index}.subMaterial.id`}
                            control={control}
                            rules={{ required: 'กรุณาเลือกวัสดุ' }}
                            render={({ field: { onChange, value } }) => (
                              <FormControl fullWidth error={!!errors.details?.[index]?.subMaterial?.id}>
                                <Autocomplete
                                  options={props.subMaterialsData || []}
                                  getOptionLabel={(option) => option.name || ""}
                                  onChange={(e, newValue) => {
                                    const selectedId = newValue?.id || "";
                                    if (selectedId === "create") {
                                      handleOpenCreateDailog("material");
                                      onChange(null);
                                    } else if (selectedId) {
                                      onChange(selectedId);
                                      if (newValue) {
                                        setValue(`details.${index}.unit`, newValue.unit || "");
                                      }
                                    }
                                  }}
                                  isOptionEqualToValue={(option, value) => option.id === value.id}
                                  filterOptions={(options, params) => {
                                    const filtered = filterOptions(options, params);
                                    if (params.inputValue !== '') {
                                      filtered.push({
                                        name: `สร้าง '${params.inputValue}'`,
                                        id: 'create',
                                      });
                                      setName(params.inputValue);
                                    }
                                    return filtered;
                                  }}
                                  renderOption={(props, option) => {
                                    const { key, ...rest } = props;
                                    return (
                                      <Typography key={key} {...rest} variant="body2" >{option?.id === "create" ? <Box component={'span'} sx={{ color: "primary.main", display: "flex", alignItems: "center" }}>
                                        <AddCircleIcon /><Box component={'span'} ml={1}> {option.name}</Box>
                                      </Box> : option.name}</Typography>
                                    );
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={isMobile ? "วัสดุ" : ""}
                                      error={!!errors.details?.[index]?.subMaterial?.id}
                                      helperText={errors.details?.[index]?.subMaterial?.id && errors.details[index]?.subMaterial?.id?.message}
                                    />
                                  )}
                                  value={props.subMaterialsData?.find(material => material.id === value) || null}
                                />
                              </FormControl>
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2} lg={2}>
                          <Controller
                            name={`details.${index}.qty`}
                            control={control}
                            defaultValue={item.qty}
                            rules={{
                              required: 'กรุณาระบุจำนวน',
                              min: { value: 0, message: 'จำนวนต้องไม่ติดลบ' },
                            }}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                type="number"
                                label={isMobile ? "จำนวน" : ""}
                                fullWidth
                                error={!!errors.details?.[index]?.qty}
                                helperText={
                                  errors.details?.[index]?.qty?.message || ''
                                }
                                InputProps={{ inputProps: { min: 0 } }}
                                onChange={(e) => {
                                  field.onChange(e.target.value);
                                }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2} lg={2}>
                          <Controller
                            name={`details.${index}.unit`}
                            control={control}
                            rules={{
                              required: 'กรุณาระบุหน่วย',
                            }}
                            render={({ field }) => (
                              <FormControl fullWidth error={!!errors.details?.[index]?.unit}>
                                <InputLabel>{isMobile ? "หน่วย" : ""}</InputLabel>
                                <Select
                                  {...field}
                                  label={isMobile ? "หน่วย" : ""}
                                  onChange={(e) => field.onChange(e.target.value)}
                                  value={field.value || ""}
                                >
                                  {(selectedMaterialUnits[getValues(`details.${index}.subMaterial.id`)] || []).map((unit, i) => (
                                    <MenuItem key={i} value={unit}>
                                      {unit}
                                    </MenuItem>
                                  ))}
                                </Select>
                                <FormHelperText>
                                  {errors.details?.[index]?.unit && errors.details[index]?.unit?.message}
                                </FormHelperText>
                              </FormControl>
                            )}
                          />
                        </Grid>


                        <Grid item xs={12} sm={12} md={2} lg={2}>
                          <FormControl fullWidth>
                            <Box
                              display="flex"
                              justifyContent={isMobile ? "flex-start" : "center"}
                              alignItems="center"
                            >
                              <Controller
                                name={`details.${index}.isCalculate`}
                                control={control}
                                render={({ field }) => (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={field.value === true}
                                        onChange={(e) => field.onChange(e.target.checked)}
                                      />
                                    }
                                    label={isMobile ? "คิดตามจำนวน" : ""}
                                  />
                                )}
                              />
                            </Box>
                          </FormControl>
                        </Grid>
                      </Grid>
                    ))}
                    <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
                      <Grid container spacing={2}>
                        {isMobile ? <>
                          <Grid item xs={4} sm={4} >
                            <Button
                              variant="outlined"
                              color="error"
                              sx={{ width: '100%' }}
                              type="button"
                              startIcon={<RemoveCircleIcon />}
                              onClick={() => remove(fields.length - 1)}
                            >
                              ลบ
                            </Button>
                          </Grid>
                          <Grid item xs={8} sm={8} >
                            <Button
                              variant="outlined"
                              sx={{ width: '100%' }}
                              type="button"
                              startIcon={<AddCircleIcon />}
                              onClick={() => append({
                                order: fields.length + 1,
                                subMaterial: {} as IMaterial,
                                qty: 0,
                                unit: "",
                                isCalculate: false,
                              })}
                            >
                              เพิ่มรายการ
                            </Button>
                          </Grid></> : <Grid item md={12} lg={12}>
                          <Button
                            variant="outlined"
                            sx={{ width: '100%' }}
                            type="button"
                            startIcon={<AddCircleIcon />}
                            onClick={() => append({
                              order: fields.length + 1,
                              subMaterial: {} as IMaterial,
                              qty: 0,
                              unit: "",
                              isCalculate: false,
                            })}
                          >
                            เพิ่มรายการ
                          </Button>
                        </Grid>
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12} mt={2} mb={2} md={12} lg={12}>
                  <Divider />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} >
                  <Grid container spacing={2}>
                    <Grid item xs={12} display={"flex"} justifyContent={"end"}>
                      <Stack spacing={2} direction="row">
                        <Button
                          variant="outlined"
                          fullWidth
                          onClick={() => navigate("/inventory-grouping")}
                        >
                          ยกเลิก
                        </Button>
                        {props.permission?.create || (props.permission?.edit && props.groupingId) ?
                          <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => onSubmit("create")}
                            style={{ marginLeft: '8px' }}
                          >
                            บันทึก
                          </Button>
                          : <></>}
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <DialogConfirm
        open={openConfirm.open}
        type={openConfirm.type === "delete" ? "error" : "success"}
        title={openConfirm.title}
        message={openConfirm.message}
        onClose={() => setOpenConfirm({ ...openConfirm, open: false })}
        onSubmit={() => {
          setOpenConfirm({ ...openConfirm, open: false });
          if (openConfirm.type === "delete") {
            onDelete();
          } else {
            handleSubmit(onFormSubmit)();
          }
        }}
      />
      <SimpleBackdrop open={props.isLoading ?? false} />
      <MaterialsCreateView open={openCreateDailog?.open && openCreateDailog?.type === "material"} onClose={() => setOpenCreateDailog({ ...openCreateDailog, open: false })} onCreate={(data) => { props?.onCreateDialog(data, "material"); }} name={name} />
    </Box >
  );
};

export default GroupingCreateView;