import { FC, useState } from "react";
import { IProvince, IDistrict, ISubDistrict } from "../../Company/Services/CompanyService";
import { IUserDetail } from "../../SuperAdminManagement/Services/UserService";
import { IVapourHeatTreatment, IVapourHeatTreatmentCriteria, IVapourHeatTreatments } from "../Services/VapourHeatTreatmentService";
import ListViewPage from "../../../components/ListViewPage";
import { Box, Breadcrumbs, Button, Grid, IconButton, TextField, Typography } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import TableDataList from "../../../components/TableDataList";
import SearchIcon from "@mui/icons-material/Search";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import PlaceCreateView from "../../../components/PlaceCreateView";
import dayjs from "dayjs";

interface VapourHeatTreatmentListViewProps {
  isLoading: boolean;
  vapourHeatTreatments: IVapourHeatTreatments;
  vapourHeatTreatment: IVapourHeatTreatment;
  onSerchByCriteria: (criteria: IVapourHeatTreatmentCriteria) => Promise<void>;
  onCreate: (data: IVapourHeatTreatment) => Promise<void>;
  onUpdate: (data: IVapourHeatTreatment) => Promise<void>;
  provinceData?: IProvince[];
  districtData?: IDistrict[];
  subDistrictData?: ISubDistrict[];
  onFetchDistrict: (id: number | undefined) => void;
  onFetchSubDistrict: (id: number | undefined) => void;
  permission: IUserDetail;
}

const VapourHeatTreatmentListView: FC<VapourHeatTreatmentListViewProps> = (props) => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [name, setName] = useState("");
  const [open, setOpen] = useState(false);
  const [vapourHeatTreatment, setVapourHeatTreatment] = useState<IVapourHeatTreatment>();


  const searchByCriteria = (pageNumber: number = 0, rowLimit?: number) => {
    setPage(pageNumber);
    setLimit(rowLimit ? rowLimit : 10);
    const criteria: IVapourHeatTreatmentCriteria = {
      name: name,
      page: pageNumber,
      limit: rowLimit ?? 10,
    };
    props.onSerchByCriteria(criteria);
  };

  const onSubmit = (data: IVapourHeatTreatment) => {
    if (data.id) {
      props.onUpdate(data);
    } else {
      props.onCreate(data);
    }
  };

  const vapourHeatTreatmentColumns = [
    { label: 'ชื่อโรงอบ', render: (row: IVapourHeatTreatment) => row.name },
    { label: 'ที่อยู่', render: (row: IVapourHeatTreatment) => row.address },
    { label: 'เบอร์โทร', render: (row: IVapourHeatTreatment) => row.tel },
    {
      label: 'บันทึกล่าสุด', render: (row: IVapourHeatTreatment) => (
        <Box component={'div'}>
          <Box component={'div'}>{row.createBy?.firstName || ""} {row.createBy?.lastName || ""}</Box>
          <Box component={'div'}>{dayjs(row.createAt).format('DD/MM/YYYY') === "Invalid Date" ? "" :
            <Box>
              <Box>{dayjs(row.createAt).format('DD/MM/YYYY')}</Box>
              <Box>{dayjs(row.createAt).format('HH:mm')}</Box>
            </Box>
          }</Box>
        </Box>
      )
    },
    {
      label: 'แก้ไขล่าสุด', render: (row: IVapourHeatTreatment) => (
        <Box component={'div'}>
          <Box component={'div'}>{row.updateBy?.firstName || ""} {row.updateBy?.lastName || ""}</Box>
          <Box component={'div'}>{dayjs(row.updateAt).format('DD/MM/YYYY') === "Invalid Date" ? "" : <Box>
            <Box>{dayjs(row.updateAt).format('DD/MM/YYYY')}</Box>
            <Box>{dayjs(row.updateAt).format('HH:mm')}</Box>
          </Box>
          }</Box>
        </Box>
      )
    },
  ];
  const vapourHeatTreatmentActions = (row: IVapourHeatTreatment) => (
    <IconButton
      onClick={() => {
        setVapourHeatTreatment(row);
        setOpen(true);
      }}>
      <KeyboardArrowRightIcon />
    </IconButton>
  );


  const actionButtons = (
    <Button
      id={`${!props.permission.create ? "hidden" : "create"}`}
      variant="contained"
      startIcon={<NoteAddIcon />}
      onClick={() => {
        setOpen(true);
        setVapourHeatTreatment({} as IVapourHeatTreatment);
      }}>
      สร้าง
    </Button>
  );


  return <ListViewPage
    breadcrumb={
      <>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Typography>โรงอบ</Typography>
          <Typography variant="h6" color="text.primary">รายการ</Typography>
        </Breadcrumbs>
      </>}

    criteria={
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={10} md={10} lg={10}>
            <TextField fullWidth label="ชื่อรายการ" onChange={(e) => setName(e.target.value)} />
          </Grid>
          <Grid item xs={12} sm={2} md={2} lg={2} textAlign={"end"} alignSelf={"center"}>
            <Button
              variant="contained"
              startIcon={<SearchIcon />}
              onClick={() => searchByCriteria(0)}>ค้นหา
            </Button>
          </Grid>
        </Grid>
      </>
    }

    table={
      <>
        <TableDataList
          data={props.vapourHeatTreatments.datas}
          total={props.vapourHeatTreatments.total}
          columns={vapourHeatTreatmentColumns}
          actionButtons={actionButtons}
          rowActions={vapourHeatTreatmentActions}
          page={page}
          limit={limit}
          onPageChange={(newPage) => {
            setPage(newPage);
            searchByCriteria(newPage, limit);
          }}
          onRowsPerPageChange={(newLimit) => {
            setLimit(newLimit);
            setPage(0);
            searchByCriteria(0, newLimit);
          }}
        />
      </>
    }

    dialog={
      <>
        <PlaceCreateView open={open}
          data={vapourHeatTreatment ?? {} as IVapourHeatTreatment}
          provinceData={props.provinceData || []}
          districtData={props.districtData || []}
          subDistrictData={props.subDistrictData || []}
          onClose={() => {
            setOpen(false);
            setVapourHeatTreatment({} as IVapourHeatTreatment);
          }}
          onSubmit={onSubmit}
          onFetchDistrict={props.onFetchDistrict}
          onFetchSubDistrict={props.onFetchSubDistrict}
          permission={props.permission}
          placeLabel="โรงอบ"
        />
      </>

    }


  />;
};

export default VapourHeatTreatmentListView;