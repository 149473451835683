import { Controller, useForm } from "react-hook-form";
import { IUserDetail } from "../../SuperAdminManagement/Services/UserService";
import { IFarmProductReceiptListView, IFarmProductReceiptCriteria, IFarmProductReceipt } from "../Services/FarmProductReceipt";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Autocomplete, Box, Breadcrumbs, Button, Chip, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import ListViewPage from "../../../components/ListViewPage";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import SearchIcon from "@mui/icons-material/Search";
import TableDataList from "../../../components/TableDataList";
import { documentStatus } from "../../../constants/documentStatus";
import { ISupplier } from "../../Supplier/Services/SupplierService";
import { getDateRangeFromStorage, setDateRangeToStorage } from "../../../utils/dateRangeLocalStorage";
import { STORAGE_KEYS } from "../../../constants/dateRangeStorageKey";


interface FarmProductReceiptListViewProps {
  isLoading: boolean;
  farmProductReceipts: IFarmProductReceiptListView;
  suppliers: ISupplier[];
  onSearchByCriteria: (criteria: IFarmProductReceiptCriteria) => void;
  permission: IUserDetail;
}

const FarmProductReceiptListView: FC<FarmProductReceiptListViewProps> = (props) => {
  const initialDateRange = getDateRangeFromStorage(STORAGE_KEYS.PROCESS);
  const { register, getValues, control } = useForm<IFarmProductReceiptCriteria>({
    defaultValues: {
      dateFrom: initialDateRange.dateFrom || dayjs().startOf('month').toDate(),
      dateTo: initialDateRange.dateTo || dayjs().toDate(),
      docNo: '',
      agent: '',
      order: '',
      status: 'all',
      page: 0,
      limit: 10,
    },
  });

  const agentList = [
    { id: '', name: 'ทั้งหมด' },
    ...props.suppliers.filter((supplier) => supplier.isOrchard)
  ];
  const status = Object.values(documentStatus);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [agent, setAgent] = useState<ISupplier>({ id: '', name: 'ทั้งหมด', isOrchard: true });

  const navigate = useNavigate();

  const column = [
    { label: 'วันที่เอกสาร', render: (row: IFarmProductReceipt) => dayjs(row.docDate).format('DD/MM/YYYY') },
    { label: 'ชื่อโปรเจกต์', render: (row: IFarmProductReceipt) => row.order?.invoiceNo },
    { label: 'เลขที่เอกสาร', render: (row: IFarmProductReceipt) => row.docNo },
    { label: 'Agent', render: (row: IFarmProductReceipt) => row.agent?.name },
    {
      label: 'บันทึกล่าสุด', render: (row: IFarmProductReceipt) => (
        <Box component={'div'}>
          <Box component={'div'}>{row.createBy?.firstName || ""} {row.createBy?.lastName || ""}</Box>
          <Box component={'div'}>{dayjs(row.createAt).format('DD/MM/YYYY') === "Invalid Date" ? "" :
            <Box>
              <Box>{dayjs(row.createAt).format('DD/MM/YYYY')}</Box>
              <Box>{dayjs(row.createAt).format('HH:mm')}</Box>
            </Box>
          }</Box>
        </Box>
      )
    },
    {
      label: 'แก้ไขล่าสุด', render: (row: IFarmProductReceipt) => (
        <Box component={'div'}>
          <Box component={'div'}>{row.updateBy?.firstName || ""} {row.updateBy?.lastName || ""}</Box>
          <Box component={'div'}>{dayjs(row.updateAt).format('DD/MM/YYYY') === "Invalid Date" ? "" : <Box>
            <Box>{dayjs(row.updateAt).format('DD/MM/YYYY')}</Box>
            <Box>{dayjs(row.updateAt).format('HH:mm')}</Box>
          </Box>
          }</Box>
        </Box>
      )
    },
    {
      label: 'สถานะ', render: (row: IFarmProductReceipt) => {
        const status = row.status !== undefined ? documentStatus[row.status] : { name: "default", color: "default" };
        return (
          <Chip
            label={status.name}
            color={status.color as "default"}
            onClick={() => {
              navigate(`/process-product-receipt-edit/${row.id}`, { state: { from: 'status' } });
            }}
          />
        );
      }
    },
  ];

  const actionRow = (row: IFarmProductReceipt) => (
    <IconButton
      onClick={() => {
        navigate(`/process-product-receipt-edit/${row.id}`);
      }}>
      <KeyboardArrowRightIcon />
    </IconButton>
  );

  const actionButtons = props.permission.create ? (
    <Button
      variant="contained"
      startIcon={<NoteAddIcon />}
      onClick={() => {
        openCreate();
      }}>
      สร้าง
    </Button>
  ) : <></>;

  const searchByCriteria = (pageNumber: number = 0, rowLimit?: number) => {
    setPage(pageNumber);
    setLimit(rowLimit ? rowLimit : 10);
    const data = getValues();
    data.agent = agent.id;
    props.onSearchByCriteria({
      ...data,
      dateFrom: dayjs(data.dateFrom).format("YYYY-MM-DD"),
      dateTo: dayjs(data.dateTo).format("YYYY-MM-DD"),
      page: pageNumber, limit: rowLimit ? rowLimit : 10
    });
  };

  const openCreate = () => {
    navigate("/process-product-receipt-create");
  };



  return (
    <ListViewPage
      breadcrumb={
        <>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Typography>รับจากสวน</Typography>
            <Typography variant="h6" color="text.primary">รายการ</Typography>
          </Breadcrumbs>
        </>
      }
      criteria={
        <>
          <Box component="form">
            <Grid container spacing={2} alignItems={"end"}>
              <Grid item xs={12} sm={10} md={10} lg={10} >
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={6} md={6} lg={4}>
                    <Controller
                      name="dateFrom"
                      control={control}
                      render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'th'}>
                          <DemoContainer components={['DatePicker', 'DatePicker']} >
                            <DatePicker sx={{ width: "100%" }}
                              defaultValue={dayjs().startOf('month')}
                              label="ตั้งแต่วันที่"
                              value={field.value ? dayjs(field.value) : dayjs()}
                              onChange={(newValue: any) => {
                                field.onChange(newValue);
                                setDateRangeToStorage(STORAGE_KEYS.PROCESS, newValue.startOf('day').toDate(), dayjs(getValues('dateTo')) ?? dayjs().startOf('day'));
                              }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={4}>
                    <Controller
                      name="dateTo"
                      control={control}
                      render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'th'}>
                          <DemoContainer components={['DatePicker', 'DatePicker']} >
                            <DatePicker
                              sx={{ width: "100%" }}
                              defaultValue={dayjs()}
                              label="ถึงวันที่"
                              value={field.value ? dayjs(field.value) : dayjs()}
                              onChange={(newValue: any) => {
                                field.onChange(newValue);
                                setDateRangeToStorage(STORAGE_KEYS.PROCESS, dayjs(getValues('dateFrom')) ?? dayjs().startOf('month').startOf('day'), newValue.startOf('day').toDate());
                              }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={4} alignSelf={'flex-end'}>
                    <TextField
                      {...register("order")}
                      label="ชื่อโปรเจกต์"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={4} alignSelf={'flex-end'}>
                    <TextField
                      {...register("docNo")}
                      label="เลขที่เอกสาร"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={4} alignSelf={'flex-end'}>
                    <Controller
                      name="agent"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          value={agent}
                          options={agentList}
                          getOptionLabel={(option: any) => {
                            if (typeof option === 'string') {
                              return option;
                            }
                            if (option.inputValue) {
                              return option.inputValue;
                            }
                            return option.name || null;
                          }}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          onChange={(event, newValue) => {
                            if (newValue) {
                              setAgent(newValue as ISupplier);
                            }
                            field.onChange(newValue);

                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Agent"
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={4} alignSelf={'flex-end'}>
                    <Controller
                      name="status"
                      control={control}
                      render={({ field }) => (
                        <FormControl fullWidth>
                          <InputLabel id="status-select-label">สถานะ</InputLabel>

                          <Select
                            labelId="status-select-label"
                            value={field.value || "all"}
                            onChange={(e) => field.onChange(e.target.value)}
                            label="สถานะ"
                          >                      {status.map((status) => (
                            <MenuItem key={status.value} value={status.value}>
                              {status.name}
                            </MenuItem>
                          ))}
                          </Select>
                        </FormControl>
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={2} md={2} lg={2} textAlign={"end"} alignSelf={"end"}>
                <Button
                  onClick={() => searchByCriteria()}
                  variant="contained"
                  startIcon={<SearchIcon />}
                >
                  ค้นหา
                </Button>
              </Grid>
            </Grid>
          </Box>
        </>
      }
      table={
        <> <TableDataList
          data={props.farmProductReceipts.datas || []}
          total={props.farmProductReceipts.total}
          columns={column}
          actionButtons={actionButtons}
          rowActions={actionRow}
          page={page}
          limit={limit}
          onPageChange={(newPage) => {
            searchByCriteria(newPage);
          }}
          onRowsPerPageChange={(newLimit) => {
            setLimit(newLimit);
            setPage(0);
            searchByCriteria(0, newLimit);
          }}
        />

        </>
      }
    />
  );
};

export default FarmProductReceiptListView;