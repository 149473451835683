import { useNavigate } from "react-router-dom";
import { IDistrict, IProduct, IProductCriteria, IProductListView, IProvince, ISubDistrict } from "../Services/ProductService";
import { FC, useState } from "react";
import { Autocomplete, Box, Breadcrumbs, Button, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from "@mui/material";
import ListViewPage from "../../../components/ListViewPage";
import { ISupplier } from "../../Supplier/Services/SupplierService";
import SearchIcon from "@mui/icons-material/Search";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { rowPerPageOptions } from "../../../constants/viewOption";
import ProductCreateView from "./ProductCreateView";
import { IUserDetail } from "../../Login/Services/LoginService";
import { ISupplierType } from "../../Supplier/Services/SupplierTypeService";
import dayjs from "dayjs";

interface ProductsViewProps {
  isLoading: boolean;
  products: IProductListView;
  suppliers: ISupplier[];
  onCreate: (data: IProduct) => void;
  onSearchByCriteria: (criteria: IProductCriteria) => void;
  permission: IUserDetail;
  provinceData?: IProvince[];
  districtData?: IDistrict[];
  subDistrictData?: ISubDistrict[];
  onCreateDialog: (data: any, type: string) => void;
  onFetchData: (id?: any, type?: string) => void;
  supplierTypesOptions?: ISupplierType[];
}

const ProductsListView: FC<ProductsViewProps> = (props) => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [name, setName] = useState("");
  const [supplier, setSupplier] = useState({ id: "", name: "ทั้งหมด" });
  const [open, setOpen] = useState(false);


  const navigate = useNavigate();

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const searchByCriteria = (pageNumber: number = 0, rowLimit?: number) => {
    setPage(pageNumber);
    setLimit(rowLimit ? rowLimit : 10);
    const criteria: IProductCriteria = {
      name: name,
      supplier: supplier.id,
      page: pageNumber,
      limit: rowLimit ?? 10,
    };
    props.onSearchByCriteria(criteria);
  };

  return (
    <ListViewPage
      breadcrumb={
        <>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Typography>ผลิตภัณฑ์</Typography>
            <Typography variant="h6" color="text.primary">รายการ</Typography>
          </Breadcrumbs>
        </>
      }
      criteria={
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <TextField
                fullWidth
                label="ชื่อผลิตภัณฑ์"
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Autocomplete
                value={supplier}
                options={[{ id: "", name: "ทั้งหมด" }, ...props.suppliers ?? [] as ISupplier[]]}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField {...params} label="Supplier" />}
                onChange={(e, value) => {
                  setSupplier(value ?? { id: "", name: "ทั้งหมด" });
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />
            </Grid>

            <Grid item xs={12} sm={2} md={2} lg={2} textAlign={"right"} alignSelf={"center"}>
              <Button
                variant="contained"
                onClick={() => {
                  searchByCriteria(0);
                }}
                startIcon={<SearchIcon />}
              >
                ค้นหา
              </Button>
            </Grid>
          </Grid>
        </>
      }
      table={
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid container>
                <Grid item xs={10} sm={10} md={10} lg={10}>
                  <Typography>ทั้งหมด {props.products.total} รายการ</Typography>
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2} alignContent={"flex-end"} textAlign={"end"}>
                  {props.permission.create &&
                    <Button
                      variant="contained"
                      startIcon={<NoteAddIcon />}
                      onClick={handleOpenDialog}
                    >
                      สร้าง
                    </Button>
                  }
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell >ชื่อผลิตภัณฑ์</TableCell>
                      <TableCell >Supplier</TableCell>
                      <TableCell >รายละเอียด</TableCell>
                      <TableCell align="center">บันทึกล่าสุด</TableCell>
                      <TableCell align="center">แก้ไขล่าสุด</TableCell>
                      <TableCell sx={{ textAlign: 'center' }}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      props.products.datas && props.products.datas.length > 0 ? props.products.datas.map((item, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell >{item.name}</TableCell>
                          <TableCell>{item.supplier ? item.supplier.name : ''}</TableCell>
                          <TableCell>{item.remark}</TableCell>
                          <TableCell align="center">
                            <Box component={'div'}>{item.createBy?.firstName || ""} {item.createBy?.lastName || ""}</Box>
                            <Box component={'div'}>{dayjs(item.createAt).format('DD/MM/YYYY') === "Invalid Date" ? "" :
                              <Box>
                                <Box>{dayjs(item.createAt).format('DD/MM/YYYY')}</Box>
                                <Box>{dayjs(item.createAt).format('HH:mm')}</Box>
                              </Box>
                            }
                            </Box>
                          </TableCell>
                          <TableCell align="center">
                            <Box component={'div'}>{item.updateBy?.firstName || ""} {item.updateBy?.lastName || ""}</Box>
                            <Box component={'div'}>{dayjs(item.updateAt).format('DD/MM/YYYY') === "Invalid Date" ? "" :
                              <Box>
                                <Box>{dayjs(item.updateAt).format('DD/MM/YYYY')}</Box>
                                <Box>{dayjs(item.updateAt).format('HH:mm')}</Box>
                              </Box>
                            }
                            </Box>
                          </TableCell>
                          <TableCell sx={{ alignContent: 'flex-end', textAlign: 'end' }}>
                            <IconButton
                              onClick={() => {
                                navigate(`/process-product-detail/${item.id}`);
                              }}
                            >
                              <ChevronRightIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      )) : <TableRow>
                        <TableCell colSpan={6} sx={{ textAlign: 'center' }}>ไม่พบข้อมูล</TableCell>
                      </TableRow>
                    }
                  </TableBody>
                </Table>
              </TableContainer>
              <Box>
                <TablePagination
                  component="div"
                  rowsPerPageOptions={rowPerPageOptions}
                  count={props.products.total ?? 0}
                  onPageChange={(e, newPage) => {
                    setPage(newPage);
                    searchByCriteria(newPage);
                  }}
                  page={page}
                  rowsPerPage={limit ? limit : 10}
                  onRowsPerPageChange={(e: any) => {
                    setLimit(e.target.value);
                    setPage(0);
                    searchByCriteria(page, e.target.value);
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </>
      }
      dialog={
        <>
          <ProductCreateView open={open} onClose={handleCloseDialog} onCreate={props.onCreate} suppliers={props.suppliers ?? [] as ISupplier[]} provinceData={props.provinceData ?? []} districtData={props.districtData ?? []} subDistrictData={props.subDistrictData ?? []} supplierTypesOptions={props.supplierTypesOptions ?? []} onFetchData={props.onFetchData} onCreateDialog={props.onCreateDialog} />

        </>
      }
      isLoading={props.isLoading ?? false}
    />
  );

};

export default ProductsListView;
