import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IDistrict, IProvince, ISubDistrict, ITransfer, ITransferCriteria, ITransferView } from "../Services/TransferService";
import { Box, Breadcrumbs, Typography, Grid, TextField, Button, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, IconButton, TablePagination, FormControl, FormControlLabel, Radio, RadioGroup, InputLabel, MenuItem, Select, Chip } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Controller, useForm } from "react-hook-form";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { documentStatus } from "../../../constants/documentStatus";
import { sortOptions } from "../../../constants/sortOptions";
import { IWarehouse } from "../Services/WareshousesService";
import TransferCreateView from "./TransferCreateView";
import { IMaterial, IMaterialInWarehouseCriteria } from "../Services/MaterialsService";
import ListViewPage from "../../../components/ListViewPage";
import { rowPerPageOptions } from "../../../constants/viewOption";
import { IUserDetail, IUserLogined } from "../../Login/Services/LoginService";
import { IUser } from "../../UserManagement/Services/RoleService";
import { getDateRangeFromStorage, setDateRangeToStorage } from "../../../utils/dateRangeLocalStorage";
import { STORAGE_KEYS } from "../../../constants/dateRangeStorageKey";

interface TransfersListViewProps {
  transfers: ITransferView;
  onCreate: (data: ITransfer) => Promise<void>;
  onUpdate: (data: ITransfer) => Promise<void>;
  onSerchByCriteria: (criteria: ITransferCriteria) => Promise<void>;
  workerData: IUser[];
  warehouseData: IWarehouse[];
  materialData: IMaterial[];
  isLoading: boolean;
  materialQuantityInWarehouseFromData?: number;
  materialQuantityInWarehouseToData?: number;
  getMaterialQuantityInWarehouse: (criteria: IMaterialInWarehouseCriteria, warehouseType: string) => Promise<void>;
  permission: IUserDetail;
  user: IUserLogined;
  provinceData?: IProvince[];
  districtData?: IDistrict[];
  subDistrictData?: ISubDistrict[];
  onFetchData: (id?: any, type?: string) => void;
  onCreateDialog: (data: any, type: string) => void;
}
const TransfersListView: FC<TransfersListViewProps> = (props) => {
  const initialDateRange = getDateRangeFromStorage(STORAGE_KEYS.INVENTORY);
  const { register, getValues, control } = useForm<ITransferCriteria>({
    defaultValues: {
      dateType: "createDate",
      status: documentStatus["all"],
      sortBy: sortOptions[0],
      dateFrom: initialDateRange.dateFrom || dayjs().startOf('month').toDate(),
      dateTo: initialDateRange.dateTo || dayjs().toDate(),
    }
  });
  const userGroup = props.user?.userGroup;
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [open, setOpen] = useState(false);
  const documentStatusOptions = Object.values(documentStatus);
  const [transfer, setTransfer] = useState<ITransfer>();
  const navigate = useNavigate();



  const handleOpenDialog = (data?: ITransfer) => {
    setOpen(true);
    setTransfer(data);
  };

  const handleCloseDialog = () => {
    setOpen(false);
    setTransfer({} as ITransfer);
  };

  const handleNavigate = (id?: string) => {
    if (id) { navigate(`/inventory-transfer/${id}`); } else {
      navigate('/inventory-transfer-create');
    }
  };

  const searchByCriteria = (pageNumber: number = 0, rowLimit?: number) => {
    setPage(pageNumber);
    setLimit(rowLimit ? rowLimit : 10);
    const data = getValues();
    props.onSerchByCriteria({
      ...data,
      dateFrom: dayjs(data.dateFrom).format("YYYY-MM-DD"),
      dateTo: dayjs(data.dateTo).format("YYYY-MM-DD"),
      page: pageNumber, limit: rowLimit ? rowLimit : 10
    });
  };

  return (
    <ListViewPage
      breadcrumb={
        <>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Typography>การโยกวัสดุ</Typography>
            <Typography variant="h6" color="text.primary">รายการ</Typography>
          </Breadcrumbs>
        </>
      }
      criteria={
        <>
          <Box component={"form"}>
            <Grid container spacing={2} alignItems={"end"}>
              <Grid item xs={12} sm={12} md={12} lg={10}>
                <Grid container spacing={1} alignItems={"end"}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Controller
                      name="dateType"
                      control={control}
                      render={({ field }) => (
                        <RadioGroup
                          row
                          name="dateType"
                          value={field.value || "createDate"}
                          onChange={(event, newValue) => {
                            field.onChange(newValue);
                          }}
                        >
                          <FormControlLabel value="createDate"
                            control={<Radio />} label="วันที่บันทึก" />
                          <FormControlLabel value="updateDate" control={<Radio />} label="วันที่แก้ไข" />
                          <FormControlLabel value="docDate" control={<Radio />} label="วันที่เอกสาร" />
                        </RadioGroup>
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={4}>
                    <Controller
                      name="dateFrom"
                      control={control}
                      render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'th'}>
                          <DemoContainer components={['DatePicker', 'DatePicker']} >
                            <DatePicker sx={{ width: "100%" }}
                              defaultValue={dayjs().startOf('month')}
                              label="ตั้งแต่วันที่"
                              value={field.value ? dayjs(field.value) : dayjs()}
                              onChange={(newValue: any) => {
                                field.onChange(newValue);
                                setDateRangeToStorage(STORAGE_KEYS.INVENTORY, newValue.startOf('day').toDate(), dayjs(getValues('dateTo')) ?? dayjs().startOf('day'));
                              }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={4}>
                    <Controller
                      name="dateTo"
                      control={control}
                      render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'th'}>
                          <DemoContainer components={['DatePicker', 'DatePicker']} >
                            <DatePicker
                              sx={{ width: "100%" }}
                              defaultValue={dayjs()}
                              label="ถึงวันที่"
                              value={field.value ? dayjs(field.value) : dayjs()}
                              onChange={(newValue: any) => {
                                field.onChange(newValue);
                                setDateRangeToStorage(
                                  STORAGE_KEYS.INVENTORY,
                                  dayjs(getValues('dateFrom') ?? dayjs().startOf('month').startOf('day')),
                                  newValue.startOf('day').toDate()
                                );
                              }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      )}
                    />
                  </Grid>
                  <Grid item lg={4} sx={{ display: { xs: "none", sm: "none", md: "none", lg: "block" } }}></Grid>
                  <Grid item xs={6} sm={6} md={6} lg={4} sx={{ mt: 0 }}>
                    <TextField fullWidth label="เลขที่เอกสาร"  {...register("docNo")} />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={4}>
                    <TextField fullWidth label="รายการ"  {...register("material")} />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={4}>
                    <TextField fullWidth label="คลังต้นทาง"  {...register("stockFrom")} />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={4}>
                    <TextField fullWidth label="คลังปลายทาง"  {...register("stockTo")} />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={4}>
                    <TextField fullWidth label="ผู้ทํารายการ"  {...register("worker")} />
                  </Grid>
                  <Grid item xs={3} sm={3} md={3} lg={2}>
                    <Controller
                      name="status"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <FormControl fullWidth>
                          <InputLabel id="status-select-label">สถานะ</InputLabel>
                          <Select
                            labelId="status-select-label"
                            id="status-select"
                            value={field.value?.value || field.value}
                            label="สถานะ"
                            onChange={(event) => {
                              const newValue = documentStatusOptions?.find((item) => item.value === event.target.value);
                              field.onChange(newValue);
                            }}
                          >
                            {documentStatusOptions.map((item, index) => (
                              <MenuItem key={index} value={item.value}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={3} sm={3} md={3} lg={2}>
                    <Controller
                      name="sortBy"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <FormControl fullWidth>
                          <InputLabel id="status-select-label">เรียงตาม</InputLabel>
                          <Select
                            labelId="status-select-label"
                            id="status-select"
                            value={field.value?.value || field.value}
                            label="เรียงตาม"
                            onChange={(event) => {
                              const newValue = sortOptions?.find((item) => item.value === event.target.value);
                              field.onChange(newValue);
                            }}
                          >
                            {sortOptions.map((item, index) => (
                              <MenuItem key={index} value={item.value}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={2} textAlign={"end"} alignSelf={"end"}>
                <Button
                  onClick={() => searchByCriteria()}
                  variant="contained"
                  startIcon={<SearchIcon />}
                >
                  ค้นหา
                </Button>
              </Grid>
            </Grid>
          </Box>
        </>}
      table={<>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid container>
              <Grid item xs={10} sm={10} md={10} lg={10}>
                <Typography>ทั้งหมด {props.transfers.total} รายการ</Typography>
              </Grid>
              <Grid item xs={2} sm={2} md={2} lg={2} alignContent={"flex-end"} textAlign={"end"}>
                {props.permission.create &&
                  <Button
                    variant="contained"
                    startIcon={<NoteAddIcon />}
                    onClick={() => handleOpenDialog()}
                  >
                    สร้าง
                  </Button>
                }
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">วันที่เอกสาร</TableCell>
                    <TableCell align="center">เลขที่เอกสาร</TableCell>
                    <TableCell align="center">รายการ</TableCell>
                    <TableCell align="center">จำนวน</TableCell>
                    <TableCell align="center">คลังต้นทาง</TableCell>
                    <TableCell align="center">คลังปลายทาง</TableCell>
                    <TableCell align="center">ผู้ทำรายการ</TableCell>
                    <TableCell align="center">บันทึกล่าสุด</TableCell>
                    <TableCell align="center">แก้ไขล่าสุด</TableCell>
                    <TableCell align="center">สถานะ</TableCell>
                    <TableCell align="center"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    props.transfers.datas && props.transfers.datas.length > 0 ? props.transfers.datas.map((item, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                      >
                        <TableCell component="th" scope="row" align="center">
                          {dayjs(item.docDate).format('DD/MM/YYYY')}
                        </TableCell>
                        <TableCell align="center">{item.docNo}</TableCell>
                        <TableCell align="center">{item.material.name}</TableCell>
                        <TableCell align="center">{item.quantity}</TableCell>
                        <TableCell align="center">{item.stockFrom.name}</TableCell>
                        <TableCell align="center">{item.stockTo.name}</TableCell>
                        <TableCell align="center">{item.worker?.firstName} {item.worker?.lastName}</TableCell>
                        <TableCell align="center"> <Box component={'div'}>{item.createBy?.firstName || ""} {item.createBy?.lastName || ""}</Box>
                          <Box component={'div'}>{dayjs(item.createAt).format('DD/MM/YYYY') === "Invalid Date" ? "" :
                            <Box>
                              <Box>{dayjs(item.createAt).format('DD/MM/YYYY')}</Box>
                              <Box>{dayjs(item.createAt).format('HH:mm')}</Box>
                            </Box>
                          }</Box>
                        </TableCell>
                        <TableCell align="center"> <Box component={'div'}>{item.updateBy?.firstName || ""} {item.updateBy?.lastName || ""}</Box>
                          <Box component={'div'}>{dayjs(item.updateAt).format('DD/MM/YYYY') === "Invalid Date" ? "" :
                            <Box>
                              <Box>{dayjs(item.updateAt).format('DD/MM/YYYY')}</Box>
                              <Box>{dayjs(item.updateAt).format('HH:mm')}</Box>
                            </Box>}</Box></TableCell>
                        <TableCell align="center">
                          <Chip label={`${documentStatus[`${item.status}`]?.name}` || 'default'} color={`${documentStatus[`${item.status}`]?.color || 'default'}` as any} onClick={() => handleOpenDialog(item)} />
                        </TableCell>
                        <TableCell align="center">
                          <IconButton
                            onClick={() =>
                              handleNavigate(item.id)
                            }
                          >
                            <KeyboardArrowRightIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                      : (
                        <TableRow>
                          <TableCell colSpan={9} align="center">
                            <Typography>ไม่พบข้อมูล</Typography>
                          </TableCell>
                        </TableRow>
                      )
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <Box mt={3}>
              <TablePagination
                component="div"
                rowsPerPageOptions={rowPerPageOptions}
                count={props.transfers.total ?? 0}
                onPageChange={(e, newPage) => {
                  setPage(newPage);
                  searchByCriteria(newPage);
                }}
                page={page}
                rowsPerPage={limit ? limit : 10}
                onRowsPerPageChange={(e: any) => {
                  setLimit(e.target.value);
                  setPage(0);
                  searchByCriteria(page, e.target.value);
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </>}
      dialog={<>
        <TransferCreateView open={open} onClose={handleCloseDialog} onCreate={props.onCreate} workerData={props.workerData} materialData={props.materialData} warehouseData={props.warehouseData}
          transfer={transfer} onUpdate={props.onUpdate}
          materialQuantityInWarehouseFrom={props.materialQuantityInWarehouseFromData}
          materialQuantityInWarehouseTo={props.materialQuantityInWarehouseToData}
          getMaterialQuantityInWarehouse={props.getMaterialQuantityInWarehouse}
          userGroup={userGroup}
          onFetchData={props.onFetchData}
          onCreateDialog={props.onCreateDialog}
          provinceData={props.provinceData}
          districtData={props.districtData}
          subDistrictData={props.subDistrictData}
        />
      </>}
      isLoading={props.isLoading ?? false}
    />
  );
};

export default TransfersListView;
