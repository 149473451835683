import {
  Autocomplete, Box, Button, ButtonProps, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, TextField
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import DeleteIcon from '@mui/icons-material/Delete';
import { IProvince, IDistrict, ISubDistrict } from "../features/Company/Services/CompanyService";
import { IUserDetail } from "../features/SuperAdminManagement/Services/UserService";
import DialogConfirm from "./DialogConfirm";

interface PlaceCreateViewProps<T> {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: T) => void;
  data: T;
  provinceData?: IProvince[];
  districtData?: IDistrict[];
  subDistrictData?: ISubDistrict[];
  onFetchDistrict: (id: number | undefined) => void;
  onFetchSubDistrict: (id: number | undefined) => void;
  permission: IUserDetail;
  placeLabel: string;
}

const PlaceCreateView: FC<PlaceCreateViewProps<any>> = (props) => {
  const { register, handleSubmit, reset, getValues, setValue, watch, control, formState: { errors } } = useForm<any>();
  watch();

  const [openConfirm, setOpenConfirm] = useState({
    open: false,
    title: "",
    message: "",
    color: "primary" as ButtonProps["color"],
    type: "create" as "create" | "edit" | "delete",
  });

  const onClose = () => {
    reset();
    props.onClose();
    setOpenConfirm({ ...openConfirm, open: false });
  };

  const onSubmit = handleSubmit(() => {
    if (props.data.id) {
      setOpenConfirm({
        open: true,
        title: `แก้ไขข้อมูล ${props.placeLabel}`,
        message: `คุณต้องการแก้ไขข้อมูลนี้ใช่หรือไม่`,
        color: "primary",
        type: "edit",
      });
    } else {
      setOpenConfirm({
        open: true,
        title: `บันทึกข้อมูล ${props.placeLabel}`,
        message: `คุณต้องการบันทึกข้อมูลนี้ใช่หรือไม่`,
        color: "primary",
        type: "create",
      });
    }
  });

  const onConfirm = () => {
    props.onSubmit(getValues());
    reset();
    onClose();
  };

  const onDelete = () => {
    props.onSubmit({ ...props.data, isDelete: true });
    reset();
    onClose();
  };

  useEffect(() => {
    reset(props.data);
  }, [props.data, props.open, reset]);

  return (
    <Dialog open={props.open} maxWidth={'lg'} fullWidth>
      <DialogTitle>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            {props.data.id ? `แก้ไข${props.placeLabel}` : `สร้าง${props.placeLabel}`}
          </Grid>
          <Grid item xs={6} textAlign="right">
            {props.data.id && (
              <Button
                variant="contained"
                color="error"
                startIcon={<DeleteIcon />}
                onClick={() => {
                  setOpenConfirm({
                    open: true,
                    title: `ลบข้อมูล ${props.placeLabel}`,
                    message: `คุณต้องการลบข้อมูลนี้ใช่หรือไม่`,
                    color: "error",
                    type: "delete",
                  });
                }}
              >
                ลบข้อมูล
              </Button>
            )}
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Box component={'form'}>
          <Grid container spacing={2} mt={1}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={`ชื่อ${props.placeLabel}`}
                {...register("name", { required: true })}
                error={!!errors.name}
                helperText={errors.name ? `กรุณาระบุชื่อ${props.placeLabel}` : ""}
                InputLabelProps={{
                  shrink: getValues("name") ? true : undefined
                }}
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <TextField
                fullWidth
                label={`ที่อยู่`}
                {...register("address", { required: true })}
                error={!!errors.address}
                helperText={errors.address ? `กรุณาระบุที่อยู่` : ""}
                InputLabelProps={{
                  shrink: getValues("address") ? true : undefined
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name="province"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    value={field?.value as IProvince || ""}
                    options={props.provinceData || []}
                    getOptionLabel={(option: any) => option.nameTh || ""}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    onChange={(event, newValue) => {
                      field.onChange(newValue);
                      if (newValue?.id) {
                        setValue("district", undefined);
                        setValue("subDistrict", undefined);
                        setValue("zipCode", "");
                        props.onFetchDistrict(newValue.id);
                        props.onFetchSubDistrict(undefined);
                      }
                    }}
                    renderInput={(params) => <TextField
                      error={!!errors.province}
                      helperText={errors.province ? `กรุณาเลือกจังหวัด` : ""}
                      {...params} label={`จังหวัด`} />}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name="district"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    disabled={!getValues("province")?.id}
                    value={field?.value as IDistrict || undefined}
                    options={props.districtData || []}
                    getOptionLabel={(option: any) => option.nameTh || ""}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    onChange={(event, newValue) => {
                      field.onChange(newValue);
                      if (newValue?.id) {
                        setValue("subDistrict", undefined);
                        setValue("zipCode", "");
                        props.onFetchSubDistrict(newValue.id);
                      }
                    }}
                    renderInput={(params) => <TextField
                      error={!!errors.district}
                      helperText={errors.district ? `กรุณาเลือกอําเภอ` : ""}
                      {...params} label={`อําเภอ`} />}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name="subDistrict"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Autocomplete
                    disabled={!getValues("district")?.id}
                    {...field}
                    value={field?.value as ISubDistrict || undefined}
                    options={props.subDistrictData || []}
                    getOptionLabel={(option: any) => option.nameTh || ""}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    onChange={(event, newValue: any) => {
                      field.onChange(newValue);
                      if (newValue?.zipCode) {
                        setValue("zipCode", newValue.zipCode);
                      }
                    }}
                    renderInput={(params) => <TextField
                      error={!!errors.subDistrict}
                      helperText={errors.subDistrict ? `กรุณาเลือกตําบล` : ""}
                      {...params} label={`ตําบล`} />}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                label={`รหัสไปรษณีย์`}
                fullWidth
                {...register("zipCode", { required: true })}
                error={!!errors.zipCode}
                helperText={errors.zipCode ? `กรุณาระบุรหัสไปรษณีย์` : " "}
                InputLabelProps={{
                  shrink: getValues("zipCode") ? true : undefined,
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label={`เบอร์โทร`}
                {...register("tel")}
                error={!!errors.tel}
                helperText={errors.tel ? `กรุณาระบุเบอร์โทร` : ""}
                InputLabelProps={{
                  shrink: getValues("tel") ? true : undefined
                }}
              />
            </Grid>
            {/* NOTE:May use in future */}
            {/* <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                disabled
                label={`ผู้ดูแล`}
                {...register("manager.id", { value: loginService.userLoginedData.id })}
                error={!!errors.manager}
                helperText={errors.manager ? `กรุณาระบุผู้ดูแล` : ""}
                InputLabelProps={{
                  shrink: getValues("manager") ? true : undefined
                }}
              />
            </Grid> */}
            <Grid item xs={12} mt={2}>
              <Divider />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          ยกเลิก
        </Button>
        {(props.permission.create || (props.permission.edit && props.data.id)) &&
          <Button variant="contained" onClick={onSubmit}>
            บันทึก
          </Button>
        }
        <DialogConfirm
          open={openConfirm.open}
          type={openConfirm.type === "delete" ? "error" : "success"}
          title={openConfirm.title}
          message={openConfirm.message}
          onClose={() => setOpenConfirm({ ...openConfirm, open: false })}
          onSubmit={() => {
            setOpenConfirm({ ...openConfirm, open: false });
            if (openConfirm.type === "delete") {
              onDelete();
            } else {
              onConfirm();
            }
          }}
        />
      </DialogActions>
    </Dialog>
  );
};


export default PlaceCreateView;
