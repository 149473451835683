import apiService from "../../../services/ApiService";
import { IUser } from "../../UserManagement/Services/RoleService";
import { makeAutoObservable, configure } from "mobx";

export interface IMarket {
  id?: string;
  name?: string;
  address?: string;
  subDistrict?: ISubDistrict | undefined;
  district?: IDistrict | undefined;
  province?: IProvince | undefined;
  zipCode?: string;
  tel?: string;
  manager?: IUser;
  isDelete?: boolean;
  createBy?: IUser | undefined;
  updateBy?: IUser | undefined;
  createAt?: Date;
  updateAt?: Date;
}

export interface IMarkets {
  datas: IMarket[];
  all: number;
  page: number;
  limit: number;
  total: number;
}

export interface IMarketCriteria {
  name?: string;
  page: number;
  limit: number;
}

export interface IProvince {
  id?: number;
  nameTh?: string;
}

export interface IDistrict {
  id?: number;
  nameTh?: string;
  province?: IProvince | null;
}

export interface ISubDistrict {
  id?: number;
  nameTh?: string;
  district?: IDistrict | null;
  zipCode?: string;
}

class MarketService {
  markets: IMarkets | undefined;
  market: IMarket | undefined;
  allMarkets: IMarket[] = [];
  province: IProvince[] = [];
  district: IDistrict[] = [];
  subDistrict: ISubDistrict[] = [];
  constructor() {
    configure({
      enforceActions: "never",
    });
    makeAutoObservable(this);
  }

  async getOne(id: string) {
    try {
      const response = await apiService.get(`market/${id}`);
      this.market = response;
      return this.market;
    } catch (error) {
      throw error;
    }
  }

  async getByCriteria(criteria: IMarketCriteria) {
    try {
      const markets = await apiService.post("market/criteria", criteria);
      this.markets = markets;
      return this.markets;
    } catch (error) {
      throw error;
    }
  }

  async create(data: IMarket) {
    try {
      const response = await apiService.post("market", data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async update(data: IMarket) {
    try {
      return await apiService.put(`market/${data.id}`, data);
    } catch (error) {
      throw error;
    }
  }

  async findAll() {
    try {
      this.allMarkets = await apiService.get("market");
      return this.allMarkets;
    } catch (error) {
      throw error;
    }
  }

  async getProvince() {
    try {
      this.province = await apiService.get(`/provinces`);
      return this.province;
    } catch (error) {
      throw error;
    }
  }

  async getDistrict(id: number) {
    try {
      this.district = await apiService.get(`/districts?provinceId=${id}`);
      return this.district;
    } catch (error) {
      throw error;
    }
  }

  async getSubDistrict(id: number) {
    try {
      this.subDistrict = await apiService.get(
        `/sub-districts?districtId=${id}`
      );
      return this.subDistrict;
    } catch (error) {
      throw error;
    }
  }

  get marketsData() {
    return this.markets;
  }

  get marketData() {
    return this.market;
  }

  get allMarketsData() {
    return this.allMarkets;
  }

  get provinceData() {
    return this.province;
  }

  get districtData() {
    return this.district;
  }

  get subDistrictData() {
    return this.subDistrict;
  }
}


const marketService = new MarketService();
export default marketService;