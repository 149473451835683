import { FC, useEffect, useState } from "react";
import { IFarmReceiptBasket } from "../features/ProductProcess/Services/FarmProductReceipt";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, TextField, Typography } from "@mui/material";
import thaiFormatNumber from "../utils/thaiFormatNumber";

interface DialogCreateBasketDetailsProps {
  open: boolean;
  index: number;
  datas: IFarmReceiptBasket[];
  onClose: () => void;
  onSubmit: (data: IFarmReceiptBasket[], totalWeight: number, index: number) => void;
}

const DialogCreateBasketDetails: FC<DialogCreateBasketDetailsProps> = (props) => {
  const [totalWeight, setTotalWeight] = useState<number>(0);

  useEffect(() => {
    calTotalWeight();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.datas]);

  const onClose = () => {
    props.onClose();
  };

  const onSubmit = () => {
    props.onSubmit(props.datas, totalWeight, props.index);
    setTotalWeight(0);
  };

  const calTotalWeight = () => {
    let total = 0;
    props.datas.forEach((data) => {
      total += Number(data.weight) ?? 0;
    });
    setTotalWeight(total);
  };



  return (
    <Dialog open={props.open} maxWidth={'lg'} fullWidth>
      <DialogTitle>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6} md={6} lg={6}>
            เกรด {props.datas[0]?.grade?.name ?? ''}
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} mt={1}>
          {
            props.datas && props.datas.length > 0 && props.datas.map((data, index) => {
              return (
                <Grid item xs={6} sm={6} md={4} lg={4} key={index}>
                  <TextField
                    label={`ตะกร้าที่ ${index + 1}`}
                    type="number"
                    fullWidth
                    value={data.weight || ''}
                    onChange={(e) => {
                      data.weight = parseFloat(e.target.value);
                      calTotalWeight();
                    }}
                  />
                </Grid>
              );
            })
          }
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} textAlign={'right'}>
            <Grid container spacing={2}>
              <Grid item xs={8} sm={8} md={8} lg={8}>
                <Typography >น้ำหนักทั้งหมด (Kg)</Typography>
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4} textAlign={'end'}>
                {thaiFormatNumber(totalWeight.toString())}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} textAlign={'right'} mt={1}>
            <Button variant="text" onClick={() => {
              onClose();

            }}>
              ยกเลิก
            </Button>
            <Button variant="contained" onClick={() => {
              onSubmit();
            }}>
              ยืนยัน
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default DialogCreateBasketDetails;