import { makeAutoObservable, configure } from "mobx";
import apiService from "../../../services/ApiService";
import { IUser } from "../../UserManagement/Services/RoleService";
import { ISubDistrict, IDistrict, IProvince } from "./ProductService";

export interface IConsignee {
  id?: string;
  name?: string;
  address?: string;
  subDistrict?: ISubDistrict | undefined;
  district?: IDistrict | undefined;
  province?: IProvince | undefined;
  zipCode?: string;
  tel?: string;
  pic: string;
  isDelete?: boolean;
  createBy?: IUser | undefined;
  updateBy?: IUser | undefined;
  createAt?: Date;
  updateAt?: Date;
}

export interface IConsignees {
  datas: IConsignee[];
  all: number;
  page: number;
  limit: number;
  total: number;
}


export interface IConsigneeWithValue {
  id?: number;
  name?: string;
  tel?: string;
  pic?: string;
  address?: string;
  value?: string;
}
export interface IConsigneesCriteria {
  name?: string;
  page: number;
  limit: number;
}

class ConsigneeService {
  consignees: IConsignees | undefined;
  consignee: IConsignee | undefined;
  allConsignees: IConsigneeWithValue[] = [];


  constructor() {
    configure({
      enforceActions: "never",
    });
    makeAutoObservable(this);
  }

  async getOne(id: string) {
    try {
      const consignee = await apiService.get(`consignee/${id}`);
      this.consignee = consignee;
      return this.consignee;
    } catch (error) {
      throw error;
    }
  }

  async getByCriteria(criteria: IConsigneesCriteria) {
    try {
      this.consignees = await apiService.post("consignee/criteria", criteria);
      return this.consignees;
    } catch (error) {
      throw error;
    }
  }


  async create(data: IConsignee) {
    try {
      return await apiService.post("consignee", data);
    } catch (error) {
      throw error;
    }
  }

  async update(data: IConsignee) {
    try {
      return await apiService.put(`consignee/${data.id}`, data);
    } catch (error) {
      throw error;
    }
  }

  async getAll() {
    try {
      this.allConsignees = await apiService.get('/consignee');
      return this.allConsignees;
    } catch (error) {
      throw error;
    }
  }

  get consigneesData() {
    return this.consignees;
  }

  get consigneeData() {
    return this.consignee;
  }

  get allConsigneesData() {
    return this.allConsignees;
  }
}

const consigneeService = new ConsigneeService();
export default consigneeService;