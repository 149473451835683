import { FC, useEffect, useState } from "react";
import { ILogin } from "../Services/LoginService";
import { Box, Container, CssBaseline, Typography } from "@mui/material";
import LoginForm from "../../../components/LoginForm";
import APP_LOGO from "../../../res/images/app_logo.png";

interface LoginViewProps {
  onLogin?: (data: ILogin) => void;
  hasError?: boolean;
  hasSuccess?: boolean;
  isLoading?: boolean;
}
const LoginView: FC<LoginViewProps> = (props) => {
  const [isLoading, setIsLoading] = useState(props.isLoading || false);

  useEffect(() => {
    setIsLoading(props.isLoading || false);
  }, [props.isLoading]);

  return (
    <Container component={'main'} maxWidth="xs" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
      <CssBaseline />
      <Box component={'div'}>
        <Box component={'div'} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
          <Box component={'img'} src={APP_LOGO} sx={{ width: '100%' }} />
        </Box>
        <Typography component="h1" variant="h5" textAlign={'center'}>
          เข้าสู่ระบบ
        </Typography>
        <LoginForm onSubmit={props.onLogin} hasError={props.hasError} hasSuccess={props.hasSuccess} isLoading={isLoading} />
      </Box>
    </Container>
  );
};

export default LoginView;
