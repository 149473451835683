import { useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import TransferService, { ITransfer } from "../Services/TransferService";
import { enqueueSnackbar } from "notistack";
import TransferDetailView from "../Views/TransferDetailView";
import transferService from "../Services/TransferService";
import materialService, { IMaterialInWarehouseCriteria } from "../Services/MaterialsService";
import clearState from "../../../utils/resetState";
import { IUserDetail } from "../../Login/Services/LoginService";
import getPermissionBySubMenu from "../../../utils/getPermissionBySubMenu";
import wareshousesService from "../Services/WareshousesService";

const TransferCreateController = observer(() => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [permission, setPermission] = useState<IUserDetail>();
  useEffect(() => {
    if (id) {
      getOne(id);
      getWorkers();
      getWarehouses();
      getMaterials();
      getProvince();
      checkPermissionActionButton();
    }

    return () => {
      clearState(materialService, ["materialQuantityInWarehouseFrom", "materialQuantityInWarehouseTo"]);
      clearState(transferService, ["transfer"]);
    };
  }, [id]);

  const getOne = async (id: string) => {
    try {
      await TransferService.getOne(id);
    } catch (error) {
      console.error("Error fetching transfer:", error);
    }
  };

  const getWorkers = async () => {
    try {
      await transferService.getWorkers();
    } catch (error) {
      console.error("Error fetching workers:", error);
    }
  };

  const getWarehouses = async () => {
    try {
      await transferService.getWarehouses();
    } catch (error) {
      console.error("Error fetching warehouses:", error);
    }
  };

  const getMaterials = async () => {
    try {
      await transferService.getMaterials();
    } catch (error) {
      console.error("Error fetching materials:", error);
    }
  };

  const onUpdate = async (data: ITransfer) => {
    try {
      await TransferService.update(id ?? "", data);
      enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      getOne(id ?? "");
    } catch (error) {
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
    }
  };

  const onDelete = async (data: ITransfer) => {
    try {
      await TransferService.update(id ?? "", data);
      enqueueSnackbar("ลบข้อมูลสำเร็จ", { variant: "success" });
      navigate(`/inventory-transfer`);
    } catch (error) {
      enqueueSnackbar("ลบข้อมูลไม่สำเร็จ", { variant: "error" });
    }
  };

  const getMaterialQuantityInWarehouse = async (criteria: IMaterialInWarehouseCriteria, warehouseType: string) => {
    try {
      await materialService.getMaterialQuantityInWarehouse(criteria, warehouseType);
    } catch (error) {
      console.error("An error occurred while getting the material in warehouse:", error);
    }
  };

  const getProvince = async () => {
    try {
      await transferService.getProvince();
    } catch (error) {
      console.error("An error occurred while getting the province:", error);
    }
  };

  const onFetchData = async (id?: any, type?: string) => {
    try {
      switch (type) {
        case "district": await transferService.getDistrict(id);
          break;
        case "subDistrict": await transferService.getSubDistrict(id);
          break;
      }
    } catch (error) {
      console.error(`An error occurred while creating the ${type}`, error);
    }
  };

  const onCreateDialog = async (data: any, type: string) => {
    try {
      let response;
      switch (type) {
        case "material":
          response = await materialService.create(data);
          getMaterials();
          break;
        case "warehouse":
          response = await wareshousesService.create(data);
          getWarehouses();
          break;
      }
      if (response) {
        enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      }
    } catch (error) {
      console.error(`An error occurred while creating the ${type}`, error);
    }
  };

  const checkPermissionActionButton = () => {
    const result = getPermissionBySubMenu("inventory-transfer");
    if (result) {
      setPermission(result);
    }
  };

  return <TransferDetailView
    onUpdate={onUpdate}
    onDelete={onDelete}
    transfer={transferService.transferDataById}
    workerData={transferService.workersData}
    warehouseData={transferService.warehousesData}
    materialData={transferService.getMaterialsData}
    getMaterialQuantityInWarehouse={getMaterialQuantityInWarehouse}
    materialQuantityInWarehouseFromData={materialService.materialQuantityInWarehouseFromData}
    materialQuantityInWarehouseToData={materialService.materialQuantityInWarehouseToData}
    permission={permission ?? {} as IUserDetail}
    onFetchData={onFetchData}
    onCreateDialog={onCreateDialog}
    provinceData={transferService.provinceData ?? []}
    districtData={transferService.districtData || []}
    subDistrictData={transferService.subDistrictData || []}
  />;
});

export default TransferCreateController;
