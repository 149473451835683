import { FC, useRef, useState, useEffect } from "react";
import { IconButton, Button, Breadcrumbs, Typography, Box, Grid, TextField, FormControl, InputLabel, MenuItem, Select, Chip } from "@mui/material";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ListViewPage from "../../../components/ListViewPage";
import TableDataList from "../../../components/TableDataList";
import { Controller, useForm } from "react-hook-form";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { IUserDetail } from "../../Login/Services/LoginService";
import { documentStatus } from "../../../constants/documentStatus";
import { IPrintShippingPackingList, IShipping, IShippingCriteria, IShippingList } from "../Services/ShippingService";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { STORAGE_KEYS } from "../../../constants/dateRangeStorageKey";
import { getDateRangeFromStorage, setDateRangeToStorage } from "../../../utils/dateRangeLocalStorage";
import PrintIcon from "@mui/icons-material/Print";
import { useReactToPrint } from "react-to-print";
import PrintShippingPackingList from "../../../components/Report/Pdf/PrintShippingPackingList";
interface ShippingsListViewProps {
  isLoading: boolean;
  datas: IShippingList;
  onSearchByCriteria: (criteria: IShippingCriteria) => void;
  permission: IUserDetail;
  printShippingPackingList: IPrintShippingPackingList;
  onGetOne?: (id: string) => void;
  isPrint: boolean;
}

const ShippingsListView: FC<ShippingsListViewProps> = (props) => {
  const initialDateRange = getDateRangeFromStorage(STORAGE_KEYS.PROCESS);
  const { register, getValues, control } = useForm<IShippingCriteria>({
    defaultValues: {
      dateFrom: initialDateRange.dateFrom || dayjs().startOf('month').toDate(),
      dateTo: initialDateRange.dateTo || dayjs().toDate(),
      docNo: '',
      order: '',
      status: 'all',
    }
  });

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const documentStatusOptions = Object.values(documentStatus);

  const navigate = useNavigate();
  const contentRef = useRef<HTMLDivElement>(null);
  const reactToPrintFn = useReactToPrint({ contentRef });

  useEffect(() => {
    if (props.isPrint) {
      reactToPrintFn && reactToPrintFn();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isPrint]);

  //SECTION - Variables for  TableDataList
  const columns = [
    { label: 'วันที่เอกสาร', render: (row: IShipping) => dayjs(row.docDate).format('DD/MM/YYYY') },
    { label: 'ชื่อโปรเจกต์', render: (row: IShipping) => row.order?.invoiceNo },
    { label: 'AWB No.', render: (row: IShipping) => row.docNo },
    {
      label: 'บันทึกล่าสุด', render: (row: IShipping) => (
        <Box component={'div'} sx={{ color: row.isDelete ? 'error.main' : 'inherit' }}>
          <Box component={'div'}>{row.createBy?.firstName || ''} {row.createBy?.lastName || ''}</Box>
          <Box component={'div'}>{dayjs(row.createAt).format('DD/MM/YYYY') === "Invalid Date" ? '' :
            <Box>
              <Box>{dayjs(row.createAt).format('DD/MM/YYYY')}</Box>
              <Box>{dayjs(row.createAt).format('HH:mm')}</Box>
            </Box>
          }</Box>
        </Box>
      )
    },
    {
      label: 'แก้ไขล่าสุด', render: (row: IShipping) => (
        <Box component={'div'} sx={{ color: row.isDelete ? 'error.main' : 'inherit' }}>
          <Box component={'div'}>{row.updateBy?.firstName || ''} {row.updateBy?.lastName || ''}</Box>
          <Box component={'div'}>{dayjs(row.updateAt).format('DD/MM/YYYY') === "Invalid Date" ? '' :
            <Box>
              <Box>{dayjs(row.updateAt).format('DD/MM/YYYY')}</Box>
              <Box>{dayjs(row.updateAt).format('HH:mm')}</Box>
            </Box>
          }</Box>
        </Box>
      )
    },
    {
      label: 'พิมพ์เอกสาร',
      render: (row: IShipping) => {
        return (
          <IconButton
            onClick={() => {
              props.onGetOne && props.onGetOne(row.id || '');
            }}
          >
            <PrintIcon />
          </IconButton>
        );
      }
    },
    {
      label: 'สถานะ',
      render: (row: IShipping) => {
        const status = documentStatusOptions.find((item) => item.value === row.status);
        const color = status?.color as 'default';
        return <Chip label={status?.name || ""} color={color}
          onClick={() => {
            navigate(`/process-shipping-edit/${row.id}`, { state: { from: 'status' } });
          }}
        />;
      }
    },
  ];

  const action = (row: IShipping) => (
    <IconButton
      onClick={() => {
        navigate(`/process-shipping-edit/${row.id}`);
      }}>
      <KeyboardArrowRightIcon />
    </IconButton>
  );

  const actionButtons = props?.permission.create ? (
    <Button
      variant="contained"
      startIcon={<NoteAddIcon />}
      onClick={() => {
        openCreate();
      }}>
      สร้าง
    </Button>
  ) : <></>;

  const searchByCriteria = (pageNumber: number = 0, rowLimit?: number) => {
    setPage(pageNumber);
    setLimit(rowLimit ? rowLimit : 10);
    const data = getValues();
    props.onSearchByCriteria({
      ...data,
      dateFrom: dayjs(data.dateFrom).format("YYYY-MM-DD"),
      dateTo: dayjs(data.dateTo).format("YYYY-MM-DD"),
      page: pageNumber, limit: rowLimit ? rowLimit : 10
    });
  };

  const openCreate = () => {
    navigate("/process-shipping-create");
  };

  return (
    <ListViewPage
      breadcrumb={
        <>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Typography>Shipping</Typography>
            <Typography variant="h6" color="text.primary">รายการ</Typography>
          </Breadcrumbs>
        </>
      }
      criteria={
        <>
          <Box component="form">
            <Grid container spacing={2} alignItems={"end"}>
              <Grid item xs={12} sm={10} md={10} lg={10} >
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <Controller
                      name="dateFrom"
                      control={control}
                      render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'th'}>
                          <DemoContainer components={['DatePicker', 'DatePicker']} >
                            <DatePicker sx={{ width: "100%" }}
                              defaultValue={dayjs().startOf('month')}
                              label="ตั้งแต่วันที่"
                              value={field.value ? dayjs(field.value) : dayjs()}
                              onChange={(newValue: any) => {
                                field.onChange(newValue);
                                setDateRangeToStorage(STORAGE_KEYS.PROCESS, newValue.startOf('day').toDate(), dayjs(getValues('dateTo')) ?? dayjs().startOf('day'));
                              }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <Controller
                      name="dateTo"
                      control={control}
                      render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'th'}>
                          <DemoContainer components={['DatePicker', 'DatePicker']} >
                            <DatePicker
                              sx={{ width: "100%" }}
                              defaultValue={dayjs()}
                              label="ถึงวันที่"
                              value={field.value ? dayjs(field.value) : dayjs()}
                              onChange={(newValue: any) => {
                                field.onChange(newValue);
                                setDateRangeToStorage(STORAGE_KEYS.PROCESS, dayjs(getValues('dateFrom')) ?? dayjs().startOf('month').startOf('day'), newValue.startOf('day').toDate());
                              }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} alignSelf={'flex-end'}>
                    <TextField fullWidth label="ชื่อโปรเจกต์" {...register("order")} />
                  </Grid>
                  <Grid item xs={6} sm={6} md={3} lg={3} alignSelf={'flex-end'}>
                    <TextField fullWidth label="เลขที่เอกสาร" {...register("docNo")} />
                  </Grid>
                  <Grid item xs={6} sm={6} md={3} lg={3} alignSelf={'flex-end'}>
                    <Controller
                      name="status"
                      control={control}
                      render={({ field }) => (
                        <FormControl fullWidth>
                          <InputLabel id="status-select-label">สถานะ</InputLabel>
                          <Select
                            labelId="status-select-label"
                            value={field.value || "all"}
                            onChange={(e) => field.onChange(e.target.value)}
                            label="สถานะ"
                          >                      {documentStatusOptions.map((status) => (
                            <MenuItem key={status.value} value={status.value}>
                              {status.name}
                            </MenuItem>
                          ))}
                          </Select>
                        </FormControl>
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={2} md={2} lg={2} textAlign={"end"} alignSelf={"end"}>
                <Button
                  onClick={() => searchByCriteria()}
                  variant="contained"
                  startIcon={<SearchIcon />}
                >
                  ค้นหา
                </Button>
              </Grid>
            </Grid>
          </Box>
        </>
      }
      table={
        <>
          <TableDataList
            data={props.datas?.datas || []}
            total={props.datas?.total}
            columns={columns}
            actionButtons={actionButtons}
            rowActions={action}
            page={page}
            limit={limit}
            onPageChange={(newPage) => {
              searchByCriteria(newPage);
            }}
            onRowsPerPageChange={(newLimit) => {
              setLimit(newLimit);
              setPage(0);
              searchByCriteria(0, newLimit);
            }}
          />
          <Box sx={{ display: 'none' }}>
            <PrintShippingPackingList ref={contentRef} data={props.printShippingPackingList} />
          </Box>
        </>
      }
    />
  );
};

export default ShippingsListView;