import { IUserDetail } from "../features/Login/Services/LoginService";

const getPermissionBySubMenu = (subMenu: string) => {
  const details = JSON.parse(localStorage.getItem("user") || "{}").details;
  const isSuperAdmin = JSON.parse(localStorage.getItem("user") || "{}").isSuperAdmin;
  if (details && details.length > 0 && !isSuperAdmin) {
    let parentMenu = '';
    if (subMenu.includes("-")) {
      parentMenu = subMenu.split("-")[0];
    } else {
      parentMenu = subMenu;
    }
    const found = details.find((data: any) => data.menu === parentMenu);
    if (found) {
      const childMenu = found.children?.find((menu: any) => menu.menu === subMenu);
      if (childMenu) {
        return childMenu;
      } else {
        return null;
      }
    }
  } else {
    // NOTE : สำหรับ super admin
    return {
      create: true,
      read: true,
      update: true,
      delete: true,
      menu: "",
      children: [],
      edit: true,
      id: "",
    } as IUserDetail;
  }
};

export default getPermissionBySubMenu;