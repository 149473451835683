import { observer } from "mobx-react-lite";
import ExpenseDetailView from "../Views/ExpenseDetailView";
import { useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import expenseService, { IExpense } from "../Services/ExpenseService";
import expenseTypeService, { IExpenseType } from "../Services/ExpenseTypeService";
import { useNavigate, useParams } from "react-router-dom";
import { IUploadFile } from "../../Company/Services/CompanyService";
import receiveService from "../../Inventory/Services/ReceiveService";
import getPermissionBySubMenu from "../../../utils/getPermissionBySubMenu";
import { IUserDetail } from "../../Login/Services/LoginService";

const ExpenseDetailController = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [permission, setPermission] = useState<IUserDetail>();
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    getExpenseTypes();
    checkPermissionActionButton();
    if (id) { getOne(id); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  const getOne = async (id: string) => {
    try {
      setIsLoading(true);
      await expenseService.getOne(id);
    } catch (error) {
      console.error("Error searching transfers by criteria:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const create = async (data: IExpense, uploadFile: IUploadFile) => {
    try {
      setIsLoading(true);
      const result = await expenseService.create(data, { file: uploadFile });
      if (result) {
        navigate(`/expense-other-detail/${result.id}`);

      }
      enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const update = async (data: IExpense, uploadFile?: IUploadFile[], deleteImages?: IUploadFile[]) => {
    try {
      setIsLoading(true);
      const _uploadFile = data.image === uploadFile ? undefined : uploadFile;
      const res = await expenseService.update(data, { file: _uploadFile });
      if (res) {
        if (deleteImages && deleteImages.length > 0) {
          const deletePromises = deleteImages.map((file: any) => {
            return receiveService.deleteImages({
              file: file,
            });
          });
          await Promise.all(deletePromises);
        }
        if (data.id) {
          getOne(data?.id ?? "");
        }
        enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      } else {
        enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      }

      if (data.isDelete) { navigate(`/expense-other`); }
    } catch (error) {
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const getExpenseTypes = async () => {
    try {
      setIsLoading(true);
      await expenseTypeService.getAll();
    } catch (error) {
      console.error("Error fetching expense types:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const checkPermissionActionButton = () => {
    const result = getPermissionBySubMenu("expense-type");
    if (result) {
      setPermission(result);
    }
  };

  //#region ประเภทค่าใช้จ่าย
  const onCreateExpenseType = async (data: IExpenseType) => {
    try {
      setIsLoading(true);
      await expenseTypeService.create(data);
      enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      getExpenseTypes();
    } catch (error) {
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };
  //#endregion
  return <ExpenseDetailView data={expenseService.expenseData} isLoading={isLoading} onSubmit={create} onUpdate={update} onDelete={update}
    typeOptions={expenseTypeService.expenseTypeListData || []}
    permission={permission ?? {} as IUserDetail}
    // ประเภทค่าใช้จ่าย
    onCreateExpenseType={onCreateExpenseType}

  />;
});

export default ExpenseDetailController;
