import { Box, Card, CardContent, Grid } from "@mui/material";
import { FC, ReactNode } from "react";
import SimpleBackdrop from "./SimpleBackdrop";

/**
 * ListViewPageProps is an interface for ListViewPage component
 * @interface
 * @property {ReactNode} breadcrumb - The breadcrumb component
 * @property {ReactNode} criteria - The criteria component
 * @property {ReactNode} table - The table component
 * @property {ReactNode} dialog - The dialog component
 * @returns {ReactNode} - React component
 * @version 1.0.0
 **/

interface ListViewPageProps {
  breadcrumb: ReactNode;
  criteria: ReactNode;
  table: ReactNode;
  dialog?: ReactNode;
  isLoading?: boolean;
}
const ListViewPage: FC<ListViewPageProps> = (props) => {
  return (
    <Box component={'div'}>
      {props.breadcrumb}

      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} sm={12} md={12} lg={12} >
          <Card>
            <CardContent>
              {props.criteria}
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Card>
            <CardContent>
              {props.table}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {props.dialog}
      <SimpleBackdrop open={props.isLoading ?? false} />
    </Box>
  );
};
export default ListViewPage;