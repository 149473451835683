import { useState } from "react";
import LoginView from "../Views/LoginView";
import { observer } from "mobx-react-lite";
import loginService, { ILogin } from "../Services/LoginService";
import { useNavigate } from "react-router-dom";

const LoginController = observer(() => {
  const navigate = useNavigate();
  const [hasError, setHasError] = useState(false);
  const [hasSuccess, setHasSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleLogin = async (data: ILogin) => {
    setIsLoading(true);
    setHasError(false);
    setHasSuccess(false);
    try {
      await loginService.login(data).then((res) => {
        setHasSuccess(true);
        setTimeout(() => {
          navigate("/");
        }, 1500);
      });
    } catch (e) {
      setTimeout(() => {
        setIsLoading(false);
        setHasError(true);
      }, 1500);
    }
  };

  return <LoginView hasError={hasError} hasSuccess={hasSuccess} onLogin={handleLogin} isLoading={isLoading} />;
});

export default LoginController;
