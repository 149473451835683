import { FC, useState } from "react";
import { IUserGroup, IUserGroupCriteria, IUserGroupListView } from "../Services/RoleService";
import { useNavigate } from "react-router-dom";
import { Box, Breadcrumbs, Button, Grid, IconButton, TextField, Typography } from "@mui/material";
import dayjs from "dayjs";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import SearchIcon from "@mui/icons-material/Search";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import ListViewPage from "../../../components/ListViewPage";
import TableDataList from "../../../components/TableDataList";
import { IUserDetail } from "../../Login/Services/LoginService";

interface RolesListViewProps {
  onSearchByCriteria: (criteria: IUserGroupCriteria) => Promise<void>;
  isLoading?: boolean | false;
  onCreate: (data: any) => Promise<void>;
  onUpdate: (data: any) => Promise<void>;
  onRemove: (id: string) => Promise<void>;
  data: IUserGroupListView;
  permission: IUserDetail;
}
const RolesListView: FC<RolesListViewProps> = (props) => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [name, setName] = useState("");
  const navigate = useNavigate();

  const searchByCriteria = (pageNumber: number = 0, rowLimit?: number) => {
    setPage(pageNumber);
    setLimit(rowLimit ? rowLimit : 10);
    const criteria: IUserGroupCriteria = {
      name: name,
      page: pageNumber,
      limit: rowLimit ?? 10,
    };
    props.onSearchByCriteria(criteria);
  };

  //SECTION - Variables for columns TableDataList
  const columns = [
    { label: 'ตำแหน่ง', render: (row: IUserGroup) => row.name },
    { label: 'บริษัท', render: (row: IUserGroup) => row?.company?.name },
    {
      label: 'บันทึกล่าสุด', render: (row: IUserGroup) => (
        <Box component={'div'}>
          <Box component={'div'}>{row.createBy?.firstName || ""} {row.createBy?.lastName || ""}</Box>
          <Box component={'div'}>{dayjs(row.createAt).format('DD/MM/YYYY') === "Invalid Date" ? "" :
            <Box>
              <Box>{dayjs(row.createAt).format('DD/MM/YYYY')}</Box>
              <Box>{dayjs(row.createAt).format('HH:mm')}</Box>
            </Box>
          }</Box>
        </Box>
      )
    },
    {
      label: 'แก้ไขล่าสุด', render: (row: IUserGroup) => (
        <Box component={'div'}>
          <Box component={'div'}>{row.updateBy?.firstName || ""} {row.updateBy?.lastName || ""}</Box>
          <Box component={'div'}>{dayjs(row.updateAt).format('DD/MM/YYYY') === "Invalid Date" ? "" :
            <Box>
              <Box>{dayjs(row.updateAt).format('DD/MM/YYYY')}</Box>
              <Box>{dayjs(row.updateAt).format('HH:mm')}</Box>
            </Box>
          }</Box>
        </Box>
      )
    },

  ];

  const actions = (row: IUserGroup) => (
    <IconButton
      onClick={() => {
        navigate(`/admin-role-detail/${row.id}`);
      }}>
      <KeyboardArrowRightIcon />
    </IconButton>
  );

  const actionButtons = props.permission.create ? (
    <Button
      variant="contained"
      startIcon={<NoteAddIcon />}
      onClick={() => {
        navigate(`/admin-role-detail`);
      }}>
      สร้าง
    </Button>
  ) : <></>;
  return (<ListViewPage
    breadcrumb={
      <>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Typography>ตำแหน่ง</Typography>
          <Typography variant="h6" color="text.primary">รายการ</Typography>
        </Breadcrumbs>
      </>
    }
    criteria={
      <>
        <Box component={"form"}>
          <Grid container spacing={2} alignItems={"end"}>
            <Grid item xs={12} sm={12} md={12} lg={10}>
              <Grid container spacing={2} alignItems={"end"}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField fullWidth label="ตำแหน่ง" value={name} onChange={(e) => setName(e.target.value)} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={2} textAlign={"end"} alignSelf={"end"}>
              <Button
                onClick={() => searchByCriteria()}
                variant="contained"
                startIcon={<SearchIcon />}
              >
                ค้นหา
              </Button>
            </Grid>
          </Grid>
        </Box>
      </>}
    table={
      <>
        <TableDataList
          data={props.data?.datas || []}
          total={props.data?.total}
          columns={columns}
          actionButtons={actionButtons}
          rowActions={actions}
          page={page}
          limit={limit}
          onPageChange={(newPage: number) => {
            setPage(newPage);
            searchByCriteria(newPage);
          }}
          onRowsPerPageChange={(newLimit) => {
            setLimit(newLimit);
            setPage(0);
            searchByCriteria(0, newLimit);
          }}
        />
      </>}
    isLoading={props.isLoading ?? false}
  />);
};


export default RolesListView;
