import { observer } from "mobx-react-lite";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import clearState from "../../../utils/resetState";
import { IUserDetail } from "../../SuperAdminManagement/Services/UserService";
import packagingService from "../Services/PackagingService";
import shippingService, { IPrintShippingPackingList, IShipping } from "../Services/ShippingService";
import { IShippingType } from "../Services/ShippingTypeService";
import ShippingCreateView from "../Views/ShippingCreateView";
import getPermissionBySubMenu from "../../../utils/getPermissionBySubMenu";
import { IUploadFile } from "../../Company/Services/CompanyService";
import { enqueueSnackbar } from "notistack";
import receiveService from "../../Inventory/Services/ReceiveService";
import loginService from "../../Login/Services/LoginService";
import expenseTypeService, { IExpenseType } from "../../Expense/Services/ExpenseTypeService";
import shippingTypeService from "../Services/ShippingTypeService";
import consigneeService, { IConsignee } from "../Services/Consignee";

const ShippingCreateController = observer(() => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [permission, setPermission] = useState<IUserDetail>();
  useEffect(() => {
    if (id) {
      getOne(id);
    }
    getOrder();
    getExpenseTypes();
    getShippingType();
    checkPermissionActionButton();
    getConsignee();
    return () => {
      clearState(packagingService, ["shipping"]);
      clearState(shippingService, ["orders", "packagings", "shipping", "shippingTypes"]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const getOne = async (id: string) => {
    try {
      setIsLoading(true);
      await shippingService.getOne(id);
    } catch (error) {
      console.error("Error fetching shipping:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getOrder = async () => {
    try {
      setIsLoading(true);
      await shippingService.getAllOrders();
    } catch (error) {
      console.error("An error occurred while creating the order:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getExpenseTypes = async () => {
    try {
      setIsLoading(true);
      await expenseTypeService.getAll();
    } catch (error) {
      console.error("Error fetching expense types:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getPackaging = async (orderId: string) => {
    try {
      setIsLoading(true);
      await shippingService.getPackagings(orderId);
    } catch (error) {
      console.error("An error occurred while creating the packaging:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getShippingType = async () => {
    try {
      setIsLoading(true);
      await shippingService.getShippingTypes();
    } catch (error) {
      console.error("An error occurred while creating the shipping type:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const getConsignee = async () => {
    try {
      setIsLoading(true);
      await consigneeService.getAll();
    } catch (error) {
      console.error("An error occurred while creating the shipping type:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const onCreate = async (data: IShipping, uploadFile: IUploadFile, uploadFileExpense: IUploadFile) => {
    try {
      setIsLoading(true);
      const res = await shippingService.create(data, uploadFile, uploadFileExpense);
      enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      getOne(id || '');
      navigate(`/process-shipping-edit/${res?.id}`);
    } catch (error) {
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      console.error("An error occurred while creating the shipping:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const onUpdate = async (data: IShipping, oldImage: IUploadFile[], uploadFile: IUploadFile[], deleteImages?: IUploadFile[], oldImageExpense?: IUploadFile[], uploadFileExpense?: IUploadFile[], deleteImagesExpense?: IUploadFile[]) => {
    try {
      setIsLoading(true);
      const _uploadFile = oldImage === uploadFile ? undefined : uploadFile;
      const _uploadFileExpense = oldImageExpense === uploadFileExpense ? undefined : uploadFileExpense;
      const res = await shippingService.update(data, _uploadFile, _uploadFileExpense);
      if (res) {
        if (deleteImages && deleteImages.length > 0) {
          const deletePromises = deleteImages.map((file: any) => {
            const isPdf = file.name && file.name.toLowerCase().endsWith('.pdf');
            return receiveService.deleteImages({
              file: file,
            }, isPdf);
          });
          await Promise.all(deletePromises);
        }
        if (deleteImagesExpense && deleteImagesExpense.length > 0) {
          const deletePromises = deleteImagesExpense.map((file: any) => {
            const isPdf = file.name && file.name.toLowerCase().endsWith('.pdf');
            return receiveService.deleteImages({
              file: file,
            }, isPdf);
          });
          await Promise.all(deletePromises);
        }
        enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
        if (data.isDelete) {
          navigate(`/process-shipping`);
        } else if (id) {
          getOne(id);
        }
      } else {
        enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      }
    } catch (error) {
      console.error("An error occurred while updating the shipping:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const checkPermissionActionButton = () => {
    const result = getPermissionBySubMenu("process-shipping");
    if (result) {
      setPermission(result);
    }
  };

  //#region ประเภทการขนส่ง
  const onCreateShippingType = async (data: IShippingType) => {
    try {
      setIsLoading(true);
      await shippingTypeService.create(data);
      enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      getShippingType();
    } catch (error) {
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };
  //#endregion

  //#region ประเภทค่าใช้จ่าย
  const onCreateExpenseType = async (data: IExpenseType) => {
    try {
      setIsLoading(true);
      await expenseTypeService.create(data);
      enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      getExpenseTypes();
    } catch (error) {
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };
  //#endregion




  //#region ผู้ชำระเงิน
  const onCreateConsignee = async (data: IConsignee) => {
    try {
      setIsLoading(true);
      await consigneeService.create(data);
      enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      getConsignee();
    } catch (error) {
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };
  //#endregion



  return (
    <ShippingCreateView
      onCreate={onCreate}
      onUpdate={onUpdate}
      order={shippingService.ordersData || []}
      packaging={shippingService.packagingsData || []}
      shipping={shippingService.shippingData}
      isLoading={isLoading}
      permission={permission ?? {} as IUserDetail}
      user={loginService.userLoginedData ?? {} as IUserDetail}
      shippingTypes={shippingService.shippingTypesData || []}
      onFetchPackaging={getPackaging}
      typeExpenseOptions={expenseTypeService.expenseTypeListData || []}
      consignees={consigneeService.allConsigneesData || []}
      // ประเภทการขนส่ง
      onCreateShippingType={onCreateShippingType}
      // ประเภทค่าใช้จ่าย
      onCreateExpenseType={onCreateExpenseType}
      onCreateConsignee={onCreateConsignee}
      printShippingPackingList={shippingService.printShippingPackingListData || {} as IPrintShippingPackingList}
    />
  );
});

export default ShippingCreateController;