import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { IUserDetail } from "../../SuperAdminManagement/Services/UserService";
import carService, { ICar, ICarCriteria, ICarView } from "../Services/CarService";
import { enqueueSnackbar } from "notistack";
import getPermissionBySubMenu from "../../../utils/getPermissionBySubMenu";
import CarListView from "../Views/CarListView";
import companyService from "../../Company/Services/CompanyService";

const CarController = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [permission, setPermission] = useState<IUserDetail>();
  const [carData, setCarData] = useState<ICarView>();

  const defaultCriteria: ICarCriteria = {
    plateNumber: "",
    page: 0,
    limit: 10,
  };

  useEffect(() => {
    getByCriteria(defaultCriteria);
    getProvince();
    checkPermissionActionButton();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getByCriteria = async (criteria: ICarCriteria) => {
    try {
      setIsLoading(true);
      await carService.getByCriteria(criteria).then((data) => {
        setCarData(data);
      });
    } catch (error) {
      console.error("An error occurred while getting the province:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const create = async (data: ICar) => {
    try {
      setIsLoading(true);
      const res = await carService.create(data);
      if (res) {
        enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      } else {
        enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      }
      getByCriteria(defaultCriteria);
    } catch (error) {
      console.error("An error occurred while creating the warehouse:", error);
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const update = async (data: ICar) => {
    try {
      setIsLoading(true);
      const res = await carService.update(data);
      if (res) {
        enqueueSnackbar("อัพเดทข้อมูลสำเร็จ", { variant: "success" });
      } else {
        enqueueSnackbar("อัพเดทข้อมูลไม่สำเร็จ", { variant: "error" });
      }
      getByCriteria(defaultCriteria);
    } catch (error) {
      console.error("An error occurred while updating the warehouse:", error);
      enqueueSnackbar("อัพเดทข้อมูลไม่สำเร็จ", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const getProvince = async () => {
    try {
      setIsLoading(true);
      await companyService.getProvince();
    } catch (error) {
      console.error("An error occurred while getting the province:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const checkPermissionActionButton = () => {
    const result = getPermissionBySubMenu("transport-car");
    if (result) {
      setPermission(result);
    }
  };


  return <CarListView isLoading={isLoading}
    cars={carData ?? {} as ICarView}
    car={carService.carData ?? {} as ICar}
    onSerchByCriteria={getByCriteria}
    provinceData={companyService.provinceData || []}
    onCreate={create}
    onUpdate={update}
    permission={permission ?? {} as IUserDetail} />;
});

export default CarController;