import { Dayjs } from "dayjs";

export const getDateRangeFromStorage = (key: string) => {
  const data = localStorage.getItem(key);
  return data ? JSON.parse(data) : { dateFrom: null, dateTo: null };
};

export const setDateRangeToStorage = (key: string, dateFrom: Dayjs, dateTo: Dayjs) => {
  localStorage.setItem(key, JSON.stringify({ dateFrom, dateTo }));
};

export const clearLocalStorageKeyOnUnload = (key: string) => {
  window.addEventListener("beforeunload", function (event) {
    localStorage.removeItem(key);
  });
};
