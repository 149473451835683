import { observer } from "mobx-react-lite";
import CapacitiesListView from "../Views/CapacitiesListView";
import { IUserDetail } from "../../SuperAdminManagement/Services/UserService";
import { useEffect, useState } from "react";
import capacityService, { ICapacity, ICapacityCriteria, ICapacityListView } from "../Services/CapacityService";
import getPermissionBySubMenu from "../../../utils/getPermissionBySubMenu";
import { enqueueSnackbar } from "notistack";

const CapacitiesListController = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [permission, setPermission] = useState<IUserDetail>();

  const defaultCriteria: ICapacityCriteria = {
    name: '',
    page: 0,
    limit: 10,
  };

  useEffect(() => {
    fetchByCriteria(defaultCriteria);
    checkPermissionActionButton();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchByCriteria = async (criteria: ICapacityCriteria) => {
    try {
      setIsLoading(true);
      await capacityService.getByCriteria(criteria);
    } catch (error) {
      console.error("An error occurred while getting the farm product receipt:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const create = async (data: ICapacity) => {
    try {
      setIsLoading(true);
      await capacityService.create(data);
    } catch (error) {
      console.error("An error occurred while creating the capacity:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const submit = async (data: ICapacity) => {
    try {
      setIsLoading(true);
      if (data?.id) {
        await update(data);
      } else {
        await create(data);
      }
      enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      await fetchByCriteria(defaultCriteria);
    } catch (error) {
      console.error("An error occurred while submiting the capacity:", error);
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const update = async (data: ICapacity) => {
    try {
      setIsLoading(true);
      await capacityService.update(data);
    } catch (error) {
      console.error("An error occurred while updating the capacity:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const checkPermissionActionButton = () => {
    const result = getPermissionBySubMenu("setting-capacity");
    if (result) {
      setPermission(result);
    }
  };

  return <CapacitiesListView
    onSubmit={submit}
    onSerchByCriteria={fetchByCriteria} data={capacityService?.capacitiesData ?? {} as ICapacityListView} isLoading={isLoading} permission={permission || {}} />;
});

export default CapacitiesListController;
