import { observer } from "mobx-react-lite";
import RolesListView from "../Views/RolesListView";
import { useState, useEffect } from "react";
import roleService, { IRoleCriteria, IRoleListView, } from "../Services/RoleService";
import { IUserDetail } from "../../Login/Services/LoginService";
import getPermissionBySubMenu from "../../../utils/getPermissionBySubMenu";

const RolesListController = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [permission, setPermission] = useState<IUserDetail>();
  const [roleData, setRoleData] = useState<IRoleListView>();
  const defaultCriteria: IRoleCriteria = {
    name: '',
    userGroup: '',
    status: undefined,
    page: 0,
    limit: 10,
  };

  useEffect(() => {
    getByCriteria(defaultCriteria);
    checkPermissionActionButton();
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , []);
  const getByCriteria = async (criteria: IRoleCriteria) => {
    try {
      setIsLoading(true);
      await roleService.getByCriteria(criteria).then((data) => {
        setRoleData(data);
      }
      );
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const create = async (data: any) => {
    try {
      setIsLoading(true);
      await roleService.create(data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const remove = async (id: string) => {
    try {
      setIsLoading(true);
      await roleService.delete(id);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const checkPermissionActionButton = () => {
    const result = getPermissionBySubMenu("user-role");
    if (result) {
      setPermission(result);
    }
  };


  return <RolesListView onCreate={create} onRemove={remove} isLoading={isLoading} onSearchByCriteria={getByCriteria} data={roleData ?? {} as IRoleListView} permission={permission ?? {} as IUserDetail} />;
});

export default RolesListController;
