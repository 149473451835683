import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import groupingService, { IGrouping } from "../Services/GroupingService";
import { enqueueSnackbar } from "notistack";
import GroupingCreateView from "../Views/GroupingCreateView";
import getPermissionBySubMenu from "../../../utils/getPermissionBySubMenu";
import { IUserDetail } from "../../Login/Services/LoginService";
import materialService from "../Services/MaterialsService";

const GroupingCreateController = observer(() => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [permission, setPermission] = useState<IUserDetail>();

  useEffect(() => {
    if (id) {
      findOne(id);
    }
    getMainMaterials();
    getSubMaterials();
    checkPermissionActionButton();
  }
    , [id]);

  const create = async (data: IGrouping) => {
    try {
      setIsLoading(true);
      const res = await groupingService.create(data);
      if (res) {
        enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
        navigate(`/inventory-grouping-create/${res.id}`);
      } else {
        enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      console.error("เกิดข้อผิดพลาดในการบันทึก", error);
    } finally {
      setIsLoading(false);
    }
  };

  const update = async (id: string, data: IGrouping) => {
    try {
      setIsLoading(true);
      const res = await groupingService.update(id, data);
      if (res) {
        enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
        findOne(id ?? "");
      } else {
        enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      console.error("เกิดข้อผิดพลาดในการบันทึก", error);
    } finally {
      setIsLoading(false);
    }
  };

  const onDelete = async (data: IGrouping) => {
    try {
      setIsLoading(true);
      await groupingService.update(id ?? "", data);
      enqueueSnackbar("ลบข้อมูลสำเร็จ", { variant: "success" });
      navigate("/inventory-grouping");
    } catch (error) {
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      console.error("เกิดข้อผิดพลาดในการบันทึก", error);
    }
    finally {
      setIsLoading(false);
    }
  };

  const getMainMaterials = async () => {
    try {
      setIsLoading(true);
      await groupingService.getMainMaterials();
    } catch (error) {
      console.error(error);
    }
    finally {
      setIsLoading(false);
    }
  };

  const getSubMaterials = async () => {
    try {
      setIsLoading(true);
      await groupingService.getSubMaterials();
    } catch (error) {
      console.error(error);
    }
    finally {
      setIsLoading(false);
    }
  };


  const findOne = async (id: string) => {
    try {
      setIsLoading(true);
      const res = await groupingService.getOne(id);
      return res;
    } catch (error) {
      console.error("เกิดข้อผิดพลาดในการค้นหา", error);
    }
    finally {
      setIsLoading(false);
    }
  };

  const onCreateDialog = async (data: any, type: string) => {
    try {
      let response;
      switch (type) {
        case "material":
          response = await materialService.create(data);
          getMainMaterials();
          getSubMaterials();
          break;
      }
      if (response) {
        enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      }
    } catch (error) {
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      console.error(`An error occurred while creating the ${type}`, error);
    }
  };
  const checkPermissionActionButton = () => {
    const result = getPermissionBySubMenu("inventory-grouping");
    if (result) {
      setPermission(result);
    }
  };

  return <GroupingCreateView
    onCreate={create}
    onUpdate={update}
    onDelete={onDelete}
    groupingData={groupingService.groupingData ?? {} as IGrouping}
    mainMaterialsData={groupingService.mainMaterialsData ?? []}
    subMaterialsData={groupingService.subMaterialsData ?? []}
    groupingId={id ?? ""}
    isLoading={isLoading}
    permission={permission ?? {} as IUserDetail}
    onCreateDialog={onCreateDialog}
  />;
});

export default GroupingCreateController;