import { observer } from "mobx-react-lite";
import { enqueueSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import wareshousesService, { IWarehouse } from "../Services/WareshousesService";
import { useEffect, useState } from "react";
import companyService from "../../Company/Services/CompanyService";
import WarehousesDetailView from "../Views/WarehousesDetailView";
import materialService, { IMaterialInWarehouseCriteria } from "../Services/MaterialsService";
import clearState from "../../../utils/resetState";
import { IUserDetail } from "../../Login/Services/LoginService";
import getPermissionBySubMenu from "../../../utils/getPermissionBySubMenu";

const WarehouseDetailController = observer(() => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [permission, setPermission] = useState<IUserDetail>();
  const navigate = useNavigate();

  const defaultCriteria: IMaterialInWarehouseCriteria = {
    warehouseId: id ?? '',
    page: 0,
    limit: 10,
  };

  useEffect(() => {
    if (id) {
      getOne(id);
      getProvince();
      getDistrict();
      getSubDistrict();
      checkPermissionActionButton();
      getMaterialInWarehouse(defaultCriteria);
      getUsers();
    }

    return () => {
      clearState(materialService, ["materialsInWarehouse"]);
    };
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , [id]);

  const update = async (data: IWarehouse) => {
    try {
      setIsLoading(true);
      const res = await wareshousesService.update(data);
      if (res) {
        enqueueSnackbar("อัพเดทข้อมูลสำเร็จ", { variant: "success" });
        getOne(data.id ?? '');
      } else {
        enqueueSnackbar("อัพเดทข้อมูลไม่สำเร็จ", { variant: "error" });
      }
      if (data.isDelete) {
        navigate(`/inventory-warehouses`);
      }
    } catch (error) {
      console.error("An error occurred while updating the warehouse:", error);
      enqueueSnackbar("อัพเดทข้อมูลไม่สำเร็จ", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const getOne = async (id: string) => {
    try {
      setIsLoading(true);
      const res = await wareshousesService.get(id);
      return res;
    } catch (error) {
      console.error("An error occurred while getting the warehouse:", error);
    } finally {
      setIsLoading(false);
    }
  };


  const getProvince = async () => {
    try {
      setIsLoading(true);
      await companyService.getProvince();
    } catch (error) {
      console.error("An error occurred while getting the province:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getDistrict = async (id?: number) => {
    if (!id) return;
    try {
      setIsLoading(true);
      await companyService.getDistrict(id);
    } catch (error) {
      console.error("An error occurred while getting the district:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getSubDistrict = async (id?: number) => {
    if (!id) return;
    try {
      setIsLoading(true);
      await companyService.getSubDistrict(id);
    } catch (error) {
      console.error("An error occurred while getting the sub-district:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getMaterialInWarehouse = async (criteria: IMaterialInWarehouseCriteria) => {
    try {
      setIsLoading(true);
      await materialService.getMaterialsInWarehouse(criteria);
    } catch (error) {
      console.error("An error occurred while getting the material in warehouse:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getUsers = async () => {
    try {
      setIsLoading(true);
      await wareshousesService.getUser();
    } catch (error) {
      console.error("An error occurred while getting the users:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const checkPermissionActionButton = () => {
    const result = getPermissionBySubMenu("inventory-warehouses");
    if (result) {
      setPermission(result);
    }
  };

  return <WarehousesDetailView
    warehouse={wareshousesService.warehouse}
    isLoading={isLoading}
    onUpdate={update}
    provinceData={companyService.provinceData || []}
    districtData={companyService.districtData || []}
    subDistrictData={companyService.subDistrictData || []}
    materialInWarehouseData={materialService.materialInWarehouseData || []}
    onSearchByCriteria={getMaterialInWarehouse}
    onFetchDistrict={getDistrict}
    onFetchSubDistrict={getSubDistrict}
    permission={permission ?? {} as IUserDetail}
    userOption={wareshousesService.usersData || []} />;
});

export default WarehouseDetailController;