import { makeAutoObservable } from "mobx";
import apiService from "../../../services/ApiService";
import { IUser } from "../../UserManagement/Services/RoleService";

export interface ICompany {
  id?: string;
  name?: string | null;
  branch?: string | null;
  tax?: string | null;
  address?: string | null;
  subDistrict?: ISubDistrict | null;
  district?: IDistrict | null;
  province?: IProvince | null;
  zipCode?: string | null;
  tel?: string | null;
  createdBy?: IUser | undefined;
  updatedBy?: IUser | undefined;
  createdAt?: string;
  updatedAt?: string;
  isDelete?: boolean;
  image?: IImage;
  isAdmin?: boolean;
  isActive?: boolean;
}
export interface IUploadFile {
  file: any;
}

export interface IProvince {
  id?: number;
  nameTh?: string;
}

export interface IDistrict {
  id?: number;
  nameTh?: string;
  province?: IProvince | null;
}

export interface ISubDistrict {
  id?: number;
  nameTh?: string;
  district?: IDistrict | null;
  zipCode?: string;
}

export interface IImage {
  name?: string;
}

class CompanyService {
  company: ICompany = {};
  province: IProvince[] = [];
  district: IDistrict[] = [];
  subDistrict: ISubDistrict[] = [];

  constructor() {
    this.company = {} as ICompany;
    this.province = [] as IProvince[];
    this.district = [] as IDistrict[];
    this.subDistrict = [] as ISubDistrict[];
    makeAutoObservable(this);
  }

  async create(data: any, uploadFile: IUploadFile) {
    try {
      const company: ICompany = await apiService.post(`/companies`, data);
      if (uploadFile.file) {
        const formData = new FormData();
        formData.append("file", uploadFile.file);
        formData.append("refId", company?.id || "");
        formData.append("refType", "company");
        await apiService.postFormData("/images/upload", formData);
      }
      window.location.reload();
      return company;
    } catch (error) {
      throw error;
    }
  }

  async getOne(id: string) {
    try {
      this.company = await apiService.get(`/companies/${id}`);
      return this.company;
    } catch (error) {
      throw error;
    }
  }

  get companyData() {
    return this.company;
  }

  async update(id: string, data: any, uploadFile?: IUploadFile) {
    try {
      const _data = { ...data };
      delete _data.image;
      const company = await apiService.put(`/companies/${id}`, _data);
      if (uploadFile?.file) {
        const formData = new FormData();
        formData.append("file", uploadFile.file);
        formData.append("refId", id);
        formData.append("refType", "company");
        await apiService.postFormData("/images/upload", formData);
        if (data.image) {
          await apiService.delete(`/images/${data.image.id}`);
        }
      }
      window.location.reload();
      return company;
    } catch (error) {
      throw error;
    }
  }

  async getProvince() {
    try {
      this.province = await apiService.get(`/provinces`);
      return this.province;
    } catch (error) {
      throw error;
    }
  }

  get provinceData() {
    return this.province;
  }

  async getDistrict(id: number) {
    try {
      this.district = await apiService.get(`/districts?provinceId=${id}`);
      return this.district;
    } catch (error) {
      throw error;
    }
  }

  get districtData() {
    return this.district;
  }
  async getSubDistrict(id: number) {
    try {
      this.subDistrict = await apiService.get(
        `/sub-districts?districtId=${id}`
      );
      return this.subDistrict;
    } catch (error) {
      throw error;
    }
  }

  get subDistrictData() {
    return this.subDistrict;
  }

}

const companyService = new CompanyService();
export default companyService;
