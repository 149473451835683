import { FC, useEffect, useState } from "react";
import { IUserDetail } from "../../SuperAdminManagement/Services/UserService";
import { ICar } from "../Services/CarService";
import { Controller, useForm } from "react-hook-form";
import { Autocomplete, Box, Button, ButtonProps, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, TextField } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import DialogConfirm from "../../../components/DialogConfirm";
import { IProvince } from "../../Company/Services/CompanyService";


interface CarCreateViewProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: ICar) => void;
  provinceData?: IProvince[];
  data?: ICar;
  permission: IUserDetail;
  name?: string;
}

const CarCreateView: FC<CarCreateViewProps> = (props) => {
  const { register, handleSubmit, reset, getValues, control, formState: { errors } } = useForm<ICar>();
  const [openConfirm, setOpenConfirm] = useState({
    open: false,
    title: "",
    message: "",
    color: "primary" as ButtonProps["color"],
    type: "create" as "create" | "edit" | "delete",
  });

  const onClose = () => {
    reset();
    props.onClose();
    setOpenConfirm({ ...openConfirm, open: false });
  };

  const onSubmit = handleSubmit(() => {
    if (props.data?.id) {
      setOpenConfirm({
        open: true,
        title: "แก้ไขข้อมูล",
        message: "คุณต้องการแก้ไขข้อมูลนี้ใช่หรือไม่",
        color: "primary",
        type: "edit",
      });
    } else {
      setOpenConfirm({
        open: true,
        title: "บันทึกข้อมูล",
        message: "คุณต้องการบันทึกข้อมูลนี้ใช่หรือไม่",
        color: "primary",
        type: "create",
      });
    }
  });

  const onConfirm = () => {
    props.onSubmit(getValues());
    reset();
    onClose();
  };

  const onDelete = () => {
    props.onSubmit({ ...props.data, isDelete: true });
    reset();
    onClose();
  };

  useEffect(() => {
    reset({ ...props.data, plateNumber: props?.name ? props?.name : props.data?.plateNumber });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data, props.open]);

  return (
    <Dialog open={props.open} maxWidth={'lg'} fullWidth>
      <DialogTitle>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6} md={6} lg={6}>
            {props.data?.id ? `แก้ไข` : `สร้าง`}ข้อมูลรถ
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} textAlign="right">
            {props.data?.id && (
              <Button
                variant="contained"
                color="error"
                startIcon={<DeleteIcon />}
                onClick={() => {
                  setOpenConfirm({
                    open: true,
                    title: "ลบข้อมูล",
                    message: "คุณต้องการลบข้อมูลนี้ใช่หรือไม่",
                    color: "error",
                    type: "delete",
                  });
                }}
              >
                ลบข้อมูล
              </Button>
            )}
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent>
        <Box component={'form'} onSubmit={onSubmit}>
          <Grid container spacing={2} mt={1}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                fullWidth
                label="เลขทะเบียน"
                {...register("plateNumber", { required: true })}
                error={!!errors.plateNumber}
                helperText={errors.plateNumber ? "กรุณาระบุเลขทะเบียน" : ""}
                InputLabelProps={{
                  shrink: getValues("plateNumber") ? true : undefined
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Controller
                name="province"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    value={field?.value as IProvince || ""}
                    options={props.provinceData || []}
                    getOptionLabel={(option: any) => {
                      if (typeof option === 'string') {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option.nameTh || "";
                    }}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    onChange={(event, newValue) => {
                      field.onChange(newValue);
                    }}
                    renderInput={(params) => <TextField
                      error={!!errors.province}
                      helperText={errors.province ? "กรุณาเลือกจังหวัด" : ""}
                      {...params} label="จังหวัด" />}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                fullWidth
                label="ยี่ห้อ"
                {...register("brand")}
                error={!!errors.brand}
                helperText={errors.brand ? "กรุณาระบุยี่ห้อ" : ""}
                InputLabelProps={{
                  shrink: getValues("brand") ? true : undefined
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <TextField
                fullWidth
                label="รุ่น"
                {...register("model")}
                error={!!errors.model}
                helperText={errors.model ? "กรุณาระบุรุ่น" : ""}
                InputLabelProps={{
                  shrink: getValues("model") ? true : undefined
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3} lg={3}>
              <TextField
                fullWidth
                label="สี"
                {...register("color")}
                error={!!errors.color}
                helperText={errors.color ? "กรุณาระบุสี" : ""}
                InputLabelProps={{
                  shrink: getValues("color") ? true : undefined
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} mt={2} md={12} lg={12}>
              <Divider />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={onClose}
        >
          ยกเลิก
        </Button>
        {props.permission.create || (props.permission.edit && props.data?.id) ?
          <Button
            variant="contained"
            onClick={() => {
              onSubmit();
            }}
          >
            บันทึก
          </Button>
          : <></>}
        <DialogConfirm
          open={openConfirm.open}
          type={openConfirm.type === "delete" ? "error" : "success"}
          title={openConfirm.title}
          message={openConfirm.message}
          onClose={() => setOpenConfirm({ ...openConfirm, open: false })}
          onSubmit={() => {
            setOpenConfirm({ ...openConfirm, open: false });
            if (openConfirm.type === "delete") {
              onDelete();
            } else {
              onConfirm();
            }
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

export default CarCreateView;