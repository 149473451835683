import { FC, useState, useEffect } from "react";
import { Box, Button, Card, CardContent, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import SearchIcon from "@mui/icons-material/Search";
import TableDataList from "./TableDataList";
import { IReportCriteria } from "../features/Report/Services/ReportService";
import SimpleBackdrop from "./SimpleBackdrop";
import TotalValuesCard from "./TotalValuesCard";
import { getDateRangeFromStorage, setDateRangeToStorage } from "../utils/dateRangeLocalStorage";
import { STORAGE_KEYS } from "../constants/dateRangeStorageKey";


interface ReportListViewProps<T> {
  isLoading: boolean;
  reportData: T;
  getReportData: (criteria: IReportCriteria) => void;
  reportTitle: string;
  totalValues: { label: string, value: number, percentage?: number; }[];
  reportColumns: { label: string, render: (row: any) => JSX.Element | string; }[];
  rowActions?: (row: T) => JSX.Element;
}

const ReportListView: FC<ReportListViewProps<any>> = (props) => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);

  const initialDateRange = getDateRangeFromStorage(STORAGE_KEYS.REPORT);

  const [dateFrom, setDateFrom] = useState<dayjs.Dayjs>(
    initialDateRange.dateFrom ? dayjs(initialDateRange.dateFrom) : dayjs().startOf("month")
  );
  const [dateTo, setDateTo] = useState<dayjs.Dayjs>(
    initialDateRange.dateTo ? dayjs(initialDateRange.dateTo) : dayjs()
  );

  const [searchDateType, setSearchDateType] = useState("date");

  const searchByCriteria = (pageNumber: number = 0, rowLimit?: number) => {
    setPage(pageNumber);
    setLimit(rowLimit ? rowLimit : 10);
    const criteria: IReportCriteria = {
      dateFrom: searchDateType === "all" ? undefined : dateFrom.format("YYYY-MM-DD"),
      dateTo: searchDateType === "all" ? undefined : dateTo.format("YYYY-MM-DD"),
      page: pageNumber,
      limit: rowLimit ? rowLimit : 10,
    };
    props.getReportData(criteria);
  };

  const handleDateFromChange = (date: dayjs.Dayjs | null) => {
    if (date) {
      setDateFrom(date);
    }
  };

  const handleDateToChange = (date: dayjs.Dayjs | null) => {
    if (date) {
      setDateTo(date);
    }
  };

  // UseEffect to store date range changes in localStorage after dateFrom and dateTo have been updated
  useEffect(() => {
    if (dateFrom && dateTo) {
      setDateRangeToStorage(STORAGE_KEYS.REPORT, dateFrom, dateTo);
    }
  }, [dateFrom, dateTo]);

  return (
    <Box component={"div"}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">{props.reportTitle}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <InputLabel id="search-select-label">เลือกช่วงเวลา</InputLabel>
                    <Select
                      labelId="search-select-label"
                      id="search-select"
                      label="เลือกช่วงเวลา"
                      value={searchDateType}
                      onChange={(e) => {
                        setSearchDateType(e.target.value);
                        if (e.target.value === "date") {
                          setDateFrom(dayjs().startOf("month"));
                          setDateTo(dayjs());
                        }
                      }}
                    >
                      <MenuItem value="all">ทั้งหมด</MenuItem>
                      <MenuItem value="date">วันที่</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {searchDateType === "date" ? (
                  <>
                    <Grid item xs={12} sm={3}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="ตั้งแต่วันที่"
                          sx={{ width: "100%" }}
                          format="DD/MM/YYYY"
                          value={dateFrom}
                          onChange={handleDateFromChange}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="ถึงวันที่"
                          sx={{ width: "100%" }}
                          format="DD/MM/YYYY"
                          value={dateTo}
                          onChange={handleDateToChange}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </>
                ) : <Grid item xs={12} sm={6} />}
                <Grid item xs={12} sm={2} textAlign="end" alignSelf="center">
                  <Button
                    variant="contained"
                    startIcon={<SearchIcon />}
                    onClick={() => searchByCriteria(0)}
                  >
                    ค้นหา
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {props.totalValues.map((total, index) => {
          return (
            <Grid item xs={12} sm={6} md={6} lg={3} key={index}>
              <TotalValuesCard total={total} index={index} />
            </Grid>
          );
        })}

      </Grid>

      <Grid container spacing={2} mt={1}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6">รายละเอียด</Typography>
                </Grid>

                <Grid item xs={12}>
                  <TableDataList
                    data={props.reportData?.datas || []}
                    total={props.reportData?.total || 0}
                    columns={props.reportColumns}
                    rowActions={props.rowActions || undefined}
                    page={page}
                    limit={limit}
                    onPageChange={(newPage) => {
                      setPage(newPage);
                      searchByCriteria(newPage, limit);
                    }}
                    onRowsPerPageChange={(newLimit) => {
                      setLimit(newLimit);
                      setPage(0);
                      searchByCriteria(0, newLimit);
                    }}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <SimpleBackdrop open={props.isLoading ?? false} />
    </Box>
  );
};

export default ReportListView;
