import { observer } from "mobx-react-lite";
import ExpenseTypesListView from "../Views/ExpenseTypesListView";
import expenseTypeService, { IExpenseType, IExpenseTypeCriteria, IExpenseTypeListView } from "../Services/ExpenseTypeService";
import { enqueueSnackbar } from "notistack";
import { useState, useEffect } from "react";
import getPermissionBySubMenu from "../../../utils/getPermissionBySubMenu";
import { IUserDetail } from "../../Login/Services/LoginService";

const ExpenseTypesListController = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [permission, setPermission] = useState<IUserDetail>();
  const defaultCriteria: IExpenseTypeCriteria = {
    name: '',
    page: 0,
    limit: 10,
  };

  useEffect(() => {
    fetchExpenseTypesByCriteria(defaultCriteria);
    checkPermissionActionButton();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchExpenseTypesByCriteria = async (criteria: IExpenseTypeCriteria) => {
    try {
      setIsLoading(true);
      await expenseTypeService.getByCriteria(criteria);
    } catch (error) {
      console.error("An error occurred while getting the expense types:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const createExpenseType = async (expenseType: IExpenseType) => {
    try {
      setIsLoading(true);
      await expenseTypeService.create(expenseType);
      enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      fetchExpenseTypesByCriteria(defaultCriteria);
    } catch (error) {
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const updateExpenseType = async (expenseType: IExpenseType) => {
    try {
      setIsLoading(true);
      await expenseTypeService.update(expenseType);
      enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      fetchExpenseTypesByCriteria(defaultCriteria);
    } catch (error) {
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const checkPermissionActionButton = () => {
    const result = getPermissionBySubMenu("expense-type");
    if (result) {
      setPermission(result);
    }
  };
  return <ExpenseTypesListView
    onCreate={createExpenseType}
    expenseTypes={expenseTypeService.expenseTypes ?? {} as IExpenseTypeListView}
    onSearchByCriteria={fetchExpenseTypesByCriteria}
    onUpdate={(data: IExpenseType) => {
      updateExpenseType(data);
    }}
    permission={permission ?? {} as IUserDetail}
    isLoading={isLoading}
  />;
});

export default ExpenseTypesListController;
