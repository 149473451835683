import { observer } from "mobx-react-lite";
import UsersListView from "../Views/UsersListView";
import userService, { IUserCriteria, IUserListView } from "../Services/UserService";
import { useState, useEffect } from "react";

const UsersListController = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState<IUserListView>();
  const defaultCriteria: IUserCriteria = {
    name: '',
    status: undefined,
    company: '',
    userGroup: '',
    isDelete: undefined,
    page: 0,
    limit: 10,
  };

  useEffect(() => {
    getByCriteria(defaultCriteria);
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , []);
  const getByCriteria = async (criteria: IUserCriteria) => {
    try {
      setIsLoading(true);
      await userService.getByCriteria(criteria).then((data) => {
        setUserData(data);
      }
      );
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const create = async (data: any) => {
    try {
      setIsLoading(true);
      await userService.create(data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const update = async (data: any) => {
    try {
      setIsLoading(true);
      await userService.update(data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const remove = async (id: string) => {
    try {
      setIsLoading(true);
      await userService.delete(id);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  return <UsersListView onCreate={create} onUpdate={update} onRemove={remove} isLoading={isLoading} onSearchByCriteria={getByCriteria} data={userData ?? {} as IUserListView} />;
});

export default UsersListController;
