import { FC, useEffect, useState } from "react";
import { IOrderEstimatePrice, IOrderWithoutRandom } from "../Services/OrderService";
import { Controller, useForm } from "react-hook-form";
import { Box, Button, ButtonProps, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, TextField, Typography } from "@mui/material";
import DialogConfirm from "../../../components/DialogConfirm";
import thaiFormatNumber from "../../../utils/thaiFormatNumber";

interface IOrderEstimatePriceCreateViewProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: IOrderEstimatePrice) => void;
  isLoading: boolean;
  order: IOrderWithoutRandom;
}

const OrderEstimatePriceCreateView: FC<IOrderEstimatePriceCreateViewProps> = (props) => {
  const { register, handleSubmit, reset, getValues, watch, setValue, control, formState: { errors } } = useForm<IOrderEstimatePrice>();

  const [openConfirm, setOpenConfirm] = useState({
    open: false,
    title: "",
    message: "",
    color: "primary" as ButtonProps["color"],
    type: "create" as "create" | "edit" | "delete",
  });

  const totalWeight = Number(watch("totalWeight")) || 0;
  const pricePerKg = Number(watch("pricePerKg")) || 0;
  const transportCost = Number(watch("transportCost")) || 0;
  const wage = Number(watch("wage")) || 0;
  const otherExpenses = Number(watch("otherExpenses")) || 0;
  const vht = Number(watch("vht")) || 0;
  const totalPackageCost = Number(watch("totalPackageCost")) || 0;
  const shippingCost = Number(watch("shippingCost")) || 0;
  const totalBox = Number(watch("totalBox")) || 0;

  const totalPrice = totalWeight * pricePerKg;
  const totalCost = totalPrice + transportCost + wage + otherExpenses + vht + totalPackageCost + shippingCost;

  const totalCostPerBox = totalBox > 0 ? totalCost / totalBox : 0;
  const shippingCostPerBox = totalBox > 0 ? shippingCost / totalBox : 0;

  useEffect(() => {
    reset(props.order.orderEstimatePrice || {});
  }, [props.order, reset]);

  // Update the form values 
  useEffect(() => {
    setValue("totalCost", totalCost);
    setValue("totalCostPerBox", totalCostPerBox);
    setValue("shippingCostPerBox", shippingCostPerBox);
  }, [totalPrice, transportCost, wage, otherExpenses, vht, totalPackageCost, shippingCost, totalBox, setValue, totalCost, totalCostPerBox, shippingCostPerBox]);

  const onSubmit = handleSubmit(() => {
    if (props.order.orderEstimatePrice?.id) {
      setOpenConfirm({
        open: true,
        title: "แก้ไขข้อมูล",
        message: "คุณต้องการแก้ไขข้อมูลนี้ใช่หรือไม่",
        color: "primary",
        type: "edit",
      });
    } else {
      setOpenConfirm({
        open: true,
        title: "บันทึกข้อมูล",
        message: "คุณต้องการบันทึกข้อมูลนี้ใช่หรือไม่",
        color: "primary",
        type: "create",
      });
    }
  });

  const onConfirm = () => {
    const value = getValues();
    const formatValue = {
      ...value,
      order: props.order.id,
      totalPrice,
      transportCost,
      wage,
      otherExpenses,
      vht,
      totalPackageCost,
      shippingCost,
      shippingCostPerBox,
      totalBox,
    };
    props.onSubmit(formatValue);
    reset();
    onClose();
  };


  const onClose = () => {
    props.onClose();
    setOpenConfirm({ ...openConfirm, open: false });
    reset();
  };

  return (
    <Dialog open={props.open} maxWidth="lg" fullWidth>
      <DialogTitle>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            ประเมินราคาทุน
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Box component={'form'} onSubmit={onSubmit}>
          <Grid container spacing={2} mt={1}>
            <Grid item xs={12} sm={12} md={4}>
              <TextField
                fullWidth
                label="จำนวน(kg)"
                type="number"
                {...register("totalWeight", { required: true })}
                error={!!errors.totalWeight}
                helperText={errors.totalWeight ? "กรุณากรอกจำนวน(kg)" : ""}
                InputLabelProps={{
                  shrink: getValues("totalWeight") ? true : undefined
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <TextField
                fullWidth
                label="ราคาต่อกิโล"
                type="number"
                {...register("pricePerKg", { required: true })}
                error={!!errors.pricePerKg}
                helperText={errors.pricePerKg ? "กรุณากรอกราคาต่อกิโล" : ""}
                InputLabelProps={{
                  shrink: getValues("pricePerKg") ? true : undefined
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} display={"flex"} alignItems={"center"}>
              <Typography>
                รวมมูลค่า: {totalPrice.toFixed(2)}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <TextField
                fullWidth
                label="ค่าขนส่ง"
                type="number"
                {...register("transportCost")}
                InputLabelProps={{
                  shrink: getValues("transportCost") ? true : undefined
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <TextField
                fullWidth
                label="ค่าแรง"
                type="number"
                {...register("wage")}
                InputLabelProps={{
                  shrink: getValues("wage") ? true : undefined
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <TextField
                fullWidth
                label="ค่าใช้จ่ายอื่นๆ"
                type="number"
                {...register("otherExpenses")}
                InputLabelProps={{
                  shrink: getValues("otherExpenses") ? true : undefined
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <TextField
                fullWidth
                label="ค่า VHT"
                type="number"
                {...register("vht")}
                InputLabelProps={{
                  shrink: getValues("vht") ? true : undefined
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <TextField
                fullWidth
                label="ค่าแพ็คเกจรวม"
                type="number"
                {...register("totalPackageCost")}
                InputLabelProps={{
                  shrink: getValues("totalPackageCost") ? true : undefined
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <TextField
                fullWidth
                label="ค่า Shipping"
                type="number"
                {...register("shippingCost")}
                InputLabelProps={{
                  shrink: getValues("shippingCost") ? true : undefined
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <TextField
                fullWidth
                label="จำนวนกล่อง"
                type="number"
                {...register("totalBox")}
                InputLabelProps={{
                  shrink: getValues("totalBox") ? true : undefined
                }}
              />
            </Grid>
            <Grid item xs={12} mt={2}>
              <Divider />
            </Grid>
            <Grid item xs={11.5} sm={12} md={12} lg={12} mt={1}>
              <Grid container spacing={2}>
                <Grid item xs={11} sm={11} md={11} lg={11} textAlign={'right'}>
                  <Typography><b>ต้นทุนต่อกล่อง</b></Typography>
                </Grid>
                <Grid item xs={1} sm={1} md={1} lg={1} >
                  <Controller
                    name="totalCostPerBox"
                    control={control}
                    defaultValue={0}
                    render={({ field }) => (
                      <Typography textAlign={'end'}>
                        <b>{thaiFormatNumber(parseFloat(field.value?.toString() || '0').toFixed(2))}</b>
                      </Typography>
                    )}
                  />
                </Grid>

                <Grid item xs={11} sm={11} md={11} lg={11} textAlign={'right'}>
                  <Typography><b>ต้นทุน Shipping ต่อกล่อง</b></Typography>
                </Grid>
                <Grid item xs={1} sm={1} md={1} lg={1} >
                  <Controller
                    name="shippingCostPerBox"
                    control={control}
                    defaultValue={0}
                    render={({ field }) => (
                      <Typography textAlign={'end'}>
                        <b>{thaiFormatNumber(parseFloat(field.value?.toString() || '0').toFixed(2))}</b>
                      </Typography>
                    )}
                  />
                </Grid>

                <Grid item xs={11} sm={11} md={11} lg={11} textAlign={'right'}>
                  <Typography><b>ต้นทุนทั้งหมด</b></Typography>
                </Grid>
                <Grid item xs={1} sm={1} md={1} lg={1} >
                  <Controller
                    name="totalCost"
                    control={control}
                    defaultValue={0}
                    render={({ field }) => (
                      <Typography textAlign={'end'}>
                        <b>{thaiFormatNumber(parseFloat(field.value?.toString() || '0').toFixed(2))}</b>
                      </Typography>
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>

          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          ยกเลิก
        </Button>
        <Button
          id="submit"
          variant="contained"
          onClick={onSubmit}
        >
          บันทึก
        </Button>
      </DialogActions>
      <DialogConfirm
        open={openConfirm.open}
        type={openConfirm.type === "delete" ? "error" : "success"}
        title={openConfirm.title}
        message={openConfirm.message}
        onClose={() => setOpenConfirm({ ...openConfirm, open: false })}
        onSubmit={() => {
          setOpenConfirm({ ...openConfirm, open: false });
          onConfirm();
        }}
      />
    </Dialog>
  );
};

export default OrderEstimatePriceCreateView;
