import { configure, makeAutoObservable } from "mobx";
import apiService from "../../../services/ApiService";
import { IMenu } from "../../../constants/menus";
import { ICompany } from "../../Company/Services/CompanyService";
import { IUser } from "../../UserManagement/Services/RoleService";

export interface IUserSuperAdmin {
  id: string;
  firstName?: string;
  lastName?: string;
  tel?: string;
  email?: string;
  address?: string;
  userGroup?: IUserGroup | undefined;
  image?: string;
  isActive?: boolean;
  isDelete: boolean;
  role?: string;
  isSuperAdmin?: boolean;
  access_token?: string;
  menus?: IMenu[] | undefined;
  company?: ICompany | undefined;
  createAt?: string;
  updateAt?: string;
  createBy?: IUser | undefined;
  updateBy?: IUser | undefined;
  details?: IUserDetail[] | undefined;
  password?: string;
  confirmPassword?: string;
}

export interface IUserListView {
  data?: IUserSuperAdmin[] | undefined;
  all: number;
  limit: number;
  page: number;
  total: number;
}

export interface IUserCriteria {
  name: string;
  company: string;
  userGroup: string;
  status?: boolean;
  isDelete?: boolean;
  page: number;
  limit: number;
}

export interface IUserGroup {
  id?: string;
  name?: string;
  canApprove?: boolean;
  canCost?: boolean;
  canFinance?: boolean;
  createAt?: Date;
  updateAt?: Date;
  createBy?: IUser | undefined;
  updateBy?: IUser | undefined;
}

export interface IUserDetail {
  id?: string;
  user?: IUser | undefined;
  menu?: string;
  read?: boolean;
  edit?: boolean;
  create?: boolean;
  delete?: boolean;
  isSelected?: boolean;
  createAt?: Date;
  createBy?: IUser | undefined;
  updateBy?: IUser | undefined;
  updateAt?: Date;
  children?: Omit<IUserDetail, "children">[];
  value?: string;
  name?: string | null;
}

class UserService {
  users: IUserListView | undefined;
  userList: IUserSuperAdmin[] | undefined;
  userData: IUserSuperAdmin | undefined;

  constructor() {
    configure({
      enforceActions: "never",
    });
    makeAutoObservable(this);
  }

  async getAll() {
    try {
      const roles = await apiService.get("users");
      this.userList = roles;
      return this.userList;
    } catch (error) {
      console.error(error);
    }
  }

  async getOne(id: string) {
    try {
      const role = await apiService.get(`users/${id}`);
      this.userData = role;
      return this.userData;
    } catch (error) {
      console.error(error);
    }
  }

  async create(user: IUser | IUserSuperAdmin) {
    try {
      const response = await apiService.post("users", user);
      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async getByCriteria(criteria: IUserCriteria) {
    try {
      this.users = await apiService.post("users/criteria", criteria);
      return this.users;
    } catch (error) {
      console.error(error);
    }
  }

  async update(user: IUser | IUserSuperAdmin) {
    try {
      const response = await apiService.put(`users/${user.id}`, user);
      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async delete(id: string) {
    try {
      const response = await apiService.put(`users/${id}`, { isDelete: true });
      return response;
    } catch (error) {
      console.error(error);
    }
  }

  get usersData() {
    return this.users;
  }

  get usersView() {
    return this.users;
  }

  get userListData() {
    return this.userList;
  }
}
const userService = new UserService();
export default userService;
