import { menus, IMenu } from "../constants/menus";
import { IRole } from "../features/UserManagement/Services/RoleService";

const getRoleMenu = (details: IRole[], isSuperAdmin?: boolean) => {
  const allMenu = menus.map((menu: IMenu) => {
    const found = isSuperAdmin ? details?.find((data: IRole) => data.menu === menu.value) : details?.find((data: IRole) => data.menu === menu.value && menu.isAdminMenu === false);
    if (found) {
      menu.id = found.id;
      menu.isSelected = found.read;
      menu.read = found.read;
      menu.create = found.create;
      menu.edit = found.edit;
      menu.delete = found.delete;
      menu.value = found.menu;
      if (found.children) {
        const _children = menu?.children?.map((child: IMenu) => {
          const foundChild = found.children?.find((dataChild: IRole) => dataChild.menu === child.value);
          if (foundChild) {
            return {
              ...child,
              id: foundChild.id,
              isSelected: foundChild.read,
              read: foundChild.read,
              create: foundChild.create,
              edit: foundChild.edit,
              delete: foundChild.delete,
              value: foundChild.menu,
            };
          }
          return child;
        });

        menu.children = _children || [];

      }
    }
    return menu;
  });
  return allMenu;
}

export default getRoleMenu;