/**
 * @param {Object} service - The service object to be cleared.
 * @param {Array} fields - The fields to be cleared.
 * @returns {Object} - The service object with the fields cleared.
 * @description - This function clears the fields of a service object.
 * @example
 * clearState(companyService, ["company"]);
 */

type Service = Record<string, any>;
const clearState = (service: Service, fields: string[]) => {
  fields.forEach(field => {
    if (service[field] !== undefined) {
      if (Array.isArray(service[field])) {
        service[field] = [];
      } else {
        service[field] = undefined;
      }
    }
  });
};
export default clearState;