import { FC, useEffect, useState } from "react";
import { IUserDetail } from "../../SuperAdminManagement/Services/UserService";
import { IGrade } from "../Services/GradeService";
import { Controller, useForm } from "react-hook-form";
import { Box, Button, ButtonProps, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, TextField } from "@mui/material";
import DialogConfirm from "../../../components/DialogConfirm";
import DeleteIcon from "@mui/icons-material/Delete";

interface GradeCreateViewProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: IGrade) => void;
  data?: IGrade;
  permission?: IUserDetail;
  name?: string;
}

const GradeCreateView: FC<GradeCreateViewProps> = (props) => {
  const { register, handleSubmit, reset, getValues, formState: { errors }, control } = useForm<IGrade>();

  const [openConfirm, setOpenConfirm] = useState({
    open: false,
    title: "",
    message: "",
    color: "primary" as ButtonProps["color"],
    type: "create" as "create" | "edit" | "delete",
  });

  const onSubmit = handleSubmit(() => {
    if (props.data?.id) {
      setOpenConfirm({
        open: true,
        title: "แก้ไขข้อมูล",
        message: "คุณต้องการแก้ไขข้อมูลนี้ใช่หรือไม่",
        color: "primary",
        type: "edit",
      });
    } else {
      setOpenConfirm({
        open: true,
        title: "บันทึกข้อมูล",
        message: "คุณต้องการบันทึกข้อมูลนี้ใช่หรือไม่",
        color: "primary",
        type: "create",
      });
    }
  });

  const onConfirm = () => {
    props.onSubmit(getValues());
    reset();
    onClose();
  };

  const onClose = () => {
    props.onClose();
    setOpenConfirm({ ...openConfirm, open: false });
  };

  const onDelete = () => {
    props.onSubmit({ ...props.data, isDelete: true });
    reset();
    onClose();
  };

  useEffect(() => {
    reset({ ...props.data, name: props.data ? props.data?.name : props.name });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open, props.data]);

  return (
    <Dialog open={props.open} maxWidth="lg" fullWidth>
      <DialogTitle>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6} md={6} lg={6}>
            {props.data?.id ? `แก้ไข` : `สร้าง`}เกรด
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} textAlign="right">
            {(props.data?.id && props?.permission?.delete) && (
              <Button
                variant="contained"
                color="error"
                startIcon={<DeleteIcon />}
                onClick={() => {
                  setOpenConfirm({
                    open: true,
                    title: "ลบข้อมูล",
                    message: "คุณต้องการลบข้อมูลนี้ใช่หรือไม่",
                    color: "error",
                    type: "delete",
                  });
                }}
              >
                ลบข้อมูล
              </Button>
            )}
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Box component={'form'} onSubmit={onSubmit}>
          <Grid container spacing={2} mt={1}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                fullWidth
                label="เกรด"
                {...register("name", { required: true })}
                error={!!errors.name}
                helperText={errors.name ? "กรุณาระบุเกรด" : ""}
                InputLabelProps={
                  {
                    shrink: getValues("name") ? true : undefined
                  }
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <Controller
                name="capacityFrom"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    type="number"
                    {...field}
                    value={field.value ?? ''}
                    onChange={(e) => field.onChange(e.target.value === '' ? null : parseFloat(e.target.value))}
                    label="ความจุต่ำสุด"
                    InputLabelProps={{
                      shrink: getValues("capacityFrom") ? true : undefined
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <Controller
                name="capacityTo"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    type="number"
                    {...field}
                    value={field.value ?? ''}
                    onChange={(e) => field.onChange(e.target.value === '' ? null : parseFloat(e.target.value))}
                    label="ความจุสูงสุด"
                    InputLabelProps={{
                      shrink: getValues("capacityTo") ? true : undefined
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <TextField
                fullWidth
                type="number"
                {...register("weightFrom", { required: true })}
                error={!!errors.weightFrom}
                helperText={errors.weightFrom ? "กรุณาระบุน้ำหนักเริ่มต้น" : ""}
                label="น้ำหนักเริ่มต้น"
                InputLabelProps={
                  {
                    shrink: getValues("weightFrom") ? true : undefined
                  }
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <TextField
                fullWidth
                type="number"
                {...register("weightTo", { required: true })}
                error={!!errors.weightTo}
                helperText={errors.weightTo ? "กรุณาระบุน้ำหนักสิ้นสุด" : ""}
                label="น้ำหนักสิ้นสุด"
                InputLabelProps={
                  {
                    shrink: getValues("weightTo") ? true : undefined
                  }
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                fullWidth
                label="หมายเหตุ"
                {...register("remark")}
                error={!!errors.remark}
                InputLabelProps={
                  {
                    shrink: getValues("remark") ? true : undefined
                  }
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} mt={2} md={12} lg={12}>
              <Divider />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
        >
          ยกเลิก
        </Button>
        {props?.permission?.create || (props?.permission?.edit && props?.data?.id) ? (
          <Button
            id="submit"
            variant="contained"
            onClick={onSubmit}
          >
            บันทึก
          </Button>
        ) : null}

      </DialogActions>
      <DialogConfirm
        open={openConfirm.open}
        type={openConfirm.type === "delete" ? "error" : "success"}
        title={openConfirm.title}
        message={openConfirm.message}
        onClose={() => setOpenConfirm({ ...openConfirm, open: false })}
        onSubmit={() => {
          setOpenConfirm({ ...openConfirm, open: false });
          if (openConfirm.type === "delete") {
            onDelete();
          } else {
            onConfirm();
          }
        }}
      />
    </Dialog>
  );

};

export default GradeCreateView;