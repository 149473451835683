import { configure, makeAutoObservable } from "mobx";
import apiService from "../../../services/ApiService";
import { IUser } from "../../UserManagement/Services/RoleService";


export interface ICompany {
  id?: string;
  name?: string;
  branch?: string;
  tax?: string;
  address?: string;
  subDistrict?: ISubDistrict | null;
  district?: IDistrict | null;
  province?: IProvince | null;
  zipCode?: string;
  tel?: string;
  createBy?: IUser | undefined;
  updateBy?: IUser | undefined;
  createAt?: string;
  updateAt?: string;
  isDelete?: boolean;
  isActive?: boolean;
  image?: IImage | undefined;
  isAdmin?: boolean;
}

export interface ICompanyCriteria {
  name: string;
  status?: boolean;
  isDelete?: boolean;
  page: number;
  limit: number;
}

export interface ICompanyListView {
  data?: ICompany[] | undefined;
  all: number;
  limit: number;
  page: number;
  total: number;
}

export interface IUploadFile {
  file: any;
}

export interface IProvince {
  id?: number;
  nameTh?: string;
}

export interface IDistrict {
  id?: number;
  nameTh?: string;
  province?: IProvince | undefined;
}

export interface ISubDistrict {
  id?: number;
  nameTh?: string;
  district?: IDistrict | undefined;
  zipCode?: string;
}

export interface IImage {
  name?: string;
}

class CompanyService {
  company: ICompany | undefined;
  companyList: ICompany[] | undefined;
  companies: ICompanyListView | undefined;
  province: IProvince[] = [];
  district: IDistrict[] = [];
  subDistrict: ISubDistrict[] = [];

  constructor() {
    configure({
      enforceActions: "never",
    });
    makeAutoObservable(this);
  }

  async create(data: any, uploadFile?: IUploadFile) {
    try {
      const company: ICompany = await apiService.post(`/companies`, data);
      if (uploadFile?.file) {
        const formData = new FormData();
        formData.append("file", uploadFile.file);
        formData.append("refId", company?.id || "");
        formData.append("refType", "company");
        await apiService.postFormData("/images/upload", formData);
      }
      return company;
    } catch (error) {
      throw error;
    }
  }

  async getOne(id: string) {
    try {
      this.company = await apiService.get(`/companies/${id}`);
      return this.company;
    } catch (error) {
      throw error;
    }
  }

  async getAll() {
    try {
      this.companyList = await apiService.get("/companies");
      return this.companyList;
    } catch (error) {
      throw error;
    }
  }

  async getByCriteria(criteria: ICompanyCriteria) {
    try {
      this.companies = await apiService.post("/companies/criteria", criteria);
      return this.companies;
    } catch (error) {
      throw error;
    }
  }


  async update(id: string, data: any, uploadFile?: IUploadFile) {
    try {
      const _data = { ...data };
      delete _data.image;
      const company = await apiService.put(`/companies/${id}`, _data);
      if (uploadFile?.file) {
        const formData = new FormData();
        formData.append("file", uploadFile.file);
        formData.append("refId", id);
        formData.append("refType", "company");
        await apiService.postFormData("/images/upload", formData);
        if (data.image) {
          await apiService.delete(`/images/${data.image.id}`);
        }
      }
      return company;
    } catch (error) {
      throw error;
    }
  }

  async getProvince() {
    try {
      this.province = await apiService.get(`/provinces`);
      return this.province;
    } catch (error) {
      throw error;
    }
  }

  async getDistrict(id: number) {
    try {
      this.district = await apiService.get(`/districts?provinceId=${id}`);
      return this.district;
    } catch (error) {
      throw error;
    }
  }


  async getSubDistrict(id: number) {
    try {
      this.subDistrict = await apiService.get(
        `/sub-districts?districtId=${id}`
      );
      return this.subDistrict;
    } catch (error) {
      throw error;
    }
  }
  get companyData() {
    return this.company;
  }

  get subDistrictData() {
    return this.subDistrict;
  }
  get districtData() {
    return this.district;
  }

  get provinceData() {
    return this.province;
  }

  get companiesList() {
    return this.companyList;
  }

  get companiesView() {
    return this.companies;
  }
}

const companyService = new CompanyService();
export default companyService;
