import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { IUserDetail } from "../../SuperAdminManagement/Services/UserService";
import dayjs from "dayjs";
import bakeService, { IBakeCriteria, IBakeListView } from "../Services/BakeService";
import getPermissionBySubMenu from "../../../utils/getPermissionBySubMenu";
import BakeListView from "../Views/BakeListView";
import { clearLocalStorageKeyOnUnload, getDateRangeFromStorage } from "../../../utils/dateRangeLocalStorage";
import { STORAGE_KEYS } from "../../../constants/dateRangeStorageKey";

const BakeListController = observer(() => {
  const initialDateRange = getDateRangeFromStorage(STORAGE_KEYS.PROCESS);
  const [isLoading, setIsLoading] = useState(false);
  const [permission, setPermission] = useState<IUserDetail>();
  const [bakeData, setBakeData] = useState<IBakeListView>();

  const defaultCriteria: IBakeCriteria = {
    dateFrom: initialDateRange.dateFrom
      ? dayjs(initialDateRange.dateFrom).format('YYYY-MM-DD')
      : dayjs().startOf('month').format('YYYY-MM-DD'),
    dateTo: initialDateRange.dateTo
      ? dayjs(initialDateRange.dateTo).format('YYYY-MM-DD')
      : dayjs().startOf('day').format('YYYY-MM-DD'),
    status: '',
    order: '',
    page: 0,
    limit: 10,
  };

  useEffect(() => {
    fetchByCriteria(defaultCriteria);
    checkPermissionActionButton();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  clearLocalStorageKeyOnUnload(STORAGE_KEYS.PROCESS);



  const fetchByCriteria = async (criteria: IBakeCriteria) => {
    try {
      setIsLoading(true);
      await bakeService.getByCriteria(criteria).then((data) => {
        setBakeData(data);
      }
      );
    } catch (error) {
      console.error("An error occurred while getting the bake:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const checkPermissionActionButton = () => {
    const result = getPermissionBySubMenu("process-bake");
    if (result) {
      setPermission(result);
    }
  };

  return (
    <BakeListView
      isLoading={isLoading}
      bakeList={bakeData ?? {} as IBakeListView}
      onSearchByCriteria={fetchByCriteria}
      permission={permission ?? {} as IUserDetail}
    />
  );
});

export default BakeListController;