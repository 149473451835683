import { configure, makeAutoObservable } from "mobx";
import { IGrouping } from "../../Inventory/Services/GroupingService";
import { IMaterial } from "../../Inventory/Services/MaterialsService";
import { IRefOrder, } from "./OrderService";
import apiService from "../../../services/ApiService";
import { IWarehouse } from "../../Inventory/Services/WareshousesService";
import { IShowImages } from "../../Inventory/Services/ReceiveService";
import { IUser } from "../../UserManagement/Services/RoleService";
import { ICapacity } from "./CapacityService";
import { IShippingDetail } from "./ShippingService";
import { IBakeRef } from "./BakeService";
import { IVapourHeatTreatment } from "./VapourHeatTreatmentService";
import { Dayjs } from "dayjs";

export interface IPackaging {
  order?: IRefOrder | null;
  warehouse?: IWarehouse | null;
  details?: IPackagingDetail[] | undefined;
  showTooltip?: boolean;
  refDoc?: string;
  oldQty?: number;
  oldCapacity?: number;
  weightBake?: number;
  weightPerBox?: number;
  totalBox?: number;
  totalExport?: number;
  totalLoss?: number;
  mainMaterial?: IMaterial | null;
  remark?: string;
  sutiTotalBox?: number;
  docNo?: string | undefined;
  docDate?: Dayjs;
  status?: string;
  isDelete?: boolean | undefined;
  createAt?: Date;
  updateAt?: Date;
  createBy?: IUser | undefined;
  updateBy?: IUser | undefined;
  id?: string;
  bakeOptions?: IGrouping[] | undefined;
  randomOptions?: IGrouping[] | undefined;
  groupOptions?: { [key: number]: IGrouping[]; };
  image?: IShowImages[] | undefined;
  file?: IShowImages[] | undefined;
  shippings?: IShippingDetail[] | undefined;
  totalWeight?: number;
  totalWeightExport?: number;
  grades?: IGrade[] | undefined;
  totalBoxExport?: number;
  totalActualWeight?: number;
  totalActualBox?: number;
  packSummaryCapacity?: string;
  packWeight?: number;
  vhtCost?: number;
  totalVht?: number;
  vat3?: number;
  vat7?: number;
  bakePerBox?: number;
}

export interface IPackagingDetail {
  id?: string;
  group?: IGrouping | null;
  quantity?: number;
  package?: string | null;
  capacityValue?: number;
  capacity?: ICapacity | null;
  order?: IRefOrder | null;
  warehouse?: IWarehouse | null;
  showTooltip?: boolean;
  mainMaterial?: IMaterial | null;
  refDoc?: string;
  oldQty?: number;
  oldCapacity?: number;
  weightBake?: number;
  weightPerBox?: number;
  totalBox?: number;
  totalExport?: number;
  grade?: IGrade | null;
  remark?: string;
  sutiTotalBox?: number;
  isBake?: boolean;
  bake?: IBakeRef | null;
  random?: IRandomInspection | null;
  status?: string;
  netWeightFrom?: number | undefined;
  netWeightTo?: number;
  grossWeightFrom?: number;
  grossWeightTo?: number;
  packaging?: IPackagingForDetail;
  materialCost?: number;
}

export interface IPackagingForDetail {
  showTooltip?: boolean;
  refDoc?: string;
  oldQty?: number;
  oldCapacity?: number;
  weightBake?: number;
  weightPerBox?: number;
  totalBox?: number;
  totalExport?: number;
  totalLoss?: number;
  mainMaterial?: IMaterial | null;
  remark?: string;
  sutiTotalBox?: number;
  docNo?: string | undefined;
  status?: string;
  isDelete?: boolean | undefined;
  createAt?: Date;
  updateAt?: Date;
  createBy?: IUser | undefined;
  updateBy?: IUser | undefined;
  id?: string;
  shipping?: IShippingDetail[] | undefined;
  totalWeight?: number;
  totalWeightExport?: number;
}

export interface IPackagingListView {
  datas?: IPackaging[] | undefined;
  all: number;
  limit: number;
  page: number;
  total: number;
}

export interface IBake {
  id?: string;
  grade?: string;
  totalWeight?: number;
  totalAfterBakeWeight?: number;
  totalRemainingWeight?: number;
  isDelete?: boolean;
  order: IRefOrder;
  docNo?: string;
  warehouse?: IWarehouse | undefined;
  remain?: number;
}

export interface IRandomInspection {
  id?: string;
  grade?: string;
  totalWeight?: number;
  totalInspectionWeight?: number;
  totalRemainingWeight?: number;
  order: IRefOrder;
  docNo?: string;
  warehouse?: IWarehouse | undefined;
  remain?: number;
}

export interface IPackagingCriteria {
  dateFrom?: string;
  dateTo?: string;
  docNo?: string;
  status?: string;
  order?: string;
  page?: number;
  limit?: number;
}

export interface IPrintPackingList {
  totalProductRecive?: number;
  totalWeightFromFarm?: number;
  totalWeightFromVHT?: number;
  totalWeightInspection?: number;
  vhts: [{ id: string; totalWeight: number; }];
  censorChecks: [{ totalWeight: number; }];
  totalWeightEliminateBeforeBake?: number;
  totalWeightCut?: number;
  totalWeightEliminateAfterBake?: number;
  totalWeightReturnToFarm?: number;
  totalBasketReturnToFarm?: number;
  totalPack?: number;
  capacitys: [{ name: string; qty: number; }];

  totalWeightRecive?: number;
  totalWeightEliminate?: number;
  totalWeightCheck?: number;
  totalWeightPacking?: number;
  totalWeightDrift?: number;
  vapourHeatTreatment: IVapourHeatTreatment;
}

export interface IGrade {
  id: string;
  name: string;
}

class PackagingService {
  grouping: IGrouping[] | undefined;
  orders: IRefOrder[] | undefined;
  printPackingList: IPrintPackingList | undefined;
  packagings: IPackaging[] = [];
  packaging: IPackaging = {} as IPackaging;
  bakes: IBake[] = [];
  randoms: IRandomInspection[] = [];
  mainMaterials: IMaterial[] = [];
  grades: IGrade[] = [];
  warehouse: IWarehouse[] = [];
  capacities: ICapacity[] | undefined;
  constructor() {
    configure({
      enforceActions: "never",
    });
    makeAutoObservable(this);
  }

  async getOne(id: string): Promise<IPackaging> {
    try {
      const data = await apiService.get(`packagings/${id}`);
      const reportData = data.reportData;
      this.printPackingList = reportData;
      delete data.reportData;
      this.packaging = data;
      return this.packaging;
    } catch (error) {
      throw error;
    }
  }

  async getByCriteria(criteria: IPackagingCriteria) {
    try {
      const Packagings = await apiService.post("packagings/criteria", criteria);
      this.packagings = Packagings;
      return Packagings;
    } catch (error) {
      throw error;
    }
  }

  async create(data: IPackaging, uploadFile: any) {
    try {
      const packaging = await apiService.post("packagings", data);
      if (uploadFile && uploadFile?.length > 0) {
        for (const file of uploadFile) {
          const formData = new FormData();
          formData.append("file", file);
          formData.append("refId", packaging.id || "");
          formData.append("refType", "packaging");
          if (file.type === "application/pdf") {
            formData.append("originalName", file.name);
            await apiService.postFormData("/files/upload", formData);
          } else {
            await apiService.postFormData("/images/upload", formData);
          }
        }
      }
      return packaging;
    } catch (error) {
      throw error;
    }
  }

  async update(id: string, data: IPackaging, uploadFile: any) {
    try {
      const packaging = await apiService.put(`packagings/${id}`, data);
      if (uploadFile && uploadFile?.length > 0) {
        for (const file of uploadFile) {
          const formData = new FormData();
          formData.append("file", file);
          formData.append("refId", id);
          formData.append("refType", "packaging");
          if (file.type === "application/pdf") {
            formData.append("originalName", file.name);
            await apiService.postFormData("/files/upload", formData);
          } else {
            await apiService.postFormData("/images/upload", formData);
          }
        }
      }
      return packaging;
    } catch (error) {
      throw error;
    }
  }

  async delete(id: string) {
    try {
      await apiService.delete(`packagings/${id}`);
    } catch (error) {
      throw error;
    }
  }


  async getAllOrders() {
    try {
      this.orders = await apiService.get("orders");
      return this.orders;
    } catch (error) {
      throw error;
    }
  }

  async getBake(id: string, packagingId?: string | undefined) {
    try {
      this.bakes = await apiService.get(`bakes/order/${id}/${packagingId}`);
      return this.bakes;
    } catch (error) {
      throw error;
    }
  }

  async getRandom(id: string, packagingId?: string | undefined) {
    try {
      this.randoms = await apiService.get(`random-inspections/order/not-baked/${id}/${packagingId}`);
      return this.randoms;
    } catch (error) {
      throw error;
    }
  }

  async getCapacity() {
    try {
      this.capacities = await apiService.get("capacities");
      return this.capacities;
    } catch (error) {
      throw error;
    }
  }

  async getAllGrouping() {
    try {
      this.grouping = await apiService.get("grouping/main-material");
      return this.grouping;
    } catch (error) {
      throw error;
    }
  }

  async getMainMaterials() {
    try {
      this.mainMaterials = await apiService.get("main-materials");
      return this.mainMaterials;
    } catch (error) {
      throw error;
    }
  }

  async getGrouping(warehouseId: string, packaging?: string | undefined) {
    try {
      this.grouping = await apiService.get(`grouping/warehouse/${warehouseId}/?packaging=${packaging}`);
      return this.grouping;
    } catch (error) {
      throw error;
    }
  }

  async getGrade() {
    try {
      this.grades = await apiService.get("grades");
      return this.grades;
    } catch (error) {
      throw error;
    }
  }

  async getWarehouse() {
    try {
      this.warehouse = await apiService.get("warehouses");
      return this.warehouse;
    } catch (error) {
      throw error;
    }
  }

  get packagingData() {
    return this.packaging;
  }
  get groupingsData() {
    return this.grouping;
  }

  get ordersData() {
    return this.orders;
  }

  get bakeData() {
    return this.bakes;
  }

  get randomData() {
    return this.randoms;
  }

  get mainMaterialsData() {
    return this.mainMaterials;
  }

  get gradesData() {
    return this.grades;
  }

  get warehouseData() {
    return this.warehouse;
  }

  get capacityData() {
    return this.capacities;
  }

  get printPackingListData() {
    return this.printPackingList;
  }
}

const packagingService = new PackagingService();
export default packagingService;