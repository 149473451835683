import { configure, makeAutoObservable } from "mobx";
import apiService from "../../../services/ApiService";
import { IExpenseType } from "./ExpenseTypeService";
import { IShowImages, IUploadFile } from "../../Inventory/Services/ReceiveService";
import { IUser } from "../../UserManagement/Services/RoleService";
import { Dayjs } from "dayjs";

export interface IExpense {
  id?: string;
  docDate?: Dayjs;
  isDelete?: boolean;
  company?: string;
  createBy?: IUser | undefined;
  createAt?: Date;
  updateBy?: IUser | undefined;
  updateAt?: Date;
  // details?: IExpenseDetail[] | undefined;
  details?: Omit<IExpenseDetail, 'details'>[] | undefined;
  type?: IExpenseType | null;
  total: number;
  worker?: IUser | undefined;
  image?: IShowImages[] | undefined;
}
export interface IExpenseCriteria {
  dateType: string;
  dateFrom: string;
  dateTo: string;
  name: string;
  type: IExpenseType | undefined;
  sortBy: IExpenseSortBy;
  page: number;
  limit: number;
}

export interface IExpenseListView {
  datas?: IExpense[] | undefined;
  all: number;
  limit: number;
  page: number;
  total: number;
}

export interface IExpenseSortBy {
  name: string;
  value: string;
  sortBy: string;
  sortType: "ASC" | "DESC";
}

export interface IExpenseDetail {
  id?: string;
  name?: string;
  entryType?: string;
  isBill?: boolean;
  amount?: number | null;
  remark?: string;
  createBy?: IUser | undefined;
  createAt?: Date;
  updateBy?: IUser | undefined;
  updateAt?: Date;
  isNew?: boolean;
  isDelete?: boolean | undefined;
}

class ExpenseService {
  expenses: IExpenseListView = {} as IExpenseListView;
  expense: IExpense = {} as IExpense;
  expenseList: IExpense[] = [];

  constructor() {
    configure({
      enforceActions: "never",
    });
    makeAutoObservable(this);
  }

  async getAll() {
    try {
      const expenses = await apiService.get("expenses");
      this.expenseList = expenses;
      return this.expenseList;
    } catch (error) {
      throw error;
    }
  }

  async getOne(id: string) {
    try {
      const expense = await apiService.get(`expenses/${id}`);
      this.expense = expense;
      return this.expense;
    } catch (error) {
      throw error;
    }
  }

  async getByCriteria(criteria: IExpenseCriteria) {
    try {
      const expenses = await apiService.post(`expenses/criteria`, criteria);
      this.expenses = expenses;
      return this.expenses;
    } catch (error) {
      throw error;
    }
  }

  async create(expense: IExpense, uploadFile?: IUploadFile) {
    try {
      const response = await apiService.post(`expenses`, expense);
      if (uploadFile?.file.length > 0 && uploadFile?.file) {
        for (const file of uploadFile.file) {
          const formData = new FormData();
          formData.append("file", file);
          formData.append("refId", response?.id || "");
          formData.append("refType", "expense");
          await apiService.postFormData("/images/upload", formData);
        }
      }
      return response;
    } catch (error) {
      throw error;
    }
  }

  async update(expense: IExpense, uploadFile?: IUploadFile) {
    try {
      const response = await apiService.put(`expenses/${expense.id}`, expense);
      if (uploadFile?.file.length > 0 && uploadFile?.file) {
        for (const file of uploadFile.file) {
          const formData = new FormData();
          formData.append("file", file);
          formData.append("refId", expense?.id ?? "");
          formData.append("refType", "expense");
          await apiService.postFormData("/images/upload", formData);
        }
      }
      return response;
    } catch (error) {
      throw error;
    }
  }

  async delete(id: string) {
    try {
      const response = await apiService.delete(`expenses/${id}`);
      return response;
    } catch (error) {
      throw error;
    }
  }

  get expenseData() {
    return this.expense;
  }

  get expensesData() {
    return this.expenses;
  }

  get expenseListData() {
    return this.expenseList;
  }
}
const expenseService = new ExpenseService();
export default expenseService;
