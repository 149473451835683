import { observer } from "mobx-react-lite";
import driverService, { IDriver, IDriverCriteria, IDriverListView } from "../Services/DriverService";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { IUserDetail } from "../../SuperAdminManagement/Services/UserService";
import DriverListView from "../Views/DriverListView";
import getPermissionBySubMenu from "../../../utils/getPermissionBySubMenu";

const DriverController = observer(() => {

  const [isLoading, setIsLoading] = useState(false);
  const [permission, setPermission] = useState<IUserDetail>();

  const defaultCriteria: IDriverCriteria = {
    name: '',
    page: 0,
    limit: 10,
  };

  useEffect(() => {
    fetchByCriteria(defaultCriteria);
    checkPermissionActionButton();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchByCriteria = async (criteria: IDriverCriteria) => {
    try {
      setIsLoading(true);
      await driverService.getByCriteria(criteria);
    } catch (error) {
      console.error("An error occurred while getting the drivers:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const create = async (data: IDriver) => {
    try {
      await driverService.create(data);
      enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      fetchByCriteria(defaultCriteria);
    } catch (error) {
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
    }
  };

  const update = async (data: IDriver) => {
    try {
      await driverService.update(data.id ?? '', data);
      enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      fetchByCriteria(defaultCriteria);
    } catch (error) {
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
    }
  };

  const checkPermissionActionButton = () => {
    const result = getPermissionBySubMenu("transport-driver");
    if (result) {
      setPermission(result);
    }
  };

  return (
    <DriverListView
      onSubmit={create}
      onUpdate={update}
      drivers={driverService.driversData ?? {} as IDriverListView}
      onSearchByCriteria={fetchByCriteria}
      isLoading={isLoading}
      permission={permission ?? {} as IUserDetail}
    />
  );
});

export default DriverController;