import { configure, makeAutoObservable } from "mobx";
import apiService from "../../../services/ApiService";
import { IUserGroup } from "./UserGroupService";



export interface IUser {
  id: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  role?: string;
  isSuperAdmin?: boolean;
  createAt?: Date;
  updateAt?: Date;
  password?: string;
  confirmPassword?: string;
  isDelete?: boolean;
  isActive?: boolean;
}

export interface IUserRole {
  id: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  role?: string;
  isSuperAdmin?: boolean;
  details?: Omit<IRole, "details">[] | undefined;
  createAt?: Date;
  updateAt?: Date;
  createBy?: IUser | undefined;
  updateBy?: IUser | undefined;
  userGroup?: IUserGroup | undefined;
  password?: string;
  confirmPassword?: string;
  isDelete?: boolean;
  isActive?: boolean;
}

export interface IRole {
  id?: string;
  user?: IUserRole | undefined;
  menu?: string;
  read?: boolean;
  edit?: boolean;
  create?: boolean;
  delete?: boolean;
  isSelected?: boolean;
  createBy?: IUser | undefined;
  createAt?: Date;
  updateBy?: IUser | undefined;
  updateAt?: Date;
  children?: IRole[] | undefined;
  value?: string;
  name?: string | null;
}

export interface IRoleCriteria {
  name: string;
  userGroup: string;
  status?: boolean;
  page: number;
  limit: number;
}

export interface IRoleListView {
  data?: IUser[] | undefined;
  all: number;
  limit: number;
  page: number;
  total: number;
}
class RoleService {
  roles: IRoleListView | undefined;
  roleList: IRole[] | undefined;
  roleData: IUser | undefined;

  constructor() {
    configure({
      enforceActions: "never",
    });
    makeAutoObservable(this);
  }

  async getAll() {
    try {
      const roles = await apiService.get("users/roles/all");
      this.roleList = roles;
      return this.roleList;
    } catch (error) {
      throw error;
    }
  }

  async getOne(id: string) {
    try {
      const role = await apiService.get(`users/${id}`);
      this.roleData = role;
      return this.roleData;
    } catch (error) {
      throw error;
    }
  }

  async create(role: IRole) {
    try {
      const response = await apiService.post("users", role);

      return response;
    } catch (error) {
      throw error;
    }
  }

  async getByCriteria(criteria: IRoleCriteria) {
    try {
      const roles = await apiService.post("users/criteria", criteria);
      this.roles = roles;
      return this.roles;
    } catch (error) {
      throw error;
    }
  }

  async update(userId: string, role: IRole) {
    try {
      const response = await apiService.put(`users/${userId}`, role);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async delete(id: string) {
    try {
      const response = await apiService.put(`users/${id}`, { isDelete: true });
      return response;
    } catch (error) {
      throw error;
    }
  }
  get rolesList() {
    return this.roleList;
  }

  get rolesData() {
    return this.roleData;
  }

  get rolesView() {
    return this.roles;
  }

}
const roleService = new RoleService();
export default roleService;
