import apiService from "../../../services/ApiService";
import { IUser } from "../../UserManagement/Services/RoleService";
import { IMaterial } from "./MaterialsService";
import { configure, makeAutoObservable } from "mobx";

export interface IGrouping {
  id: string;
  mainMaterial: IMaterial | null;
  isDelete?: boolean;
  createBy?: IUser | undefined;
  updateBy?: IUser | undefined;
  createAt?: string;
  updateAt?: string;
  details: IGroupingDetail[];
  name?: string;
  nwFrom?: number;
  nwTo?: number;
  ngFrom?: number;
  ngTo?: number;
}

export interface IGroupingDetail {
  id?: string;
  order: number;
  qty: number;
  subMaterial: IMaterial;
  mainMaterial?: IMaterial | undefined;
  unit: string;
  isCalculate: boolean;
}

export interface IGroupingView {
  datas: IGrouping[];
  all: number;
  page: number;
  limit: number;
  total: number;
}

export interface IGroupingCriteria {
  mainMaterialGrouping: string;
  page: number;
  limit: number;
}

export interface IProvince {
  id?: number;
  nameTh?: string;
}

export interface IDistrict {
  id?: number;
  nameTh?: string;
  province?: IProvince | null;
}

export interface ISubDistrict {
  id?: number;
  nameTh?: string;
  district?: IDistrict | null;
  zipCode?: string;
}

class GroupingService {
  groupings: IGroupingView = {} as IGroupingView;
  grouping: IGrouping = {} as IGrouping;
  mainMaterials: IMaterial[] = [] as IMaterial[];
  subMaterials: IMaterial[] = [] as IMaterial[];
  province: IProvince[] = [];
  district: IDistrict[] = [];
  subDistrict: ISubDistrict[] = [];
  constructor() {
    configure({
      enforceActions: "never",
    });
    makeAutoObservable(this);
  }

  async getOne(id: string): Promise<IGrouping> {
    try {
      const response = await apiService.get(`/grouping/${id}`);
      if (response) {
        this.grouping = response;
      }
      return this.grouping;
    }
    catch (error) {
      throw error;
    }
  }

  async getByCriteria(criteria: IGroupingCriteria) {
    try {
      const response = await apiService.post(`/grouping/criteria`, criteria);
      if (response.datas) {
        this.groupings = response;
      }
      return this.groupings;
    }
    catch (error) {
      throw error;
    }
  }

  async create(data: IGrouping) {
    try {
      const response = await apiService.post('/grouping', data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async update(id: string, data: IGrouping) {
    try {
      const response = await apiService.put(`/grouping/${id}`, data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getMainMaterials() {
    try {
      this.mainMaterials = await apiService.get('/materials/main/all');
      return this.mainMaterials;
    } catch (error) {
      throw error;
    }
  }
  async getSubMaterials() {
    try {
      this.subMaterials = await apiService.get('/materials/sub/all');
      return this.subMaterials;
    } catch (error) {
      throw error;
    }
  }

  async getProvince() {
    try {
      this.province = await apiService.get(`/provinces`);
      return this.province;
    } catch (error) {
      throw error;
    }
  }

  async getDistrict(id: number) {
    try {
      this.district = await apiService.get(`/districts?provinceId=${id}`);
      return this.district;
    } catch (error) {
      throw error;
    }
  }

  async getSubDistrict(id: number) {
    try {
      this.subDistrict = await apiService.get(
        `/sub-districts?districtId=${id}`
      );
      return this.subDistrict;
    } catch (error) {
      throw error;
    }
  }

  get groupingData() {
    return this.grouping;
  }

  get groupingsData() {
    return this.groupings;
  }

  get mainMaterialsData() {
    return this.mainMaterials;
  }

  get subMaterialsData() {
    return this.subMaterials;
  }

  get provinceData() {
    return this.province;
  }

  get districtData() {
    return this.district;
  }

  get subDistrictData() {
    return this.subDistrict;
  }
}

const groupingService = new GroupingService();
export default groupingService;