import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import groupingService, { IGroupingCriteria, IGroupingView } from "../Services/GroupingService";
import GroupingsListView from "../Views/GroupingsListView";
import getPermissionBySubMenu from "../../../utils/getPermissionBySubMenu";
import { IUserDetail } from "../../Login/Services/LoginService";

const GroupingsListController = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [permission, setPermission] = useState<IUserDetail>();
  const [groupingData, setGroupingData] = useState<IGroupingView>();
  const defaultCriteria: IGroupingCriteria = {
    mainMaterialGrouping: "",
    page: 0,
    limit: 10,
  };
  useEffect(() => {
    searchByCriteria(defaultCriteria);
    checkPermissionActionButton();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchByCriteria = async (criteria: IGroupingCriteria) => {
    try {
      setIsLoading(true);
      await groupingService.getByCriteria(criteria).then((data) => {
        setGroupingData(data);
      });
    } catch (error) {
      console.error("Error searching materials by criteria:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const checkPermissionActionButton = () => {
    const result = getPermissionBySubMenu("inventory-grouping");
    if (result) {
      setPermission(result);
    }
  };

  return <GroupingsListView groupings={groupingData ?? {} as IGroupingView} isLoading={isLoading} onSearchByCriteria={searchByCriteria} permission={permission ?? {} as IUserDetail} />;
});

export default GroupingsListController;