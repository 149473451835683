import { configure, makeAutoObservable } from "mobx";
import apiService from "../../../services/ApiService";
import { IRefOrder } from "./OrderService";
import { ISupplier } from "../../Supplier/Services/SupplierService";
import { IShowImages } from "../../Inventory/Services/ReceiveService";
import { IProduct } from "./ProductService";
import { IVapourHeatTreatment } from "./VapourHeatTreatmentService";
import { IUser } from "../../UserManagement/Services/RoleService";
import { IGrade } from "./GradeService";
import { ICar } from "../../Transport/Services/CarService";
import { IDriver } from "../../Transport/Services/DriverService";
import { IExpenseType } from "../../Expense/Services/ExpenseTypeService";
import { IRandomInspection } from "./RandomInspectionService";
import { ISupplierType } from "../../Supplier/Services/SupplierTypeService";
import { Dayjs } from "dayjs";

export interface IFarmProductReceipt {
  id?: string;
  docDate?: Dayjs;
  docNo?: string;
  grade?: IGrade | undefined;
  basketAmount?: number;
  weightPerBasketFromFarm?: number;
  preWeighingWeight?: number;
  emptyBasketWeight?: number;
  totalWeight?: number;
  netWeight?: number;
  totalRemainingWeight?: number;
  weightPerBasket?: number;
  order: IRefOrder;
  product: IProduct;
  farmExpenseDetail?: IFarmExpenseDetail[] | undefined;
  farmReceiptBasket?: IFarmReceiptBasket[] | undefined;
  vapourHeatTreatment: IVapourHeatTreatment | undefined;
  transportRef?: ITransportRefference | undefined;
  pricePerKg?: number;
  lostM?: number;
  deposit?: number;
  depositDate?: Date;
  totalRemain?: number;
  remainPaymentDate?: Date;
  grandTotal?: number;
  wageCost?: number;
  agent?: ISupplier | undefined;
  status?: string;
  createBy?: IUser | undefined;
  updateBy?: IUser | undefined;
  approveBy?: IUser | undefined;
  image?: IShowImages[] | undefined;
  file?: IShowImages[] | undefined;
  createAt?: string;
  updateAt?: string;
  randomInspections?: IRandomInspection[] | undefined;
  totalWeightFromFarm?: number;
}

export interface IFarmProductReceiptWithcostPerBox extends IFarmProductReceipt {
  costPerBox?: number;
}


export interface ITransportRefference {
  id?: string;
  docNo?: string;
}

export interface IFarmExpenseDetail {
  id?: string;
  expenseType?: IExpenseType | undefined;
  amount?: number;
  farmProductReceipt?: IFarmProductReceipt | undefined;
  type?: number;
  car?: ICar | undefined;
  driver?: IDriver | undefined;
  payBy?: IUser | undefined;
  payDate?: Date;
  isDelete?: boolean;
  createBy?: IUser | undefined;
  updateBy?: IUser | undefined;
  createAt?: string;
  updateAt?: string;
}

export interface IFarmReceiptBasket {
  id?: string;
  grade?: IGrade | undefined;
  weight?: number;
  emptyBasketWeight?: number;
  farmProductReceipt?: IFarmProductReceipt | undefined;
  createBy?: IUser | undefined;
  updateBy?: IUser | undefined;
  createAt?: string;
  updateAt?: string;
}

export interface IFarmProductReceiptListView {
  datas?: IFarmProductReceipt[];
  all: number;
  limit: number;
  page: number;
  total: number;
}

export interface IFarmProductReceiptCriteria {
  dateFrom?: string;
  dateTo?: string;
  docNo?: string;
  agent?: string;
  order?: string;
  status?: string;
  page?: number;
  limit?: number;
}

export interface IProvince {
  id?: number;
  nameTh?: string;
}

export interface IDistrict {
  id?: number;
  nameTh?: string;
  province?: IProvince | null;
}

export interface ISubDistrict {
  id?: number;
  nameTh?: string;
  district?: IDistrict | null;
  zipCode?: string;
}

class FarmProductReceiptService {
  farmProductReceiptList: IFarmProductReceiptListView | undefined;
  farmProductReceipt: IFarmProductReceipt | undefined;
  province: IProvince[] = [];
  district: IDistrict[] = [];
  subDistrict: ISubDistrict[] = [];
  supplierTypeList: ISupplierType[] | undefined;
  constructor() {
    configure({
      enforceActions: "never",
    });
    makeAutoObservable(this);
  }

  async getAll() {
    try {
      const farmProductReceiptList = await apiService.get("farm-product-receipts");
      this.farmProductReceiptList = farmProductReceiptList;
      return farmProductReceiptList;
    } catch (error) {
      throw error;
    }
  }


  async getOne(id: string) {
    try {
      const farmProductReceipt = await apiService.get(`farm-product-receipts/${id}`);
      this.farmProductReceipt = farmProductReceipt;
      return farmProductReceipt;
    } catch (error) {
      throw error;
    }
  }

  async getByCriteria(criteria: IFarmProductReceiptCriteria) {
    try {
      const farmProductReceiptList = await apiService.post("farm-product-receipts/criteria", criteria);
      this.farmProductReceiptList = farmProductReceiptList;
      return farmProductReceiptList;
    } catch (error) {
      throw error;
    }
  }

  async create(data: IFarmProductReceipt, uploadFile: any) {
    try {
      const farmProductReceipt = await apiService.post("farm-product-receipts", data);
      if (uploadFile && uploadFile?.length > 0) {
        for (const file of uploadFile) {
          const formData = new FormData();
          formData.append("file", file);
          formData.append("refId", farmProductReceipt.id || "");
          formData.append("refType", "farmProductReceipt");

          if (file.type === "application/pdf") {
            formData.append("originalName", file.name);
            await apiService.postFormData("/files/upload", formData);
          } else {
            await apiService.postFormData("/images/upload", formData);
          }
        }
      }
      return farmProductReceipt;
    } catch (error) {
      throw error;
    }
  }

  async update(id: string, data: IFarmProductReceipt, uploadFile: any) {
    try {
      delete data.image;
      delete data.file;
      const farmProductReceipt = await apiService.put(`farm-product-receipts/${id}`, data);
      if (uploadFile && uploadFile?.length > 0) {
        for (const file of uploadFile) {
          const formData = new FormData();
          formData.append("file", file);
          formData.append("refId", id || "");
          formData.append("refType", "farmProductReceipt");

          if (file.type === "application/pdf") {
            formData.append("originalName", file.name);
            await apiService.postFormData("/files/upload", formData);
          } else {
            await apiService.postFormData("/images/upload", formData);
          }
        }
      }
      return farmProductReceipt;
    } catch (error) {
      throw error;
    }
  }

  async delete(id: string) {
    try {
      const farmProductReceipt = await apiService.delete(`farm-product-receipts/${id}`);
      return farmProductReceipt;
    } catch (error) {
      throw error;
    }
  }

  async getProvince() {
    try {
      this.province = await apiService.get(`/provinces`);
      return this.province;
    } catch (error) {
      throw error;
    }
  }

  async getDistrict(id: number) {
    try {
      this.district = await apiService.get(`/districts?provinceId=${id}`);
      return this.district;
    } catch (error) {
      throw error;
    }
  }

  async getSubDistrict(id: number) {
    try {
      this.subDistrict = await apiService.get(
        `/sub-districts?districtId=${id}`
      );
      return this.subDistrict;
    } catch (error) {
      throw error;
    }
  }

  async getAllSupplierType() {
    try {
      const supplierTypes = await apiService.get("supplier/type/all");
      this.supplierTypeList = supplierTypes;
      return this.supplierTypeList;
    } catch (error) {
      throw error;
    }
  }

  get farmProductReceiptData() {
    return this.farmProductReceipt;
  }

  get provinceData() {
    return this.province;
  }

  get districtData() {
    return this.district;
  }

  get subDistrictData() {
    return this.subDistrict;
  }

  get supplierTypeListData() {
    return this.supplierTypeList;
  }
}

const farmProductReceiptService = new FarmProductReceiptService();
export default farmProductReceiptService;