
import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import productProcessService, { IProduct, IProductCriteria, IProductListView } from "../Services/ProductService";
import { enqueueSnackbar } from "notistack";
import ProductsListView from "../Views/ProductsListView";
import supplierService, { ISupplierCriteria } from "../../Supplier/Services/SupplierService";
import getPermissionBySubMenu from "../../../utils/getPermissionBySubMenu";
import { IUserDetail } from "../../Login/Services/LoginService";
import supplierTypeService from "../../Supplier/Services/SupplierTypeService";

const ProductListController = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [permission, setPermission] = useState<IUserDetail>();
  const [productData, setProductData] = useState<IProductListView>();
  const defaultCriteria: IProductCriteria = {
    name: "",
    supplier: "",
    page: 0,
    limit: 10,
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    searchByCriteria(defaultCriteria);
    fetchOrchardSupplier();
    getProvince();
    getSupplierType();
    checkPermissionActionButton();
  };


  const fetchOrchardSupplier = async () => {
    try {
      await supplierService.getOrchardSupplier();
    } catch (error) {
      console.error("An error occurred while getting the suppliers:", error);
    }
  };

  const searchByCriteria = async (criteria: IProductCriteria) => {
    try {
      setIsLoading(true);
      await productProcessService.getByCriteria(criteria).then((data) => {
        setProductData(data);
      }
      );
    } catch (error) {
      console.error("Error searching product process by criteria:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const create = async (data: IProduct) => {
    try {
      setIsLoading(true);
      const res = await productProcessService.create(data);
      if (res) {
        enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      } else {
        enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      }
      fetchData();
    } catch (error) {
      console.error("An error occurred while creating product process:", error);
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const getProvince = async () => {
    try {
      await productProcessService.getProvince();
    } catch (error) {
      console.error("An error occurred while getting the province:", error);
    }
  };

  const getSupplierType = async () => {
    try {
      await productProcessService.getAllSupplierType();
    } catch (error) {
      console.error("An error occurred while getting the supplier type:", error);
    }
  };


  const onCreateDialog = async (data: any, type: string) => {
    try {
      let response;
      switch (type) {
        case "supplier": response = await supplierService.create(data);
          fetchOrchardSupplier();
          break;
        case "supplierType": response = await supplierTypeService.create(data);
          getSupplierType();
          break;
      }
      if (response) {
        enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      }
    } catch (error) {
      console.error(`An error occurred while creating the ${type}`, error);
    }
  };

  const onFetchData = async (id?: any, type?: string) => {
    try {
      switch (type) {
        case "district": await productProcessService.getDistrict(id);
          break;
        case "subDistrict": await productProcessService.getSubDistrict(id);
          break;
      }
    } catch (error) {
      console.error(`An error occurred while creating the ${type}`, error);
    }
  };


  const checkPermissionActionButton = () => {
    const result = getPermissionBySubMenu("process-product");
    if (result) {
      setPermission(result);
    }
  };

  return <ProductsListView
    isLoading={isLoading}
    onCreate={create}
    products={productData ?? { all: 0, datas: [], limit: 10, page: 0, total: 0 } as IProductListView}
    onSearchByCriteria={searchByCriteria}
    suppliers={supplierService.supplierListData ?? [] as ISupplierCriteria[]}
    permission={permission ?? {} as IUserDetail}
    onCreateDialog={onCreateDialog}
    onFetchData={onFetchData}
    provinceData={productProcessService.provinceData ?? []}
    districtData={productProcessService.districtData ?? []}
    subDistrictData={productProcessService.subDistrictData ?? []}
    supplierTypesOptions={productProcessService.supplierTypeListData ?? []}
  />;
});

export default ProductListController;
