import { FC, useEffect, useState } from "react";
import {
  Box, Button, ButtonProps, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, Grid, TextField, Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { IMaterial } from "../Services/MaterialsService";
import DialogConfirm from "../../../components/DialogConfirm";

interface MaterialsCreateViewProps {
  open: boolean;
  onClose: () => void;
  onCreate: (data: IMaterial) => void;
  name?: string;
}

const MaterialsCreateView: FC<MaterialsCreateViewProps> = (props) => {
  const { register, handleSubmit, reset, getValues, setValue, watch, formState: { errors } } = useForm<IMaterial>({
    defaultValues: {
      name: '',
      price: 0,
      qty: 0,
      unit: '',
      subUnit: '',
      valueUnit: 0,
      isMain: false,
      height: 0,
      width: 0,
      length: 0,
      CBM: 0,
      weight: 0
    }
  });
  const isMain = watch("isMain");
  const unit = watch("unit");
  const subUnit = watch("subUnit");


  const [openConfirm, setOpenConfirm] = useState({
    open: false,
    title: "",
    message: "",
    color: "primary" as ButtonProps["color"],
    type: "create" as "create",
  });

  const handleFormSubmit = (data: IMaterial) => {
    const formatData = {
      ...data,
      valueUnit: data.valueUnit || 0,
      height: data.height || 0,
      width: data.width || 0,
      length: data.length || 0,
      CBM: data.CBM || 0,
      weight: data.weight || 0


    };
    props.onCreate(formatData);
    props.onClose();
    reset();
  };

  const onSubmit = handleSubmit(() => {
    setOpenConfirm({
      open: true,
      title: "บันทึกข้อมูล",
      message: "คุณต้องการบันทึกข้อมูลนี้ใช่หรือไม่",
      color: "primary",
      type: "create",
    });
  });

  const onConfirm = () => {
    handleFormSubmit(getValues());
  };

  const onClose = () => {
    props.onClose();
    setOpenConfirm({ ...openConfirm, open: false });
  };

  const getValueUnitLabel = () => {
    if (unit && subUnit) {
      return `${unit} / ${subUnit}`;
    }
    return "ค่าเทียบหน่วย";
  };

  const height = watch("height");
  const width = watch("width");
  const length = watch("length");

  const calCBM = () => {
    const sum = ((height || 0) * (width || 0) * (length || 0)) / 1000000;
    const result = Math.round(sum * 100) / 100;
    setValue("CBM", result);
  };

  useEffect(() => {
    if (props.open) {
      reset({
        name: props.name || ""
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open, reset]);

  useEffect(() => {
    calCBM();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [height, width, length]);

  return (
    <Dialog open={props.open} fullWidth maxWidth={"lg"}>
      <DialogTitle>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography >เพิ่มรายการวัสดุ</Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Box component="form" onSubmit={onSubmit}>
          <Grid container spacing={2} mt={1}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <TextField
                {...register("name", { required: true })}
                label="ชื่อรายการ"
                fullWidth
                error={!!errors.name}
                helperText={errors.name ? "กรุณาระบุชื่อรายการ" : ""}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <TextField
                fullWidth
                label="มูลค่า"
                type="number"
                {...register("price", { required: "กรุณาระบุมูลค่า", min: { value: 0, message: "มูลค่าต้องไม่ติดลบ" } })}
                error={!!errors.price}
                helperText={errors.price ? errors.price.message : ""}
                InputLabelProps={{ shrink: getValues("price") ? true : undefined }}
                InputProps={{ inputProps: { min: 0 } }}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={4} lg={4}>
              <TextField
                fullWidth
                label="หน่วยหลัก"
                {...register("unit", {
                  required: "กรุณาระบุหน่วยหลัก"
                })}
                error={!!errors.unit}
                helperText={errors.unit ? errors.unit.message : ""}
                InputLabelProps={{ shrink: getValues("unit") ? true : undefined }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <TextField
                fullWidth
                label="หน่วยรอง"
                {...register("subUnit")}
                InputLabelProps={{ shrink: getValues("subUnit") ? true : undefined }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <TextField
                fullWidth
                label={getValueUnitLabel()}
                type="number"
                defaultValue={0}
                error={!!errors.valueUnit}
                {...register("valueUnit", {
                  min: { value: subUnit ? 1 : 0, message: subUnit ? "จำนวนวัสดุต้องไม่ติดลบและต้องมากกว่า 0" : "จำนวนวัสดุต้องไม่ติดลบ" },
                  required: subUnit ? "กรุณาระบุค่าเทียบหน่วย" : false
                })}
                helperText={errors.valueUnit ? errors.valueUnit.message : ""}
                InputLabelProps={{ shrink: getValues("valueUnit") ? true : undefined }}
                InputProps={{ inputProps: { min: subUnit ? 1 : 0 } }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <TextField
                fullWidth
                label={"ความสูง (cm)"}
                type="number"
                defaultValue={0}
                {...register("height", {
                  min: { value: 0, message: "ความสูงต้องไม่ติดลบ" },
                })}
                InputLabelProps={{ shrink: getValues("height") ? true : undefined }}
                InputProps={{ inputProps: { min: 0 } }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <TextField
                fullWidth
                label={"ความกว้าง (cm)"}
                type="number"
                defaultValue={0}
                {...register("width", {
                  min: { value: 0, message: "ความกว้างต้องไม่ติดลบ" },
                })}
                InputLabelProps={{ shrink: getValues("width") ? true : undefined }}
                InputProps={{ inputProps: { min: 0 } }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <TextField
                fullWidth
                label={"ความยาว (cm)"}
                type="number"
                defaultValue={0}

                {...register("length", {
                  min: { value: 0, message: "ความยาวต้องไม่ติดลบ" },
                })}
                InputLabelProps={{ shrink: getValues("length") ? true : undefined }}
                InputProps={{ inputProps: { min: 0 } }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <TextField
                fullWidth
                label={"CBM"}
                type="number"
                defaultValue={0}
                {...register("CBM", {
                  min: { value: 0, message: "CBM ต้องไม่ติดลบ" },
                })}
                InputLabelProps={{ shrink: getValues("CBM") ? true : undefined }}
                InputProps={{ inputProps: { min: 0 } }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <TextField
                fullWidth
                label={"น้ำหนัก (kg)"}
                type="number"
                defaultValue={0}
                {...register("weight", {
                  min: { value: 0, message: "น้ำหนักต้องไม่ติดลบ" },
                })}
                InputLabelProps={{ shrink: getValues("weight") ? true : undefined }}
                InputProps={{ inputProps: { min: 0 } }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} mt={2}>
              <FormControl fullWidth>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      {...register("isMain")}
                      checked={isMain}
                      onChange={(e) => setValue("isMain", e.target.checked)}
                    />
                  }
                  label="วัสดุหลัก"
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} mt={2} md={12} lg={12}>
            <Divider />
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          ยกเลิก
        </Button>
        <Button type="submit" onClick={onSubmit} variant="contained" color="primary">
          บันทึก
        </Button>
      </DialogActions>
      <DialogConfirm
        open={openConfirm.open}
        type="success"
        title={openConfirm.title}
        message={openConfirm.message}
        onClose={() => setOpenConfirm({ ...openConfirm, open: false })}
        onSubmit={() => {
          setOpenConfirm({ ...openConfirm, open: false });
          onConfirm();
        }}
      />
    </Dialog>
  );
};

export default MaterialsCreateView;
