import { FC, useEffect, useState } from "react";
import { IUserDetail } from "../../SuperAdminManagement/Services/UserService";
import { IDriver } from "../Services/DriverService";
import { useForm } from "react-hook-form";
import { Box, Button, ButtonProps, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, TextField } from "@mui/material";
import DialogConfirm from "../../../components/DialogConfirm";
// icon
import DeleteIcon from "@mui/icons-material/Delete";


interface DriverCreateViewProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: IDriver) => void;
  data?: IDriver;
  permission: IUserDetail;
  name?: string;
}

const DriverCreateView: FC<DriverCreateViewProps> = (props) => {
  const { register, handleSubmit, reset, getValues, formState: { errors } } = useForm<IDriver>();

  const [openConfirm, setOpenConfirm] = useState({
    open: false,
    title: "",
    message: "",
    color: "primary" as ButtonProps["color"],
    type: "create" as "create" | "edit" | "delete",
  });

  const onSubmit = handleSubmit(() => {
    if (props.data?.id) {
      setOpenConfirm({
        open: true,
        title: "แก้ไขข้อมูล",
        message: "คุณต้องการแก้ไขข้อมูลนี้ใช่หรือไม่",
        color: "primary",
        type: "edit",
      });
    } else {
      setOpenConfirm({
        open: true,
        title: "บันทึกข้อมูล",
        message: "คุณต้องการบันทึกข้อมูลนี้ใช่หรือไม่",
        color: "primary",
        type: "create",
      });
    }
  });

  const onConfirm = () => {
    props.onSubmit(getValues());
    reset();
    onClose();
  };

  const onClose = () => {
    props.onClose();
    setOpenConfirm({ ...openConfirm, open: false });
  };

  const onDelete = () => {
    props.onSubmit({ ...props.data, isDelete: true });
    reset();
    onClose();
  };

  useEffect(() => {
    reset({ ...props.data, firstName: props?.name ? props?.name : props.data?.firstName });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open, props.data]);

  return (
    <Dialog open={props.open} maxWidth="lg" fullWidth>
      <DialogTitle>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6} md={6} lg={6}>
            {props.data?.id ? `แก้ไข` : `สร้าง`}คนขับ
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} textAlign="right">
            {(props.data?.id && props.permission.delete) && (
              <Button
                variant="contained"
                color="error"
                startIcon={<DeleteIcon />}
                onClick={() => {
                  setOpenConfirm({
                    open: true,
                    title: "ลบข้อมูล",
                    message: "คุณต้องการลบข้อมูลนี้ใช่หรือไม่",
                    color: "error",
                    type: "delete",
                  });
                }}
              >
                ลบข้อมูล
              </Button>
            )}
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Box component={'form'} onSubmit={onSubmit}>
          <Grid container spacing={2} mt={1}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                fullWidth
                label="ชื่อ"
                {...register("firstName", { required: true })}
                error={!!errors.firstName}
                helperText={errors.firstName ? "กรุณาระบุชื่อ" : ""}
                InputLabelProps={
                  {
                    shrink: getValues("firstName") ? true : undefined
                  }
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                fullWidth
                label="นามสกุล"
                {...register("lastName")}
                InputLabelProps={
                  {
                    shrink: getValues("lastName") ? true : undefined
                  }
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                fullWidth
                label="เบอร์โทร"
                {...register("phone")}
                InputLabelProps={
                  {
                    shrink: getValues("phone") ? true : undefined
                  }
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                fullWidth
                label="เลขใบขับขี่"
                {...register("driverId")}
                InputLabelProps={
                  {
                    shrink: getValues("driverId") ? true : undefined
                  }
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} mt={2} md={12} lg={12}>
              <Divider />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
        >
          ยกเลิก
        </Button>
        {props.permission.create || (props.permission.edit && props.data?.id) ? (
          <Button
            id="submit"
            variant="contained"
            onClick={onSubmit}
          >
            บันทึก
          </Button>
        ) : null}

      </DialogActions>
      <DialogConfirm
        open={openConfirm.open}
        type={openConfirm.type === "delete" ? "error" : "success"}
        title={openConfirm.title}
        message={openConfirm.message}
        onClose={() => setOpenConfirm({ ...openConfirm, open: false })}
        onSubmit={() => {
          setOpenConfirm({ ...openConfirm, open: false });
          if (openConfirm.type === "delete") {
            onDelete();
          } else {
            onConfirm();
          }
        }}
      />
    </Dialog>
  );
};

export default DriverCreateView;