import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";
import productService, { IProduct } from "../Services/ProductService";
import { enqueueSnackbar } from "notistack";
import ProductDetailView from "../Views/ProductDetailView";
import supplierService, { ISupplier } from "../../Supplier/Services/SupplierService";
import { useEffect, useState } from "react";
import orderService, { IOrderCriteria, IOrderListView } from "../Services/OrderService";
import clearState from "../../../utils/resetState";
import getPermissionBySubMenu from "../../../utils/getPermissionBySubMenu";
import { IUserDetail } from "../../Login/Services/LoginService";
import supplierTypeService from "../../Supplier/Services/SupplierTypeService";
import { clearLocalStorageKeyOnUnload, getDateRangeFromStorage } from "../../../utils/dateRangeLocalStorage";
import { STORAGE_KEYS } from "../../../constants/dateRangeStorageKey";
import dayjs from "dayjs";

const ProductDetailController = observer(() => {
  const initialDateRange = getDateRangeFromStorage(STORAGE_KEYS.PROCESS);
  const { id } = useParams();
  const navigate = useNavigate();
  const [permission, setPermission] = useState<IUserDetail>();
  const defaultCriteria: IOrderCriteria = {
    product: id ?? '',
    dateFrom: initialDateRange.dateFrom
      ? dayjs(initialDateRange.dateFrom).format('YYYY-MM-DD')
      : dayjs().startOf('month').format('YYYY-MM-DD'),
    dateTo: initialDateRange.dateTo
      ? dayjs(initialDateRange.dateTo).format('YYYY-MM-DD')
      : dayjs().startOf('day').format('YYYY-MM-DD'),
    page: 0,
    limit: 10,
  };

  useEffect(() => {
    if (id) {
      getOne(id);
    }
    getOrderByCriteria(defaultCriteria);
    fetchOrchardSupplier();
    getProvince();
    getSupplierType();
    checkPermissionActionButton();

    return () => {
      clearState(productService, ["product"]);
    };
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , [id]);

  clearLocalStorageKeyOnUnload(STORAGE_KEYS.PROCESS);

  const onUpdate = async (data: IProduct) => {
    try {
      await productService.update(data);
      enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      getOne(id ?? "");
    } catch (error) {
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
    }
  };


  const onDelete = async (data: IProduct) => {
    try {
      await productService.update(data);
      enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      navigate(`/process-product`);
    } catch (error) {
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
    }
  };

  const fetchOrchardSupplier = async () => {
    try {
      await supplierService.getOrchardSupplier();
    } catch (error) {
      console.error("An error occurred while getting the suppliers:", error);
    }
  };

  const getOrderByCriteria = async (criteria: IOrderCriteria) => {
    try {
      await orderService.getByCriteria(criteria);
    } catch (error) {
      console.error("An error occurred while getting the products:", error);
    }
  };

  const getOne = async (id: string) => {
    try {
      await productService.getOne(id);
    } catch (error) {
      console.error("Error fetching product:", error);
    }
  };

  const getProvince = async () => {
    try {
      await productService.getProvince();
    } catch (error) {
      console.error("An error occurred while getting the province:", error);
    }
  };

  const getSupplierType = async () => {
    try {
      await productService.getAllSupplierType();
    } catch (error) {
      console.error("An error occurred while getting the supplier type:", error);
    }
  };

  const onCreateDialog = async (data: any, type: string) => {
    try {
      let response;
      switch (type) {
        case "supplier": response = await supplierService.create(data);
          fetchOrchardSupplier();
          break;
        case "supplierType": response = await supplierTypeService.create(data);
          getSupplierType();
          break;
      }
      if (response) {
        enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      }
    } catch (error) {
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      console.error(`An error occurred while creating the ${type}`, error);
    }
  };

  const onFetchData = async (id?: any, type?: string) => {
    try {
      switch (type) {
        case "district": await productService.getDistrict(id);
          break;
        case "subDistrict": await productService.getSubDistrict(id);
          break;
      }
    } catch (error) {
      console.error(`An error occurred while creating the ${type}`, error);
    }
  };


  const checkPermissionActionButton = () => {
    const result = getPermissionBySubMenu("process-product");
    if (result) {
      setPermission(result);
    }
  };

  return <ProductDetailView
    onUpdate={onUpdate}
    product={productService.productData}
    onDelete={onDelete}
    suppliers={supplierService.supplierListData ?? [] as ISupplier[]}
    getOrderByCriteria={getOrderByCriteria}
    orders={orderService.ordersData ?? {} as IOrderListView}
    permission={permission ?? {} as IUserDetail}
    onCreateDialog={onCreateDialog}
    onFetchData={onFetchData}
    provinceData={productService.provinceData ?? []}
    districtData={productService.districtData ?? []}
    subDistrictData={productService.subDistrictData ?? []}
    supplierTypesOptions={productService.supplierTypeListData ?? []}
  />;

});

export default ProductDetailController;