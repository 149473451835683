import { configure, makeAutoObservable } from "mobx";
import apiService from "../../../services/ApiService";
import { IUserGroup } from "../../SuperAdminManagement/Services/UserService";

export interface ILogin {
  email: string;
  password: string;
  isRemember: boolean;
}

export interface IUserLogined {
  id: string;
  firstName: string;
  lastName: string;
  tel: string;
  email: string;
  address: string;
  userGroup: IUserGroup;
  image: string;
  isActived: boolean;
  role: string;
  isSuperAdmin: boolean;
  access_token: string;
  details: IUserDetail[];
}

export interface IUserDetail {
  children: Omit<IUserDetail, "children">[],
  id: string;
  delete: boolean;
  edit: boolean;
  read: boolean;
  create: boolean;
  menu: string;
}

class LoginService {
  userLogined: IUserLogined = {} as IUserLogined;
  isAuthenticated = false;
  constructor() {
    makeAutoObservable(this);
    configure({
      enforceActions: "never",
    });
  }

  async login(login: ILogin) {
    const response = await apiService.post("/auth/login", login);
    if (response.access_token) {
      this.isAuthenticated = true;
      this.userLogined = response;
      localStorage.setItem("user", JSON.stringify(this.userLogined));

      return response;
    } else {
      throw new Error("An error occurred while logging in");
    }
  }

  logout() {
    this.isAuthenticated = false;
    this.userLogined = {} as IUserLogined;
    localStorage.removeItem("user");
    localStorage.removeItem("menus");
  }

  setUserLoginedData(data: IUserLogined) {
    this.userLogined = data;
  }

  get userLoginedData() {
    return this.userLogined;
  }
}
const loginService = new LoginService();
export default loginService;
