import { observer } from "mobx-react-lite";
import RoleDetailView from "../Views/RoleDetailView";
import roleService, { IUser } from "../Services/RoleService";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import userGroupService from "../Services/UserGroupService";
import { enqueueSnackbar } from "notistack";
import clearState from "../../../utils/resetState";
import { IUserDetail } from "../../Login/Services/LoginService";
import getPermissionBySubMenu from "../../../utils/getPermissionBySubMenu";

const RoleDetailController = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [permission, setPermission] = useState<IUserDetail>();
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (id) { getOne(id); }
    getAllUserGroups();
    checkPermissionActionButton();
    return () => {
      clearState(roleService, ["roleData"]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const getAllUserGroups = async () => {
    try {
      setIsLoading(true);
      await userGroupService.getAll();
    } catch (error) {
      console.error("Error searching user groups by criteria:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getOne = async (id: string) => {
    try {
      setIsLoading(true);
      await roleService.getOne(id);
    } catch (error) {
      console.error("Error searching user by criteria:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const create = async (data: any) => {
    try {
      setIsLoading(true);
      const response = await roleService.create(data);
      getOne(response.id);
      enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      navigate(`/user-role-detail/${response.id}`);
    } catch (error: any) {
      if (error?.status === 409) {
        enqueueSnackbar("บัญชีนี้มีอยู่ในระบบแล้ว", { variant: "error" });
      } else { enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" }); }
    } finally {
      setIsLoading(false);
    }
  };

  const update = async (data: any) => {
    try {
      setIsLoading(true);
      if (id) { await roleService.update(id, data); }
      if (data.isDelete) {
        navigate(`/user-role`);
      } else { getOne(data.id); }
      enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const remove = async () => {
    try {
      setIsLoading(true);
      if (id) { await roleService.delete(id); }
      enqueueSnackbar("ลบข้อมูลสำเร็จ", { variant: "success" });
      navigate(`/user-role`);
    } catch (error) {
      enqueueSnackbar("ลบข้อมูลไม่สำเร็จ", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const onCreateDialog = async (data: any, type: string) => {
    try {
      let response;
      switch (type) {
        case "position":
          response = await userGroupService.create(data);
          getAllUserGroups();
          break;
      }
      if (response) {
        enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      }
    } catch (error) {
      console.error(`An error occurred while creating the ${type}`, error);
    }
  };

  const checkPermissionActionButton = () => {
    const result = getPermissionBySubMenu("user-role");
    if (result) {
      setPermission(result);
    }
  };
  return <RoleDetailView onCreate={create} onUpdate={update} onRemove={remove} isLoading={isLoading} data={id ? roleService.rolesData as IUser : undefined} userGroup={userGroupService.getGroupList} permission={permission ?? {} as IUserDetail} onCreateDialog={onCreateDialog} />;
});

export default RoleDetailController;
