import { configure, makeAutoObservable } from "mobx";
import { IUser } from "../../UserManagement/Services/RoleService";
import apiService from "../../../services/ApiService";

export interface IShippingType {
  id: number;
  name: string;
  createBy?: IUser | undefined;
  updateBy?: IUser | undefined;
  createAt?: Date;
  updateAt?: Date;
  isDelete?: boolean;
}

export interface IShippingTypeWithValue {
  id?: number;
  name?: string;
  value?: string;
}

export interface IShippingTypeList {
  datas?: IShippingType[] | undefined;
  all: number;
  page: number;
  limit: number;
  total: number;
}

export interface IShippingTypeCriteria {
  dateFrom?: Date;
  dateTo?: Date;
  name: string;
  page: number;
  limit: number;
}
class ShippingTypeService {
  shippingType: IShippingType = {} as IShippingType;
  shippingTypes: IShippingTypeList = {} as IShippingTypeList;
  constructor() {
    configure({
      enforceActions: "never",
    });
    makeAutoObservable(this);
  }


  async getOne(id: string): Promise<IShippingType> {
    try {
      this.shippingType = await apiService.get(`shipping-types/${id}`);
      return this.shippingType;
    } catch (error) {
      throw error;
    }
  }

  async getByCriteria(criteria: IShippingTypeCriteria): Promise<IShippingTypeList> {
    try {
      this.shippingTypes = await apiService.post("shipping-types/criteria", criteria);
      return this.shippingTypes;
    } catch (error) {
      throw error;
    }
  }

  async create(data: IShippingType) {
    try {
      this.shippingType = await apiService.post("shipping-types", data);
      return this.shippingType;
    } catch (error) {
      throw error;
    }
  }

  async update(data: IShippingType) {
    try {
      this.shippingType = await apiService.put(`shipping-types/${data.id}`, data);
      return this.shippingType;
    } catch (error) {
      throw error;
    }
  }

  async delete(id: string) {
    try {
      this.shippingType = await apiService.delete(`shipping-types/${id}`);
      return this.shippingType;
    } catch (error) {
      throw error;
    }
  }

  get shippingTypeData() {
    return this.shippingType;
  }

  get shippingTypesData() {
    return this.shippingTypes;
  }
}
const shippingTypeService = new ShippingTypeService();
export default shippingTypeService;