import { observer } from "mobx-react-lite";
import ExpensesListView from "../Views/ExpensesListView";
import expenseService, { IExpense, IExpenseCriteria } from "../Services/ExpenseService";
import { useEffect, useState } from "react";
import { sortOptions } from "../../../constants/sortOptions";
import { enqueueSnackbar } from "notistack";
import expenseTypeService from "../Services/ExpenseTypeService";
import dayjs from "dayjs";
import getPermissionBySubMenu from "../../../utils/getPermissionBySubMenu";
import { IUserDetail } from "../../Login/Services/LoginService";
import { clearLocalStorageKeyOnUnload, getDateRangeFromStorage } from "../../../utils/dateRangeLocalStorage";
import { STORAGE_KEYS } from "../../../constants/dateRangeStorageKey";

const ExpensesListController = observer(() => {
  const initialDateRange = getDateRangeFromStorage(STORAGE_KEYS.EXPENSE);
  const [isLoading, setIsLoading] = useState(false);
  const [permission, setPermission] = useState<IUserDetail>();
  const defaultCriteria: IExpenseCriteria = {
    name: '',
    dateType: 'createDate',
    dateFrom: initialDateRange.dateFrom
      ? dayjs(initialDateRange.dateFrom).format('YYYY-MM-DD')
      : dayjs().startOf('month').format('YYYY-MM-DD'),
    dateTo: initialDateRange.dateTo
      ? dayjs(initialDateRange.dateTo).format('YYYY-MM-DD')
      : dayjs().startOf('day').format('YYYY-MM-DD'),
    sortBy: sortOptions[0],
    page: 0,
    limit: 10,
    type: undefined,
  };

  useEffect(() => {
    searchByCriteria(defaultCriteria);
    getExpenseTypes();
    checkPermissionActionButton();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  clearLocalStorageKeyOnUnload(STORAGE_KEYS.EXPENSE);

  const searchByCriteria = async (criteria: IExpenseCriteria) => {
    try {
      setIsLoading(true);
      await expenseService.getByCriteria(criteria);
    } catch (error) {
      console.error("Error searching transfers by criteria:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const create = async (data: IExpense) => {
    try {
      setIsLoading(true);
      await expenseService.create(data);
      enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      searchByCriteria(defaultCriteria);
    } catch (error) {
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const update = async (data: IExpense) => {
    try {
      setIsLoading(true);
      await expenseService.update(data);
      enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      searchByCriteria(defaultCriteria);
    } catch (error) {
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const getExpenseTypes = async () => {
    try {
      setIsLoading(true);
      await expenseTypeService.getAll();
    } catch (error) {
      console.error("Error fetching expense types:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const checkPermissionActionButton = () => {
    const result = getPermissionBySubMenu("expense-type");
    if (result) {
      setPermission(result);
    }
  };
  return <ExpensesListView
    onCreate={create} onUpdate={update} data={expenseService.expenses}
    onSerchByCriteria={searchByCriteria} isLoading={isLoading} typeOptions={expenseTypeService.expenseTypeListData || []}
    expenses={expenseService.expensesData}
    permission={permission ?? {} as IUserDetail}
  />;
});

export default ExpensesListController;
