import { Autocomplete, Box, Button, ButtonProps, Stack, Divider, Card, Grid, styled, TextField, Typography, useMediaQuery, CardContent, IconButton, Breadcrumbs, Link, Tooltip, tooltipClasses, Checkbox, FormControlLabel, FormControl, RadioGroup, Radio, createFilterOptions } from "@mui/material";
import { FC, useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";
import DialogConfirm from "../../../components/DialogConfirm";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { theme } from "../../../themes";
import { IUploadFile } from "../../Company/Services/CompanyService";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import ImagePreview from "../../../components/ImagePreview";
import { v4 as uuidv4 } from "uuid";
import { IRefOrder, } from "../Services/OrderService";
import { useNavigate } from "react-router-dom";
import { IUserDetail } from "../../SuperAdminManagement/Services/UserService";
import { useLocation } from "react-router-dom";
import { IUserLogined } from "../../Login/Services/LoginService";
import SimpleBackdrop from "../../../components/SimpleBackdrop";
import { IShipping, IShippingDetail, IPackagingExpense, IPrintShippingPackingList } from "../Services/ShippingService";
import { IShippingType as shippingType } from "../Services/ShippingTypeService";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import CircleIcon from '@mui/icons-material/Circle';
import { IExpenseType, IExpenseTypeWithValue } from "../../Expense/Services/ExpenseTypeService";
import { IExpense, IExpenseDetail } from "../../Expense/Services/ExpenseService";
import ShippingTypeCreateView from "./ShippingTypeCreateView";
import thaiFormatNumber from "../../../utils/thaiFormatNumber";
import { IShippingTypeWithValue } from "../Services/ShippingTypeService";
import ExpenseTypeCreateView from "../../Expense/Views/ExpenseTypeCreateView";
import { IConsignee, IConsigneeWithValue } from "../Services/Consignee";
import ConsigneeCreateView from "./ConsigneeCreateView";
import { useReactToPrint } from "react-to-print";
import PrintShippingPackingList from "../../../components/Report/Pdf/PrintShippingPackingList";
import PrintIcon from "@mui/icons-material/Print";

interface ShippingCreateViewProps {
  onCreate: (data: IShipping, uploadFile: IUploadFile, uploadFileExpense: IUploadFile) => void;
  onUpdate: (data: IShipping, oldImage: IUploadFile[], fileUpload: IUploadFile[], deleteFile: IUploadFile[], oldImageExpense: IUploadFile[], fileUploadExpense: IUploadFile[], deleteFileExpense: IUploadFile[]) => void;
  order: IRefOrder[];
  permission: IUserDetail;
  packaging: IPackagingExpense[];
  user: IUserLogined;
  isLoading: boolean;
  shipping: IShipping;
  shippingTypes: IShippingTypeWithValue[];
  onFetchPackaging: (order: string) => void;
  typeExpenseOptions: IExpenseTypeWithValue[];
  consignees: IConsigneeWithValue[];
  onCreateShippingType: (data: shippingType) => void;
  onCreateExpenseType: (data: IExpenseType) => void;
  onCreateConsignee: (data: IConsignee) => void;
  printShippingPackingList: IPrintShippingPackingList;
}

const ShippingCreateView: FC<ShippingCreateViewProps> = (props) => {
  const filterOptions = createFilterOptions<any>();
  const { id } = useParams();
  const defaultValues: IShippingDetail = {
    id: uuidv4(),
    etdDate: new Date(),
    etaDate: new Date(),
    quantity: undefined,
    packaging: undefined,
  };
  const defaultValuesExpenseDetails: IExpenseDetail = {
    name: "", amount: 0, id: undefined
  };

  const defaultValuesExpense: IExpense[] = [{
    id: uuidv4(),
    docDate: dayjs().startOf("day"),
    isDelete: false,
    company: "",
    createBy: undefined,
    createAt: new Date(),
    updateBy: undefined,
    updateAt: new Date(),
    details: [defaultValuesExpenseDetails],
    type: null,
    total: 0,
    worker: undefined,
    image: undefined,
  }];


  const defaultValuesShipping = {
    id: id,
    nonVat: undefined,
    docDate: dayjs().format("YYYY-MM-DD"),
    date: new Date(),
    vat: undefined,
    vat7: undefined,
    vat3: undefined,
    totalAmount: undefined,
    costPerBox: undefined,
    details: [defaultValues],
    status: "waiting",
    isFailed: false,
    isSuccess: false,
    expenses: defaultValuesExpense
  };


  const { register, setValue, formState: { errors }, getValues, control, watch, setError, clearErrors, reset, handleSubmit, trigger } = useForm<IShipping>({
    defaultValues: defaultValuesShipping
  });
  const navigate = useNavigate();
  const { fields: detailFields, append: appendDetail, remove: removeDetail } = useFieldArray({
    control,
    name: "details",
  });
  const { fields: expenseFields, append: appendExpense, remove: removeExpense } = useFieldArray({
    control,
    name: "expenses",
  });

  const expenses: IExpense[] = watch("expenses") || [];
  const [openConfirm, setOpenConfirm] = useState({
    open: false,
    title: "",
    message: "",
    color: "primary" as ButtonProps["color"],
    type: "create" as "create" | "update" | "delete" | "approved",
  });
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [images, setImages] = useState<any[]>([]);
  const [pdfFiles, setPdfFiles] = useState<any[]>([]);
  const [deleteImages, setDeleteImages] = useState<any>([]);
  const [openImage, setOpenImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const details = watch("details");
  const [sutiTotalBox, setSutiTotalBox] = useState(0);
  const order = watch("order");
  const location = useLocation();
  const { from } = location.state || { from: null };
  const isFromStatus = from === "status" || from === "order";
  const isDisabled = isFromStatus ? true : false;
  const userGroup = props.user?.userGroup;
  const [showIndex, setShowIndex] = useState<number | null>(null);
  const sutiCostPerBox = watch("costPerBox");
  // NOTE:Expense
  const isFailed = watch("isFailed");
  const isSuccess = watch("isSuccess");
  const [removeDetails, setRemoveDetails] = useState<IExpense[]>([]);
  const [selectedFilesExpense, setSelectedFilesExpense] = useState<any>([]);
  const [imagesExpense, setImagesExpense] = useState<any[]>([]);
  const [deleteImagesExpense, setDeleteImagesExpense] = useState<any>([]);
  const [openImageExpense, setOpenImageExpense] = useState(false);
  const [selectedImageExpense, setSelectedImageExpense] = useState<string | null>(null);
  const [pdfFilesExpense, setPdfFilesExpense] = useState<any[]>([]);
  const totalExpense = expenses?.reduce((acc: number, cur: IExpense) => {
    const accAmount = acc + (parseFloat(String(cur.details?.[0]?.amount)) || 0);
    return accAmount;
  }, 0) || 0;
  const [expenseDate, setExpenseDate] = useState(dayjs());
  const [openShippingType, setOpenShippingType] = useState(false);
  const [openConsignee, setOpenConsignee] = useState(false);
  const [name, setName] = useState("");

  const [selectedConsignee, setSelectedConsignee] = useState<IConsignee | null>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const reactToPrintFn = useReactToPrint({ contentRef });


  watch();

  const handleFileChange = (_: React.ChangeEvent<HTMLInputElement>, from: string = "shipping") => {
    const files = _.target.files;
    if (files) {
      if (from === "shipping") {
        setSelectedFiles([...selectedFiles, ...Array.from(files)]);
      }
      else {
        setSelectedFilesExpense([...selectedFilesExpense, ...Array.from(files)]);
      }
    }
  };

  const handleFileRemove = (index: number, from: string = "shipping") => {
    if (from === "shipping") {
      const _selectedFiles = [...selectedFiles];
      _selectedFiles.splice(index, 1);
      setSelectedFiles(_selectedFiles);
    }
    else {
      const _selectedFiles = [...selectedFilesExpense];
      _selectedFiles.splice(index, 1);
      setSelectedFilesExpense(_selectedFiles);
    }
  };

  const handleFileRemoveImageList = (file: any, from: string = "shipping") => {
    if (from === "shipping") {
      const _images = [...images];
      const index = _images.findIndex((img) => img.name === file.name);
      if (index > -1) {
        const deletedImage = _images[index];
        setDeleteImages([...deleteImages, { id: deletedImage.id, name: deletedImage.name }]);
        _images.splice(index, 1);
        setImages(_images);
      }
    } else {
      const _images = [...imagesExpense];
      const index = _images.findIndex((img) => img.name === file.name);
      if (index > -1) {
        const deletedImage = _images[index];
        setDeleteImagesExpense([...deleteImagesExpense, { id: deletedImage.id, name: deletedImage.name }]);
        _images.splice(index, 1);
        setImagesExpense(_images);
      }
    }
  };
  const handleFileRemovePdfList = (file: any, from: string = "shipping") => {
    if (from === "shipping") {
      const _pdfFiles = [...pdfFiles];
      const index = _pdfFiles.findIndex((pdf) => pdf.name === file.name);
      if (index > -1) {
        const deletedFile = _pdfFiles[index];
        setDeleteImages([...deleteImages, { id: deletedFile.id, name: deletedFile.name }]);
        _pdfFiles.splice(index, 1);
        setPdfFiles(_pdfFiles);
      }
    } else {
      const _pdfFilesExpense = [...pdfFilesExpense];
      const index = _pdfFilesExpense.findIndex((pdf) => pdf.name === file.name);
      if (index > -1) {
        const deletedFile = _pdfFilesExpense[index];
        setDeleteImagesExpense([...deleteImagesExpense, { id: deletedFile.id, name: deletedFile.name }]);
        _pdfFilesExpense.splice(index, 1);
        setPdfFilesExpense(_pdfFilesExpense);
      }
    }
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const onImageClick = (imageSrc: string, from: string = "shipping") => {
    if (from === "shipping") {
      setSelectedImage(imageSrc);
      setOpenImage(true);
    } else {
      setSelectedImageExpense(imageSrc);
      setOpenImageExpense(true);
    }
  };

  const onCreate = () => {
    const data = getValues();
    if (id) {
      onUpdate();
    } else { props.onCreate({ ...data }, selectedFiles, selectedFilesExpense); }
    reset();
    setImages([]);
    setPdfFiles([]);
    setSelectedFiles([]);
    setDeleteImages([]);
    setImagesExpense([]);
    setSelectedFilesExpense([]);
    setDeleteImagesExpense([]);
    setPdfFilesExpense([]);
  };

  const onUpdate = () => {
    const data = getValues();
    data.expenses = expenses ? [...expenses, ...removeDetails] : removeDetails;
    props.onUpdate({ ...data, id }, images, selectedFiles, deleteImages, imagesExpense, selectedFilesExpense, deleteImagesExpense);
    setImages([]);
    setPdfFiles([]);
    setSelectedFiles([]);
    setDeleteImages([]);
    setImagesExpense([]);
    setSelectedFilesExpense([]);
    setDeleteImagesExpense([]);
    setPdfFilesExpense([]);
  };

  const onDelete = () => {
    if (id) {
      props.onUpdate({ isDelete: true, id: id }, images, selectedFiles, deleteImages, imagesExpense, selectedFilesExpense, deleteImagesExpense);
    }
  };

  const onSubmit = handleSubmit(() => {
    const status = getValues("status") || "";
    if (status === "approved" || status === "rejected") {
      setOpenConfirm({
        open: true,
        title: status === "approved" ? "อนุมัติเอกสาร" : "ปฏิเสธเอกสาร",
        message: status === "approved" ? "คุณต้องการอนุมัติข้อมูลนี้ใช่หรือไม่" : "คุณต้องการปฏิเสธข้อมูลนี้ใช่หรือไม่",
        color: status === "approved" ? "primary" : "error",
        type: "approved",
      });
    }
    else {
      setOpenConfirm({
        open: true,
        title: "บันทึกข้อมูล",
        message: "คุณต้องการบันทึกข้อมูลนี้ใช่หรือไม่",
        color: "primary",
        type: "create",
      });
    }
  });

  const calTotalbox = (_box?: number, _index?: number) => {
    details?.forEach((detail: IShippingDetail, index: number) => {

      let totalBox = 0;
      const _totalBox = detail?.packaging?.details?.reduce((acc, cur) => {
        return acc + parseFloat((cur.totalBox || 0).toString());
      }, 0) || 0;
      if (_index === index) {
        totalBox = _box || 0;
      } else {
        totalBox = _totalBox || 0;
      }
      const _remain = totalBox > 0 ? totalBox : details?.[index]?.quantity;

      if (_remain && _remain > _totalBox) {
        setError(`details.${index}.quantity`, {
          type: "manual",
          message: "จำนวนสินค้าไม่เพียงพอ",
        });
      } else {
        clearErrors(`details.${index}.quantity`);
      }
      setValue(`details.${index}.quantity`, totalBox);
    });
    calTotalCTS();
  };

  const calTotalCTS = (deleteIndex?: number) => {
    const totalCTS = details?.reduce((acc, cur, index) => {
      if (index !== deleteIndex) {
        return acc + parseFloat((cur.quantity || 0).toString());
      } else {
        return acc;
      }
    }, 0);
    setSutiTotalBox(totalCTS || 0);
    calCostPerBox(totalCTS);
  };

  const totalAmount = watch('totalAmount');
  const calTotalAmount = (_totalAmonut?: number, _v7?: number, _v3?: number) => {
    const _nonVat = getValues("nonVat") || 0;
    const _vat = getValues("vat") || 0;
    const _vat7 = _v7 ? _v7 : (_vat * 0.07).toFixed(2) || 0;
    const _vat3 = _v3 ? _v3 : (_vat * 0.03).toFixed(2) || 0;
    const totalAmount = _totalAmonut ? _totalAmonut : Number(_nonVat) + Number(_vat) + (Number(_vat7) - Number(_vat3));
    setValue("vat7", Number(_vat7));
    setValue("vat3", Number(_vat3));
    setValue("totalAmount", totalAmount);
    const costPerBox = totalAmount / sutiTotalBox;
    setValue("costPerBox", costPerBox);
  };

  const calCostPerBox = (totalCTS?: number) => {
    const totalAmount = getValues("totalAmount") || 0;
    const costPerBox = totalAmount / (totalCTS || 1);
    setValue("costPerBox", (costPerBox !== Infinity && costPerBox !== -Infinity) ? costPerBox : 0);
  };

  const triggerValue = () => {
    trigger('nonVat');
    trigger('vat');
    trigger('vat7');
    trigger('vat3');
    trigger('totalAmount');
    trigger('costPerBox');
  };

  // ฟังก์ชันจัดการเปิด Tooltip
  const handleOpen = (index: number) => {
    setShowIndex(index);
  };

  // ฟังก์ชันจัดการปิด Tooltip
  const handleClose = () => {
    setShowIndex(null);
  };

  const handleRemove = (index: number, _expenseFields?: IExpense) => {
    if (_expenseFields) {
      _expenseFields.isDelete = true;
      setRemoveDetails([...removeDetails, _expenseFields]);
    }
    removeExpense(index);
  };

  useEffect(() => {
    if (id) {
      const { shipping } = props;
      reset(shipping);
      if (shipping?.expenses && shipping?.expenses?.length > 0) { setValue('expenses', shipping?.expenses); } else {
        setValue('expenses', defaultValuesExpense);
      }
      if (shipping?.order?.id) { props.onFetchPackaging(shipping?.order?.id); }
      setImages(shipping?.shippingImages || []);
      setImagesExpense(shipping?.expenseImages || []);
      setPdfFiles(shipping?.shippingFiles || []);
      setPdfFilesExpense(shipping?.expenseFiles || []);
      setSelectedConsignee(shipping?.consignee ?? null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, props.shipping]);


  return (
    <Box component={'div'}>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={1} sm={1} md={0.5} lg={0.5}>
          <IconButton
            aria-label="delete"
            onClick={() => navigate("/process-shipping")}
          >
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={8} sm={5} md={5} lg={5} display={"flex"} alignItems={"center"}>
          {/* NOTE: check from order or not */}
          {from === "order" ? <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Typography>Shipping</Typography>
            <Link component="button" onClick={() => navigate(`/process-order-edit/${order?.id}`)}>
              ใบคำสั่งซื้อ
            </Link>
            <Typography variant="h6" color="text.primary">
              {props.shipping?.id ? "แก้ไข" : "สร้าง"}
            </Typography>
          </Breadcrumbs> :
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Typography>Shipping</Typography>
              <Link component="button" onClick={() => navigate("/process-shipping")}>
                รายการ
              </Link>
              <Typography variant="h6" color="text.primary">
                {props.shipping?.id ? "แก้ไข" : "สร้าง"}
              </Typography>
            </Breadcrumbs>}
        </Grid>
        <Grid item xs={12} sm={3} md={3} lg={3} display={"flex"} justifyContent={"end"}>
          {props.shipping?.id && (
            <Button component="label" variant="outlined" onClick={() => { reactToPrintFn(); }} startIcon={<PrintIcon />}>
              พิมพ์เอกสาร
            </Button>
          )}
        </Grid>
        <Grid item xs={12} sm={3} md={3} lg={3} display={"flex"} justifyContent={"end"}>
          {(props.shipping && props.permission.delete && props.shipping.id) && (
            <Button
              variant="contained"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={() => {
                setOpenConfirm({
                  open: true,
                  title: "ลบข้อมูล",
                  message: "คุณต้องการลบข้อมูลนี้ใช่หรือไม่",
                  color: "error",
                  type: "delete",
                });
              }}
            >
              ลบข้อมูล
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Card>
            <CardContent>
              <Box component="form">
                <Grid container spacing={2} >
                  <Grid item xs={12} sm={12} md={12} lg={12} alignSelf={'center'}>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography variant="h6">Shipping</Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <FormControl fullWidth>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Controller
                          name="docDate"
                          control={control}
                          render={({ field }) => (
                            <DatePicker
                              disabled={isDisabled}
                              slotProps={{
                                textField: {
                                  variant: 'outlined',
                                  error: !!errors.docDate,
                                  helperText: errors.docDate ? "กรุณาระบุวันที่เอกสาร" : "",
                                }
                              }}
                              label="วันที่เอกสาร"
                              format="DD/MM/YYYY"
                              {...field}
                              defaultValue={dayjs(new Date())}
                              value={field.value ? dayjs(field.value) : dayjs(new Date())}
                              onChange={(date) => {
                                field.onChange(date ? date.format('YYYY-MM-DD') : null);
                              }}

                            />
                          )}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={2} alignSelf={'center'}>
                    <Controller
                      name="order"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          disabled={isDisabled}
                          value={field?.value as IRefOrder || null}
                          options={props.order || []}
                          getOptionLabel={(option: any) => {
                            if (typeof option === 'string') {
                              return option;
                            }
                            if (option.inputValue) {
                              return option?.inputValue;
                            }
                            return option?.invoiceNo || "";
                          }}
                          isOptionEqualToValue={(option, value) => option?.id === value?.id}
                          onChange={(_, newValue) => {
                            field.onChange(newValue);
                            if (newValue?.id) {
                              props.onFetchPackaging(newValue?.id);
                              const _defaultValues = [defaultValues] as IShippingDetail[];
                              setValue("details", _defaultValues);
                            }
                          }}
                          renderInput={(params) => <TextField
                            {...params}
                            error={!!errors.order}
                            helperText={errors.order ? "กรุณาเลือกโปรเจกต์" : ""}
                            label="ชื่อโปรเจกต์" />}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={2} alignSelf={'center'}>
                    <Controller
                      name="type"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          disabled={isDisabled}
                          value={field?.value as IShippingTypeWithValue || null}
                          options={props.shippingTypes || []}
                          getOptionLabel={(option: any) => {
                            if (typeof option === 'string') {
                              return option;
                            }
                            if (option.inputValue) {
                              return option?.inputValue;
                            }
                            return option?.name || "";
                          }}
                          isOptionEqualToValue={(option, value) => option?.id === value?.id}
                          onChange={(_, newValue: IShippingTypeWithValue | null) => {
                            if (String(newValue?.id) === "create") {
                              setOpenShippingType(true);
                              if (newValue?.value) { setName(newValue?.value); }
                            } else if (String(newValue?.id) !== "create") {
                              field.onChange(newValue);
                            }
                          }}
                          filterOptions={(options, params) => {
                            const filtered = filterOptions(options, params);
                            if (params.inputValue !== '') {
                              filtered.push({
                                name: `สร้าง '${params.inputValue}'`,
                                id: 'create',
                                value: params.inputValue
                              });
                            }
                            return filtered;
                          }}
                          renderOption={(props, option) => {
                            const { key, ...rest } = props;
                            return (
                              <Typography key={key} {...rest} variant="body2" >{String(option.id) === "create" ? <Box component={'span'} sx={{ color: "primary.main", display: "flex", alignItems: "center" }}>
                                <AddCircleIcon /><Box component={'span'} ml={1}> {option.name}</Box>
                              </Box> : option.name}</Typography>
                            );
                          }}
                          renderInput={(params) => <TextField
                            {...params}
                            error={!!errors.type}
                            helperText={errors.type ? "กรุณาเลือกประเภทการขนส่ง" : ""}
                            label="ประเภทการขนส่ง" />}
                        />
                      )}
                    />
                  </Grid>


                  {(userGroup?.canCost || userGroup?.canFinance) &&
                    (<>
                      <Grid item xs={12} sm={12} md={12} lg={2} alignSelf={'center'}>
                        <Controller
                          name="nonVat"
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <TextField
                              label="NonVat"
                              fullWidth
                              type="number"
                              value={field.value || undefined}
                              helperText={errors?.nonVat ? "กรุณาระบุ NonVat" : ""}
                              error={!!errors?.nonVat}
                              InputLabelProps={{
                                shrink: getValues("nonVat") ? true : undefined,
                              }}
                              onChange={e => {
                                const _value = parseFloat(e.target.value);
                                field.onChange(_value);
                                calTotalAmount();
                                trigger('totalAmount');
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={2} alignSelf={'center'}>
                        <Controller
                          name="vat"
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <TextField label="Vat"
                              fullWidth
                              type="number"
                              value={(getValues("vat")) || undefined}
                              InputLabelProps={{
                                shrink: getValues("vat") ? true : undefined,
                              }}
                              helperText={errors?.vat ? "กรุณาระบุ Vat" : ""}
                              error={!!errors?.vat}
                              onChange={e => {
                                const _value = parseFloat(e.target.value);
                                field.onChange(_value);
                                calTotalAmount();
                                triggerValue();
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={2} alignSelf={'center'}>
                        <Controller
                          name="vat7"
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <TextField label="Vat7"
                              fullWidth
                              type="number"
                              value={field.value || undefined}
                              InputLabelProps={{
                                shrink: getValues('vat7') ? true : undefined,
                              }}
                              helperText={errors?.vat7 ? "กรุณาระบุ Vat7" : ""}
                              error={!!errors?.vat7}
                              onChange={e => {
                                const _value = parseFloat(e.target.value);
                                field.onChange(_value);
                                calTotalAmount();
                                triggerValue();
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={2} alignSelf={'center'}>
                        <Controller
                          name="vat3"
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <TextField label="Vat3"
                              fullWidth
                              type="number"
                              value={field.value || undefined}
                              InputLabelProps={{
                                shrink: getValues('vat3') ? true : undefined,
                              }}
                              helperText={errors?.vat3 ? "กรุณาระบุ Vat3" : ""}
                              error={!!errors?.vat3}
                              onChange={e => {
                                const _value = parseFloat(e.target.value);
                                field.onChange(_value);
                                calTotalAmount();
                                triggerValue();
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={2} alignSelf={'center'}>
                        <Controller
                          name="totalAmount"
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <TextField label="จำนวนเงินทั้งหมด"
                              fullWidth
                              type="number"
                              value={field.value || undefined}
                              InputLabelProps={{
                                shrink: totalAmount ? true : undefined,
                              }}
                              helperText={errors?.totalAmount ? "กรุณาระบุจำนวนเงินทั้งหมด" : ""}
                              error={!!errors?.totalAmount}
                              onChange={e => {
                                const _value = parseFloat(e.target.value);
                                field.onChange(_value);
                                calTotalAmount(_value);
                              }}
                            />
                          )}
                        />
                      </Grid>
                    </>)
                  }
                  {
                    userGroup?.canFinance && (
                      <Grid item xs={12} sm={12} md={12} lg={2} alignSelf={'center'}>
                        <Controller
                          name="costPerBox"
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <TextField label="ราคาต่อกล่อง"
                              fullWidth
                              type="number"
                              value={field.value || undefined}
                              helperText={errors?.costPerBox ? "กรุณาระบุราคาต่อกล่อง" : ""}
                              error={!!errors?.costPerBox}
                              InputLabelProps={{
                                shrink: getValues("costPerBox") ? true : undefined,
                              }}
                              onChange={e => {
                                const _value = parseFloat(e.target.value);
                                field.onChange(_value);
                              }}
                            />
                          )}
                        />
                      </Grid>
                    )
                  }

                  <Grid item xs={0} sm={0} md={0} lg={8} >
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                  </Grid>



                  <Grid item xs={12} sm={12} md={12} lg={4} alignSelf={'center'}>
                    <Controller
                      name="consignee"
                      control={control}
                      // rules={{ required: true }}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          disabled={isDisabled}
                          value={field?.value as IConsigneeWithValue || null}
                          options={props.consignees || []}
                          getOptionLabel={(option: any) => {
                            if (typeof option === 'string') {
                              return option;
                            }
                            if (option.inputValue) {
                              return option?.inputValue;
                            }
                            return option?.name || "";
                          }}
                          isOptionEqualToValue={(option, value) => option?.id === value?.id}
                          onChange={(_, newValue: IConsigneeWithValue | null) => {
                            if (String(newValue?.id) === "create") {
                              setOpenConsignee(true);
                              if (newValue?.value) { setName(newValue?.value); }
                            } else if (String(newValue?.id) !== "create") {
                              field.onChange(newValue);
                              setSelectedConsignee(newValue as IConsignee || null);
                            }
                          }}
                          filterOptions={(options, params) => {
                            const filtered = filterOptions(options, params);
                            if (params.inputValue !== '') {
                              filtered.push({
                                name: `สร้าง '${params.inputValue}'`,
                                id: 'create',
                                value: params.inputValue
                              });
                            }
                            return filtered;
                          }}
                          renderOption={(props, option) => {
                            const { key, ...rest } = props;
                            return (
                              <Typography key={key} {...rest} variant="body2" >{String(option.id) === "create" ? <Box component={'span'} sx={{ color: "primary.main", display: "flex", alignItems: "center" }}>
                                <AddCircleIcon /><Box component={'span'} ml={1}> {option.name}</Box>
                              </Box> : option.name}</Typography>
                            );
                          }}
                          renderInput={(params) => <TextField
                            {...params}
                            error={!!errors.consignee}
                            helperText={errors.consignee ? "กรุณาเลือกผู้รับสินค้า" : ""}
                            label="ผู้รับสินค้า" />}
                        />
                      )}
                    />
                  </Grid>





                  <Grid item xs={12} sm={12} md={12} lg={2} alignSelf={'center'}>
                    <Controller
                      name={'date'}
                      control={control}
                      render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'th'}>
                          <DatePicker sx={{ width: "100%" }}
                            slotProps={{
                              textField: {
                                variant: 'outlined',
                                error: !!errors.date,
                                helperText: errors.date ? "กรุณาระบุวันที่" : "",
                              }
                            }}
                            defaultValue={dayjs(new Date())}
                            label="วันที่"
                            value={field.value ? dayjs(field.value) : dayjs()}
                            onChange={(newValue: any) => {
                              field.onChange(newValue);
                            }}
                          />
                        </LocalizationProvider>
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={2} alignSelf={'center'}>
                    <Controller
                      name="invoiceNo"
                      control={control}
                      // rules={{ required: true }}
                      render={({ field }) => (
                        <TextField label="Invoice No."
                          fullWidth
                          {...register("invoiceNo")}
                          value={(getValues("invoiceNo")) || undefined}
                          helperText={errors?.docNo ? "กรุณาระบุ Invoice No." : ""}
                          error={!!errors?.docNo}
                          InputLabelProps={{
                            shrink: getValues("invoiceNo") ? true : undefined,
                          }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={2} alignSelf={'center'}>
                    <Controller
                      name="shipFrom"
                      control={control}
                      // rules={{ required: true }}
                      render={({ field }) => (
                        <TextField label="Ship From"
                          fullWidth
                          {...register("shipFrom")}
                          value={(getValues("shipFrom")) || undefined}
                          helperText={errors?.shipFrom ? "กรุณาระบุ Ship From" : ""}
                          error={!!errors?.shipFrom}
                          InputLabelProps={{
                            shrink: getValues("shipFrom") ? true : undefined,
                          }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={2} alignSelf={'center'}>
                    <Controller
                      name="shipTo"
                      control={control}
                      // rules={{ required: true }}
                      render={({ field }) => (
                        <TextField label="Ship To"
                          fullWidth
                          {...register("shipTo")}
                          value={(getValues("shipTo")) || undefined}
                          helperText={errors?.shipTo ? "กรุณาระบุ Ship To" : ""}
                          error={!!errors?.shipTo}
                          InputLabelProps={{
                            shrink: getValues("shipTo") ? true : undefined,
                          }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={4}>
                    <Box
                      sx={{
                        border: '1px solid #ddd',
                        borderRadius: '8px',
                        padding: '20px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '24px',
                        maxHeight: '300px',
                        overflowY: 'auto',
                      }}
                    >
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={4}>
                          <Typography
                            variant="body1"
                            sx={{
                              wordWrap: 'break-word',
                              wordBreak: 'break-word',
                            }}
                          >
                            <span style={{ fontWeight: 'bold' }}>เบอร์โทร:</span> {selectedConsignee?.tel || '-'}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <Typography
                            variant="body1"
                            sx={{
                              wordWrap: 'break-word',
                              wordBreak: 'break-word',
                            }}
                          >
                            <span style={{ fontWeight: 'bold' }}>PIC:</span> {selectedConsignee?.pic || '-'}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                          <Typography
                            variant="body1"
                            sx={{
                              wordWrap: 'break-word',
                              wordBreak: 'break-word',
                            }}
                          >
                            <span style={{ fontWeight: 'bold' }}>ที่อยู่:</span> {selectedConsignee?.address || '-'}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={2} alignSelf={'flex-start'}>
                    <Controller
                      name="paymentBy"
                      control={control}
                      // rules={{ required: true }}
                      render={({ field }) => (
                        <TextField label="Payment By"
                          fullWidth
                          {...register("paymentBy")}
                          value={(getValues("paymentBy")) || undefined}
                          helperText={errors?.paymentBy ? "กรุณาระบุ Payment By" : ""}
                          error={!!errors?.paymentBy}
                          InputLabelProps={{
                            shrink: getValues("paymentBy") ? true : undefined,
                          }}
                        />
                      )}
                    />
                  </Grid>


                  <Grid item xs={12} sm={12} md={12} lg={2} alignSelf={'flex-start'}>
                    <Controller
                      name="flightNo"
                      control={control}
                      // rules={{ required: true }}
                      render={({ field }) => (
                        <TextField label="Flight No."
                          fullWidth
                          {...register("flightNo")}
                          value={(getValues("flightNo")) || undefined}
                          helperText={errors?.flightNo ? "กรุณาระบุ Flight No." : ""}
                          error={!!errors?.flightNo}
                          InputLabelProps={{
                            shrink: getValues("flightNo") ? true : undefined,
                          }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={2} alignSelf={'flex-start'}>
                    <Controller
                      name="docNo"
                      control={control}
                      // rules={{ required: true }}
                      render={({ field }) => (
                        <TextField label="AWB No."
                          fullWidth
                          {...register("docNo")}
                          value={(getValues("docNo")) || undefined}
                          helperText={errors?.docNo ? "กรุณาระบุ AWB No." : ""}
                          error={!!errors?.docNo}
                          InputLabelProps={{
                            shrink: getValues("docNo") ? true : undefined,
                          }}
                        />
                      )}
                    />
                  </Grid>



                  <Grid item xs={12} sm={12} md={12} lg={12} alignSelf={'center'} mt={2}>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography variant="subtitle1">จำนวนบรรจุ</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  {
                    details && details?.length > 0 && details.map((field, index) => (
                      <React.Fragment key={`${field.id}`}>
                        {!isMobile && (
                          <Grid item xs={0.5} sm={0.5} md={0.5} lg={0.5} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                            <IconButton
                              color="error"
                              disabled={isDisabled}
                              onClick={() => {
                                removeDetail(index);
                                calTotalCTS(index);
                              }}
                            >
                              <RemoveCircleIcon />
                            </IconButton>
                          </Grid>
                        )}
                        <Grid item xs={12} sm={12} md={12} lg={11} alignSelf={'center'}>
                          <Grid container spacing={2}>
                            {isMobile && (
                              <Grid item xs={12} sm={12} md={12} lg={12} alignSelf={'center'}>
                                <Typography variant="h6">{`ลำดับที่ ${index + 1}`}</Typography>
                              </Grid>
                            )}
                            <Grid item xs={12} sm={12} md={4} lg={2.5} >
                              <Controller
                                name={`details.${index}.etdDate`}
                                control={control}
                                render={({ field }) => (
                                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'th'}>
                                    <DemoContainer components={['DatePicker', 'DatePicker']} >
                                      <DatePicker sx={{ width: "100%" }}
                                        slotProps={{
                                          textField: {
                                            variant: 'outlined',
                                            error: !!errors.details?.[index]?.etdDate,
                                            helperText: errors.details?.[index]?.etdDate ? "กรุณาระบุวันที่ ETD" : "",
                                          }
                                        }}
                                        defaultValue={dayjs(new Date())}
                                        label="วันที่ ETD"
                                        value={field.value ? dayjs(field.value) : dayjs()}
                                        onChange={(newValue: any) => {
                                          field.onChange(newValue);
                                        }}
                                      />
                                    </DemoContainer>
                                  </LocalizationProvider>
                                )}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={2.5}>
                              <Controller
                                name={`details.${index}.etaDate`}
                                control={control}
                                render={({ field }) => (
                                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'th'}>
                                    <DemoContainer components={['DatePicker', 'DatePicker']} >
                                      <DatePicker sx={{ width: "100%" }}
                                        slotProps={{
                                          textField: {
                                            variant: 'outlined',
                                            error: !!errors.details?.[index]?.etaDate,
                                            helperText: errors.details?.[index]?.etaDate ? "กรุณาระบุวันที่ ETA" : "",
                                          }
                                        }}
                                        defaultValue={dayjs(new Date())}
                                        label="วันที่ ETA"
                                        value={field.value ? dayjs(field.value) : dayjs()}
                                        onChange={(newValue: any) => {
                                          field.onChange(newValue);
                                        }}
                                      />
                                    </DemoContainer>
                                  </LocalizationProvider>
                                )}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} alignSelf={'center'}>
                              <Grid container>
                                <Grid item xs={12} sm={12} md={12} lg={12} alignSelf={'center'} mt={1}>
                                  <Controller
                                    name={`details.${index}.packaging`}
                                    control={control}
                                    disabled={!order}
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                      <Autocomplete
                                        {...field}
                                        disabled={isDisabled}
                                        value={field?.value || null}
                                        options={props.packaging || []}
                                        getOptionLabel={(option: IPackagingExpense) => {
                                          if (typeof option === 'string') {
                                            return option;
                                          }
                                          return option?.docNo || "";
                                        }}
                                        isOptionEqualToValue={(option: any, value) => option?.id === value?.id}
                                        onChange={(_, newValue) => {
                                          field.onChange(newValue);
                                          calTotalbox();
                                        }}
                                        renderInput={(params) => <TextField
                                          error={!!errors.details?.[index]?.packaging}
                                          helperText={
                                            errors.details?.[index]?.packaging?.message ? errors.details?.[index]?.packaging?.message : (errors.details?.[index]?.packaging ? "กรุณาเลือกเอกสารการแพ็ค" : "")
                                          }
                                          {...params} label={"เอกสารการแพ็ค"} />}
                                      />
                                    )}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={3} mt={1}>
                              <Controller
                                name={`details.${index}.quantity`}
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                  <TextField
                                    fullWidth
                                    label="จํานวนส่งออก (กล่อง)"
                                    type="number"
                                    {...field}
                                    disabled={isDisabled}
                                    value={field.value || undefined}
                                    error={!!errors.details?.[index]?.quantity}
                                    helperText={errors.details?.[index]?.quantity?.message ? errors.details?.[index]?.quantity?.message : errors.details?.[index]?.quantity ? "กรุณาระบุจํานวนส่งออก" : ""}
                                    InputLabelProps={{ shrink: field.value ? true : undefined }}
                                    onChange={(e) => {
                                      const value = parseFloat(e.target.value) || 0;
                                      field.onChange(e.target.value);
                                      calTotalbox(value, index);
                                      trigger('costPerBox');
                                    }}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} alignItems={'center'} justifyContent="center" >
                              {details?.[index]?.packaging?.details?.map((item, i) => {
                                return <Grid container key={`item-${i}`} alignItems="center" justifyContent="end" sx={{ fontSize: '0.5rem' }}>
                                  <Grid item xs={12} sm={12} md={6} lg={6} mt={-1}>
                                    <Grid container key={`item-${i}`} textAlign={'center'} justifyContent="start" alignItems="center" >
                                      {i === 0 &&
                                        <>
                                          <Grid item xs={3} sm={3} md={3} lg={3}>
                                            <Typography key={i} variant="subtitle2">บรรจุภัณฑ์</Typography>
                                          </Grid>
                                          <Grid item xs={3} sm={3} md={3} lg={3}>
                                            <Typography key={i} variant="subtitle2">CBM</Typography>
                                          </Grid>
                                          <Grid item xs={3} sm={3} md={3} lg={3}>
                                            <Typography key={i} variant="subtitle2">NW</Typography>
                                          </Grid>
                                          <Grid item xs={3} sm={3} md={3} lg={3}>
                                            <Typography key={i} variant="subtitle2">GW</Typography>
                                          </Grid>
                                        </>
                                      }
                                      <Grid item xs={3} sm={3} md={3} lg={3}>
                                        <Grid container justifyContent="start" alignItems="center" >
                                          <Grid item xs={0.2} sm={0.2} md={1} lg={1}>
                                            <CircleIcon fontSize="inherit" />
                                          </Grid>
                                          <Grid item xs={11} sm={11} md={11} lg={11}>
                                            <Tooltip
                                              title={item?.group?.mainMaterial?.name || ''}
                                              open={showIndex === i}
                                              placement="bottom"
                                              arrow
                                              slotProps={{
                                                popper: {
                                                  sx: {
                                                    [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                                                    {
                                                      marginTop: '0px',
                                                    },
                                                    [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
                                                    {
                                                      marginBottom: '0px',
                                                    },
                                                    [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]:
                                                    {
                                                      marginLeft: '0px',
                                                    },
                                                    [`&.${tooltipClasses.popper}[data-popper-placement*="left"] .${tooltipClasses.tooltip}`]:
                                                    {
                                                      marginRight: '0px',
                                                    },
                                                  },
                                                },
                                              }}
                                            >
                                              <Button color="inherit" disableRipple onClick={(e) => {
                                                if (showIndex === i) {
                                                  handleClose();
                                                } else { handleOpen(i); }
                                              }} >
                                                <Typography key={i} variant="caption">
                                                  {isMobile && item?.group?.mainMaterial?.name && item?.group?.mainMaterial?.name?.length > 7
                                                    ? `${item?.group?.mainMaterial?.name?.substring(0, 7)}...`
                                                    : item?.group?.mainMaterial?.name || ''}
                                                </Typography>
                                              </Button>
                                            </Tooltip>

                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      <Grid item xs={3} sm={3} md={3} lg={3}>
                                        <Typography key={i} variant="caption">{item?.group?.mainMaterial?.CBM}</Typography>
                                      </Grid>
                                      <Grid item xs={3} sm={3} md={3} lg={3}>
                                        <Typography key={i} variant="caption">{`${item?.netWeightFrom} - ${item?.netWeightTo}`}</Typography>
                                      </Grid>
                                      <Grid item xs={3} sm={3} md={3} lg={3}>
                                        <Typography key={i} variant="caption">{`${item?.grossWeightFrom} - ${item?.grossWeightTo}`}</Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>;
                              })

                              }
                            </Grid>

                          </Grid>
                        </Grid>
                        {index !== details?.length - 1 && (
                          <Grid item xs={12} sm={12} md={12} lg={12} alignSelf={'center'}>
                            <Divider />
                          </Grid>
                        )}
                      </React.Fragment>
                    ))
                  }
                  <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
                    <Grid container spacing={2}>
                      {isMobile ? <>
                        <Grid item xs={4} sm={4} >
                          <Button
                            variant="outlined"
                            color="error"
                            disabled={isDisabled}
                            sx={{ width: '100%' }}
                            type="button"
                            startIcon={<RemoveCircleIcon />}
                            onClick={() => {
                              removeDetail(detailFields?.length - 1);
                              calTotalCTS(detailFields?.length - 1);
                            }
                            }
                          >
                            ลบ
                          </Button>
                        </Grid>
                        <Grid item xs={8} sm={8} >
                          <Button
                            variant="outlined"
                            sx={{ width: '100%' }}
                            type="button"
                            disabled={isDisabled}
                            startIcon={<AddCircleIcon />}
                            onClick={() => {
                              appendDetail(defaultValues);
                            }
                            }
                          >
                            เพิ่มรายการ
                          </Button>
                        </Grid></> : <Grid item md={12} lg={12}>
                        <Button
                          variant="outlined"
                          sx={{ width: '100%' }}
                          type="button"
                          disabled={isDisabled}
                          startIcon={<AddCircleIcon />}
                          onClick={() => {
                            appendDetail(defaultValues);
                          }}
                        >
                          เพิ่มรายการ
                        </Button>
                      </Grid>
                      }
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
                    <Divider />
                  </Grid>
                </Grid>
                <Grid container spacing={2} mt={3}>
                  <Grid item xs={12} sm={8} md={8} lg={8}>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={12} alignSelf={'center'}>
                        <TextField label="หมายเหตุ"
                          {...register("remark")}
                          disabled={isDisabled}
                          InputLabelProps={{
                            shrink: getValues("remark") ? true : undefined,
                          }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mt: 2 }}>
                        <Typography>เอกสาร</Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Button component="label" variant="outlined" disabled={isDisabled} startIcon={<UploadFileIcon />}>
                          เลือกไฟล์
                          <VisuallyHiddenInput type="file" accept=".jpg, .jpeg, .png, .pdf" multiple onChange={handleFileChange} />
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography variant="caption">รองรับไฟล์นามสกุล .jpg, .jpeg, .png, .pdf</Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        {selectedFiles?.length > 0 && (
                          <Box component={'div'} mt={1}>
                            {selectedFiles?.map((file: any, index: number) => (
                              <Grid item xs={12} sm={12} md={12} key={`sf-${index.toString()}`} mt={1}>
                                <Grid container >
                                  <Grid item xs={1} sm={1} md={1} lg={1} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                                    <IconButton size="small" color="error" disabled={isDisabled} onClick={() => handleFileRemove(index)}>
                                      <RemoveCircleIcon />
                                    </IconButton>
                                  </Grid>
                                  <Grid item xs={11} sm={11} md={11} lg={11}>
                                    {file.type === 'application/pdf' ? (
                                      <Typography variant="body2">
                                        <a
                                          href={URL.createObjectURL(file)}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          style={{ color: "inherit", cursor: "pointer" }}
                                        >
                                          {file.name}
                                        </a>
                                      </Typography>
                                    ) : (
                                      <img
                                        src={URL.createObjectURL(file)}
                                        alt={`ตัวอย่างไฟล์ ${index + 1}`}
                                        style={{ maxWidth: "50%", height: "auto", cursor: "pointer" }}
                                        onClick={() => onImageClick(URL.createObjectURL(file))}
                                      />
                                    )}
                                  </Grid>
                                </Grid>
                              </Grid>
                            ))}
                          </Box>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        {images && (
                          <Box component={'div'} mt={1}>
                            {images.map((file: any, index: number) => (
                              <Grid item xs={12} sm={12} md={12} key={`im-${index.toString()}`} mt={1}>
                                <Grid container >
                                  <Grid item xs={1} sm={1} md={1} lg={1} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                                    <IconButton size="small"
                                      color="error"
                                      disabled={isDisabled}
                                      onClick={() => handleFileRemoveImageList(file)}>
                                      <RemoveCircleIcon />
                                    </IconButton>
                                  </Grid>
                                  <Grid item xs={11} sm={11} md={11} lg={11}>
                                    <img
                                      src={`${process.env.REACT_APP_API_URL}/uploads/${(file).name
                                        }`}
                                      alt="Selected"
                                      style={{ maxWidth: "50%", height: "auto", cursor: 'pointer' }}
                                      onClick={() => onImageClick(`${process.env.REACT_APP_API_URL}/uploads/${(file).name}`)}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            ))}
                          </Box>
                        )}
                      </Grid>

                      <Grid item xs={12}>
                        {pdfFiles.length > 0 && (
                          <Box component={'div'} mt={1}>
                            {pdfFiles.map((file: any, index: number) => (
                              <Grid item xs={12} key={`pdf-${index}`} mt={1}>
                                <Grid container>
                                  <Grid item xs={1} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                    <IconButton size="small" color="error" onClick={() => handleFileRemovePdfList(file)}>
                                      <RemoveCircleIcon />
                                    </IconButton>
                                  </Grid>
                                  <Grid item xs={11}>
                                    <a
                                      href={`${process.env.REACT_APP_API_URL}/uploads/${file.name}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      style={{ color: "inherit", cursor: "pointer" }}
                                    >
                                      {file.originalName}
                                    </a>
                                  </Grid>
                                </Grid>
                              </Grid>
                            ))}
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} justifyContent={'flex-end'}>
                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Typography >จำนวนทั้งหมด (CTS) </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6} textAlign={'end'}>
                        <Typography >
                          {`${thaiFormatNumber(parseFloat(sutiTotalBox.toString()).toFixed(2))} (กล่อง)`}
                        </Typography>
                      </Grid>
                      {userGroup?.canFinance &&
                        (<>
                          <Grid item xs={6} sm={6} md={6} lg={6}>
                            <Typography >ราคาต่อกล่อง</Typography>
                          </Grid>
                          <Grid item xs={6} sm={6} md={6} lg={6} textAlign={'end'}>
                            <Typography >
                              {`${thaiFormatNumber(parseFloat(((sutiCostPerBox !== Infinity && sutiCostPerBox !== -Infinity) ? (sutiCostPerBox || 0) : 0).toString()).toFixed(2))} (บาท)`}
                            </Typography>
                          </Grid>
                        </>)
                      }

                    </Grid>
                  </Grid>
                  {id &&
                    <>
                      <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
                        <Divider />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12} lg={12} container justifyContent={'flex-end'}>
                        <RadioGroup
                          row
                          onChange={(e) => {
                            if (e.target.value === "isSuccess") {
                              setValue("isSuccess", true);
                              setValue("isFailed", false);
                            } else {
                              setValue("isSuccess", false);
                              setValue("isFailed", true);
                            }
                          }}
                          value={isSuccess ? "isSuccess" : isFailed ? "isFailed" : ""}

                        >
                          <FormControlLabel
                            value="isSuccess"
                            control={<Radio disabled={isDisabled} />}
                            label="การขนส่งสําเร็จ"
                          />
                          <FormControlLabel
                            value="isFailed"
                            control={<Radio disabled={isDisabled} />}
                            label="การขนส่งล้มเหลว"
                          />
                        </RadioGroup>

                      </Grid>
                      {(isFailed || isSuccess) && (
                        <>
                          <Grid item xs={12} sm={12} md={12} lg={12} alignSelf={'center'}>
                            <Grid container>
                              <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Typography variant="h6">ค่าใช้จ่าย</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={4}>
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              adapterLocale={'th'}
                            >
                              <DemoContainer components={['DatePicker', 'DatePicker']} >
                                <DatePicker
                                  sx={{ width: "100%" }}
                                  slotProps={{
                                    textField: {
                                      variant: 'outlined',
                                      helperText: expenseDate ? "" : "กรุณาระบุวันที่เอกสาร",
                                    }
                                  }}
                                  label="วันที่เอกสาร"
                                  value={expenseDate}
                                  onChange={(newValue: any) => {
                                    setExpenseDate(newValue);
                                  }}
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography>รายการค่าใช้จ่าย</Typography>
                          </Grid>
                          {
                            !isMobile && (
                              <Grid item xs={12}>
                                <Grid container spacing={2}>
                                  <Grid
                                    item
                                    xs={0.5}
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                    display={"flex"}
                                  ></Grid>
                                  <Grid
                                    item
                                    xs={0.5}
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                    display={"flex"}
                                  >
                                    <Typography>ลำดับ</Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            )
                          }
                          {
                            expenses.map((data: IExpense, indexExpense: number) => (
                              <Grid item xs={12} sm={12} md={12} lg={12} key={`full-grid-${data.id}`}>
                                <Grid container spacing={2} alignItems={"center"} key={`container-${data.id}`}>
                                  {
                                    !isMobile && (
                                      <Grid item xs={1} sm={1} md={1} lg={1}>
                                        <Grid container >
                                          <Grid item xs={6} sm={6} md={6} lg={6} mb={1.5} textAlign={"center"}>
                                            <IconButton
                                              aria-label={`delete-${indexExpense}`}
                                              size="small"
                                              color="error"
                                              onClick={() => {
                                                handleRemove(indexExpense, data);
                                              }}
                                            >
                                              <RemoveCircleIcon />
                                            </IconButton>
                                          </Grid>
                                          <Grid item xs={6} sm={6} md={6} lg={6} mb={2} textAlign={"center"}>
                                            <Typography>{indexExpense + 1}</Typography>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    )
                                  }
                                  {
                                    isMobile && (
                                      <Grid item xs={12} sm={2} md={2} lg={2} key={`{order-${indexExpense}}`}>
                                        <Typography>รายการที่ {indexExpense + 1}</Typography>
                                      </Grid>
                                    )
                                  }
                                  <Grid item xs={12} sm={12} md={4} lg={4} >
                                    <Controller
                                      name={`expenses.${indexExpense}.details.${0}.name`}
                                      control={control}
                                      render={({ field }) => (
                                        <TextField
                                          label="รายการ"
                                          variant="outlined"
                                          fullWidth
                                          value={field.value}
                                          onChange={field.onChange}
                                          error={!!errors.expenses?.[indexExpense]?.details?.[0]?.name}
                                          helperText={errors?.expenses?.[indexExpense]?.details?.[0]?.name ? "กรุณาระบุรายการ" : ""}
                                        />
                                      )}
                                    />

                                  </Grid>
                                  <Grid item xs={6} sm={5} md={3} lg={3} >
                                    <Controller
                                      name={`expenses.${indexExpense}.type`}
                                      control={control}
                                      render={({ field }) => (
                                        <Autocomplete
                                          {...field}
                                          value={field?.value as IExpenseTypeWithValue | null}
                                          options={props.typeExpenseOptions || []}
                                          getOptionLabel={(option: any) => {
                                            if (typeof option === 'string') {
                                              return option;
                                            }
                                            if (option.inputValue) {
                                              return option.inputValue;
                                            }
                                            return option.name || "";
                                          }}
                                          isOptionEqualToValue={(option, value) => option.id === value?.id}
                                          onChange={(_, newValue: IExpenseTypeWithValue | null) => {
                                            if (String(newValue?.id) === "create") {
                                              setOpenShippingType(true);
                                              if (newValue?.value) { setName(newValue?.value); }
                                            } else if (String(newValue?.id) !== "create") {
                                              field.onChange(newValue);
                                            }
                                          }}
                                          filterOptions={(options, params) => {
                                            const filtered = filterOptions(options, params);
                                            if (params.inputValue !== '') {
                                              filtered.push({
                                                name: `สร้าง '${params.inputValue}'`,
                                                id: 'create',
                                                value: params.inputValue
                                              });
                                            }
                                            return filtered;
                                          }}
                                          renderOption={(props, option) => {
                                            const { key, ...rest } = props;
                                            return (
                                              <Typography key={key} {...rest} variant="body2" >{String(option.id) === "create" ? <Box component={'span'} sx={{ color: "primary.main", display: "flex", alignItems: "center" }}>
                                                <AddCircleIcon /><Box component={'span'} ml={1}> {option.name}</Box>
                                              </Box> : option.name}</Typography>
                                            );
                                          }}
                                          renderInput={(params) => <TextField
                                            error={!!errors.expenses?.[indexExpense]?.type}
                                            helperText={errors?.expenses?.[indexExpense]?.type ? "กรุณาระบุประเภทค่าใช้จ่าย" : ""}
                                            {...params} label="ประเภทค่าใช้จ่าย" />}
                                        />
                                      )}
                                    />
                                  </Grid>
                                  <Grid item xs={6} sm={3} md={3} lg={2}>
                                    <Controller
                                      name={`expenses.${indexExpense}.details.${0}.amount`}
                                      control={control}
                                      render={({ field }) => (
                                        <TextField
                                          label="จำนวนเงิน"
                                          variant="outlined"
                                          fullWidth
                                          value={field.value}
                                          onChange={(e) => {
                                            field.onChange(e.target.value);
                                          }}
                                          type="number"
                                          error={!!errors.expenses?.[indexExpense]?.details?.[0]?.amount}
                                          helperText={errors?.expenses?.[indexExpense]?.details?.[0]?.amount ? "กรุณาระบุจำนวนเงิน" : ""}
                                        />
                                      )}
                                    />
                                  </Grid>
                                  <Grid item xs={6} sm={4} md={4} lg={2} alignSelf={'center'}>
                                    <FormControl fullWidth>
                                      <Box
                                        display="flex"
                                        justifyContent={"flex-start"}
                                        alignItems="center">
                                        <FormControlLabel
                                          control={
                                            <Checkbox color="primary" checked={watch(`expenses.${indexExpense}.details.${0}.isBill`)}
                                              onChange={(e) => {
                                                setValue(`expenses.${indexExpense}.details.${0}.isBill`, e.target.checked);
                                              }}
                                            />
                                          }
                                          label="บิล/บันทึก"
                                        />
                                      </Box>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={12} lg={12} justifyContent={"center"} alignItems={"center"} display={"flex"}>
                                    <Controller
                                      name={`expenses.${indexExpense}.details.${0}.remark`}
                                      control={control}
                                      render={({ field }) => (
                                        <TextField
                                          multiline
                                          label="หมายเหตุ"
                                          variant="outlined"
                                          fullWidth
                                          value={field.value}
                                          onChange={field.onChange}
                                        />
                                      )}
                                    />
                                  </Grid>
                                  {
                                    isMobile && (
                                      <Grid item xs={12} sm={12} mt={2} md={12} lg={12} key={`divider-expense-${data.id}`}>
                                        <Divider />
                                      </Grid>
                                    )
                                  }
                                </Grid>
                              </Grid>
                            ))
                          }
                          {
                            !isMobile ? (
                              <>
                                <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
                                  <Button
                                    variant="outlined"
                                    fullWidth
                                    startIcon={<AddCircleIcon />}
                                    onClick={() => {
                                      appendExpense({ id: uuidv4(), docDate: dayjs() ?? null, type: null, details: [{ name: "", amount: 0, isBill: false, remark: "" }], total: 0 });
                                    }}
                                  >
                                    เพิ่มรายการ
                                  </Button>
                                </Grid>
                                <Grid item xs={12} sm={12} mt={2} md={12} lg={12}>
                                  <Divider />
                                </Grid>
                              </>
                            ) : (
                              <>
                                <Grid item xs={4} sm={4} md={12} lg={12} >
                                  <Button
                                    variant="outlined"
                                    color="error"
                                    sx={{ width: '100%' }}
                                    type="button"
                                    startIcon={<RemoveCircleIcon />}
                                    onClick={() => handleRemove(expenseFields?.length - 1, expenseFields?.[expenseFields?.length - 1])}
                                  >
                                    ลบ
                                  </Button>
                                </Grid>
                                <Grid item xs={8} sm={8} md={12} lg={12}>
                                  <Button
                                    variant="outlined"
                                    sx={{ width: '100%' }}
                                    type="button"
                                    startIcon={<AddCircleIcon />}
                                    onClick={() => {
                                      appendExpense({ id: uuidv4(), docDate: dayjs() ?? null, type: null, details: [{ name: "", amount: 0, isBill: false, remark: "" }], total: 0 });
                                    }}
                                  >
                                    เพิ่มรายการ
                                  </Button>
                                </Grid>
                              </>
                            )
                          }
                          <Grid item xs={12} sm={12} md={12} lg={12} >
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={7} md={7} lg={7}>
                                <Grid container>
                                  <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Typography>เอกสาร</Typography>
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Button component="label" variant="outlined" startIcon={<UploadFileIcon />}>
                                      เลือกไฟล์
                                      <VisuallyHiddenInput type="file" accept=".jpg, .jpeg, .png, .pdf" multiple onChange={(e) => handleFileChange(e, 'expense')} />
                                    </Button>
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Typography variant="caption">รองรับไฟล์นามสกุล .jpg, .jpeg, .png, .pdf</Typography>
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={12} lg={12}>
                                    {selectedFilesExpense && selectedFilesExpense?.length > 0 && (
                                      <Box component={'div'} mt={1}>
                                        {selectedFilesExpense?.map((file: any, index: number) => (
                                          <Grid item xs={12} sm={12} md={12} key={`sf-${index.toString()}`} mt={1}>
                                            <Grid container >
                                              <Grid item xs={1} sm={1} md={1} lg={1} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                                                <IconButton size="small" color="error" onClick={() => handleFileRemove(index, 'expense')}>
                                                  <RemoveCircleIcon />
                                                </IconButton>
                                              </Grid>
                                              <Grid item xs={11} sm={11} md={11} lg={11}>
                                                {file.type === 'application/pdf' ? (
                                                  <Typography variant="body2">
                                                    <a
                                                      href={URL.createObjectURL(file)}
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                      style={{ color: "inherit", cursor: "pointer" }}
                                                    >
                                                      {file.name}
                                                    </a>
                                                  </Typography>
                                                ) : (
                                                  <img
                                                    src={URL.createObjectURL(file)}
                                                    alt={`ตัวอย่างไฟล์ ${index + 1}`}
                                                    style={{ maxWidth: "50%", height: "auto", cursor: "pointer" }}
                                                    onClick={() => onImageClick(URL.createObjectURL(file))}
                                                  />
                                                )}
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        ))}
                                      </Box>
                                    )}
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={12} lg={12}>
                                    {imagesExpense && (
                                      <Box component={'div'} mt={1}>
                                        {imagesExpense.map((file: any, index: number) => (
                                          <Grid item xs={12} sm={12} md={12} key={`im-${index.toString()}`} mt={1}>
                                            <Grid container >
                                              <Grid item xs={1} sm={1} md={1} lg={1} justifyContent={'center'} alignItems={'center'} display={'flex'} key={`im-btn-${index.toString()}`}>
                                                <IconButton size="small"
                                                  color="error" onClick={() => handleFileRemoveImageList(file, 'expense')}>
                                                  <RemoveCircleIcon />
                                                </IconButton>
                                              </Grid>
                                              <Grid item xs={11} sm={11} md={11} lg={11} key={`im-img-${index.toString()}`}>
                                                <img
                                                  src={`${process.env.REACT_APP_API_URL}/uploads/${(file).name
                                                    }`}
                                                  alt="Selected"
                                                  style={{ maxWidth: "50%", height: "auto", cursor: 'pointer' }}
                                                  onClick={() => onImageClick(`${process.env.REACT_APP_API_URL}/uploads/${(file).name}`, 'expense')}
                                                />
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        ))}
                                      </Box>
                                    )}
                                  </Grid>

                                  <Grid item xs={12}>
                                    {pdfFilesExpense.length > 0 && (
                                      <Box component={'div'} mt={1}>
                                        {pdfFilesExpense.map((file: any, index: number) => (
                                          <Grid item xs={12} key={`pdf-${index}`} mt={1}>
                                            <Grid container>
                                              <Grid item xs={1} justifyContent={'center'} alignItems={'center'} display={'flex'} >
                                                <IconButton size="small" color="error" onClick={() => handleFileRemovePdfList(file, 'expense')}>
                                                  <RemoveCircleIcon />
                                                </IconButton>
                                              </Grid>
                                              <Grid item xs={11}>
                                                <a
                                                  href={`${process.env.REACT_APP_API_URL}/uploads/${file.name}`}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  style={{ color: "inherit", cursor: "pointer" }}
                                                >
                                                  {file.originalName}
                                                </a>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        ))}
                                      </Box>
                                    )}
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={12} sm={5} md={5} lg={5} >
                                <Grid container spacing={2}>

                                  <Grid item xs={8} sm={8} md={8} lg={8}>
                                    <Typography>จำนวนรายจ่ายทั้งหมด</Typography>
                                  </Grid>
                                  <Grid item xs={4} sm={4} md={4} lg={4} textAlign={'end'}>
                                    <Typography>
                                      {` ${thaiFormatNumber(
                                        totalExpense.toString()
                                      )} บาท`}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </>
                      )
                      }
                    </>
                  }

                  <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} display={"flex"} justifyContent={"flex-end"} >
                    <Stack spacing={2} direction="row">
                      {isFromStatus ? (
                        <>
                          {userGroup?.canApprove && props.shipping?.status === "waiting" && (
                            <>
                              <Button
                                variant="contained"
                                fullWidth
                                color="error"
                                onClick={() => {
                                  setValue("status", "rejected" as string);
                                  onSubmit();
                                }}
                              >
                                ปฏิเสธ
                              </Button>
                              <Button
                                variant="contained"
                                color="success"
                                fullWidth
                                onClick={() => {
                                  setValue("status", "approved" as string);
                                  onSubmit();
                                }}
                                style={{ marginLeft: '8px' }}
                              >
                                อนุมัติ
                              </Button>
                            </>
                          )}
                        </>
                      ) : (
                        (!id || (props.shipping?.status === "waiting")) && (
                          <>
                            <Button
                              variant="outlined"
                              onClick={() => navigate("/process-packaging")}
                            >
                              ยกเลิก
                            </Button>
                            {props.permission.create && (
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  if (!errors.details) onSubmit();
                                }}
                              >
                                บันทึก
                              </Button>
                            )}
                          </>
                        )
                      )}
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
              <SimpleBackdrop open={props.isLoading ?? false} />
              <ImagePreview
                open={openImage || openImageExpense}
                imageSrc={openImage ? selectedImage : selectedImageExpense}
                onClose={() => openImage ? setOpenImage(false) : setOpenImageExpense(false)}
              />
              <Box sx={{ display: 'none' }}>
                <PrintShippingPackingList ref={contentRef} data={props.printShippingPackingList} />
              </Box>
              <DialogConfirm
                open={openConfirm.open}
                type={openConfirm.type === "delete" ? "error" : "success"}
                title={openConfirm.title}
                message={openConfirm.message}
                onClose={() => setOpenConfirm({ ...openConfirm, open: false })}
                onSubmit={() => {
                  setOpenConfirm({ ...openConfirm, open: false });
                  if (openConfirm.type === "create" || openConfirm.type === "approved") {
                    onCreate();
                  } else if (openConfirm.type === "update") {
                    onUpdate();
                  } else if (openConfirm.type === "delete") {
                    onDelete();
                  }
                }}
              />
              <ShippingTypeCreateView
                open={openShippingType}
                onClose={() => setOpenShippingType(false)}
                onSubmit={(data: shippingType) => {
                  setOpenShippingType(false);
                  props.onCreateShippingType(data);
                }}
                data={{} as shippingType}
                permission={props.permission}
                name={name}
              />
              <ExpenseTypeCreateView
                open={openShippingType}
                onClose={() => setOpenShippingType(false)}
                onSubmit={(data: IExpenseType) => {
                  setOpenShippingType(false);
                  props.onCreateExpenseType(data);
                }}
                data={{} as IExpenseType}
                permission={props.permission}
                name={name}
              />
              <ConsigneeCreateView
                open={openConsignee}
                onClose={() => setOpenConsignee(false)}
                onSubmit={(data: IConsignee) => {
                  setOpenConsignee(false);
                  props.onCreateConsignee(data);
                }}
                data={{} as IConsignee}
                permission={props.permission}
                name={name}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box >
  );
};

export default ShippingCreateView;