import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { IUserDetail } from "../../SuperAdminManagement/Services/UserService";
import randomInspectionService, { IRandomInspectionCriteria, IRandomInspections } from "../Services/RandomInspectionService";
import getPermissionBySubMenu from "../../../utils/getPermissionBySubMenu";
import RandomInspectionListView from "../Views/RandomInspectionListView";
import dayjs from "dayjs";
import { clearLocalStorageKeyOnUnload, getDateRangeFromStorage } from "../../../utils/dateRangeLocalStorage";
import { STORAGE_KEYS } from "../../../constants/dateRangeStorageKey";



const RandomInspectionListController = observer(() => {
  const initialDateRange = getDateRangeFromStorage(STORAGE_KEYS.PROCESS);
  const [isLoading, setIsLoading] = useState(false);
  const [permission, setPermission] = useState<IUserDetail>();
  const [randomInspectionData, setRandomInspectionData] = useState<IRandomInspections>();
  const defaultCriteria: IRandomInspectionCriteria = {
    dateFrom: initialDateRange.dateFrom
      ? dayjs(initialDateRange.dateFrom).format('YYYY-MM-DD')
      : dayjs().startOf('month').format('YYYY-MM-DD'),
    dateTo: initialDateRange.dateTo
      ? dayjs(initialDateRange.dateTo).format('YYYY-MM-DD')
      : dayjs().startOf('day').format('YYYY-MM-DD'),
    docNo: "",
    status: "all",
    order: "",
    page: 0,
    limit: 10,
  };

  useEffect(() => {
    searchByCriteria(defaultCriteria);
    checkPermissionActionButton();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  clearLocalStorageKeyOnUnload(STORAGE_KEYS.PROCESS);

  const searchByCriteria = async (criteria: IRandomInspectionCriteria) => {
    try {
      setIsLoading(true);
      await randomInspectionService.getByCriteria(criteria).then((data) => {
        setRandomInspectionData(data);
      }
      );
    } catch (error) {
      console.error("เกิดข้อผิดพลาดในการค้นหา", error);
    } finally {
      setIsLoading(false);
    }
  };

  const checkPermissionActionButton = () => {
    const result = getPermissionBySubMenu("process-random-inspection");
    if (result) {
      setPermission(result);
    }
  };


  return <RandomInspectionListView isLoading={isLoading} permission={permission ?? {} as IUserDetail} searchByCriteria={searchByCriteria} randomInspectionsData={randomInspectionData ?? {} as IRandomInspections} />;
});

export default RandomInspectionListController;