import { useEffect, useState } from "react";

import { observer } from "mobx-react-lite";
import receiveService, { IReceiveCriteria, IReceiveView } from "../Services/ReceiveService";
import ReceivesListView from "../Views/ReceivesListView";
import { IUserDetail } from "../../Login/Services/LoginService";
import getPermissionBySubMenu from "../../../utils/getPermissionBySubMenu";
import { STORAGE_KEYS } from "../../../constants/dateRangeStorageKey";
import { clearLocalStorageKeyOnUnload, getDateRangeFromStorage } from "../../../utils/dateRangeLocalStorage";
import dayjs from "dayjs";

const ReceivesListController = observer(() => {
  const initialDateRange = getDateRangeFromStorage(STORAGE_KEYS.INVENTORY);
  const [isLoading, setIsLoading] = useState(false);
  const [permission, setPermission] = useState<IUserDetail>();
  const [receiveData, setReceiveData] = useState<IReceiveView>();
  const defaultCriteria: IReceiveCriteria = {
    dateFrom: initialDateRange.dateFrom
      ? dayjs(initialDateRange.dateFrom).format('YYYY-MM-DD')
      : dayjs().startOf('month').format('YYYY-MM-DD'),
    dateTo: initialDateRange.dateTo
      ? dayjs(initialDateRange.dateTo).format('YYYY-MM-DD')
      : dayjs().startOf('day').format('YYYY-MM-DD'),
    page: 0,
    limit: 10,
  };

  useEffect(() => {
    searchByCriteria(defaultCriteria);
    getWarehouses();
    checkPermissionActionButton();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  clearLocalStorageKeyOnUnload(STORAGE_KEYS.INVENTORY);


  const searchByCriteria = async (criteria: IReceiveCriteria) => {
    try {
      setIsLoading(true);

      await receiveService.getByCriteria(criteria).then((data) => {
        setReceiveData(data);
      });
    } catch (error) {
      console.error("Error searching materials by criteria:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getWarehouses = async () => {
    try {
      setIsLoading(true);
      await receiveService.getWarehouses();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const checkPermissionActionButton = () => {
    const result = getPermissionBySubMenu("inventory-receive");
    if (result) {
      setPermission(result);
    }
  };


  return <ReceivesListView
    receives={receiveData ?? {} as IReceiveView} onSerchByCriteria={searchByCriteria} warehousesData={receiveService.warehousesData || []} isLoading={isLoading}
    permission={permission ?? {} as IUserDetail} />;
});

export default ReceivesListController;
