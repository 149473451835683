import { IDistrict, IProvince, ISubDistrict } from "../features/Inventory/Services/ReceiveService";
import { ISupplierType } from "../features/Supplier/Services/SupplierTypeService";

// NOTE: inputs for create supplier
interface ISupplierOptions {
  supplierTypesOptions: ISupplierType[];
  datasInChildCreateDialog: any[];
  provinceData: IProvince[];
  districtData: IDistrict[];
  subDistrictData: ISubDistrict[];
  name?: string;
}
export const supplierDatas = (props: ISupplierOptions) => {

  return [
    { type: "text", label: "ชื่อ Supplier", name: "name", xs: 12, sm: 12, md: 8, lg: 8, defaultValue: props?.name || "", isRequired: true },
    { type: "autocomplete", label: "ประเภท Supplier", name: "supplierType", xs: 12, sm: 12, md: 4, lg: 4, options: props?.supplierTypesOptions, labelOption: "name", valueOption: "id", defaultValue: null, datasInChildCreateDialog: props.datasInChildCreateDialog, isRequired: true, isCreate: true },
    { type: "text", label: "ที่อยู่", name: "address", xs: 12, sm: 12, md: 8, lg: 8, defaultValue: undefined, isRequired: true },
    { type: "autocomplete", label: "จังหวัด", name: "province", xs: 12, sm: 12, md: 4, lg: 4, options: props?.provinceData, labelOption: "nameTh", valueOption: "id", defaultValue: null, datasInChildCreateDialog: props.datasInChildCreateDialog, isRequired: true, isFetchData: "district" },
    { type: "autocomplete", label: "อําเภอ", name: "district", xs: 12, sm: 12, md: 4, lg: 4, options: props?.districtData, labelOption: "nameTh", valueOption: "id", defaultValue: null, datasInChildCreateDialog: props.datasInChildCreateDialog, isRequired: true, isFetchData: "subDistrict" },
    { type: "autocomplete", label: "ตําบล", name: "subDistrict", xs: 12, sm: 12, md: 4, lg: 4, options: props?.subDistrictData, labelOption: "nameTh", valueOption: "id", defaultValue: null, datasInChildCreateDialog: props.datasInChildCreateDialog, isRequired: true, setField: "zipCode", setFieldValue: "zipCode" },
    { type: "text", label: "รหัสไปรษณีย์", name: "zipCode", xs: 12, sm: 12, md: 4, lg: 4, defaultValue: "", isRequired: true },
    { type: "text", label: "สาขา", name: "branch", xs: 12, sm: 12, md: 4, lg: 4, defaultValue: "", isRequired: false },
    { type: "text", label: "ชื่อผู้ติดต่อ", name: "contract", xs: 12, sm: 12, md: 4, lg: 4, defaultValue: "" },
    { type: "text", label: "เบอร์โทร", name: "tel", xs: 12, sm: 12, md: 4, lg: 4, defaultValue: "" },
    { type: "checkbox", label: "แสดงในเมนูรับจากสวน", name: "isOrchard", xs: 12, sm: 12, md: 4, lg: 4, defaultValue: false },
  ];
};

// NOTE: inputs for create warehouse
interface IWarehouseOptions {
  provinceData: IProvince[];
  districtData: IDistrict[];
  subDistrictData: ISubDistrict[];
  name?: string;
}
export const warehouseDatas = (props: IWarehouseOptions) => {
  return [
    { type: "text", label: "ชื่อคลัง", name: "name", xs: 12, sm: 12, md: 12, lg: 12, defaultValue: props?.name || "", isRequired: true },
    { type: "text", label: "ที่อยู่", name: "address", xs: 12, sm: 12, md: 8, lg: 8, defaultValue: undefined, isRequired: true },
    { type: "autocomplete", label: "จังหวัด", name: "province", xs: 12, sm: 12, md: 4, lg: 4, options: props?.provinceData, labelOption: "nameTh", valueOption: "id", defaultValue: null, isRequired: true, isFetchData: "district" },
    { type: "autocomplete", label: "อําเภอ", name: "district", xs: 12, sm: 12, md: 4, lg: 4, options: props?.districtData, labelOption: "nameTh", valueOption: "id", defaultValue: null, isRequired: true, isFetchData: "subDistrict" },
    { type: "autocomplete", label: "ตําบล", name: "subDistrict", xs: 12, sm: 12, md: 4, lg: 4, options: props?.subDistrictData, labelOption: "nameTh", valueOption: "id", defaultValue: null, isRequired: true, setField: "zipCode", setFieldValue: "zipCode" },
    { type: "text", label: "รหัสไปรษณีย์", name: "zipCode", xs: 12, sm: 12, md: 4, lg: 4, defaultValue: "", isRequired: true },
    { type: "text", label: "เบอร์โทร", name: "tel", xs: 12, sm: 12, md: 4, lg: 4, defaultValue: "" },
  ];
};

// NOTE: inputs for create vapourHeatTreatment
interface IVapourHeatTreatmentOptions {
  provinceData: IProvince[];
  districtData: IDistrict[];
  subDistrictData: ISubDistrict[];
  name?: string;
}
export const vapourHeatTreatmentDatas = (props: IVapourHeatTreatmentOptions) => {
  return [
    { type: "text", label: "ชื่อโรงอบ", name: "name", xs: 12, sm: 12, md: 12, lg: 12, defaultValue: props?.name || "", isRequired: true },
    { type: "text", label: "ที่อยู่", name: "address", xs: 12, sm: 12, md: 8, lg: 8, defaultValue: undefined, isRequired: true },
    { type: "autocomplete", label: "จังหวัด", name: "province", xs: 12, sm: 12, md: 4, lg: 4, options: props?.provinceData, labelOption: "nameTh", valueOption: "id", defaultValue: null, isRequired: true, isFetchData: "district" },
    { type: "autocomplete", label: "อําเภอ", name: "district", xs: 12, sm: 12, md: 4, lg: 4, options: props?.districtData, labelOption: "nameTh", valueOption: "id", defaultValue: null, isRequired: true, isFetchData: "subDistrict" },
    { type: "autocomplete", label: "ตําบล", name: "subDistrict", xs: 12, sm: 12, md: 4, lg: 4, options: props?.subDistrictData, labelOption: "nameTh", valueOption: "id", defaultValue: null, isRequired: true, setField: "zipCode", setFieldValue: "zipCode" },
    { type: "text", label: "รหัสไปรษณีย์", name: "zipCode", xs: 12, sm: 12, md: 4, lg: 4, defaultValue: "", isRequired: true },
    { type: "text", label: "เบอร์โทร", name: "tel", xs: 12, sm: 12, md: 4, lg: 4, defaultValue: "" },
  ];
};

// NOTE: inputs for create market
interface IMarketOptions {
  provinceData: IProvince[];
  districtData: IDistrict[];
  subDistrictData: ISubDistrict[];
  name?: string;
}
export const marketDatas = (props: IMarketOptions) => {

  return [
    { type: "text", label: "ชื่อตลาด", name: "name", xs: 12, sm: 12, md: 12, lg: 12, defaultValue: props?.name || "", isRequired: true },
    { type: "text", label: "ที่อยู่", name: "address", xs: 12, sm: 12, md: 8, lg: 8, defaultValue: undefined, isRequired: true },
    { type: "autocomplete", label: "จังหวัด", name: "province", xs: 12, sm: 12, md: 4, lg: 4, options: props?.provinceData, labelOption: "nameTh", valueOption: "id", defaultValue: null, isRequired: true, isFetchData: "district" },
    { type: "autocomplete", label: "อําเภอ", name: "district", xs: 12, sm: 12, md: 4, lg: 4, options: props?.districtData, labelOption: "nameTh", valueOption: "id", defaultValue: null, isRequired: true, isFetchData: "subDistrict" },
    { type: "autocomplete", label: "ตําบล", name: "subDistrict", xs: 12, sm: 12, md: 4, lg: 4, options: props?.subDistrictData, labelOption: "nameTh", valueOption: "id", defaultValue: null, isRequired: true, setField: "zipCode", setFieldValue: "zipCode" },
    { type: "text", label: "รหัสไปรษณีย์", name: "zipCode", xs: 12, sm: 12, md: 4, lg: 4, defaultValue: "", isRequired: true },
    { type: "text", label: "เบอร์โทร", name: "tel", xs: 12, sm: 12, md: 4, lg: 4, defaultValue: "" },
  ];
};

// NOTE: inputs for create company
interface ICompanyOptions {
  provinceData: IProvince[];
  districtData: IDistrict[];
  subDistrictData: ISubDistrict[];
  name?: string;
}
export const companyDatas = (props: ICompanyOptions) => {

  return [
    { type: "text", label: "ชื่อบริษัท", name: "name", xs: 12, sm: 12, md: 8, lg: 8, defaultValue: props?.name || "", isRequired: true, },
    { type: "text", label: "สาขา", name: "branch", xs: 12, sm: 12, md: 4, lg: 4, defaultValue: "", isRequired: true, sx: { mt: { xs: 2, md: 0 } } },
    { type: "text", label: "เลขประจําตัวผู้เสียภาษี", name: "tax", xs: 12, sm: 12, md: 4, lg: 4, defaultValue: "", isRequired: true, sx: { mt: { xs: 2, md: 0 } } },
    { type: "text", label: "ที่อยู่", name: "address", xs: 12, sm: 12, md: 8, lg: 8, defaultValue: undefined, isRequired: true, sx: { mt: { xs: 2, md: 0 } } },
    { type: "autocomplete", label: "จังหวัด", name: "province", xs: 12, sm: 12, md: 4, lg: 4, options: props?.provinceData, labelOption: "nameTh", valueOption: "id", defaultValue: null, isRequired: true, isFetchData: "district", sx: { mt: { xs: 0, md: 0, lg: 0 } } },
    { type: "autocomplete", label: "อําเภอ", name: "district", xs: 12, sm: 12, md: 4, lg: 4, options: props?.districtData, labelOption: "nameTh", valueOption: "id", defaultValue: null, isRequired: true, isFetchData: "subDistrict", sx: { mt: { xs: 0, md: 0, lg: 0 } } },
    { type: "autocomplete", label: "ตําบล", name: "subDistrict", xs: 12, sm: 12, md: 4, lg: 4, options: props?.subDistrictData, labelOption: "nameTh", valueOption: "id", defaultValue: null, isRequired: true, setField: "zipCode", setFieldValue: "zipCode", sx: { mt: { xs: 0, md: 0, lg: 0 } } },
    { type: "text", label: "รหัสไปรษณีย์", name: "zipCode", xs: 12, sm: 12, md: 4, lg: 4, defaultValue: "", isRequired: true, sx: { mt: { xs: 1, md: 0 } } },
    { type: "text", label: "เบอร์โทร", name: "tel", xs: 12, sm: 12, md: 4, lg: 4, defaultValue: "", sx: { mt: { xs: 2, md: 2 } } },
  ];
};