import { FC, useState } from "react";
import { IExpenseType } from "../Services/ExpenseTypeService";
import { Autocomplete, Box, Breadcrumbs, Button, FormControl, FormControlLabel, Grid, IconButton, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { IExpense, IExpenseCriteria, IExpenseListView } from "../Services/ExpenseService";
import { sortOptions } from "../../../constants/sortOptions";
import dayjs from "dayjs";
import ListViewPage from "../../../components/ListViewPage";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import TableDataList from "../../../components/TableDataList";
import { IUserDetail } from "../../Login/Services/LoginService";
import thaiFormatNumber from "../../../utils/thaiFormatNumber";
import { STORAGE_KEYS } from "../../../constants/dateRangeStorageKey";
import { getDateRangeFromStorage, setDateRangeToStorage } from "../../../utils/dateRangeLocalStorage";

interface ExpensesListViewProps {
  onCreate: (data: IExpense) => Promise<void>;
  onUpdate: (data: IExpense) => Promise<void>;
  onSerchByCriteria: (criteria: IExpenseCriteria) => Promise<void>;
  data: IExpenseListView;
  isLoading: boolean;
  typeOptions: IExpenseType[];
  expenses: IExpenseListView;
  permission: IUserDetail;
}
const ExpensesListView: FC<ExpensesListViewProps> = (props) => {
  const initialDateRange = getDateRangeFromStorage(STORAGE_KEYS.EXPENSE);
  const { register, getValues, control } = useForm<IExpenseCriteria>({
    defaultValues: {
      dateType: "createDate",
      name: "",
      sortBy: sortOptions[0],
      dateFrom: initialDateRange.dateFrom || dayjs().startOf('month').toDate(),
      dateTo: initialDateRange.dateTo || dayjs().toDate(),
    }
  });
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const navigate = useNavigate();

  const searchByCriteria = (pageNumber: number = 0, rowLimit?: number) => {
    setPage(pageNumber);
    setLimit(rowLimit ? rowLimit : 10);
    const data = getValues();
    props.onSerchByCriteria({
      ...data,
      dateFrom: dayjs(data.dateFrom).format("YYYY-MM-DD"),
      dateTo: dayjs(data.dateTo).format("YYYY-MM-DD"),
      page: pageNumber, limit: rowLimit ? rowLimit : 10
    });
  };

  //SECTION - Variables for Warehouses TableDataList
  const expenseColumns = [
    { label: 'วันที่เอกสาร', render: (row: IExpense) => dayjs(row.docDate).format('DD/MM/YYYY') },
    { label: 'รายการ', render: (row: IExpense) => row.details?.map((detail) => (detail.name)).slice(0, 3).join(', ') + (row.details && row.details?.length > 3 ? ', ...' : '') },
    { label: 'ประเภท', render: (row: IExpense) => row.type?.name || "" },
    {
      label: 'รายรับ', render: (row: IExpense) => {
        const income = row.details?.filter((detail) => detail.entryType === 'income').map((detail) => detail.amount).reduce((a, b) => (parseFloat(String(a)) || 0) + (parseFloat(String(b)) || 0), 0);
        return thaiFormatNumber(parseFloat(String(income || 0)).toFixed(2));
      }
    },
    {
      label: 'รายจ่าย', render: (row: IExpense) => {
        const expense = row.details?.filter((detail) => detail.entryType === 'expense').map((detail) => detail.amount).reduce((a, b) => (parseFloat(String(a)) || 0) + (parseFloat(String(b)) || 0), 0);
        return thaiFormatNumber(parseFloat(String(expense || 0)).toFixed(2));
      }
    },
    { label: 'ยอดรวมสุทธิ', render: (row: IExpense) => thaiFormatNumber(row.total.toString() || '0') },
    { label: 'ผู้ทำรายการ', render: (row: IExpense) => `${row.worker?.firstName || ""} ${row.worker?.lastName || ""}` },
    {
      label: 'บันทึกล่าสุด', render: (row: IExpense) => (
        <Box component={'div'}>
          <Box component={'div'}>{row.createBy?.firstName || ""} {row.createBy?.lastName || ""}</Box>
          <Box component={'div'}>{dayjs(row.createAt).format('DD/MM/YYYY') === "Invalid Date" ? "" :
            <Box>
              <Box>{dayjs(row.createAt).format('DD/MM/YYYY')}</Box>
              <Box>{dayjs(row.createAt).format('HH:mm')}</Box>
            </Box>
          }</Box>
        </Box>
      )
    },
    {
      label: 'แก้ไขล่าสุด', render: (row: IExpense) => (
        <Box component={'div'}>
          <Box component={'div'}>{row.updateBy?.firstName || ""} {row.updateBy?.lastName || ""}</Box>
          <Box component={'div'}>{dayjs(row.updateAt).format('DD/MM/YYYY') === "Invalid Date" ? "" :
            <Box>
              <Box>{dayjs(row.updateAt).format('DD/MM/YYYY')}</Box>
              <Box>{dayjs(row.updateAt).format('HH:mm')}</Box>
            </Box>
          }</Box>
        </Box>
      )
    },
  ];

  const expenseActions = (row: IExpense) => (
    <IconButton
      onClick={() => {
        navigate(`/expense-other-detail/${row.id}`);
      }}>
      <KeyboardArrowRightIcon />
    </IconButton>
  );

  const actionButtons = props.permission.create ? (
    <Button
      variant="contained"
      startIcon={<NoteAddIcon />}
      onClick={() => {
        navigate(`/expense-other-detail`);
      }}>
      สร้าง
    </Button>
  ) : <></>;

  return (
    <ListViewPage
      breadcrumb={
        <>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Typography>ค่าใช้จ่าย</Typography>
            <Typography variant="h6" color="text.primary">รายการ</Typography>
          </Breadcrumbs>
        </>
      }
      criteria={
        <>
          <Box component={"form"}>
            <Grid container spacing={2} alignItems={"end"}>
              <Grid item xs={12} sm={12} md={12} lg={10}>
                <Grid container spacing={1} alignItems={"end"}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Controller
                      name="dateType"
                      control={control}
                      render={({ field }) => (
                        <RadioGroup
                          row
                          name="dateType"
                          value={field.value || "createDate"}
                          onChange={(event, newValue) => {
                            field.onChange(newValue);
                          }}
                        >
                          <FormControlLabel value="createDate"
                            control={<Radio />} label="วันที่บันทึก" />
                          <FormControlLabel value="updateDate" control={<Radio />} label="วันที่แก้ไข" />
                          <FormControlLabel value="docDate" control={<Radio />} label="วันที่เอกสาร" />
                        </RadioGroup>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Controller
                      name="dateFrom"
                      control={control}
                      render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'th'}>
                          <DemoContainer components={['DatePicker', 'DatePicker']} >
                            <DatePicker sx={{ width: "100%" }}
                              defaultValue={dayjs().startOf('month')}
                              label="ตั้งแต่วันที่"
                              value={field.value ? dayjs(field.value) : dayjs()}
                              onChange={(newValue: any) => {
                                field.onChange(newValue);
                                setDateRangeToStorage(STORAGE_KEYS.EXPENSE, newValue.startOf('day').toDate(), dayjs(getValues('dateTo')) ?? dayjs().startOf('day'));
                              }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Controller
                      name="dateTo"
                      control={control}
                      render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'th'}>
                          <DemoContainer components={['DatePicker', 'DatePicker']} >
                            <DatePicker
                              sx={{ width: "100%" }}
                              defaultValue={dayjs()}
                              label="ถึงวันที่"
                              value={field.value ? dayjs(field.value) : dayjs()}
                              onChange={(newValue: any) => {
                                field.onChange(newValue);
                                setDateRangeToStorage(STORAGE_KEYS.EXPENSE, dayjs(getValues('dateFrom')) ?? dayjs().startOf('month').startOf('day'), newValue.startOf('day').toDate());
                              }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <TextField fullWidth label="รายการ"  {...register("name")} />
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} lg={3}>
                    <Controller
                      name="type"
                      control={control}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          value={field?.value as IExpenseType | null}
                          options={props.typeOptions || []}
                          getOptionLabel={(option: any) => {
                            if (typeof option === 'string') {
                              return option;
                            }
                            if (option.inputValue) {
                              return option.inputValue;
                            }
                            return option.name || "";
                          }}
                          isOptionEqualToValue={(option, value) => option.id === value?.id}
                          onChange={(event, newValue) => {
                            field.onChange(newValue);
                          }}
                          renderInput={(params) => <TextField
                            {...params} label="ประเภทค่าใช้จ่ายอื่นๆ" />}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} lg={3}>
                    <Controller
                      name="sortBy"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <FormControl fullWidth>
                          <InputLabel id="status-select-label">เรียงตาม</InputLabel>
                          <Select
                            labelId="status-select-label"
                            id="status-select"
                            value={field.value?.value || field.value}
                            label="เรียงตาม"
                            onChange={(event) => {
                              const newValue = sortOptions?.find((item) => item.value === event.target.value);
                              field.onChange(newValue);
                            }}
                          >
                            {sortOptions.map((item, index) => (
                              <MenuItem key={index} value={item.value}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={2} textAlign={"end"} alignSelf={"end"}>
                <Button
                  onClick={() => searchByCriteria()}
                  variant="contained"
                  startIcon={<SearchIcon />}
                >
                  ค้นหา
                </Button>
              </Grid>
            </Grid>
          </Box>
        </>}
      table={
        <>
          <TableDataList
            data={props.expenses.datas || []}
            total={props.expenses.total}
            columns={expenseColumns}
            actionButtons={actionButtons}
            rowActions={expenseActions}
            page={page}
            limit={limit}
            onPageChange={(newPage) => {
              setPage(newPage);
              searchByCriteria(newPage);
            }}
            onRowsPerPageChange={(newLimit) => {
              setLimit(newLimit);
              setPage(0);
              searchByCriteria(0, newLimit);
            }}
          />
        </>}
      isLoading={props.isLoading ?? false}
    />
  );
};

export default ExpensesListView;
