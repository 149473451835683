import { observer } from "mobx-react-lite";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import clearState from "../../../utils/resetState";
import { IUserDetail } from "../../Login/Services/LoginService";
import getPermissionBySubMenu from "../../../utils/getPermissionBySubMenu";
import RoleDetailView from "../Views/RoleDetailView";
import roleService, { IUserGroup } from "../Services/RoleService";
import companyService from "../Services/CompanyService";

const RoleDetailSuperAdminController = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [permission, setPermission] = useState<IUserDetail>();
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    getProvince();
    getAllCompanies();
    if (id) { getOne(id); }
    checkPermissionActionButton();
    return () => {
      clearState(roleService, ["userGroupData"]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  const getOne = async (id: string) => {
    try {
      setIsLoading(true);
      await roleService.getOne(id);
    } catch (error) {
      console.error("Error searching transfers by criteria:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const create = async (data: any) => {
    try {
      setIsLoading(true);
      const response = await roleService.create(data);
      if (response.id) {
        enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
        navigate(`/user-group-detail/${response.id}`);
      } else {
        enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const update = async (data: any) => {
    try {
      setIsLoading(true);
      await roleService.update(data);
      enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      getOne(data.id);
    } catch (error) {
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const remove = async (id: string) => {
    try {
      setIsLoading(true);
      await roleService.delete(id);
      enqueueSnackbar("ลบข้อมูลสำเร็จ", { variant: "success" });
      navigate(`/user-group`);
    } catch (error) {
      enqueueSnackbar("ลบข้อมูลไม่สำเร็จ", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const getAllCompanies = async () => {
    try {
      setIsLoading(true);
      await companyService.getAll();
    } catch (error) {
      console.error("Error searching companies by criteria:", error);
    } finally {
      setIsLoading(false);
    }
  };


  const onCreateDialog = async (data: any, type: string) => {
    try {
      let response;
      switch (type) {
        case "company":
          response = await companyService.create(data);
          getAllCompanies();
          break;
      }
      if (response) {
        enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      }
    } catch (error) {
      console.error(`An error occurred while creating the ${type}`, error);
    }
  };

  const onFetchData = async (id?: any, type?: string) => {
    try {
      switch (type) {
        case "district": await companyService.getDistrict(id);
          break;
        case "subDistrict": await companyService.getSubDistrict(id);
          break;
      }
    } catch (error) {
      console.error(`An error occurred while creating the ${type}`, error);
    }
  };

  const getProvince = async () => {
    try {
      setIsLoading(true);
      await companyService.getProvince();
    } catch (error) {
      console.error("An error occurred while getting the province:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const checkPermissionActionButton = () => {
    const result = getPermissionBySubMenu("user-group");
    if (result) {
      setPermission(result);
    }
  };

  return <RoleDetailView
    onCreate={create}
    onUpdate={update}
    onRemove={remove}
    isLoading={isLoading}
    data={id ? roleService.getGroupData as IUserGroup : undefined}
    permission={permission ?? {} as IUserDetail}
    company={companyService.companiesList}
    onCreateDialog={onCreateDialog}
    onFetchData={onFetchData}
    provinceData={companyService.provinceData || []}
    districtData={companyService.districtData || []}
    subDistrictData={companyService.subDistrictData || []}
  />;
});

export default RoleDetailSuperAdminController;
