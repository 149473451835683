import apiService from "../../../services/ApiService";
import { ISubDistrict, IDistrict, IProvince } from "../../Company/Services/CompanyService";
import { IUser } from "../../UserManagement/Services/RoleService";

import { makeAutoObservable, configure } from "mobx";

export interface IVapourHeatTreatment {
  id?: string;
  name?: string;
  address?: string;
  subDistrict?: ISubDistrict | undefined;
  district?: IDistrict | undefined;
  province?: IProvince | undefined;
  zipCode?: string;
  tel?: string;
  manager?: IUser;
  isDelete?: boolean;
  createBy?: IUser | undefined;
  updateBy?: IUser | undefined;
  createAt?: Date;
  updateAt?: Date;
}

export interface IVapourHeatTreatments {
  datas: IVapourHeatTreatment[];
  all: number;
  page: number;
  limit: number;
  total: number;
}

export interface IVapourHeatTreatmentCriteria {
  name?: string;
  page: number;
  limit: number;
}

class VapourHeatTreatmentService {
  vapourHeatTreatments: IVapourHeatTreatments | undefined;
  vapourHeatTreatment: IVapourHeatTreatment | undefined;
  allVapourHeatTreatments: IVapourHeatTreatment[] = [];


  constructor() {
    configure({
      enforceActions: "never",
    });
    makeAutoObservable(this);
  }

  async getOne(id: string) {
    try {
      const vapourHeatTreatment = await apiService.get(`vapour-heat-treatment/${id}`);
      this.vapourHeatTreatment = vapourHeatTreatment;
      return this.vapourHeatTreatment;
    } catch (error) {
      throw error;
    }
  }

  async getByCriteria(criteria: IVapourHeatTreatmentCriteria) {
    try {
      this.vapourHeatTreatments = await apiService.post("vapour-heat-treatment/criteria", criteria);
      return this.vapourHeatTreatments;
    } catch (error) {
      throw error;
    }
  }


  async create(data: IVapourHeatTreatment) {
    try {
      return await apiService.post("vapour-heat-treatment", data);
    } catch (error) {
      throw error;
    }
  }

  async update(data: IVapourHeatTreatment) {
    try {
      return await apiService.put(`vapour-heat-treatment/${data.id}`, data);
    } catch (error) {
      throw error;
    }
  }

  async getVapourHeatTreatments() {
    try {
      this.allVapourHeatTreatments = await apiService.get('/vapour-heat-treatment');
      return this.allVapourHeatTreatments;
    } catch (error) {
      throw error;
    }
  }

  get vapourHeatTreatmentsData() {
    return this.vapourHeatTreatments;
  }

  get vapourHeatTreatmentData() {
    return this.vapourHeatTreatment;
  }

  get allVapourHeatTreatmentsData() {
    return this.allVapourHeatTreatments;
  }
}

const vapourHeatTreatmentService = new VapourHeatTreatmentService();
export default vapourHeatTreatmentService;