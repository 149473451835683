import { configure, makeAutoObservable } from "mobx";
import { IUser } from "../../UserManagement/Services/RoleService";
import apiService from "../../../services/ApiService";
import { IProvince } from "../../Company/Services/CompanyService";

export interface ICar {
  id?: string;
  plateNumber?: string;
  province?: IProvince | undefined;
  model?: string;
  brand?: string;
  color?: string;
  isDelete?: boolean;
  createAt?: Date;
  updateAt?: Date;
  createBy?: IUser | undefined;
  updateBy?: IUser | undefined;
}

export interface ICarView {
  datas: ICar[];
  all: number;
  page: number;
  limit: number;
  total: number;
}

export interface ICarCriteria {
  plateNumber?: string;
  page: number;
  limit: number;
}


class CarService {
  cars: ICarView | undefined;
  car: ICar | undefined;
  carList: ICar[] | undefined;
  constructor() {
    configure({
      enforceActions: "never",
    });
    makeAutoObservable(this);
  }


  async getAll() {
    try {
      const response = await apiService.get("car");
      this.carList = response;
      return this.carList;
    } catch (error) {
      throw error;
    }
  }

  async getOne(id: string) {
    try {
      const response = await apiService.get(`car/${id}`);
      this.car = response;
      return this.car;
    } catch (error) {
      throw error;
    }
  }

  async getByCriteria(criteria: ICarCriteria) {
    try {
      const response = await apiService.post("car/criteria", criteria);
      this.cars = response;
      return this.cars;
    } catch (error) {
      throw error;
    }
  }

  async create(data: ICar) {
    try {
      const response = await apiService.post("car", data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async update(data: ICar) {
    try {
      const response = await apiService.put(`car/${data.id}`, data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  get carData() {
    return this.car;
  }

  get carsData() {
    return this.cars;
  }

  get carListData() {
    return this.carList;
  }
}

const carService = new CarService();
export default carService;