import { configure, makeAutoObservable } from "mobx";
import { IUser } from "../../UserManagement/Services/RoleService";
import apiService from "../../../services/ApiService";

export interface ICapacity {
  id?: string;
  name?: string | null;
  value?: number | null;
  description?: string | null;
  createBy?: IUser | undefined;
  updateBy?: IUser | undefined;
  createAt?: string;
  updateAt?: string;
  isDelete?: boolean;
}

export interface ICapacityCriteria {
  page: number;
  limit: number;
  name: string;
}

export interface ICapacityListView {
  datas?: ICapacity[] | undefined;
  all: number;
  limit: number;
  page: number;
  total: number;
}
class CapacityService {
  capacities: ICapacityListView | undefined;
  capacity: ICapacity = {} as ICapacity;
  allCacities: ICapacity[] | undefined;

  constructor() {
    configure({
      enforceActions: "never",
    });
    makeAutoObservable(this);
  }

  async getAll() {
    try {
      this.allCacities = await apiService.get("capacity");
      return this.allCacities;
    } catch (error) {
      throw error;
    }
  }

  async getOne(id: string) {
    try {
      this.capacity = await apiService.get(`capacities/${id}`);
      return this.capacity;
    } catch (error) {
      throw error;
    }
  }

  async create(capacity: ICapacity) {
    try {
      const result = await apiService.post("capacities", capacity);
      return result;
    } catch (error) {
      throw error;
    }
  }

  async update(capacity: ICapacity) {
    try {
      const result = await apiService.put(`capacities/${capacity.id}`, capacity);
      return result;
    } catch (error) {
      throw error;
    }
  }

  async delete(id: string) {
    try {
      const result = await apiService.delete(`capacities/${id}`);
      return result;
    } catch (error) {
      throw error;
    }
  }

  async getByCriteria(criteria: ICapacityCriteria) {
    try {
      this.capacities = await apiService.post(`capacities/criteria`, criteria);
      return this.capacities;
    } catch (error) {
      throw error;
    }
  }

  get capacityData() {
    return this.capacity;
  }
  get capacitiesData() {
    return this.capacities;
  }

  get allCapacities() {
    return this.allCacities;
  }
}
const capacityService = new CapacityService();
export default capacityService;
