import { Box, Breadcrumbs, Button, Grid, IconButton, TextField, Typography } from "@mui/material";
import { FC, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import { IWarehouse, IWarehouseCriteria, IWarehouses } from "../Services/WareshousesService";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { IMaterialCriteria } from "../Services/MaterialsService";
import { IDistrict, IProvince, ISubDistrict } from "../../Company/Services/CompanyService";
import ListViewPage from "../../../components/ListViewPage";
import TableDataList from "../../../components/TableDataList";
import { useNavigate } from "react-router-dom";
import { IUserDetail } from "../../Login/Services/LoginService";
import PlaceCreateView from "../../../components/PlaceCreateView";
import dayjs from "dayjs";

interface WarehousesViewProps {
  isLoading: boolean;
  warehouses: IWarehouses;
  warehouse: IWarehouse;
  onSerchByCriteria: (criteria: IWarehouseCriteria) => Promise<void>;
  onCreate: (data: IWarehouse) => Promise<void>;
  onUpdate: (data: IWarehouse) => Promise<void>;
  provinceData?: IProvince[];
  districtData?: IDistrict[];
  subDistrictData?: ISubDistrict[];
  onFetchDistrict: (id: number | undefined) => void;
  onFetchSubDistrict: (id: number | undefined) => void;
  permission: IUserDetail;
}
const WarehousesView: FC<WarehousesViewProps> = (props) => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [name, setName] = useState("");
  const [open, setOpen] = useState(false);
  const [warehouse, setWarehouse] = useState<IWarehouse>();


  const navigate = useNavigate();

  const searchByCriteria = (pageNumber: number = 0, rowLimit?: number) => {
    setPage(pageNumber);
    setLimit(rowLimit ? rowLimit : 10);
    const criteria: IMaterialCriteria = {
      name: name,
      page: pageNumber,
      limit: rowLimit ?? 10,
    };
    props.onSerchByCriteria(criteria);
  };

  const onSubmit = (data: IWarehouse) => {
    if (data.id) {
      props.onUpdate(data);
    } else {
      props.onCreate(data);
    }
  };

  //SECTION - Variables for Warehouses TableDataList
  const warehouseColumns = [
    { label: 'ชื่อคลัง', align: undefined, render: (row: IWarehouse) => row.name },
    { label: 'ที่อยู่', render: (row: IWarehouse) => <>{`${row.address} ${row.province?.nameTh} ${row.district?.nameTh} ${row.subDistrict?.nameTh}`}</> },
    { label: 'เบอร์โทร', render: (row: IWarehouse) => row.tel },
    {
      label: 'บันทึกล่าสุด', render: (row: IWarehouse) => (
        <Box component={'div'}>
          <Box component={'div'}>{row.createBy?.firstName || ""} {row.createBy?.lastName || ""}</Box>
          <Box component={'div'}>{dayjs(row.createAt).format('DD/MM/YYYY') === "Invalid Date" ? "" :
            <Box>
              <Box>{dayjs(row.createAt).format('DD/MM/YYYY')}</Box>
              <Box>{dayjs(row.createAt).format('HH:mm')}</Box>
            </Box>
          }
          </Box>
        </Box>
      )
    },
    {
      label: 'แก้ไขล่าสุด', render: (row: IWarehouse) => (
        <Box component={'div'}>
          <Box component={'div'}>{row.updateBy?.firstName || ""} {row.updateBy?.lastName || ""}</Box>
          <Box component={'div'}>{dayjs(row.updateAt).format('DD/MM/YYYY') === "Invalid Date" ? "" :
            <Box>
              <Box>{dayjs(row.updateAt).format('DD/MM/YYYY')}</Box>
              <Box>{dayjs(row.updateAt).format('HH:mm')}</Box>
            </Box>
          }
          </Box>
        </Box>
      )
    },
  ];

  const warehouseActions = (row: IWarehouse) => (
    <IconButton
      onClick={() => {
        navigate(`/inventory-warehouses-detail/${row.id}`);
      }}>
      <KeyboardArrowRightIcon />
    </IconButton>
  );

  const actionButtons = props.permission.create ? (
    <Button
      variant="contained"
      startIcon={<NoteAddIcon />}
      onClick={() => {
        setOpen(true);
        setWarehouse({} as IWarehouse);
      }}>
      สร้าง
    </Button>
  ) : <></>;
  //!SECTION - Variables for Warehouses TableDataList

  return (
    <ListViewPage
      breadcrumb={
        <>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Typography>คลังวัสดุ</Typography>
            <Typography variant="h6" color="text.primary">รายการ</Typography>
          </Breadcrumbs>
        </>
      }
      criteria={
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={10} md={10} lg={10}>
              <TextField fullWidth label="ชื่อรายการ" onChange={(e) => setName(e.target.value)} />
            </Grid>
            <Grid item xs={12} sm={2} md={2} lg={2} textAlign={"end"} alignSelf={"center"}>
              <Button
                variant="contained"
                startIcon={<SearchIcon />}
                onClick={() => searchByCriteria(0)}>ค้นหา
              </Button>
            </Grid>
          </Grid>
        </>
      }
      table={
        <>
          <TableDataList
            data={props.warehouses.datas}
            total={props.warehouses.total}
            columns={warehouseColumns}
            actionButtons={actionButtons}
            rowActions={warehouseActions}
            page={page}
            limit={limit}
            onPageChange={setPage}
            onRowsPerPageChange={(newLimit) => {
              setLimit(newLimit);
              setPage(0);
              searchByCriteria(0, newLimit);
            }}
          />
        </>
      }
      dialog={
        <>
          <PlaceCreateView open={open}
            data={warehouse ?? {} as IWarehouse}
            provinceData={props.provinceData || []}
            districtData={props.districtData || []}
            subDistrictData={props.subDistrictData || []}
            onClose={() => {
              setOpen(false);
              setWarehouse({} as IWarehouse);
            }}
            onSubmit={onSubmit}
            onFetchDistrict={props.onFetchDistrict}
            onFetchSubDistrict={props.onFetchSubDistrict}
            permission={props.permission}
            placeLabel="คลังวัสดุ"
          />
        </>
      }
      isLoading={props.isLoading ?? false}
    />
  );
};
export default WarehousesView;