import {
  Box, Button, TextField, Grid, ButtonProps, Breadcrumbs, Typography, Link, Card, CardContent, IconButton, Stack, Divider, FormControl, FormControlLabel, Checkbox, InputLabel, MenuItem, Select, Chip,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  IMaterial,
  IMaterialTransaction,
  IMaterialTransactionCriteria,
  IMaterialTransactions,
} from "../Services/MaterialsService";
import DialogConfirm from "../../../components/DialogConfirm";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import dayjs from "dayjs";
import { IWarehouse } from "../Services/WareshousesService";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import TableDataList from "../../../components/TableDataList";
import MoveUpIcon from '@mui/icons-material/MoveUp';
import AddHomeIcon from '@mui/icons-material/AddHome';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import { IUserDetail } from "../../Login/Services/LoginService";
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import thaiFormatNumber from "../../../utils/thaiFormatNumber";
import { getDateRangeFromStorage, setDateRangeToStorage } from "../../../utils/dateRangeLocalStorage";
import { STORAGE_KEYS } from "../../../constants/dateRangeStorageKey";



interface MaterialDetailViewProps {
  material?: IMaterial;
  onUpdate: (data: IMaterial) => void;
  onDelete: (data: IMaterial) => void;
  materialTransactionsData: IMaterialTransactions;
  onSearchByCriteria: (criteria: IMaterialTransactionCriteria) => void;
  warehousesData?: IWarehouse[];
  isLoading?: boolean;
  permission?: IUserDetail;
}

const MaterialDetailView: FC<MaterialDetailViewProps> = (props) => {
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    watch,
    setValue,
    formState: { errors },
  } = useForm<IMaterial>();

  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalQuantity, setTotalQuantity] = useState<number | null>(null);
  const [totalAverageCost, setTotalAverageCost] = useState<number | null>(null);
  const [searchDateType, setSearchDateType] = useState("date");
  const [selectedWarehouse, setSelectedWarehouse] = useState<string | null>(null);

  const avgPrice = watch("avgPrice");


  const [openConfirm, setOpenConfirm] = useState({
    open: false,
    title: "",
    message: "",
    color: "primary" as ButtonProps["color"],
    type: "create" as "create" | "update" | "delete",
  });

  const initialDateRange = getDateRangeFromStorage(STORAGE_KEYS.INVENTORY);
  const { control: controlTransaction, getValues: getValuesTransaction } = useForm<IMaterialTransactionCriteria>({
    defaultValues: {
      warehouseId: "all",
      dateFrom: initialDateRange.dateFrom || dayjs().startOf('month').format('YYYY-MM-DD'),
      dateTo: initialDateRange.dateTo || dayjs().format('YYYY-MM-DD'),
    }
  });
  const isMain = watch("isMain", props.material?.isMain || false);
  const unit = watch("unit");
  const subUnit = watch("subUnit");

  useEffect(() => {
    if (props.material) {
      reset(props.material);
    }
  }, [props.material, reset]);

  useEffect(() => {
    if (props.materialTransactionsData) {
      setTotalQuantity(props.materialTransactionsData.totalQuantity);
      if (totalQuantity !== null && avgPrice !== undefined) {
        setTotalAverageCost(Number(avgPrice) * totalQuantity);
      } else {
        setTotalAverageCost(0);
      }
    }
  }, [avgPrice, props.materialTransactionsData, totalQuantity]);

  const materialTransactionColumns = [
    {
      label: "ประเภท",
      render: (row: IMaterialTransaction) => {
        let chipProps;

        switch (row.refType) {
          case "receive": chipProps = { label: "รับเข้า", color: "secondary", icon: <AddHomeIcon /> };
            break;
          case "transfer": chipProps = { label: "โยกย้าย", color: "primary", icon: <MoveUpIcon /> };
            break;
          case "packaging":
            chipProps = { label: "แพ็ค", color: "info", icon: <Inventory2OutlinedIcon />, sx: { backgroundColor: 'LightSeaGreen' } };
            break;
          default: chipProps = { label: "รับเข้า", color: "secondary", icon: <AddHomeIcon /> };
            break;
        }

        return (
          <Chip
            label={chipProps.label}
            color={chipProps.color as "secondary" | "primary" | "info"}
            icon={chipProps.icon}
            sx={{ width: '100%', ...chipProps.sx }}
          />

        );
      },
    },
    {
      label: "เลขที่เอกสาร", render: (row: IMaterialTransaction) => <Box component={'div'}>
        <Box component={'div'}>{row.refDoc || ""}</Box>
        <Box component={'div'}>{row.poNumber ? `(${row.poNumber})` : ""}</Box>
      </Box>
    },
    {
      label: "ผู้ทำรายการ",
      render: (row: IMaterialTransaction) =>
        <Box component={'div'}>
          <Box component={'div'}>{row.worker?.firstName || ""} {row.worker?.lastName || ""}</Box>
          <Box component={'div'}>{dayjs(row.docDate).format('DD/MM/YYYY HH:mm') === "Invalid Date" ? "" : dayjs(row.docDate).format('DD/MM/YYYY HH:mm')}</Box>
        </Box>
    },
    {
      label: "จำนวน", render: (row: IMaterialTransaction) => thaiFormatNumber(row.quantity.toString())
    },
    {
      label: "คลังต้นทาง",
      render: (row: IMaterialTransaction) => {
        if (!row.stockFrom?.name) return null;
        const isSelected = selectedWarehouse === row.stockFrom.id?.toString();
        const chipColor = isSelected ? "error" : "default";
        const chipIcon = isSelected ? <KeyboardDoubleArrowUpIcon /> : undefined;
        return (
          <Chip
            label={row.stockFrom.name}
            color={chipColor as "error" | "default"}
            icon={chipIcon}
          />
        );
      },
    },
    {
      label: "คลังปลายทาง",
      render: (row: IMaterialTransaction) => {
        if (!row.stockTo?.name) return null;
        const isSelected = selectedWarehouse === row.stockTo.id?.toString();
        const chipColor = isSelected ? "success" : "default";
        const chipIcon = isSelected ? <KeyboardDoubleArrowDownIcon /> : undefined;
        return (
          <Chip
            label={row.stockTo.name}
            color={chipColor as "success" | "default"}
            icon={chipIcon}
          />
        );
      },
    },

  ];

  const materialtransactionActions = (row: IMaterialTransaction) => (
    <IconButton
      onClick={() =>
        row.refType === "receive" && !row.order
          ? navigate(`/inventory-receive-create/${row.refId}`)
          : (row.order ? navigate(`/process-order-edit/${row.order}`) : navigate(`/inventory-transfer/${row.transfer}`))
      }
    >
      <KeyboardArrowRightIcon />
    </IconButton>
  );

  const actionsButton = (
    <>
      <Typography color="text.primary">
        {`จำนวนทั้งหมด: ${totalQuantity ? totalQuantity?.toFixed(2) : 0}`}
      </Typography>
      <Typography color="text.primary">
        {`มูลค่าเฉลี่ย: ${totalAverageCost ? thaiFormatNumber(totalAverageCost.toString()) : 0}`}
      </Typography>
    </>
  );

  const searchByCriteria = (pageNumber: number = 0, rowLimit?: number) => {
    setPage(pageNumber);
    setLimit(rowLimit ?? 10);
    const criteria: IMaterialTransactionCriteria = {
      materialId: props.material?.id || "",
      warehouseId: getValuesTransaction("warehouseId") === "all" ? undefined : getValuesTransaction("warehouseId"),

      dateFrom: searchDateType === "all" ? undefined : dayjs(getValuesTransaction("dateFrom")).format("YYYY-MM-DD"),
      dateTo: searchDateType === "all" ? undefined : dayjs(getValuesTransaction("dateTo")).format("YYYY-MM-DD"),
      page: pageNumber,
      limit: rowLimit ?? 10,
    };

    props.onSearchByCriteria(criteria);

    const warehouseId = getValuesTransaction("warehouseId");
    if (warehouseId !== undefined) {
      setSelectedWarehouse(warehouseId);
    }

  };

  const onDelete = () => {
    const data = getValues();
    props.onDelete({ ...data, isDelete: true });
    reset();
  };

  const onFormSubmit = (data: IMaterial) => {
    const formatData = {
      ...data,
      valueUnit: data.valueUnit || 0,
      height: data.height || 0,
      width: data.width || 0,
      length: data.length || 0,
      CBM: data.CBM || 0,
      weight: data.weight || 0,
    };

    props.onUpdate(formatData);
    reset();
  };

  const onSubmit = (type: "create" | "delete") => {
    if (type === "delete") {
      setOpenConfirm({
        open: true,
        title: "ลบข้อมูล",
        message: "คุณต้องการลบข้อมูลนี้ใช่หรือไม่",
        color: "error",
        type: "delete",
      });
    } else {
      handleSubmit((data) => {
        setOpenConfirm({
          open: true,
          title: "บันทึกข้อมูล",
          message: "คุณต้องการบันทึกข้อมูลนี้ใช่หรือไม่",
          color: "primary",
          type: "create",
        });
      })();
    }
  };

  const getValueUnitLabel = () => {
    if (unit && subUnit) {
      return `${unit} / ${subUnit}`;
    }
    return "ค่าเทียบหน่วย";
  };

  const height = watch("height");
  const width = watch("width");
  const length = watch("length");
  const calCBM = () => {
    if (height && width && length) {
      const sum = (height * width * length) / 1000000;
      return Math.round(sum * 100) / 100;
    }
    return 0;
  };

  useEffect(() => {
    const result = calCBM();
    setValue("CBM", result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [height, width, length]);

  return (
    <Box component={"div"}>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={1} sm={1} md={0.5} lg={0.5}>
          <IconButton aria-label="back" onClick={() => navigate("/inventory-material")}>
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={11} sm={8} md={8.5} lg={8.5} display={"flex"} alignItems={"center"}>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Typography>การจัดการวัสดุ</Typography>
            <Link component="button" onClick={() => navigate("/inventory-material")}>
              รายการ
            </Link>
            <Typography variant="h6" color="text.primary">
              รายละเอียด
            </Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12} sm={3} md={3} lg={3} display={"flex"} justifyContent={"end"}>
          {(props.material && props.permission?.delete) && (
            <Button
              variant="contained"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={() => onSubmit("delete")}
            >
              ลบข้อมูล
            </Button>
          )}
        </Grid>
      </Grid>

      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Card>
            <CardContent>
              <Box component="form" onSubmit={handleSubmit(onFormSubmit)} mt={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography variant="h6">ข้อมูลวัสดุ</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={4}>
                    <TextField
                      fullWidth
                      label="ชื่อวัสดุ"
                      {...register("name", {
                        required: "กรุณาระบุชื่อวัสดุ",
                      })}
                      error={!!errors.name}
                      helperText={errors.name ? errors.name.message : ""}
                      InputLabelProps={{ shrink: getValues("name") ? true : undefined }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={4}>
                    <TextField
                      fullWidth
                      label="มูลค่า"
                      type="number"
                      {...register("price", {
                        required: "กรุณาระบุมูลค่า",
                        min: { value: 0, message: "มูลค่าต้องไม่ติดลบ" },
                      })}
                      error={!!errors.price}
                      helperText={errors.price ? errors.price.message : ""}
                      InputLabelProps={{ shrink: getValues("price") ? true : undefined }}
                      InputProps={{ inputProps: { min: 0 } }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={4} display={"flex"} alignItems={"center"}>
                    <Typography >
                      ต้นทุนเฉลี่ย (ต่อชิ้น): {getValues("avgPrice") || "0.00"}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={4} md={4} lg={4} >
                    <TextField
                      fullWidth
                      label="หน่วยหลัก"
                      {...register("unit", {
                        required: "กรุณาระบุหน่วยหลัก",
                      })}
                      error={!!errors.unit}
                      helperText={errors.unit ? errors.unit.message : ""}
                      InputLabelProps={{ shrink: getValues("unit") ? true : undefined }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4}>
                    <TextField
                      fullWidth
                      label="หน่วยรอง"
                      {...register("subUnit")}
                      InputLabelProps={{ shrink: getValues("subUnit") ? true : undefined }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4}>
                    <TextField
                      fullWidth
                      label={getValueUnitLabel()}
                      type="number"
                      defaultValue={0}
                      {...register("valueUnit", {
                        min: { value: subUnit ? 1 : 0, message: subUnit ? "จำนวนวัสดุต้องไม่ติดลบและต้องมากกว่า 0" : "จำนวนวัสดุต้องไม่ติดลบ" },
                        required: subUnit ? "กรุณาระบุค่าเทียบหน่วย" : false,
                      })}
                      error={!!errors.valueUnit}
                      helperText={errors.valueUnit ? errors.valueUnit.message : ""}
                      InputLabelProps={{ shrink: getValues("valueUnit") ? true : undefined }}
                      InputProps={{ inputProps: { min: subUnit ? 1 : 0 } }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4}>
                    <TextField
                      fullWidth
                      label={"ความสูง (cm)"}
                      type="number"
                      defaultValue={0}
                      {...register("height", {
                        min: { value: 0, message: "ความสูงต้องไม่ติดลบ" },
                      })}
                      InputLabelProps={{ shrink: getValues("height") ? true : undefined }}
                      InputProps={{ inputProps: { min: 0 } }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4}>
                    <TextField
                      fullWidth
                      label={"ความกว้าง (cm)"}
                      type="number"
                      defaultValue={0}
                      {...register("width", {
                        min: { value: 0, message: "ความกว้างต้องไม่ติดลบ" },
                      })}
                      InputLabelProps={{ shrink: getValues("width") ? true : undefined }}
                      InputProps={{ inputProps: { min: 0 } }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4}>
                    <TextField
                      fullWidth
                      label={"ความยาว (cm)"}
                      type="number"
                      defaultValue={0}
                      {...register("length", {
                        min: { value: 0, message: "ความยาวต้องไม่ติดลบ" },
                      })}
                      InputLabelProps={{ shrink: getValues("length") ? true : undefined }}
                      InputProps={{ inputProps: { min: 0 } }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4}>
                    <TextField
                      fullWidth
                      label={"CBM"}
                      type="number"
                      defaultValue={0}
                      {...register("CBM", {
                        min: { value: 0, message: "CBM ต้องไม่ติดลบ" },
                      })}
                      InputLabelProps={{ shrink: getValues("CBM") ? true : undefined }}
                      InputProps={{ inputProps: { min: 0 } }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4}>
                    <TextField
                      fullWidth
                      label={"น้ำหนัก (kg)"}
                      type="number"
                      defaultValue={0}
                      {...register("weight", {
                        min: { value: 0, message: "น้ำหนักต้องไม่ติดลบ" },
                      })}
                      InputLabelProps={{ shrink: getValues("weight") ? true : undefined }}
                      InputProps={{ inputProps: { min: 0 } }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={4}>
                    <FormControl fullWidth>
                      <FormControlLabel
                        control={
                          <Checkbox {...register("isMain")} checked={isMain} />
                        }
                        label="วัสดุหลัก"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Divider />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} display={"flex"} justifyContent={"end"}>
                        <Stack spacing={2} direction="row">
                          <Button
                            variant="outlined"
                            fullWidth
                            onClick={() => navigate("/inventory-material")}
                          >
                            ยกเลิก
                          </Button>
                          {props.permission?.create || (props.permission?.edit && props.material?.id) ?
                            <Button
                              variant="contained"
                              color="primary"
                              fullWidth
                              onClick={() => onSubmit("create")}
                              style={{ marginLeft: "8px" }}
                            >
                              บันทึก
                            </Button>
                            : <></>}
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <DialogConfirm
                  open={openConfirm.open}
                  type={openConfirm.type === "delete" ? "error" : "success"}
                  title={openConfirm.title}
                  message={openConfirm.message}
                  onClose={() => setOpenConfirm({ ...openConfirm, open: false })}
                  onSubmit={() => {
                    setOpenConfirm({ ...openConfirm, open: false });
                    if (openConfirm.type === "delete") {
                      onDelete();
                    } else {
                      handleSubmit(onFormSubmit)();
                    }
                  }}
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} >
                  <Typography variant="h6">รายการเคลื่อนย้ายวัสดุ</Typography>
                </Grid>

                <Grid item xs={12} sm={3} md={3} lg={3}>
                  <Controller
                    name="warehouseId"
                    control={controlTransaction}
                    render={({ field }) => (
                      <FormControl fullWidth>
                        <InputLabel id="warehouse-select-label">คลัง</InputLabel>
                        <Select
                          labelId="warehouse-select-label"
                          value={field.value || "all"}
                          onChange={(e) => field.onChange(e.target.value)}
                          label="คลัง"
                        >
                          <MenuItem value="all">ทั้งหมด</MenuItem>
                          {props.warehousesData?.map((warehouse) => (
                            <MenuItem key={warehouse.id} value={warehouse.id}>
                              {warehouse.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3} lg={3} >
                  <FormControl fullWidth>
                    <InputLabel id="search-select-label">เลือกช่วงเวลา</InputLabel>
                    <Select
                      labelId="search-select-label"
                      id="search-select"
                      label="เลือกช่วงเวลา"
                      value={searchDateType}
                      onChange={(e) => setSearchDateType(e.target.value)}
                    >
                      <MenuItem value="all">ทั้งหมด</MenuItem>
                      <MenuItem value="date">วันที่ทำรายการ</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                {searchDateType === "date" ? (
                  <>
                    <Grid item xs={12} sm={2} md={2} lg={2}>
                      <Controller
                        name="dateFrom"
                        control={controlTransaction}
                        render={({ field }) => (
                          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'th'}>
                            <DatePicker
                              label="ตั้งแต่วันที่"
                              sx={{ width: "100%" }}
                              value={field.value ? dayjs(field.value) : dayjs()}
                              onChange={(newValue: any) => {
                                field.onChange(newValue);
                                setDateRangeToStorage(STORAGE_KEYS.INVENTORY, newValue.startOf('day').toDate(), dayjs(getValuesTransaction('dateTo')) ?? dayjs().startOf('day'));
                              }}
                            />
                          </LocalizationProvider>
                        )}
                      />

                    </Grid>
                    <Grid item xs={12} sm={2} md={2} lg={2}>
                      <Controller
                        name="dateTo"
                        control={controlTransaction}
                        render={({ field }) => (
                          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'th'}>
                            <DatePicker
                              label="ถึงวันที่"
                              sx={{ width: "100%" }}
                              value={field.value ? dayjs(field.value) : dayjs()}
                              onChange={(newValue: any) => {
                                field.onChange(newValue);
                                setDateRangeToStorage(STORAGE_KEYS.INVENTORY, dayjs(getValuesTransaction('dateFrom')) ?? dayjs().startOf('month').startOf('day'), newValue.startOf('day').toDate());
                              }}
                            />
                          </LocalizationProvider>
                        )}
                      />

                    </Grid>
                  </>
                ) : (
                  <Grid item xs={0} sm={4} md={4} lg={4} />
                )}
                <Grid item xs={12} sm={2} md={2} lg={2} textAlign="end" alignSelf="center">
                  <Button
                    variant="contained"
                    startIcon={<SearchIcon />}
                    onClick={() => searchByCriteria(0)}
                  >
                    ค้นหา
                  </Button>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TableDataList
                    data={props.materialTransactionsData?.datas || []}
                    total={props.materialTransactionsData?.total || 0}
                    columns={materialTransactionColumns}
                    rowActions={materialtransactionActions}
                    actionButtons={actionsButton}
                    page={page}
                    limit={limit}
                    onPageChange={(newPage) => {
                      setPage(newPage);
                      searchByCriteria(newPage, limit);
                    }}
                    onRowsPerPageChange={(newLimit) => {
                      setLimit(newLimit);
                      setPage(0);
                      searchByCriteria(0, newLimit);
                    }}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MaterialDetailView;
