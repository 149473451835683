import { observer } from "mobx-react-lite";
import { useState, useEffect } from "react";
import userGroupService, { IUserGroupCriteria, IUserGroupListView } from "../Services/UserGroupService";
import UserGroupsListView from "../Views/UserGroupsListView";
import { IUserDetail } from "../../Login/Services/LoginService";
import getPermissionBySubMenu from "../../../utils/getPermissionBySubMenu";

const UserGroupsListController = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [permission, setPermission] = useState<IUserDetail>();
  const [userData, setUserData] = useState<IUserGroupListView>();
  const defaultCriteria: IUserGroupCriteria = {
    name: '',
    page: 0,
    limit: 10,
  };

  useEffect(() => {
    getByCriteria(defaultCriteria);
    checkPermissionActionButton();
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , []);

  const getByCriteria = async (criteria: IUserGroupCriteria) => {
    try {
      setIsLoading(true);
      await userGroupService.getByCriteria(criteria).then((data) => {
        setUserData(data);
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };


  const create = async (data: any) => {
    try {
      setIsLoading(true);
      await userGroupService.create(data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const update = async (data: any) => {
    try {
      setIsLoading(true);
      await userGroupService.update(data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const remove = async (id: string) => {
    try {
      setIsLoading(true);
      await userGroupService.delete(id);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const checkPermissionActionButton = () => {
    const result = getPermissionBySubMenu("user-group");
    if (result) {
      setPermission(result);
    }
  };


  return <UserGroupsListView onCreate={create} onUpdate={update} onRemove={remove} isLoading={isLoading} onSearchByCriteria={getByCriteria} data={userData ?? {} as IUserGroupListView} permission={permission ?? {} as IUserDetail} />;
});

export default UserGroupsListController;
