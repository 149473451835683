import { configure, makeAutoObservable } from "mobx";
import apiService from "../../../services/ApiService";
import { IUser } from "../../UserManagement/Services/RoleService";

export interface ISupplierType {
  id: string;
  name: string;
  isDelete: boolean;
  // TODO : uncomment this when user ready
  // userId : string;
  createAt?: Date;
  updateAt?: Date;
  createBy?: IUser | undefined;
  updateBy?: IUser | undefined;
}

export interface ISupplierTypeCriteria {
  name: string;
  page: number;
  limit: number;
}

export interface ISupplierTypeListView {
  datas?: ISupplierType[] | undefined;
  all: number;
  limit: number;
  page: number;
  total: number;
}


class SupplierTypeService {
  supplierTypes: ISupplierTypeListView | undefined;
  supplierTypeList: ISupplierType[] | undefined;
  constructor() {
    configure({
      enforceActions: "never",
    });
    makeAutoObservable(this);
  }



  async getAll() {
    try {
      const supplierTypes = await apiService.get("supplier/type/all");
      this.supplierTypeList = supplierTypes;
      return this.supplierTypeList;
    } catch (error) {
      throw error;
    }
  }

  async getByCriteria(criteria: ISupplierTypeCriteria) {
    try {
      const supplierTypes = await apiService.post(`supplier/type/criteria`, criteria);
      this.supplierTypes = supplierTypes;
      return this.supplierTypes;
    } catch (error) {
      throw error;
    }
  }

  async create(supplierType: ISupplierType) {
    try {
      await apiService.post("supplier/type", supplierType);
    } catch (error) {
      throw error;
    }
  }

  async update(supplierType: ISupplierType) {
    try {
      await apiService.put(`supplier/type/${supplierType.id}`, supplierType);
    } catch (error) {
      throw error;
    }
  }

  async delete(supplierType: ISupplierType) {
    try {
      await apiService.delete(`supplier/type/${supplierType.name}`);
    } catch (error) {
      throw error;
    }
  }

  get supplierType() {
    return this.supplierTypes;
  }

  get supplierTypeListData() {
    return this.supplierTypeList;
  }
}

const supplierTypeService = new SupplierTypeService();
export default supplierTypeService;