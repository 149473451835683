import { Box, Grid, Typography } from "@mui/material";
import dayjs from "dayjs";
import React, { } from "react";
import { IPrintPackingList } from "../../../features/ProductProcess/Services/PackagingService";

interface IPrintPackingListProps {
  title?: string;
  date?: string;
  data: IPrintPackingList;
}

const PrintPackingList = React.forwardRef((props: IPrintPackingListProps, ref) => {
  return (
    <Box ref={ref} sx={{
      margin: 0,
      width: "100%",
      borderCollapse: "collapse",
      paddingLeft: '20px',
      paddingRight: '20px',
      '&@page': {
        size: 'A4 portrait',
      },
      marginLeft: '30px',
      marginTop: '20px',
      overflow: 'hidden',
      fontSize: 8,
      display: 'block',
      pageBreakBefore: 'auto', // Allow page break before this element 
    }}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography variant="h6" align="center" gutterBottom>
            {props.title}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography variant="h6" align="center" gutterBottom>
            {`โรงอบ - ${props.data.vapourHeatTreatment && props.data.vapourHeatTreatment.name ? props.data.vapourHeatTreatment.name : ''}`}
          </Typography>
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4}>
          <Typography align="center">ว/ด/ป</Typography>
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4}>
          <Typography align="center">{dayjs().format("DD/MM/YYYY")}</Typography>
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4}></Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container spacing={1} mt={1}>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Typography align="center">รายการ</Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Typography align="center">จำนวน</Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Typography align="center">หน่วย</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container spacing={1} mt={1}>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Typography>รับเข่งมะม่วง</Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Typography align="center">{props.data.totalProductRecive ? props.data.totalProductRecive?.toLocaleString() : '-'}</Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Typography align="center">ใบ</Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Typography>น้ำหนักชาวสวน</Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Typography align="center">{props.data.totalWeightFromFarm ? props.data.totalWeightFromFarm?.toLocaleString() : '-'}</Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Typography align="center">Kg</Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Typography>น้ำหนักหน้าโรงอบ</Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Typography align="center">{props.data.totalWeightFromVHT ? props.data.totalWeightFromVHT?.toLocaleString() : '-'}</Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Typography align="center">Kg</Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Typography>ส่งผ่าตรวจ</Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Typography align="center">{props.data.totalWeightInspection ? props.data.totalWeightInspection?.toLocaleString() : '-'}</Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Typography align="center">Kg</Typography>
            </Grid>
            {
              props.data.vhts && props.data.vhts.length > 0 ? props.data.vhts.map((vht, index) => {
                return (
                  <React.Fragment key={index}>
                    <Grid item xs={4} sm={4} md={4} lg={4} >
                      <Typography>
                        {index === 0 ? `VHT (รอบที่ ${index + 1})` : `\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0(รอบที่ ${index + 1})`}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4}>
                      <Typography align="center">{vht.totalWeight.toLocaleString()}</Typography>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4}>
                      <Typography align="center">ใบ</Typography>
                    </Grid>
                  </React.Fragment>
                );
              })
                : null
            }
            {
              props.data.censorChecks ? props.data.censorChecks.toString().split(',').map((censorCheck, index) => {
                return (
                  <React.Fragment key={index}>
                    <Grid item xs={4} sm={4} md={4} lg={4}>
                      <Typography>
                        {index === 0 ? `น้ำหนักตรวจเซ็นเซอร์ (ตู้ที่ ${index + 1})` : `\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0(ตู้ที่ ${index + 1})`}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4}>
                      <Typography align="center">{censorCheck.toLocaleString()}</Typography>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4}>
                      <Typography align="center">Kg</Typography>
                    </Grid>
                  </React.Fragment>
                );
              })
                : null
            }
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Typography>คัดออกก่อนอบ(ตกเกรด)</Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Typography align="center">{props.data.totalWeightEliminateBeforeBake ? props.data.totalWeightEliminateBeforeBake?.toLocaleString() : '-'}</Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Typography align="center">Kg</Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Typography>น้ำหนักขั้วที่ตัดทิ้ง</Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Typography align="center">{props.data.totalWeightCut ? props.data.totalWeightCut?.toLocaleString() : '-'}</Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Typography align="center">Kg</Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Typography>คัดออกหลังอบ(คืน)</Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Typography align="center">{props.data.totalWeightEliminateAfterBake ? props.data.totalWeightEliminateAfterBake?.toLocaleString() : '-'}</Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Typography align="center">Kg</Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Typography>คืนมะม่วงให้ชาวสวน</Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Typography align="center">{props.data.totalWeightReturnToFarm ? props.data.totalWeightReturnToFarm?.toLocaleString() : '-'}</Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Typography align="center">Kg</Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Typography>คืนเข่งมะม่วง</Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Typography align="center">{props.data.totalBasketReturnToFarm ? props.data.totalBasketReturnToFarm?.toLocaleString() : '-'}</Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Typography align="center">ใบ</Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Typography>PACK</Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Typography align="center">{props.data.totalPack ? props.data.totalPack?.toLocaleString() : '-'}</Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Typography align="center">box</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} mt={1}>
          <Typography variant="h6" align="center" gutterBottom>
            Size
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} mt={1}>
          <Grid container spacing={1} mt={1}>
            {
              props.data && props.data.capacitys && props.data.capacitys.length > 0 ? props.data.capacitys.map((capacity, index) => {
                return (
                  <React.Fragment key={index}>
                    <Grid item xs={4} sm={4} md={4} lg={4}>
                      <Typography>{capacity.name}</Typography>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4}>
                      <Typography align="right">{capacity.qty.toLocaleString()}</Typography>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4}>
                      <Typography align="center">box</Typography>
                    </Grid>
                  </React.Fragment>
                );
              })
                : null
            }
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} mt={1}>
          <Typography variant="h6" align="center" gutterBottom>
            สรุป
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container spacing={1} mt={1}>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Typography>น้ำหนักรับซื้อเข้า</Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Typography align="center">{props.data.totalWeightRecive ? props.data.totalWeightRecive?.toLocaleString() : '-'}</Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Typography align="center">Kg</Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Typography>น้ำหนักคัดออก</Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Typography align="center">{props.data.totalWeightEliminate ? props.data.totalWeightEliminate?.toLocaleString() : '-'}</Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Typography align="center">Kg</Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Typography>น้ำหนักตรวจ</Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Typography align="center">{props.data.totalWeightCheck ? props.data.totalWeightCheck?.toLocaleString() : '-'}</Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Typography align="center">Kg</Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Typography>น้ำหนักลงกล่อง</Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Typography align="center">{props.data.totalWeightPacking ? props.data.totalWeightPacking?.toLocaleString() : '-'}</Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Typography align="center">Kg</Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Typography>น้ำหนักขาด/เกิน</Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Typography align="center">{props.data.totalWeightDrift ? props.data.totalWeightDrift?.toLocaleString() : '-'}</Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Typography align="center">Kg</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
});

export default PrintPackingList;