import { Box, Paper, Typography } from "@mui/material";
import { FC } from "react";
// import TrendingUpIcon from "@mui/icons-material/TrendingUp";
// import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import thaiFormatNumber from "../utils/thaiFormatNumber";

interface TotalValuesCardProps {
  total: { label: string; value: number | null; percentage?: number; };
  index: number;
}

const TotalValuesCard: FC<TotalValuesCardProps> = (props) => {
  // const isNegative = props.total.percentage && props.total.percentage < 0;
  const backGroundColorBox = ['#f3e5f5', '#fcf7dd', '#dcebfa', '#fddcdc'];

  return (
    <Paper sx={{ padding: 2, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', marginBottom: 1 }}>
        <Typography variant="h6" sx={{ fontWeight: 'medium' }}>
          {props.total.label}
        </Typography>

        {/* <Box sx={{ flexGrow: 1 }} /> */}

        {/* NOTE: uncomment if want to show percentage */}
        {/* <Box sx={{
          display: 'flex',
          alignItems: 'center',
          color: isNegative ? 'red' : 'green',
          backgroundColor: isNegative ? '#ffebee' : '#e0f2f1',
          padding: '0 8px',
          borderRadius: '16px',
        }}>
          {isNegative ? <TrendingDownIcon sx={{ fontSize: 16 }} /> : <TrendingUpIcon sx={{ fontSize: 16 }} />}
          <Typography variant="body2" ml={0.5}>
            {props.total.percentage ? `${Math.abs(props.total.percentage).toFixed(2)}%` : 'N/A'}
          </Typography>
        </Box> */}
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <Box sx={{
          width: 60,
          height: 60,
          borderRadius: '50%',
          backgroundColor: `${backGroundColorBox[props.index] || '#f3e5f5'}`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginRight: 2,
        }}>
          <Typography variant="h4">฿</Typography>
        </Box>

        <Box sx={{ flexGrow: 1, textAlign: 'left' }}>
          <Typography variant="h4" sx={{ fontWeight: 'bold', display: 'inline-flex', alignItems: 'baseline' }}>
            {thaiFormatNumber((props.total.value ?? 0).toFixed(2)) ?? '*'}
            <Typography variant="caption" ml={0.5}>
              บาท
            </Typography>
          </Typography>
        </Box>
      </Box>


    </Paper>
  );
};



export default TotalValuesCard;