import { createTheme } from "@mui/material";
import "../res/fonts/Sarabun/style.css";

export const theme = createTheme({
  palette: {
    mode: "light",
    background: {
      default: "#FAFAFA"
    },
  },
  typography: {
    htmlFontSize: 18,
    fontFamily: "'Sarabun', sans-serif",
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
  },
  components: {
    MuiTextField: {
      defaultProps: {
        onWheel: (e) => {
          if (e.target instanceof HTMLInputElement && e.target.type === 'number') {
            e.target.blur();
          }
        },
      },
      styleOverrides: {
        root: {
          '& input[type=number]': {
            '&::-webkit-inner-spin-button': {
              display: 'none',
              margin: 0,
            },
            '&::-webkit-outer-spin-button': {
              display: 'none',
              margin: 0,
            },
            '&[type=number]': {
              MozAppearance: 'textfield', 
            },
          },
        },
      },
    },
  },
});
