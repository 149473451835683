import { observer } from "mobx-react-lite";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import userGroupService, { IUserGroup } from "../Services/UserGroupService";
import UserGroupDetailView from "../Views/UserGroupDetailView";
import { enqueueSnackbar } from "notistack";
import clearState from "../../../utils/resetState";
import { IUserDetail } from "../../Login/Services/LoginService";
import getPermissionBySubMenu from "../../../utils/getPermissionBySubMenu";

const UserGroupDetailController = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [permission, setPermission] = useState<IUserDetail>();
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (id) { getOne(id); }
    checkPermissionActionButton();
    return () => {
      clearState(userGroupService, ["userGroupData"]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  const getOne = async (id: string) => {
    try {
      setIsLoading(true);
      await userGroupService.getOne(id);
    } catch (error) {
      console.error("Error searching transfers by criteria:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const create = async (data: any) => {
    try {
      setIsLoading(true);
      const response = await userGroupService.create(data);
      if (response.id) {
        enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
        navigate(`/user-group-detail/${response.id}`);
      } else {
        enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const update = async (data: any) => {
    try {
      setIsLoading(true);
      await userGroupService.update(data);
      enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      getOne(data.id);
    } catch (error) {
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const remove = async (id: string) => {
    try {
      setIsLoading(true);
      await userGroupService.delete(id);
      enqueueSnackbar("ลบข้อมูลสำเร็จ", { variant: "success" });
      navigate(`/user-group`);
    } catch (error) {
      enqueueSnackbar("ลบข้อมูลไม่สำเร็จ", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const checkPermissionActionButton = () => {
    const result = getPermissionBySubMenu("user-group");
    if (result) {
      setPermission(result);
    }
  };

  return <UserGroupDetailView onCreate={create} onUpdate={update} onRemove={remove} isLoading={isLoading} data={id ? userGroupService.getGroupData as IUserGroup : undefined} permission={permission ?? {} as IUserDetail} />;
});

export default UserGroupDetailController;
