import { FC, useEffect, useState } from "react";
import { IWarehouse } from "../Services/WareshousesService";
import { IProvince, IDistrict, ISubDistrict } from "../../Company/Services/CompanyService";
import { Controller, useForm } from "react-hook-form";
import { Autocomplete, Box, Breadcrumbs, Button, ButtonProps, Card, CardContent, Divider, Grid, IconButton, Link, Stack, TextField, Typography } from "@mui/material";
import DialogConfirm from "../../../components/DialogConfirm";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import { IUserDetail } from "../../Login/Services/LoginService";
import TableDataList from "../../../components/TableDataList";
import { IMaterialInWarehouse, IMaterialInWarehouseCriteria, IMaterialInWarehouses } from "../Services/MaterialsService";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { IUser } from "../../UserManagement/Services/RoleService";

interface WarehousesDetailViewProps {
  warehouse?: IWarehouse;
  onUpdate: (data: IWarehouse) => void;
  provinceData?: IProvince[];
  districtData?: IDistrict[];
  subDistrictData?: ISubDistrict[];
  isLoading: boolean;
  onFetchDistrict: (id: number | undefined) => void;
  onFetchSubDistrict: (id: number | undefined) => void;
  materialInWarehouseData?: IMaterialInWarehouses;
  onSearchByCriteria: (criteria: IMaterialInWarehouseCriteria) => void;
  permission: IUserDetail;
  userOption: IUser[];
}

const WarehousesDetailView: FC<WarehousesDetailViewProps> = (props) => {
  const { register, handleSubmit, reset, getValues, control, setValue, formState: { errors } } = useForm<IWarehouse>();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);

  const [openConfirm, setOpenConfirm] = useState({
    open: false,
    title: "",
    message: "",
    color: "primary" as ButtonProps["color"],
    type: "create" as "create" | "update" | "delete",
  });

  const materialColumns = [
    { label: 'รายการ', render: (row: IMaterialInWarehouse) => row.materialName },
    { label: 'ราคาต่อหน่วย', render: (row: IMaterialInWarehouse) => row.materialPrice },
    { label: 'จำนวน', render: (row: IMaterialInWarehouse) => row.totalQuantity },
  ];

  const materialActions = (row: IMaterialInWarehouse) => (
    <IconButton
      onClick={() => {
        navigate(`/inventory-material-detail/${row.materialId}`);
      }}>
      <KeyboardArrowRightIcon />
    </IconButton>
  );

  useEffect(() => {
    if (props.warehouse) {
      reset(props.warehouse);
    }
  }, [props.warehouse, reset]);

  useEffect(() => {
    if (getValues("province")) {
      props.onFetchDistrict(getValues("province")?.id);
    } else {
      setValue("district", undefined);
      setValue("subDistrict", undefined);
      setValue("zipCode", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValues("province")]);

  useEffect(() => {
    if (getValues("district")) {
      props.onFetchSubDistrict(getValues("district")?.id);
    } else {
      setValue("subDistrict", undefined);
      setValue("zipCode", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValues("district")]);


  const onFormSubmit = (data: IWarehouse) => {
    props.onUpdate(data);
    reset();
  };

  const onSubmit = (type: "create" | "delete") => {
    if (type === "delete") {
      setOpenConfirm({
        open: true,
        title: "ลบข้อมูล",
        message: "คุณต้องการลบข้อมูลนี้ใช่หรือไม่",
        color: "error",
        type: "delete",
      });
    } else {
      handleSubmit((data) => {
        setOpenConfirm({
          open: true,
          title: "บันทึกข้อมูล",
          message: "คุณต้องการบันทึกข้อมูลนี้ใช่หรือไม่",
          color: "primary",
          type: "create",
        });
      })();
    }
  };

  const searchByCriteria = (pageNumber: number = 0, rowLimit?: number) => {
    setPage(pageNumber);
    setLimit(rowLimit ?? 10);
    const criteria: IMaterialInWarehouseCriteria = {
      warehouseId: (props.warehouse?.id ?? '').toString(),
      page: pageNumber,
      limit: rowLimit ?? 10,
    };

    props.onSearchByCriteria(criteria);
  };
  return (
    <Box component={"div"}>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={1} sm={1} md={0.5} lg={0.5}>
          <IconButton
            aria-label="back"
            onClick={() => navigate("/inventory-warehouses")}
          >
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={11} sm={8} md={8.5} lg={8.5} display={"flex"} alignItems={"center"}>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Typography>คลังสินค้า</Typography>
            <Link component="button" onClick={() => navigate("/inventory-warehouses")}>
              รายการ
            </Link>
            <Typography variant="h6" color="text.primary">
              รายละเอียด
            </Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12} sm={3} md={3} lg={3} display={"flex"} justifyContent={"end"}>
          {props.warehouse && (
            <Button
              id={`${!props.permission.delete ? "hidden" : "delete"}`}
              variant="contained"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={() => onSubmit("delete")}
            >
              ลบข้อมูล
            </Button>
          )}
        </Grid>
      </Grid>

      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Card>
            <CardContent>
              <Box component="form" onSubmit={handleSubmit(onFormSubmit)} mt={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TextField
                      fullWidth
                      label="ชื่อคลังวัสดุ"
                      {...register("name", { required: true })}
                      error={!!errors.name}
                      helperText={errors.name ? "กรุณาระบุชื่อคลังวัสดุ" : ""}
                      InputLabelProps={{
                        shrink: getValues("name") ? true : undefined
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={8} lg={8}>
                    <TextField
                      fullWidth
                      label="ที่อยู่"
                      {...register("address", { required: true })}
                      error={!!errors.address}
                      helperText={errors.address ? "กรุณาระบุที่อยู่" : ""}
                      InputLabelProps={{
                        shrink: getValues("address") ? true : undefined
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Controller
                      name="province"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          value={field?.value as IProvince || ""}
                          options={props.provinceData || []}
                          getOptionLabel={(option: any) => {
                            if (typeof option === 'string') {
                              return option;
                            }
                            if (option.inputValue) {
                              return option.inputValue;
                            }
                            return option.nameTh || "";
                          }}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          onChange={(event, newValue) => {
                            field.onChange(newValue);
                            if (newValue?.id) {
                              setValue("district", undefined);
                              setValue("subDistrict", undefined);
                              setValue("zipCode", "");
                              props.onFetchDistrict(newValue.id);
                              props.onFetchSubDistrict(undefined);
                            }
                          }}
                          renderInput={(params) => <TextField
                            error={!!errors.province}
                            helperText={errors.province ? "กรุณาเลือกจังหวัด" : ""}
                            {...params} label="จังหวัด" />}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Controller
                      name="district"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          disabled={!getValues("province")?.id}
                          value={field?.value as IDistrict || null}
                          options={props.districtData || []}
                          getOptionLabel={(option: any) => {
                            if (typeof option === 'string') {
                              return option;
                            }
                            if (option.inputValue) {
                              return option.inputValue;
                            }
                            return option.nameTh || "";
                          }}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          onChange={(event, newValue) => {
                            field.onChange(newValue);
                            if (newValue?.id) {
                              setValue("subDistrict", undefined);
                              setValue("zipCode", "");
                              props.onFetchSubDistrict(newValue.id);
                            }
                          }}
                          renderInput={(params) => <TextField
                            error={!!errors.district}
                            helperText={errors.district ? "กรุณาเลือกอําเภอ" : ""}
                            {...params} label="อําเภอ" />}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Controller
                      name="subDistrict"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Autocomplete
                          disabled={!getValues("district")?.id}
                          {...field}
                          value={field?.value as ISubDistrict || null}
                          options={props.subDistrictData || []}
                          getOptionLabel={(option: any) => {
                            if (typeof option === 'string') {
                              return option;
                            }
                            if (option.inputValue) {
                              return option.inputValue;
                            }
                            return option.nameTh || "";
                          }}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          onChange={(event, newValue: any) => {
                            field.onChange(newValue);
                            if (newValue?.zipCode) {
                              setValue("zipCode", newValue.zipCode);
                            }
                          }}
                          renderInput={(params) => <TextField
                            error={!!errors.subDistrict}
                            helperText={errors.subDistrict ? "กรุณาเลือกตําบล" : ""}
                            {...params} label="ตําบล" />}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <TextField
                      label="รหัสไปรษณีย์"
                      fullWidth
                      {...register("zipCode", { required: true })}
                      error={!!errors.zipCode}
                      helperText={errors.zipCode ? "กรุณาระบุรหัสไปรษณีย์" : " "}
                      InputLabelProps={{
                        shrink: getValues("zipCode") ? true : undefined,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <TextField
                      fullWidth
                      label="เบอร์โทร"
                      {...register("tel")}
                      InputLabelProps={{
                        shrink: getValues("tel") ? true : undefined
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Divider />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} display={"flex"} justifyContent={"end"}>
                        <Stack spacing={2} direction="row">
                          <Button
                            variant="outlined"
                            fullWidth
                            onClick={() => navigate("/inventory-warehouses")}
                          >
                            ยกเลิก
                          </Button>
                          {props.permission.edit ?
                            <Button
                              variant="contained"
                              color="primary"
                              fullWidth
                              onClick={() => onSubmit("create")}
                              style={{ marginLeft: '8px' }}
                            >
                              บันทึก
                            </Button>
                            : <></>}
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <DialogConfirm
                  open={openConfirm.open}
                  type={openConfirm.type === "delete" ? "error" : "success"}
                  title={openConfirm.title}
                  message={openConfirm.message}
                  onClose={() => setOpenConfirm({ ...openConfirm, open: false })}
                  onSubmit={() => {
                    if (openConfirm.type === "delete") {
                      setValue("isDelete", true);
                      handleSubmit(onFormSubmit)();
                    } else {
                      setOpenConfirm({ ...openConfirm, open: false });
                      handleSubmit(onFormSubmit)();
                    }

                  }}
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Card>
            <CardContent>
              <Typography mb={2} variant="h6">รายการวัสดุ</Typography>
              <TableDataList
                data={props.materialInWarehouseData?.datas || []}
                total={props.materialInWarehouseData?.total || 0}
                columns={materialColumns}
                rowActions={materialActions}
                page={page}
                limit={limit}
                onPageChange={(newPage) => {
                  setPage(newPage);
                  searchByCriteria(newPage, limit);
                }}
                onRowsPerPageChange={(newLimit) => {
                  setLimit(newLimit);
                  setPage(0);
                  searchByCriteria(0, newLimit);
                }}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};
export default WarehousesDetailView;
