import { observer } from "mobx-react-lite";
import CostReportListView from "../Views/CostReportListView";
import { useEffect, useState } from "react";
import reportService, { ICostReport, ICostReports, IReportCriteria } from "../Services/ReportService";
import { clearLocalStorageKeyOnUnload, getDateRangeFromStorage } from "../../../utils/dateRangeLocalStorage";
import { STORAGE_KEYS } from "../../../constants/dateRangeStorageKey";
import dayjs from "dayjs";


const CostReportListController = observer(() => {
  const initialDateRange = getDateRangeFromStorage(STORAGE_KEYS.REPORT);
  const [isLoading, setIsLoading] = useState(false);
  const [costReportData, setCostReportData] = useState<ICostReports>();
  const defaultCriteria: IReportCriteria = {
    dateFrom: initialDateRange.dateFrom
      ? dayjs(initialDateRange.dateFrom).format('YYYY-MM-DD')
      : dayjs().startOf('month').format('YYYY-MM-DD'),
    dateTo: initialDateRange.dateTo
      ? dayjs(initialDateRange.dateTo).format('YYYY-MM-DD')
      : dayjs().startOf('day').format('YYYY-MM-DD'),
    page: 0,
    limit: 10,
  };

  useEffect(() => {
    getCostReportData(defaultCriteria);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  clearLocalStorageKeyOnUnload(STORAGE_KEYS.REPORT);

  const getCostReportData = async (criteria: IReportCriteria) => {
    try {
      setIsLoading(true);
      await reportService.getCostReports(criteria).then((data) => {
        data?.datas?.sort((a: ICostReport, b: ICostReport) => (b.orderDate?.toString() ?? '').localeCompare(a.orderDate?.toString() ?? ''));
        setCostReportData(data);
      });

    } catch (error) {
      console.error("An error occurred while getting the cost reports:", error);
    } finally {
      setIsLoading(false);
    }
  };


  return <CostReportListView isLoading={isLoading} costReportData={costReportData ?? {} as ICostReports} getCostReportData={getCostReportData}
  />;
});

export default CostReportListController;
