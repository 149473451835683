import { configure, makeAutoObservable } from "mobx";
import apiService from "../../../services/ApiService";
import { ISupplierType } from "./SupplierTypeService";
import { ISubDistrict, IDistrict, IProvince } from "../../Company/Services/CompanyService";
import { IUser } from "../../UserManagement/Services/RoleService";

export interface ISupplier {
  id: string;
  name: string;
  supplierType?: ISupplierType | undefined;
  address?: string;
  subDistrict?: ISubDistrict | undefined;
  district?: IDistrict | undefined;
  province?: IProvince | undefined;
  zipCode?: string;
  branch?: string;
  contract?: string;
  tel?: string;
  isDelete?: boolean;
  isOrchard?: boolean;
  createBy?: IUser | undefined;
  createAt?: string;
  updateBy?: IUser | undefined;
  updateAt?: string;
}

export interface ISupplierCriteria {
  name: string;
  isOrchard?: boolean;
  supplierType: string;
  page: number;
  limit: number;
}



export interface ISupplierListView {
  datas?: ISupplier[] | undefined;
  all: number;
  limit: number;
  page: number;
  total: number;
}


class SupplierService {
  suppliers: ISupplierListView | undefined;
  supplier: ISupplier | undefined;
  supplierList = [] as ISupplier[];

  constructor() {
    configure({
      enforceActions: "never",
    });
    makeAutoObservable(this);
  }

  async getAll() {
    try {
      this.supplierList = await apiService.get("supplier");
      return this.supplierList;
    } catch (error) {
      throw error;
    }
  }

  async getOne(id: string) {
    try {
      const supplier = await apiService.get(`supplier/${id}`);
      this.supplier = supplier;
      return supplier;
    } catch (error) {
      throw error;
    }
  }

  async getOrchardSupplier() {
    try {
      this.supplierList = await apiService.get("supplier/orchard/all");
      return this.supplierList;
    } catch (error) {
      throw error;
    }
  }

  async getSupplierType() {
    try {
      const supplierTypes = await apiService.get("supplier/type");
      return supplierTypes;
    } catch (error) {
      throw error;
    }
  }

  async getByCriteria(criteria: ISupplierCriteria) {
    try {
      const suppliers = await apiService.post(`supplier/criteria`, criteria);
      this.suppliers = suppliers;
      return this.suppliers;
    } catch (error) {
      throw error;
    }
  }

  async create(data: ISupplier) {
    try {
      const response = await apiService.post(`/supplier`, data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async update(data: ISupplier) {
    try {
      const response = await apiService.put(`/supplier/${data.id}`, data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async delete(id: string) {
    try {
      const response = await apiService.delete(`/supplier/${id}`);
      return response;
    } catch (error) {
      throw error;
    }
  }

  get suppliersData() {
    return this.suppliers;
  }

  get supplierData() {
    return this.supplier;
  }

  get supplierListData() {
    return this.supplierList;
  }

}
const supplierService = new SupplierService();
export default supplierService;
