import { observer } from "mobx-react-lite";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { IUserDetail } from "../../SuperAdminManagement/Services/UserService";
import gradeService, { IGradeCriteria, IGradeListView } from "../Services/GradeService";
import { IGrade } from "../Services/GradeService";
import getPermissionBySubMenu from "../../../utils/getPermissionBySubMenu";
import GradeListView from "../Views/GradeListView";

const GradeController = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [permission, setPermission] = useState<IUserDetail>();

  const defaultCriteria: IGradeCriteria = {
    name: '',
    page: 0,
    limit: 10,
  };

  useEffect(() => {
    fetchByCriteria(defaultCriteria);
    checkPermissionActionButton();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchByCriteria = async (criteria: IGradeCriteria) => {
    try {
      setIsLoading(true);
      await gradeService.getByCriteria(criteria);
    } catch (error) {
      console.error("An error occurred while getting the drivers:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const create = async (data: IGrade) => {
    try {
      await gradeService.create(data);
      enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      fetchByCriteria(defaultCriteria);
    } catch (error) {
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
    }
  };

  const update = async (data: IGrade) => {
    try {
      await gradeService.update(data.id ?? '', data);
      enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      fetchByCriteria(defaultCriteria);
    } catch (error) {
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
    }
  };

  const checkPermissionActionButton = () => {
    const result = getPermissionBySubMenu("setting-grade");
    if (result) {
      setPermission(result);
    }
  };

  return (
    <GradeListView
      onSubmit={create}
      onUpdate={update}
      onSearchByCriteria={fetchByCriteria}
      grades={gradeService.gradesData ?? {} as IGradeListView}
      isLoading={isLoading}
      permission={permission ?? {} as IUserDetail}
    />
  );
});

export default GradeController;