import { configure, makeAutoObservable } from "mobx";
import apiService from "../../../services/ApiService";
import { IUser } from "../../UserManagement/Services/RoleService";

export interface IExpenseType {
  id: string;
  name: string;
  isDelete: boolean;
  company: string;
  createBy: IUser;
  createAt: Date;
  updateBy: IUser;
  updateAt: Date;
}

export interface IExpenseTypeWithValue extends IExpenseType {
  value: string;
}

export interface IExpenseTypeCriteria {
  name: string;
  page: number;
  limit: number;
}

export interface IExpenseTypeListView {
  datas?: IExpenseType[] | undefined;
  all: number;
  limit: number;
  page: number;
  total: number;
}

class ExpenseTypeService {
  expenseTypes: IExpenseTypeListView | undefined;
  expenseTypeList: IExpenseTypeWithValue[] | undefined;
  expenseTypeData: IExpenseType | undefined;

  constructor() {
    configure({
      enforceActions: "never",
    });
    makeAutoObservable(this);
  }

  async getAll() {
    try {
      const expenseTypes = await apiService.get("expenses/type/all");
      this.expenseTypeList = expenseTypes;
      return this.expenseTypeList;
    } catch (error) {
      throw error;
    }
  }

  async getOne(id: string) {
    try {
      const expenseType = await apiService.get(`expenses/type/${id}`);
      this.expenseTypeData = expenseType;
      return this.expenseTypeData;
    } catch (error) {
      throw error;
    }
  }

  async getByCriteria(criteria: IExpenseTypeCriteria) {
    try {
      const expenseTypes = await apiService.post(`expenses/type/criteria`, criteria);
      this.expenseTypes = expenseTypes;
      return this.expenseTypes;
    } catch (error) {
      throw error;
    }
  }

  async create(expenseType: IExpenseType) {
    try {
      const response = await apiService.post(`expenses/type`, expenseType);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async update(expenseType: IExpenseType) {
    try {
      const response = await apiService.put(`expenses/type/${expenseType.id}`, expenseType);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async delete(id: string) {
    try {
      const response = await apiService.delete(`expenses/type/${id}`);
      return response;
    } catch (error) {
      throw error;
    }
  }

  get allExpenseTypes() {
    return this.expenseTypes;
  }

  get expenseType() {
    return this.expenseTypeData;
  }

  get expenseTypeListData() {
    return this.expenseTypeList;
  }
}
const expenseTypeService = new ExpenseTypeService();
export default expenseTypeService;
