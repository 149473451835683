import { FC, Fragment, useEffect, useState } from "react";
import { IUserDetail, IUserSuperAdmin } from "../Services/UserService";
import { IUserGroup } from "../../UserManagement/Services/UserGroupService";
import { menus, IMenu } from "../../../constants/menus";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { Autocomplete, Box, Breadcrumbs, Button, Card, CardContent, Checkbox, Divider, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, Stack, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, Link, createFilterOptions } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import DialogConfirm from "../../../components/DialogConfirm";
import SimpleBackdrop from "../../../components/SimpleBackdrop";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import { ICompany, IDistrict, IProvince, ISubDistrict } from "../Services/CompanyService";
import { IRole } from "../../UserManagement/Services/RoleService";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import UserGroupCreateView from "../../UserManagement/Views/UserGroupCreateView";
import { companyDatas } from "../../../constants/dialogCreateInputs";
import DialogCreate from "../../../components/DialogCreate";

interface UserDetailViewProps {
  data: IUserSuperAdmin | undefined;
  onCreate: (data: IUserSuperAdmin) => Promise<void>;
  onUpdate: (data: IUserSuperAdmin) => Promise<void>;
  onRemove: (id: string) => Promise<void>;
  isLoading: boolean;
  userGroup?: IUserGroup[] | undefined;
  company?: ICompany[] | undefined;
  onCreateDialog: (data: any, type: string) => void;
  onFetchData?: (id?: any, type?: string) => void;
  provinceData?: IProvince[];
  districtData?: IDistrict[];
  subDistrictData?: ISubDistrict[];
}
const filterOptions = createFilterOptions<any>();
const UserDetailView: FC<UserDetailViewProps> = (props) => {
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const allMenu: IMenu[] = menus.filter((menu: IMenu) => user.isSuperAdmin ? menu : !menu.isAdminMenu).map((menu: IMenu) => {
    menu.children = menu.children?.map((child: IMenu) => {
      return { ...child, isSelected: false, read: false, edit: false, create: false, delete: false };
    });
    return { ...menu, isSelected: false, read: false, edit: false, create: false, delete: false };
  });
  const { register, formState: { errors }, reset, getValues, control, watch, setValue, setError, handleSubmit
  } = useForm<IUserSuperAdmin>({
    defaultValues: {
      details: allMenu as IUserDetail[],
      isActive: true
    }
  });
  const { fields, replace } = useFieldArray({
    control,
    name: 'details',
    keyName: "key"
  });


  const navigate = useNavigate();
  const [openConfirm, setOpenConfirm] = useState({
    open: false,
    title: "",
    message: "",
    action: "create" as "create" | "edit" | "delete" | "warning",
    type: "success" as "success" | "error" | "warning",
    data: {} as IUserSuperAdmin
  });
  const [isCkeckAll, setIsCheckAll] = useState(false);
  const { id } = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [openCreateDailog, setOpenCreateDailog] = useState({ open: false, datas: [], type: "", title: "" });
  const [nameType, setNameType] = useState("");
  const [name, setName] = useState("");
  const _companyDatas: any = companyDatas({
    provinceData: props?.provinceData || [],
    districtData: props?.districtData || [],
    subDistrictData: props?.subDistrictData || [],
    name: name,
  }) || [];


  const handleClickShowPassword = (isConfirm?: boolean) => {
    if (isConfirm) { setShowConfirmPassword((show) => !show); } else { setShowPassword((show) => !show); }
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleChangeCheckall = () => {
    const _fields = fields.map((menu: IUserDetail) => {
      if (!isCkeckAll) {
        // parent menu
        menu.isSelected = !isCkeckAll;
        menu.isSelected = !isCkeckAll;
        menu.read = !isCkeckAll;
        menu.edit = !isCkeckAll;
        menu.create = !isCkeckAll;
        menu.delete = !isCkeckAll;

      } else if (isCkeckAll) {
        // parent menu
        menu.isSelected = false;
        menu.isSelected = false;
        menu.read = false;
        menu.edit = false;
        menu.create = false;
        menu.delete = false;
      };
      // child menu
      menu.children?.map((childMenu: IUserDetail) => {
        if (!isCkeckAll) {
          childMenu.isSelected = true;
          childMenu.read = true;
          childMenu.edit = true;
          childMenu.create = true;
          childMenu.delete = true;
        } else if (isCkeckAll) {
          childMenu.isSelected = false;
          childMenu.read = false;
          childMenu.edit = false;
          childMenu.create = false;
          childMenu.delete = false;
        };
        return childMenu;
      });
      return menu;
    });
    replace(_fields);
  };

  const handleOpenConfirm = (action: "create" | "edit" | "delete" | "warning" = "create") => {
    const data = getValues();
    setOpenConfirm({
      ...openConfirm,
      open: true,
      title: action !== "create" ? (action === "delete" ? "ลบข้อมูล" : "แก้ไขข้อมูล") : ("บันทึกข้อมูล"),
      message: action !== "create" ? (action === "delete" ? "คุณต้องการลบข้อมูลนี้ใช่หรือไม่" : "คุณต้องการแก้ไขข้อมูลนี้ใช่หรือไม่") : "คุณต้องการบันทึกข้อมูลนี้ใช่หรือไม่",
      type: action === "create" || action === "edit" ? "success" : (action === "delete" ? "error" : "warning"),
      action: action,
      data: data,
    });
  };

  const handleSave = (data: IUserSuperAdmin) => {
    props.onCreate({ ...data });
    reset();
  };

  const handleEdit = (data: IUserSuperAdmin) => {
    if (id) {
      props.onUpdate({ ...data, id: id, isDelete: data.isActive === true ? false : data.isDelete });
      reset();
    }
  };
  const handleDelete = (id: string) => {
    props.onUpdate({ id: id, isDelete: true, isActive: false });
    reset();
  };

  const onSubmit = handleSubmit(() => {
    const data = getValues();
    if (data.password !== data.confirmPassword) {
      setError("password", {
        type: "",
        message: "รหัสผ่านไม่ตรงกับยืนยันรหัสผ่าน"
      });
      setError("confirmPassword", {
        type: "",
        message: "รหัสผ่านไม่ตรงกับยืนยันรหัสผ่าน"
      });
      return;
    }
    handleOpenConfirm(id ? "edit" : "create");
  });

  const onConfirmSubmit = () => {
    if (openConfirm.action === "create") {
      handleSave(openConfirm.data);
    } else if (openConfirm.action === "edit") {
      handleEdit(openConfirm.data);
    } else if (openConfirm.action === "delete" && id) {
      handleDelete(id);
    }
    setOpenConfirm({ ...openConfirm, open: false });
  };
  watch();

  const handleCheckboxChange = (index: number, field: "isSelected" | "read" | "create" | "edit" | "delete", value: boolean, childIndex?: any) => {
    if (childIndex === undefined) {
      if (field === 'isSelected') {
        // parent menu
        fields[index].isSelected = value;
        fields[index].read = value;
        fields[index].create = value;
        fields[index].edit = value;
        fields[index].delete = value;
        // child menu
        fields[index].children?.map((child: IUserDetail) => {
          child.isSelected = value;
          child.read = value;
          child.create = value;
          child.edit = value;
          child.delete = value;
          return child;
        });
      } else {
        // parent menu
        fields[index][field] = value;
        if (value === false) {
          fields[index].isSelected = value;
          if (field === 'read') {
            fields[index].create = value;
            fields[index].edit = value;
            fields[index].delete = value;
          }
        }
      }
    } else {
      if (field === 'isSelected' && fields[index].children![childIndex]) {
        // child menu
        fields[index].children![childIndex].isSelected = value;
        fields[index].children![childIndex].read = value;
        fields[index].children![childIndex].create = value;
        fields[index].children![childIndex].edit = value;
        fields[index].children![childIndex].delete = value;
      } else {
        // child menu
        fields[index].children![childIndex][field] = value;
        if (value === false) {
          fields[index].children![childIndex].isSelected = value;
          if (field === 'read') {
            fields[index].children![childIndex].create = value;
            fields[index].children![childIndex].edit = value;
            fields[index].children![childIndex].delete = value;
          }
        }
      }
    }
    if (value === false) {
      setIsCheckAll(false);
    }
    setValue('details', fields);
  };

  const handleCheckboxByRole = (detailsInRole: IRole[]) => {
    // NOTE: flat map to get all children menu
    const flatChild: IRole[] = detailsInRole.flatMap((data: IRole) => data.children).filter((child: IRole | undefined): child is IRole => child !== undefined);

    // NOTE:Map all menu
    const _detail = fields?.map((menu: IUserDetail) => {
      // NOTE: find menu in role
      const found = detailsInRole.find((data: IRole) => data.menu === menu.value);
      let _menu = { ...menu };
      if (found) {
        _menu = { ...found, value: found.menu, isSelected: found.read };
      } else {
        _menu = { ...menu, isSelected: false, read: false, create: false, edit: false, delete: false };
      }
      // NOTE: map children menu
      _menu.children = menu?.children?.map((child: IRole) => {
        const foundChild = flatChild?.find((dataChild: IRole) => dataChild.menu === child.value);
        if (foundChild) {
          if (foundChild.read === false) {
            setIsCheckAll(false);
          }
          return {
            ...child,
            id: foundChild.id,
            isSelected: foundChild.read,
            read: foundChild.read,
            create: foundChild.create,
            edit: foundChild.edit,
            delete: foundChild.delete,
          };
        } else {
          return { ...child, isSelected: false, read: false, create: false, edit: false, delete: false };
        }
      });
      return _menu;
    });
    setValue('details', _detail);
  };

  const findChildName = (value: string | undefined, isChild?: boolean): string | null => {
    if (isChild) {
      for (const parentMenu of allMenu) {
        const foundChild = parentMenu.children?.find(c => c.value === value);
        if (foundChild) {
          return foundChild.name || null;
        }
      }
    } else {
      return allMenu.find((m) => (m.value === value))?.name || "";
    }
    return null;
  };

  const handleOpenCreateDailog = (name: string) => {
    setNameType(name);
    switch (name) {
      case "position": setOpenCreateDailog(
        {
          open: true, datas: [], type: "position", title: "สร้างตำแหน่ง"
        }
      );
        break;
      case "company": setOpenCreateDailog(
        {
          open: true, datas: _companyDatas, type: "company", title: "สร้างบริษัท"
        }
      );
        break;
    }
  };

  useEffect(() => {
    reset({
      details: allMenu as IUserDetail[]
    });
    if (id) {

      setIsCheckAll(true);
      const _findParentFalse = allMenu.find((m) => (m.read === false));
      if (_findParentFalse) {
        setIsCheckAll(false);
      }
      //NOTE:  map menu
      const _details = allMenu.map((menu: IMenu) => {
        const found = props.data?.details?.find((data: IUserDetail) => data.menu === menu.value);
        if (found) {
          menu.id = found.id;
          menu.isSelected = found.read;
          menu.read = found.read;
          menu.create = found.create;
          menu.edit = found.edit;
          menu.delete = found.delete;
          menu.value = found.menu;
          if (found.children) {
            const _children = menu?.children?.map((child: IMenu) => {
              const foundChild = found.children?.find((dataChild: IUserDetail) => dataChild.menu === child.value);
              if (foundChild) {
                if (foundChild.read === false) {
                  setIsCheckAll(false);
                }
                return {
                  ...child,
                  id: foundChild.id,
                  isSelected: foundChild.read,
                  read: foundChild.read,
                  create: foundChild.create,
                  edit: foundChild.edit,
                  delete: foundChild.delete,
                  value: foundChild.menu,
                };
              }
              return child;
            });

            menu.children = _children || [];

          }
        }
        return menu;
      });
      reset({ ...props.data, details: _details });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data, reset, id]);

  return (
    <Box component={"div"}>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={1} sm={1} md={0.5} lg={0.5}>
          <IconButton
            aria-label="delete"
            onClick={() => navigate("/admin-user")}
          >
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={11} sm={8} md={8.5} lg={8.5} display={"flex"} alignItems={"center"}>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Typography>จัดการระบบ</Typography>
            <Link component="button" onClick={() => navigate("/admin-user")}>
              รายการ
            </Link>
            <Typography variant="h6" color="text.primary">
              รายละเอียด
            </Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12} sm={3} md={3} lg={3} display={"flex"} justifyContent={"end"}>
          {props.data && (
            <Button
              variant="contained"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={() => handleOpenConfirm("delete")}
            >
              ลบข้อมูล
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Card>
            <CardContent>
              <Box component="form" mt={2} onSubmit={onSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={3}>
                    <TextField label="ชื่อ" variant="outlined" fullWidth {...register("firstName", { required: true })} error={!!errors.firstName}
                      helperText={errors.firstName ? "กรุณาระบุชื่อ" : " "}
                      InputLabelProps={{
                        shrink: getValues("firstName") ? true : undefined,
                      }} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={3}>
                    <TextField label="นามสกุล" variant="outlined" fullWidth {...register("lastName", { required: true })} error={!!errors.lastName}
                      helperText={errors.lastName ? "กรุณาระบุนามสกุล" : " "}
                      InputLabelProps={{
                        shrink: getValues("lastName") ? true : undefined,
                      }} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={3}>
                    <Controller
                      name="company"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          value={field?.value as ICompany || null}
                          options={props.company || []}
                          getOptionLabel={(option: any) => {
                            if (typeof option === 'string') {
                              return option;
                            }
                            if (option.inputValue) {
                              return option.inputValue;
                            }
                            return option.name || "";
                          }}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          filterOptions={(options, params) => {
                            const filtered = filterOptions(options, params);
                            if (params.inputValue !== '') {
                              filtered.push({
                                name: `สร้าง '${params.inputValue}'`,
                                id: 'create',
                              });
                              setName(params.inputValue);
                            }
                            return filtered;
                          }}
                          clearOnBlur
                          onChange={(event, newValue) => {
                            const selectedId = newValue?.id || "";
                            if (selectedId === "create") {
                              handleOpenCreateDailog("company");
                              field.onChange(null);
                            } else if (selectedId) {
                              field.onChange(newValue);
                              setValue("userGroup", undefined);
                              props.onFetchData && props.onFetchData(selectedId, "position");
                            }
                          }}
                          renderOption={(props, option) => {
                            const { key, ...rest } = props;
                            return (
                              <Typography key={key} {...rest} variant="body2" >{option?.id === "create" ? <Box component={'span'} sx={{ color: "primary.main", display: "flex", alignItems: "center" }}>
                                <AddCircleIcon /><Box component={'span'} ml={1}> {option.name}</Box>
                              </Box> : option.name}</Typography>
                            );
                          }}
                          renderInput={(params) => <TextField
                            error={!!errors.company}
                            helperText={errors.company ? "กรุณาระบุบริษัท" : ""}
                            {...params} label="บริษัท" />}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={3} sx={{ mt: { xs: 2, sm: 0, md: 0, lg: 0 } }}>
                    <Controller
                      name="userGroup"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          disabled={!getValues("company")?.id ? true : false}
                          value={field?.value as IUserGroup || null}
                          options={props.userGroup || []}
                          getOptionLabel={(option: any) => {
                            if (typeof option === 'string') {
                              return option;
                            }
                            if (option.inputValue) {
                              return option.inputValue;
                            }
                            return option.name || "";
                          }}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          onChange={(event, newValue) => {
                            if (newValue?.id === "create") {
                              handleOpenCreateDailog("position");
                              field.onChange(null);
                            } else if (newValue?.id) {
                              field.onChange(newValue);
                              if (newValue?.details) { handleCheckboxByRole(newValue?.details); }
                            }
                          }}
                          filterOptions={(options, params) => {
                            const filtered = filterOptions(options, params);
                            if (params.inputValue !== '') {
                              filtered.push({
                                name: `สร้าง '${params.inputValue}'`,
                                id: 'create',
                              });
                              setName(params.inputValue);
                            }
                            return filtered;
                          }}
                          renderOption={(props, option) => {
                            const { key, ...rest } = props;
                            return (
                              <Typography key={key} {...rest} variant="body2" >{option?.id === "create" ? <Box component={'span'} sx={{ color: "primary.main", display: "flex", alignItems: "center" }}>
                                <AddCircleIcon /><Box component={'span'} ml={1}> {option.name}</Box>
                              </Box> : option.name}</Typography>
                            );
                          }}
                          renderInput={(params) => <TextField
                            error={!!errors.userGroup}
                            helperText={errors.userGroup ? "กรุณาระบุตำแหน่ง" : ""}
                            {...params} label="ตำแหน่ง" />}
                          clearOnBlur
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={3} sx={{ mt: { xs: 2, sm: 2, md: 2, lg: 0 } }}>
                    <TextField label="อีเมล" variant="outlined" fullWidth {...register("email", { required: true, pattern: /^\S+@\S+$/i })} error={!!errors.email}
                      helperText={errors.email ? "กรุณาระบุอีเมล" : " "}
                      InputProps={{
                        readOnly: props.isLoading,
                      }}
                      InputLabelProps={{
                        shrink: getValues("email") ? true : undefined,
                      }} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={3}>
                    <FormControl fullWidth variant="outlined">
                      <TextField
                        label="รหัสผ่าน"
                        variant="outlined"
                        type={showPassword ? 'text' : 'password'}
                        fullWidth
                        {...register("password", { required: !id })}
                        error={!!errors.password}
                        helperText={errors.password && !errors.password.message ? "กรุณาระบุรหัสผ่าน" : (errors.password && errors.password.message ? errors.password.message : "")}
                        InputLabelProps={{
                          shrink: getValues("password") ? true : undefined,
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => handleClickShowPassword()}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>

                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={3} sx={{ mt: { xs: 2, sm: 2, md: 2, lg: 0 } }}>
                    <FormControl fullWidth variant="outlined">
                      <TextField
                        label="ยืนยันรหัสผ่าน"
                        variant="outlined"
                        type={showConfirmPassword ? 'text' : 'password'}
                        fullWidth
                        {...register("confirmPassword", { required: !id })}
                        error={!!errors.confirmPassword}
                        helperText={errors.confirmPassword && !errors.confirmPassword.message ? "กรุณาระบุยืนยันรหัสผ่าน" : (errors.confirmPassword && errors.confirmPassword.message ? errors.confirmPassword.message : " ")}
                        InputLabelProps={{
                          shrink: getValues("confirmPassword") ? true : undefined,
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => handleClickShowPassword(true)}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={3} sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Controller
                      name="isActive"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <FormControlLabel
                          control={
                            <Switch
                              checked={value}
                              onChange={onChange}
                            />
                          }
                          label="Active"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} mt={2} md={12} lg={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} sm={12} mt={2} md={12} lg={12}>
                    <Typography variant="body1">สิทธิ์การใช้งาน</Typography>
                  </Grid>
                  <Grid item xs={12} >
                    <TableContainer sx={{ height: 600, overflow: 'auto' }} >
                      <Table sx={{ minWidth: 650 }} stickyHeader >
                        <TableHead >
                          <TableRow >
                            <TableCell width={100}><Checkbox value={isCkeckAll} checked={isCkeckAll} onChange={() => {
                              setIsCheckAll(!isCkeckAll);
                              handleChangeCheckall();
                            }} /></TableCell>
                            <TableCell width={400} align="left" >เมนู</TableCell>
                            <TableCell>ดูข้อมูล</TableCell>
                            <TableCell>สร้าง</TableCell>
                            <TableCell>แก้ไข</TableCell>
                            <TableCell>ลบ</TableCell>
                            <TableCell width={200} align="center"></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {fields && fields.length > 0 &&
                            fields.map((menu, index) => (
                              <Fragment key={`menu-parent-${index}-${menu?.menu}`}>
                                <TableRow>
                                  <TableCell width={100}>
                                    <Controller
                                      name={`details.${index}.isSelected`}
                                      control={control}
                                      render={({ field }) => (
                                        <Checkbox
                                          {...field}
                                          checked={menu.isSelected ?? false}
                                          defaultValue={""}
                                          onChange={() => handleCheckboxChange(index, 'isSelected', !menu.isSelected)}
                                        />
                                      )}
                                    />
                                  </TableCell>
                                  <TableCell width={400} align="left" sx={{ fontWeight: 'bold' }}>
                                    {findChildName(id && menu.menu ? menu.menu : menu.value, false)}
                                  </TableCell>
                                  <TableCell>
                                    <Controller
                                      name={`details.${index}.read`}
                                      control={control}
                                      render={({ field }) => (
                                        <Checkbox
                                          {...field}
                                          checked={menu.read ?? false}
                                          onChange={() => {
                                            handleCheckboxChange(index, 'read', !menu.read);
                                          }}
                                        />
                                      )}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <Controller
                                      name={`details.${index}.create`}
                                      control={control}
                                      render={({ field }) => (
                                        <Checkbox
                                          {...field}
                                          checked={menu.create ?? false}
                                          onChange={() => {
                                            handleCheckboxChange(index, 'create', !menu.create);
                                          }}
                                        />
                                      )}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <Controller
                                      name={`details.${index}.edit`}
                                      control={control}
                                      render={({ field }) => (
                                        <Checkbox
                                          {...field}
                                          checked={menu.edit ?? false}
                                          onChange={() => {
                                            handleCheckboxChange(index, 'edit', !menu.edit);
                                          }}
                                        />
                                      )}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <Controller
                                      name={`details.${index}.delete`}
                                      control={control}
                                      render={({ field }) => (
                                        <Checkbox
                                          {...field}
                                          checked={menu.delete ?? false}
                                          onChange={() => {
                                            handleCheckboxChange(index, 'delete', !menu.delete);
                                          }}
                                        />
                                      )}
                                    />
                                  </TableCell>
                                  <TableCell width={200} align="center"></TableCell>
                                </TableRow>
                                {menu.children?.map((child, indexchild) => {
                                  if (!child.value || !child.name) {
                                    return null;
                                  }
                                  return (
                                    <Fragment key={`menu-child-${indexchild}-${child?.value}`}>
                                      <TableRow>
                                        <TableCell width={100}>
                                          <Controller
                                            name={`details.${index}.children`}
                                            control={control}
                                            render={({ field }) => (
                                              <Checkbox
                                                {...field}
                                                checked={menu.children![indexchild].isSelected ?? false}
                                                defaultValue={""}
                                                onChange={() => handleCheckboxChange(index, 'isSelected', !menu.children![indexchild].isSelected, indexchild)}
                                              />
                                            )}
                                          />
                                        </TableCell>
                                        <TableCell width={400} align="left" sx={{ pl: 5 }}>
                                          {`- ${findChildName(child.value, true)}`}
                                        </TableCell>
                                        <TableCell>
                                          <Controller
                                            name={`details.${index}.children`}
                                            control={control}
                                            render={({ field }) => (
                                              <Checkbox
                                                {...field}
                                                checked={menu.children![indexchild].read ?? false}
                                                onChange={() => {
                                                  handleCheckboxChange(index, 'read', !menu.children![indexchild].read, indexchild);
                                                }}
                                              />
                                            )}
                                          />
                                        </TableCell>
                                        <TableCell>
                                          <Controller
                                            name={`details.${index}.children`}
                                            control={control}
                                            render={({ field }) => (
                                              <Checkbox
                                                {...field}
                                                checked={menu.children![indexchild].create ?? false}
                                                onChange={() => {
                                                  handleCheckboxChange(index, 'create', !menu.children![indexchild].create, indexchild);
                                                }}
                                              />
                                            )}
                                          />
                                        </TableCell>
                                        <TableCell>
                                          <Controller
                                            name={`details.${index}.children`}
                                            control={control}
                                            render={({ field }) => (
                                              <Checkbox
                                                {...field}
                                                checked={menu.children![indexchild].edit ?? false}
                                                onChange={() => {
                                                  handleCheckboxChange(index, 'edit', !menu.children![indexchild].edit, indexchild);
                                                }}
                                              />
                                            )}
                                          />
                                        </TableCell>
                                        <TableCell>
                                          <Controller
                                            name={`details.${index}.children.${indexchild}.delete` as any}
                                            control={control}
                                            render={({ field }) => (
                                              <Checkbox
                                                {...field}
                                                checked={menu.children![indexchild].delete ?? false}
                                                onChange={() => {
                                                  handleCheckboxChange(index, 'delete', !menu.children![indexchild].delete, indexchild);
                                                }}
                                              />
                                            )}
                                          />
                                        </TableCell>
                                        <TableCell width={200} align="center"></TableCell>
                                      </TableRow>
                                    </Fragment>
                                  );
                                })}
                              </Fragment>
                            ))}

                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} display={"flex"} justifyContent={"end"}>
                        <Stack spacing={2} direction="row">
                          <Button
                            variant="outlined"
                            fullWidth
                            onClick={() => navigate("/expense-other")}
                          >
                            ยกเลิก
                          </Button>
                          {
                            <Button
                              variant="contained"
                              color="primary"
                              fullWidth
                              type="submit"
                              style={{ marginLeft: '8px' }}
                            >
                              บันทึก
                            </Button>
                          }
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <SimpleBackdrop open={props.isLoading ?? false} />
                <DialogConfirm
                  open={openConfirm.open}
                  type={openConfirm.type}
                  title={openConfirm.title}
                  message={openConfirm.message}
                  onClose={() => setOpenConfirm({ ...openConfirm, open: false })}
                  onSubmit={onConfirmSubmit}
                />
                <UserGroupCreateView
                  onCreate={(data) => { props?.onCreateDialog({ ...data, company: getValues("company") }, nameType); }}
                  onUpdate={(data) => Promise.resolve()}
                  onRemove={() => Promise.resolve()}
                  onClose={() => setOpenCreateDailog({ ...openCreateDailog, open: false })}
                  open={openCreateDailog?.open && openCreateDailog?.type === "position"}
                  name={name}
                />
                <DialogCreate datas={_companyDatas}
                  open={openCreateDailog?.open && openCreateDailog?.type !== "position"}
                  title={openCreateDailog?.title}
                  type={openCreateDailog?.type}
                  onClose={() => {
                    setOpenCreateDailog({ ...openCreateDailog, open: false });
                    setName("");
                  }}
                  onSubmit={(data, type) => { if (props?.onCreateDialog) { props?.onCreateDialog(data, type); } }} onFetchData={(id, type) => {
                    if (props?.onFetchData) { props?.onFetchData(id, type); }
                  }}
                  onCreateDialog={(data, type) => { if (props?.onCreateDialog) { props?.onCreateDialog(data, type); } }}
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserDetailView;
